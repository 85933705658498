import jwt_decode from 'jwt-decode'
import { keys } from './keys'
import { USER_STORAGE_KEY } from '../constants'

let token = localStorage.getItem('token')

const Auth = {
  isAuth: () => {
    return Boolean(token)
  },
  getToken: () => {
    return token
  },
  setToken: val => {
    token = val
    localStorage.setItem('token', token)
    return token
  },
  getUserData: () => {
    const user = JSON.parse(localStorage.getItem(USER_STORAGE_KEY) || '[]')

    return user
  },
  isExpired: myToken => {
    const decodeToken = jwt_decode(myToken)
    const now = new Date().getTime() / 1000
    if (now > decodeToken.exp) {
      return true
    } else {
      return decodeToken._id
    }
  },
  logout: () => {
    token = ''
    localStorage.setItem('token', '')
  },
  isAdmin: () => {
    const user = JSON.parse(localStorage.getItem(USER_STORAGE_KEY) || '[]')

    if (![keys.ROLES.admin].includes(user.role)) {
      return false
    }
    return true
  },
}

export default Auth
