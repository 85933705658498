import React from 'react'
import {Card, Icon, Image} from 'semantic-ui-react'

const TeacherCard = ({CourseType, src}) => {
  return (
    <div className="w-96">
      <div className="relative p-0">

        <img className="justify-center h-80 w-72 mx-auto" alt="no img" src={src} />

        <div>
          <div className="p-2 mb-2 flex flex-col text-center">
            <p className="text-2xl font-semibold">Amnda johns</p>
            <p className="text-primary font-medium">{CourseType}</p>
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default TeacherCard
