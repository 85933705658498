import React from 'react'
import {Card, Icon, Image} from 'semantic-ui-react'

const TitleTopTypes = {
  business: 'Business',
  accounting: 'Accounting',
  finance: 'Finance',
}

const CourseCard = ({
  CourseName,
  srcCourse,
  srcIns,
  TitleTop,
  text1 = 'X videos',
  text2 = 'X exercises',
}) => {
  let titleTopColor = ''

  titleTopColor = 'bg-yellow-500 text-white'
  // switch (TitleTop) {
  //   case TitleTopTypes.business:
  //     titleTopColor = 'bg-green-500 text-white'
  //     break

  //   case TitleTopTypes.accounting:
  //     titleTopColor = 'bg-red-600 text-white'
  //     break
  //   case TitleTopTypes.finance:
  //     titleTopColor = 'bg-purple-700 text-white'
  //     break
  //   default:
  //     titleTopColor = 'bg-yellow-500 text-white'
  //     break
  // }

  return (
    <div className="w-80 gap-4">
      <div className="CourseCard relative p-0 border-2 bg-white rounded-lg">
        <img
          className="justify-center rounded-t-lg"
          alt="no img"
          src={srcCourse}
        />

        <div
          className={`absolute top-0 right-0 ${titleTopColor} py-2 px-8 rounded-bl-md`}
        >
          {TitleTop}
        </div>

        <div>
          <div className="relative mt-4 px-2 flex justify-between">
            {/* <Image
              className=" absolute right-5 bottom-4 rounded-full h-12 w-12"
              src={srcIns}
              size="tiny"
              circular
              rounded
            /> */}
            <p className="text-primaryRedColor-hover font-semibold">
              {CourseName}
            </p>
          </div>
          {/* <div className="p-2 mb-2">
            <p className="text-primary">By Amnda johns</p>
          </div> */}
          <div className="px-2 py-4 justify-between text-primaryGrayColor">
            <div className="py-0">
              <Icon name="video" className="mr-2" />
              {text1}
            </div>
            <div className="py-0">
              <Icon name="write" className="mr-2" />
              {text2}
            </div>
            {/* <div>
              <Icon className="text-yellow-500" name="star" />
              4.5 star
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseCard
