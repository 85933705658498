import React from 'react'
import {FaLink, FaSchool, FaUserGraduate} from 'react-icons/fa'
import {Link} from 'react-router-dom'
import { useLanguage } from '../../context/languageContext'
import { content } from '../../localization/content'
import routes from '../../routes'
import { Popup } from 'semantic-ui-react'

const ClassCard = ({classname, school, voucherCode, id, subjectId, numberOfStudents}) => {
  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  return (
    // <Link to={routes.instructor.class(id, subjectId)} className="text-current">
      <div className="w-72 h-56 border-2 shadow-md hover:opacity-90  hover:text-current ">
        <Link
          to={routes.instructor.class(id, subjectId)}
          className="bg-indigo-200 h-24 cursor-pointer flex justify-center items-center"
        >
          <p className="font-semibold text-lg">{classname}</p>
        </Link>

        <div className="py-4 px-2">
          <div className="flex items-center py-2">
            <FaUserGraduate />
            <p className="ml-2">{langContent.Students_numbers}: {numberOfStudents}</p>
          </div>

          {/* <div className="flex items-center my-2">
            <FaSchool />
            <p className="ml-2">{school}</p>
          </div> */}

        <Popup trigger={
          <div className="flex items-center cursor-pointer py-2" onClick={() => { navigator.clipboard.writeText(voucherCode) }}>
            <FaLink />
            <p className="ml-2"> {voucherCode}</p>
          </div>
        }>
          <Popup.Content>
            {langContent.Copy_to_clipboard}
          </Popup.Content>
        </Popup>

        </div>
      </div>
    // </Link>
  )
}

export default ClassCard
