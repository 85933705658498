import React from 'react'
import {Link} from 'react-router-dom'
import './error.css'

const ErrorComp = () => {
  return (
    <div className="h-screen">
      {/* <h1>404 - Not Found!</h1>
    <Link to="/">Go Home</Link> */}
      <figure>
        <div class="sad-mac"></div>
        <figcaption>
          <span class="sr-text">Error 404: Not Found</span>
          <span class="e"></span>
          <span class="r"></span>
          <span class="r"></span>
          <span class="o"></span>
          <span class="r"></span>
          <span class="_4"></span>
          <span class="_0"></span>
          <span class="_4"></span>
          <div className='w-full my-8'></div>
          <span class="n"></span>
          <span class="o"></span>
          <span class="t"></span>
          <span class="f"></span>
          <span class="o"></span>
          <span class="u"></span>
          <span class="n"></span>
          <span class="d"></span>
        </figcaption>
        <h1 className="text-center mt-5 ">
          <Link className="text-primary hover:text-blue-300" to="/">
            Go Home
          </Link>
        </h1>
      </figure>
    </div>
  )
}

export default ErrorComp
