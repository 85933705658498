import React, {useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {Breadcrumb, Button, Dimmer, Loader, Message, Segment} from 'semantic-ui-react'
import { authAxios } from '../../../config/axiosConfig'
import { useLanguage } from '../../../context/languageContext'
import useAsync from '../../../hooks/useAsync'
import { content } from '../../../localization/content'
import routes from '../../../routes'
import { formatResponseError } from '../../../utils/format-response'
import { truncate } from '../../../utils/string'
import ChapterTabs from './chapter-tabs'
import HomeworkRoom from './homework-room'
import PracticeRoom from './practice-room'
import StudentPdf from './student-pdf'


const ChapterContent = () => {
  const [activeMenu, setActiveMenu] = useState('practicRoom')
  const [chapterData, setChapterData] = React.useState()
  const [forceRelood, setForceRelood] = React.useState(false)
  const history = useHistory()

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]


  const {run: archieveChapter, isLoading: archievingChapter} = useAsync()

  const { classid,subjectid, chapterid} = useParams()

  const {
    run: getchapter,
    error: errorGettingchapter,
    isLoading: gettingchapter,
  } = useAsync()

  React.useEffect(() => {
    if (chapterid)
      getchapter(authAxios.get(`/chapter/${chapterid}`)).then(({data}) => {
        setChapterData(data)
      })
  }, [chapterid, getchapter, forceRelood])

  let chName = chapterData?.chapterName || ''

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent w-full">

      {errorGettingchapter ? (
        <Message error list={formatResponseError(errorGettingchapter)} />
      ) : null}
      <Dimmer active={archievingChapter || gettingchapter} inverted>
        {/* <Loader active={true} /> */}
      </Dimmer>
      <p className="my-2 text-3xl font-semibold  ">
      {langContent.Chapter}: {truncate(chName, 55)}
      </p>

      <Breadcrumb className="my-4">
          <Breadcrumb.Section onClick={() => history.push('/')} link>
          {langContent.home}
          </Breadcrumb.Section>
          <Breadcrumb.Divider>/</Breadcrumb.Divider>
          <Breadcrumb.Section
            onClick={() => history.push(routes.voucherStudent.dashboard)}
            link
          >
            {' '}
            {langContent.My_Class}
          </Breadcrumb.Section>
          <Breadcrumb.Divider>/</Breadcrumb.Divider>
          <Breadcrumb.Section onClick={() => history.push(routes.voucherStudent.chapters.all(classid, subjectid))}> {langContent.Class} </Breadcrumb.Section>
          <Breadcrumb.Divider>/</Breadcrumb.Divider>
          <Breadcrumb.Section active>{truncate(chName, 55)} </Breadcrumb.Section>
        </Breadcrumb>

      <div className="flex justify-between items-center">
        <ChapterTabs
          activeMenu={activeMenu}
          setActiveMenu={value => setActiveMenu(value)}
        />
       
      </div>
      {/* <hr /> */}
      <div className="mt-2">
        {activeMenu === 'practicRoom' && <PracticeRoom />}
        {activeMenu === 'homeworkRoom' && <HomeworkRoom />}
        {activeMenu === 'studentPdf' && <StudentPdf />}

      </div>
      </Segment>

    </div>
  )
}

export default ChapterContent
