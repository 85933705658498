import React from 'react'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import {
  Breadcrumb,
  Button,
  Dimmer,
  Icon,
  Image,
  Loader,
  Message,
  Modal,
  Segment,
} from 'semantic-ui-react'
import {loadStripe} from '@stripe/stripe-js'
import {Elements, useStripe} from '@stripe/react-stripe-js'
import queryString from 'query-string'
import {AiOutlineCheck, AiOutlineClose, AiOutlineWarning} from 'react-icons/ai'

import routes from '../../../routes'
import Icon_Visa from '../../../assets/images/Icon_Visa.svg'
import master_card_logo from '../../../assets/images/master_card_logo.svg'
import credit_card_icon from '../../../assets/images/credit_card_icon.svg'
import useAsync from '../../../hooks/useAsync'
import {authAxios} from '../../../config/axiosConfig'
import CheckoutForm from './checkout-form'
import {formatResponseError} from '../../../utils/format-response'

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`)

const Checkout = () => {
  const history = useHistory()
  const {subjectid, planid} = useParams()
  const [addingNewCard, setAddingNewCard] = React.useState(false)
  const [statusModal, setStatusModal] = React.useState(false)

  const [paystatus, setPayStatus] = React.useState('')
  const [clientSecret, setClientSecret] = React.useState('')
  const [paymentMethods, setPaymentMethods] = React.useState([])
  const [selectedCard, setSelectedCard] = React.useState()
  const [paymentIntentId, setPaymentIntentId] = React.useState()
  const location = useLocation()

  const {
    run: getPaymentMethod,
    isLoading: loadingPaymentMethod,
    error: errorPaymentMethod,
  } = useAsync()

  const {
    run: payByCard,
    isLoading: loadingPayByCard,
    error: errorPayByCard,
  } = useAsync()

  React.useEffect(() => {
    getPaymentMethod(
      authAxios.post('/user/subscribe/init', {
        subject: subjectid,
        plan: planid,
      }),
    ).then(({data}) => {
      console.log(data)
      setPaymentMethods(data.paymentMethods || [])
      setClientSecret(data.clientSecret)
    })
  }, [getPaymentMethod, planid, subjectid])

  React.useEffect(() => {
    const {payment_intent: paymentIntentId} = queryString.parse(location.search)
    if (paymentIntentId) {
      setPaymentIntentId(paymentIntentId)
      setStatusModal(true)
    }
  }, [location.search])

  const handleSubmit = () => {
    payByCard(
      authAxios.post('/user/subscribe/charge', {
        subject: subjectid,
        plan: planid,
        paymentMethodId: selectedCard?.stripePaymentMethodId,
      }),
    ).then(({data}) => {
      setStatusModal(true)
      console.log(data)
      setPayStatus(data)
    })
  }

  return (
    <>
      <Segment className="border-none shadow-none bg-transparent w-full">
        {errorPaymentMethod ? (
          <Message error list={formatResponseError(errorPaymentMethod)} />
        ) : null}

        {errorPayByCard ? (
          <Message error list={formatResponseError(errorPayByCard)} />
        ) : null}

        <Dimmer active={loadingPaymentMethod} inverted>
          <Loader active={true} />
        </Dimmer>

        <div>
          <div className="mt-8">
            <Breadcrumb className="mt-4">
              <Breadcrumb.Section onClick={() => history.push('/')} link>
                Home
              </Breadcrumb.Section>
              <Breadcrumb.Divider>/</Breadcrumb.Divider>
              <Breadcrumb.Section
                onClick={() => history.push(routes.nonVoucherStudent.dashboard)}
                link
              >
                {' '}
                Subjects{' '}
              </Breadcrumb.Section>
              <Breadcrumb.Divider>/</Breadcrumb.Divider>
              <Breadcrumb.Section
                onClick={() =>
                  history.push(routes.nonVoucherStudent.chapters.all(subjectid))
                }
              >
                {' '}
                Chapters{' '}
              </Breadcrumb.Section>
              <Breadcrumb.Divider>/</Breadcrumb.Divider>
              <Breadcrumb.Section
                onClick={() =>
                  history.push(
                    routes.nonVoucherStudent.chapters.subscribe.pLanes(
                      subjectid,
                    ),
                  )
                }
              >
                subscription plans{' '}
              </Breadcrumb.Section>
              <Breadcrumb.Divider>/</Breadcrumb.Divider>
              <Breadcrumb.Section active> Chekout </Breadcrumb.Section>
            </Breadcrumb>
          </div>
          <p className="text-lg font-medium my-6">Checkout</p>

          {planid === 'monthly' ? (
            <div className="py-4 px-8 rounded-md border-2 border-solid border-primary mr-4">
              <div className="text-xl text-primary font-medium">
                <p>Indil's Monthly subscription</p>
              </div>

              <div className="flex items-center text-4xl font-extrabold -ml-2 my-2">
                <Icon name="eur"></Icon>
                <p className="mt-1.5">4.99</p>
              </div>

              <div className=" flex  gap-x-20">
                <div className="flex mt-4 w-64">
                  <Icon name="check" className="text-green-500"></Icon>
                  <p className="px-1">
                    The perfect way to get used to our tools
                  </p>
                </div>

                <div className="flex mt-4 w-80">
                  <Icon name="check" className="text-green-500"></Icon>
                  <p className="px-1">
                    You will be able to check 1 chapter and practice before
                    subscribing
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="py-4 px-8 rounded-md border-2 border-solid border-primary mr-4">
              <div className="text-xl text-primary font-medium">
                <p>Indil's yearly subscription</p>
              </div>

              <div className="flex items-center text-4xl font-extrabold -ml-2 my-2">
                <Icon name="eur"></Icon>
                <p className="mt-1.5">39.99</p>
              </div>

              <div className=" flex  gap-x-20">
                <div className="flex mt-4 w-64">
                  <Icon name="check" className="text-green-500"></Icon>
                  <p className="px-1">
                    The perfect way to get used to our tools
                  </p>
                </div>

                <div className="flex mt-4 w-80">
                  <Icon name="check" className="text-green-500"></Icon>
                  <p className="px-1">
                    You will be able to check 1 chapter and practice before
                    subscribing
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="mt-8 flex ml-1  ">
            <Image className=" mr-2" src={credit_card_icon} />

            <p className="text-primary text-2xl">Card</p>
          </div>

          {paymentMethods.length > 0 ? (
            <div className="flex flex-wrap my-5">
              {paymentMethods.map((k, i) => (
                <div
                  as={Button}
                  onClick={() => setSelectedCard(k)}
                  className={`w-80 h-44 my-5 mr-5 pay_card_bg rounded-lg ${
                    selectedCard?._id === k?._id
                      ? 'ring-4 ring-offset-2 ring-RingColor'
                      : ''
                  }  `}
                >
                  <div>
                    {k?.brand === 'mastercard' ? (
                      <Image className="ml-4 mt-3" src={master_card_logo} />
                    ) : (
                      <Image className="ml-4 mt-1" src={Icon_Visa} />
                    )}
                  </div>

                  <div className="text-white text-2xl px-6 mt-4 ">
                    <span>xxxx</span>
                    <span className="mx-7">xxxx</span>
                    <span className="mr-6">xxxx</span>
                    <span>{k?.last4}</span>
                  </div>

                  <div className="flex px-6 mt-4">
                    <div className="ml-auto">
                      <p className="text-gray-200 text-sm">Exp date</p>
                      <p className="text-white">
                        {k?.expMonth} - {k?.expYear}
                      </p>
                    </div>
                  </div>
                </div>
              ))}

              <Button
                onClick={() => setAddingNewCard(true)}
                className="w-80 h-44 my-5 rounded-lg bg-primaryLight border border-dashed border-primary text-xl text-primary hover:cursor-pointer"
              >
                Use another card
              </Button>
            </div>
          ) : (
            clientSecret && (
              <div className="max-w-xl">
                <Elements stripe={stripePromise} options={{clientSecret}}>
                  <CheckoutForm
                    setStatusModal={setStatusModal}
                    clientSecret={clientSecret}
                  />
                  {/* {paymentIntentId && (
                    <PaymentStatusModal
                      open={statusModal}
                      onClose={() => setStatusModal(false)}
                      clientSecret={clientSecret}
                      paymentIntentId={paymentIntentId}
                    />
                  )} */}
                </Elements>
              </div>
            )
          )}

          {paymentMethods?.length > 0 && (
            <div className="flex items-center">
              <Button
                primary
                className="my-4 px-16  mx-auto"
                disabled={!selectedCard}
                loading={loadingPayByCard}
                onClick={handleSubmit}
              >
                Checkout securely
              </Button>
            </div>
          )}

          {addingNewCard && (
            <AddNewCardModal
              subject={subjectid}
              setStatusModal={setStatusModal}
              statusModal={statusModal}
              plan={planid}
              stripePromise={stripePromise}
              open={addingNewCard}
              onClose={() => setAddingNewCard(false)}
            />
          )}

          {statusModal && (
            <PaymentStatusModal
              paymentIntentId={paymentIntentId}
              open={statusModal}
              onClose={() => setStatusModal(false)}
              paystatus={paystatus}
            />
          )}
        </div>
      </Segment>
    </>
  )
}

export default Checkout

const AddNewCardModal = ({
  open,
  onClose,
  subject,
  plan,
  stripePromise,
  setStatusModal,
  paymentIntentId,
  statusModal,
}) => {
  const [clientSecret, setClientSecret] = React.useState('')

  const {
    run: newPaymentMehtod,
    isLoading: loadingPaymentMethod,
    error: errorPaymentMethod,
  } = useAsync()

  React.useEffect(() => {
    newPaymentMehtod(
      authAxios.post('/user/subscribe/new-card', {
        subject,
        plan,
      }),
    ).then(({data}) => {
      setClientSecret(data.clientSecret)
    })
  }, [newPaymentMehtod, plan, subject])

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      closeIcon
      size="tiny"
      className="animate__animated animate__fadeInDown"
    >
      {/* <Modal.Header></Modal.Header> */}
      <Modal.Content className="flex flex-col justify-center items-center ">
        <Segment className="border-none shadow-none bg-transparent w-full">
          <Dimmer active={loadingPaymentMethod} inverted>
            <Loader active={true} />
          </Dimmer>
          {errorPaymentMethod ? (
            <Message error list={formatResponseError(errorPaymentMethod)} />
          ) : null}

          <div className="">
            <p className="text-center font-medium text-lg text-primary">
              Add a new credit card
            </p>
          </div>
          <div className=" max-w-lg min-w-full">
            {clientSecret && (
              <Elements stripe={stripePromise} options={{clientSecret}}>
                <CheckoutForm
                  setStatusModal={setStatusModal}
                  clientSecret={clientSecret}
                />
                {/* {paymentIntentId && (
                  <PaymentStatusModal
                    open={statusModal}
                    onClose={() => setStatusModal(false)}
                    clientSecret={clientSecret}
                    paymentIntentId={paymentIntentId}
                  />
                )} */}
              </Elements>
            )}
          </div>
        </Segment>
      </Modal.Content>
    </Modal>
  )
}

const PaymentStatusModal = ({open, onClose, paymentIntentId, paystatus}) => {
  const {subjectid} = useParams()
  const history = useHistory()
  const [payStatus, setPayStatus] = React.useState('')
  const [payStatusMessage, setPayStatusMessage] = React.useState('')

  // console.log(paymentIntentId)

  const {
    run: getPaymentState,
    isLoading: loadingPaymentState,
    error: errorPaymentState,
  } = useAsync()

  React.useEffect(() => {
    if (paystatus) {
      switch (paystatus?.status) {
        case 'succeeded':
          setPayStatus('success')
          setPayStatusMessage('Thank you, subscription successfully done.')
          // setStatusModal(true)
          console.log('success')
          break
        case 'processing':
          setPayStatus('processing')
          setPayStatusMessage(
            'Payment proccessing, well updated you once the payment is received.',
          )
          break
        case 'required_payment_method':
          setPayStatus('required_payment_method')
          setPayStatusMessage(
            'payment failed, please try another payment method.',
          )
          break
        default:
          break
      }
    }
  }, [paystatus])

  React.useEffect(() => {
    if (paymentIntentId) {
      getPaymentState(
        authAxios.get(
          `/user/subscribe/status?paymentIntentId=${paymentIntentId}`,
        ),
      ).then(({data}) => {
        console.log(data)
        switch (data?.status) {
          case 'succeeded':
            setPayStatus('success')
            setPayStatusMessage('Thank you, subscription successfully done.')
            // setStatusModal(true)
            console.log('success')
            break
          case 'processing':
            setPayStatus('processing')
            setPayStatusMessage(
              'Payment proccessing, well updated you once the payment is received.',
            )
            break
          case 'required_payment_method':
            setPayStatus('required_payment_method')
            setPayStatusMessage(
              'payment failed, please try another payment method.',
            )
            break
          default:
            break
        }
      })
    }
  }, [getPaymentState, paymentIntentId])

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      size="tiny"
      // closeIcon
    >
      <Modal.Content className="flex flex-col h-60 items-center relative">
        <Segment className="border-none shadow-none bg-transparent w-full flex flex-col h-60 items-center relative">
          {errorPaymentState ? (
            <Message error list={formatResponseError(errorPaymentState)} />
          ) : null}

          <Dimmer active={loadingPaymentState} inverted>
            <Loader active={true} />
          </Dimmer>

          <div>
            {payStatus === 'success' ? (
              <>
                <AiOutlineCheck
                  className="text-6xl text-green-400 shadow-lg rounded-full h-20 w-20 p-2 mx-auto"
                  circular
                />

                <div className="mt-6">
                  <p className="my-3 font-medium">{payStatusMessage}</p>
                </div>
              </>
            ) : payStatus === 'processing' ? (
              <>
                <AiOutlineWarning
                  className="text-6xl text-yellow-300 shadow-lg rounded-full h-20 w-20 p-2 mx-auto"
                  circular
                />

                <div className="mt-6">
                  <p className="my-3 font-medium">{payStatusMessage}</p>
                </div>
              </>
            ) : (
              <>
                <AiOutlineClose
                  className="text-6xl text-red-500 shadow-lg rounded-full h-20 w-20 p-2 mx-auto"
                  circular
                />

                <div className="mt-6">
                  <p className="my-3 font-medium">{payStatusMessage}</p>
                </div>
              </>
            )}
          </div>

          <div className="">
            <Button
              onClick={() => {
                onClose()
                history.replace(
                  routes.nonVoucherStudent.chapters.all(subjectid),
                )
              }}
              className="border border-solid border-primary bg-transparent"
            >
              Continue to proceed
            </Button>
          </div>
        </Segment>
      </Modal.Content>
    </Modal>
  )
}
