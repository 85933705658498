import React from 'react'
import {Link, useParams} from 'react-router-dom'
import {
  Dimmer,
  Icon,
  Image,
  Loader,
  Message,
  Segment,
  Step,
} from 'semantic-ui-react'
import {BsCheck} from 'react-icons/bs'

import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'
import useAsync from '../../../hooks/useAsync'
import {authAxios} from '../../../config/axiosConfig'
import {formatResponseError} from '../../../utils/format-response'
import checked from '../../../assets/images/checked.svg'
import redCircle from '../../../assets/images/redCircle.svg'
import pending from '../../../assets/images/pending.svg'
import lock from '../../../assets/images/lock.svg'
import routes from '../../../routes'

const TeacherHomeworkRoomReview = ({level, onUpdate}) => {
  const [levelData, setLevelData] = React.useState(false)

  const {subjectid, chapterid, classid} = useParams()

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  const {
    run: getLevel,
    error: errorGettingLevel,
    isLoading: gettingLevel,
  } = useAsync()

  React.useEffect(() => {
    getLevel(
      authAxios.get(
        // `/student/chapter/view/homework/?chapter=${chapterid}&classId=${classid}&type=${level}`,
        `/chapter/homework/${chapterid}?type=${level}`,
      ),
    ).then(({data}) => {
      console.log({levelData: data})
      setLevelData(data)
    })
  }, [chapterid, classid, getLevel, level, onUpdate])

  return (
    <div className="p-2 border-2 solid rounded-md">
      <Segment className="border-none shadow-none  bg-transparent">
        {errorGettingLevel ? (
          <Message error list={formatResponseError(errorGettingLevel)} />
        ) : null}

        <Dimmer className="h-full" active={gettingLevel} inverted>
          <Loader active={true} />
        </Dimmer>

        <Step.Group
          vertical
          className="border-none shadow-none gap-y-1 bg-transparent w-full"
        >
          {levelData?.length > 0 &&
            levelData?.map((s, idx) => (
              <>
                <div className="flex items-start -my-1">
                  <Image
                    src={lock}
                    className=" rounded-full p-0 bg-white -mr-1.5"
                  />

                  <div
                    className={`min-w-full ${
                      levelData?.length - 1 !== idx
                        ? 'border-l border-gray-300 '
                        : ''
                    }  pl-4`}
                  >
                    <p className="w-full -mt-1 flex">
                      {s?.exercise?.type === 'wex'
                        ? langContent.WEX
                        : langContent.test}{' '}
                      {idx + 1}
                      <>
                        {s?.exercise?.type === 'wex' ? (
                          <Link
                            className="mt-0 flex cursor-pointer mx-2"
                            to={routes.instructor.exercise.wexlock(
                              classid,
                              subjectid,
                              chapterid,
                              s?.exercise._id,
                            )}
                          >
                            {s?.exercise?.name}
                          </Link>
                        ) : (
                          <Link
                            className="mt-0 flex cursor-pointer mx-3"
                            to={routes.instructor.exercise.testlock(
                              classid,
                              subjectid,
                              chapterid,
                              s?.exercise._id,
                            )}
                          >
                            {s?.exercise?.name}
                          </Link>
                        )}
                      </>
                    </p>
                    <p className="flex text-primaryRed mb-4">
                    {s?.lock?.lock?.length > 0 && <> {langContent.You_need_to_pass} {'('} </>}
                      {s?.lock?.lock?.length > 0 &&
                        s?.lock?.lock?.map((l, i) => (
                          <>
                            {' '}
                            {l?.name} {s?.lock?.lock?.length - 1 === i ? null : ', '}
                          </>
                        ))}{' '}
                      {s?.lock?.lock?.length > 0 && (
                        <> {')'} {langContent.to_unlock_this_exercice} </>
                      )}
                    </p>
                  </div>
                </div>
              </>
            ))}
        </Step.Group>
      </Segment>
    </div>
  )
}

export default TeacherHomeworkRoomReview
