import React, {useState} from 'react'
import useLocalStorage from '../../../hooks/use-local-storage'
import StudentChapters from './student-chapters'
import StudentExercises from './student-exercises'
import StudentRoomTabs from './student-room-tabs'

const StudentRoom = () => {
  const [activeMenu, setActiveMenu] = useState('studentChapters')

  // const [activeMenu, setActiveMenu] = useLocalStorage(
  //   'activeMenu',
  //   'studentChapters',
  // )

  return (
    <div>
      <div className="">
        <StudentRoomTabs
          activeMenu={activeMenu}
          setActiveMenu={value => setActiveMenu(value)}
        />
      </div>

      <div className="mt-2 ">
        {activeMenu === 'studentChapters' && <StudentChapters />}
        {activeMenu === 'studentExercises' && <StudentExercises />}
      </div>
    </div>
  )
}

export default StudentRoom
