export const content = {
  en: {
    I_agree_to: 'I agree to',
    I_have_16_and_I_agree_to:
      'I confirm that I am over 16 years old and I accept the',
    I_agree_to_Privacy_and_Policy: 'the Privacy Policy',
    Privacy_and_Policy: 'Privacy Policy',
    Download_user_guide: 'Download',
    General_conditions: 'General conditions',
    User_guide: 'User guide',
    Resend_verification_email: 'Resend verification email',
    Copy_to_clipboard: 'click to copy to clipboard',
    Your_request_has_been_accepted: 'Your request has been accepted',
    Your_request_has_been_sent_to_the_admin:
      'Your request has been sent to the admin',
    It_might_take_up_to_48_hours_to_receive_a_response:
      'It might take up to 48 hours to receive a response',
    Please_check_your_email:
      "Please check your email for a confirmation. Don't forget to check your “junk mail” folder or “spam” folder. If you do not see the email in a few minutes please send us an email to contact@indil.education",
    Videos: 'Videos',
    home: 'Home',
    about_us: 'About us',
    contact_us: 'Contact us',
    login: 'Log In',
    sign_up: 'Sign Up',
    we_give_u_knowledge: 'Learning accounting has never been so exciting',
    whether_you_are_a_high_school1:
      'Master accounting with engaging activities and video lessons.',
    whether_you_are_a_high_school2:
      'Whether you are a high school student, teacher or pursuing a degree in finance, business or accounting, this platform is specially designed for you.',
    whether_you_are_a_high_school3:
      'Replace your school books with INDIL and discover an exciting new way to learn.',
    school: 'SCHOOL',
    quizzes: 'Quizzes',
    about_indil: 'ABOUT INDIL',
    whether_you_are_a_high1:
      'We provide learners and teachers with an intelligent tutorial system that promotes individual and interactive learning to learn Luxembourgish accounting.',
    whether_you_are_a_high2:
      'Our platform allows you to explore theory through quality explanatory videos, and then put your knowledge into practice through practical exercises suitable for all levels.',
    whether_you_are_a_high3:
      'Pedagogical and personalized feedback improves your learning path.',
    whether_you_are_a_high4:
      'Join our learning community and learn accounting in an exciting and effective way.',
    why_indil: 'WHY INDIL ?',
    finance_classes: 'Finance classes',
    finance_classes_p:
      "You can learn on your own pace,anytime you want, either you'll study",
    accounting_classes: 'Accounting Classes',
    accounting_classes_p:
      "You can learn on your own pace,anytime you want, either you'll study",
    Business_classes: 'Business Classes',
    Business_classes_p:
      "You can learn on your own pace,anytime you want, either you'll study",
    EXPLORE_OUR_SUBJECTS: 'EXPLORE OUR SUBJECTS ',
    TESTIMONIALS: 'TESTIMONIALS',
    My_Class: 'My Class',

    Student_room: "Student's room",
    welcome: 'Welcome',
    Student: 'Student',
    Add_voucher: 'Add voucher',
    Logout: 'Logout',
    Delete: 'Delete',
    Enter_voucher_code: 'Enter voucher code to access your classe',
    Enter_voucher_code_placeHolder: 'Enter voucher code',
    Submit: 'Submit',
    Cancel: 'Cancel',

    Class: 'Class',
    Chapter: 'Chapter',
    Chapters: 'Chapters',
    ChaptersInteractives: 'Interactive chapters',

    Practic_Room: 'Practice Room',
    Homework_room: 'Homework Room',
    Section: 'Section',
    Skill_competency: 'Skill (competency)',
    Video: 'Video',
    PEX: 'Theory',

    PEX_content: 'PEX content',
    Start_PEX: 'Start Exercice',
    Type: 'Type',
    Question: 'Question',
    Competency: 'Competency',
    Are_you_sure_you_want_to_start: 'Are you sure you want to start?',

    Cancel_exercice: 'Cancel ( to exit the exercice)',
    Correct_answer: 'Correct answer',
    Incomplete_answer: 'Incomplete answer',
    Your_score_is: 'Your score is',
    Your_need_to_try_it_again: 'Your need to try it again',
    Question_finished: 'Question finished',
    Your_can_proceed_to_the_next_PEX: 'You can proceed to the next exercice',

    Basic_Level: 'Basic Level',
    Intermediate_level: 'Intermediate level',
    Advanced_level: 'Advanced Level',
    test: 'Test',
    WEX: 'Exercice',
    You_need_to_pass: 'You need to pass',
    to_unlock_this_exercice: 'to unlock this exercice',

    WEX_content: 'WEX content',
    Start_WEX: 'Start Exercise',

    Student_chapters: 'Student chapters',
    Student_Exercices: 'Student Exercices',
    Chapters_name: 'Chapter’s name',
    Progress: 'Progress',

    PEXs_completed: 'PEXs completed',
    WEX_completed: ' WEX completed',
    Tests_completed: 'Tests completed',
    Name: 'Name',
    Level: 'Level',
    Score: 'Score',
    Duration: 'Duration',
    Hints: 'Hints',
    Not_done_yet: 'Not done yet',
    beginner: 'beginner',
    intermediate: 'intermediate',
    advanced: 'advanced',

    Log_in_to_your_account: 'Log in to your account',
    Student_voucher: 'Student voucher',
    Student_non_voucher: 'Student non voucher',
    I_am_student: 'I am a student',
    Instructor: 'Instructor',
    I_am_instructor: 'I am an instructor',

    Email_Address: 'Email Address',
    Password: 'Password',
    Login_sign: 'Login',
    Dont_have_an_account_on_INDIL_Register:
      'Don’t have an account on INDIL? Register',
    Successfully_loged_in: 'Successfully loged in',

    Profile: 'Profile',
    Search: 'Search',
    Teacher: 'Teacher',
    Create_new_class: 'Create new class',
    Students_numbers: 'Students numbers ',
    Dashboard: 'Dashboard',

    Students_info: 'Class overview',
    Chapter_progress: 'Chapter progress',
    Exercise: 'Completed Exercices',
    Subject_content: 'Chapters',
    PDFs: 'PDFs',

    Students_name: 'Students name',
    Average_PEX_score: 'Average Theory score',
    Average_WEX_score: 'Average Exercice score',
    Average_test_score: 'Average test score',
    Action: 'Action',

    teacher_chapter_progress_p_1:
      'This view will help you better understand and check a global overview',
    teacher_chapter_progress_p_2:
      'You can also check the top 5 frequent errors related to this chapter',

    Exercice_name: 'Exercice name',
    Number_of_students: 'Number of students',
    Avergae_score: 'Average score',
    Avergae_number_of_hints: 'Average number of hints',
    Avergae_time: 'Average time',

    Pex: 'Theory',
    Test: 'Test',

    Overview: 'Overview',
    Question_breakdown: 'Question breakdown',

    Average_time_taken_by_students: 'Average time',
    Status: 'Status',

    Zero: 'Zero',
    One: 'One',
    Two: 'Two',
    more: 'more',
    students: 'students',

    hint: 'Hint',

    //amortization
    Fixed_Assets: 'Fixed Assets',
    Acquistion_date: 'Acquistion date',
    Acquistion_date_value: 'Enter date: (dd-mm-yyyy)',
    Original_value: 'Original value',
    Depreciation_rate: 'Depreciation rate',
    Year_column: 'Year column',
    Net_value_at_beginning_of_the_year: 'Net value at beginning of the year',
    Loss_in_value_per_year: 'Loss in value per year',
    Accumulated_loss: 'Accumulated loss',
    Net_value_at_the_end_of_the_year: 'Net value at the end of the year',
    Enter_year: 'Enter year',
    Enter_net_value_beginning: 'Ennter net value beginning',
    Enter_loss_in_value: 'Enter loss in value',
    Enter_accumulated_loss: 'Enter accumulated loss',
    Enter_net_value_end: 'Enter net value end',

    //journal
    Account_Name: 'Account Name',
    Account_Number: 'Account Number',
    Debit_Value: 'Debit Value',
    Credit_Value: 'Credit Value ',
    Account_name_on_debit: 'Account name on debit',
    Account_name_on_credit: 'account name on credit ',
    Enter_Date: 'Enter Date ',
    Enter_wording: 'Enter wording',

    //t-account
    Active: 'Active',
    Liabilities: 'Liabilities',
    Charges: 'Charges',
    Products: 'Products',

    My_Portal: 'My Portal',
    My_Profile: 'My Profile',
    Edit_Profile: 'Edit Profile',
    Personal_Information: 'Personal Information',
    First_Name: 'First Name',
    Last_Name: 'Last Name',
    School_Name: 'School Name ',
    Confirm_password: 'Confirm password ',
    Register: 'Register',
    Have_an_account: 'Have an account?',
    Subject: 'Subject',
    School: 'School',
    University: 'University',
    Country: 'Country',

    testimonials_p_1:
      ' I love indil, This platform has helped me a lot when I first started my major in finance ',
    testimonials_p_2:
      ' I love indil, This platform has helped me a lot when I first started my major in finance ',
    testimonials_p_3:
      ' I love indil, This platform has helped me a lot when I first started my major in finance ',

    I_have_a_voucher: 'I have a voucher',
    I_dont_have_a_voucher: 'I don’t have a voucher ',
    Create_Account: 'Create Account',
    Full_Name: 'Full Name',

    //balance-sheet
    Balance_au: 'Balance au',
    account_name: 'account name',
    value: 'Value',

    register: 'Register',
    fullName: 'Full Name',
    email: 'Email Address',
    confirmPassword: 'Confirm Password',
    required: 'This Field is Required',
    requiredTerms: 'You must accept the terms and conditions',
    notMatch: 'Password is not match',
    userName: "User Name (can't be changed later)",
    studentRegisterCheckBox1:
      'Email me platform updates, recommended courses, and the latest news',
    studentRegisterCheckBox2:
      'By clicking on register, I agree to the Terms of Service and Privacy Policy',
    studentRegisterCheckBox3: 'I agree that I am over 12 years old',
    instructorDegree: 'Degree',
    university: 'university',
    faculty: 'faculty',
    specialization: 'Specialization',
    phoneNumber: 'Phone Number',
    homePage: 'Home Page',
    aboutUs: 'About us',
    contact: 'Contact',
    confirm_title: 'Delete confirmation',
    confirm_delete_account: 'Are you sure to  delete your account permanently?',
    account_delete_success: 'your account is deleted successfully',
    account_delete_error: 'can not delete your account',
    yes: 'Yes',
    no: 'No',
    privacyDetails: {
      menuItemTitle: 'Politique de Protection des Données',
      title: 'Politique de Protection des Données à Caractère Personnel',
      body: `
      <p><b>Smart Education S.A.R.L.-S.</b>, ayant son siège d’activité à L-1911, 9, rue du Laboratoire, Luxembourg, inscrite au Registre de Commerce et des Sociétés, Luxembourg sous le numéro B278733 (« <b>Smart Education</b> ») est responsable du traitement des données personnelles utilisées aux fins d’offrir les fonctionnalités de son programme d’enseignement dispensé via sa plateforme digitale (« <b>Plateforme</b> »)</p>
      <br />
      <p>Cette Politique de Protection des Données (« <b>Politique de Protection des Données</b> ») a pour objet d’informer toutes les personnes physiques concernées, notamment les élèves, le(s) représentant(s) des élèves, les enseignants, ci-après dénommées « utilisateurs », « Vous » ou « Votre/Vos », sur la manière dont Smart Education collecte, utilise et conserve de telles données personnelles dans le cadre de son programme d’enseignement à travers l’utilisation d’une plateforme digitale et sur les moyens dont chacune de ces personnes physiques concernées dispose pour contrôler cette utilisation.</p>
      <br />
      <p><b>Article 1 -	Données collectées</b></p>
      <br />
      <p>Les données collectées relatives à l'identification, à l'identité et à la scolarité sont collectées directement auprès des élèves ou de leurs représentants légaux et auprès des enseignants au moment de l’inscription à la Plateforme, à la création d’un compte, au cours de la connexion à ce compte respectivement compte utilisateur ou compte enseignant, lors de la mise à jour des informations et de la souscription à un service.</p>
      <br />
      <p>Les données personnelles peuvent inclure ce qui suit :</p>
      <br />
      <ul style="padding-left: 40px; list-style: disc;">
        <li>Pseudonyme / identifiant / User id</li>
        <li>Prénom et nom ;</li>
        <li>Adresse électronique ;</li>
        <li>Classe et cours suivis ;</li>
        <li>En cas d’organisation : type d’organisation (société, école, association, etc.), nom de l’organisation, taille de l’organisation, service et intitulé du poste ;</li>
        <li>Paramètres et mot de passe du compte,</li>
        <li>Données d’identification électronique collectées par les cookies présents sur la Plateforme dans le cas où vous les acceptez (adresse IP, moment de connexion, pages visitées, type de système d’exploitation, type de connexion, type d’équipement mobile) ; pour plus d’information sur les cookies, veuillez consulter la rubrique dédiée à ce sujet,  </li>
        <li>Données liées au contenu éducatif généré par les visiteurs</li>
        <li>Et toute autre donnée personnelle pouvant s’avérer pertinente aux fins énoncées ci-après .</li>
      </ul>
      <br />
      <p>Dans tous les cas, les données personnelles collectées se limitent aux données nécessaires aux finalités exposées dans cette Politique de Protection des Données. Ci-après, les « <b>Données</b> »</p>
      <br />
      <p>Certaines fonctionnalités et caractéristiques de notre Plateforme, ne peuvent être utilisées que si vous communiquez à Smart Education certaines données personnelles lorsque vous visitez ou utilisez notre Plateforme. Vous êtes libre de fournir ou non tout ou partie de vos données personnelles. Toutefois, si vous choisissez de ne pas les fournir, une telle décision pourrait empêcher la réalisation ou la réalisation satisfaisante des finalités, certains services et certaines fonctionnalités de notre Plateforme pourraient ne pas fonctionner correctement et/ou vous vous verrez refuser l’accès à certaines pages de notre Plateforme.</p>
      <br />
      <p><b>Article 2 -	Objectifs du traitement des données (finalités) et bases légales</b></p>
      <br />
      <p>La base légale d’un traitement de données est licite si au moins l’une des 6 conditions de l’article 6 (Licéité du traitement) RGPD est remplie. Selon le traitement, l’activité de Smart Education est fondée sur plusieurs bases légales distinctes:</p>
      <br />
      <p>– Le traitement des données personnelles de l’utilisateur qui « a consenti au traitement de ses données à caractère personnel pour [les] finalités spécifiques » suivantes:</p>
      <br />
      <ul style="padding-left: 40px; list-style: disc;">
        <li>Permettre à Smart Education de mesurer la fréquentation de la Plateforme via les données collectées par les cookies.</li>
      </ul>
      <br />
      <p>– Le traitement des données personnelles est réalisé pour les besoins de l’exécution du contrat de services à la base de la mise à disposition d’un outil pédagogique aux élèves :</p>
      <br />
      <ul style="padding-left: 40px; list-style: disc;">
        <li>Permettre aux élèves ainsi qu'à leurs responsables d'accéder, à un service numérique consistant en une plateforme virtuelle comme support à un programme d’enseignement en comptabilité avec l’accord du Ministère chargé de l'Éducation nationale, de l’Enfance et de la Jeunesse ;</li>
        <li>Permettre au personnel enseignant habilité par le Ministère chargé de l'Éducation nationale, de l’Enfance et de la Jeunesse d’administrer les comptes des utilisateurs en fonction des services auxquels ils peuvent prétendre, en accédant eux-mêmes à leur compte enseignant au moyen d’un identifiant unique IAM.</li>
        <li>Evaluer les compétences et les performances de l’élève ; </li>
        <li>Accéder à l’ensemble des caractéristiques et options offertes par la Plateforme (notamment les fonctionnalités disponibles sur abonnement);</li>
        <li>Bien administrer les services proposés via la Plateforme, </li>
        <li>Permettre Smart Education et les utilisateurs de sa Plateforme d’échanger, de demander et d’obtenir des informations sur les fonctionnalités de la Plateforme et les données produites par les élèves et les enseignants à l’occasion de l’utilisation de la Plateforme (exercices, évaluations, corrections);</li>
        <li>Permettre une utilisation interactive et personnalisée de notre Plateforme ;</li>
        <li>Permettre de vous inscrire (ou d’inscrire l’élève dont vous êtes l’enseignant ou le représentant) à des classes;</li>
      </ul>
      <br />
      <p>– Le traitement des données personnelles est basé sur les intérêts légitimes de Smart Education à améliorer l’expérience des utilisateurs de la Plateforme :</p>
      <br />
      <ul style="padding-left: 40px; list-style: disc;">
        <li>Mettre à disposition la documentation spécialisée et une assistance technique et pédagogique adaptée selon les informations collectées auprès des comptes des élèves et des enseignants Aux fins d’une bonne administration des services proposés via la Plateforme</li>
        <li>Mettre à disposition la documentation spécialisée et une assistance technique et pédagogique adaptée selon les informations collectées auprès des comptes des élèves et des enseignants;</li>
        <li>Permettre à Smart Education de gérer des enquêtes de satisfaction ;</li>
        <li>Permettre à Smart Education de gérer ses activités de recherche et développement : demandes de formations spécifiques, incidents, les questions de qualité, tests, etc. ;</li>
        <li>Utiliser l’adresse de courrier électronique à des fins administratives ou d’autres objectifs ne relevant pas du marketing (par exemple, pour vous notifier d’importants changements apportés à la Plateforme).</li>
      </ul>
      <br />
      <p><b>Article 3 -	Cookies</b></p>
      <br />
      <p>Notre Plateforme peut utiliser des cookies ou d’autres technologies susceptibles de collecter ou conserver des données personnelles. Les cookies sont des fichiers textes stockés et utilisés pour enregistrer des données personnelles et non personnelles concernant votre navigation sur notre Plateforme.  Les cookies peuvent être permanents (et rester sur votre ordinateur après votre déconnexion de notre Plateforme afin d’être utilisés lors de vos visites ultérieures sur notre site) ou temporaires (et disparaître dès que vous vous déconnectez de notre site).</p>
      <br />
      <p>Smart Education utilise des cookies afin d’améliorer votre expérience d’utilisateur, notamment en :</p>
      <br />
      <ul style="padding-left: 40px; list-style: disc;">
        <li>Permettant à un service de reconnaître votre matériel, ainsi vous n’aurez pas à fournir plusieurs fois les mêmes informations afin d’exécuter la même tâche ;</li>
        <li>Reconnaissant le nom d’utilisateur et le mot de passe que vous avez déjà fournis afin que vous n’ayez pas à les refournir sur chaque page web qui en ferait la demande ;</li>
        <li>Analyser le trafic et les données de connexion votre adresse IP, la date et l’heure à laquelle vous accédez à la Plateforme ou à laquelle l’utilisez, type de système d’exploitation, type de connexion, type d’équipement mobile.</li>
        <li>Décompter les utilisateurs à l’année. </li>
      </ul>
      <br />
      <p>Vous pouvez refuser systématiquement les cookies (y compris les cookies utilisés à des fins de mesure d’audience) en cliquant sur l’option « refuser tous les cookies » dans la bannière qui s’affiche en bas de votre écran, ou sélectionner les cookies que vous souhaitez accepter, en cliquant sur l’option « paramétrer les cookies ». Toutefois, si vous refusez les cookies strictement essentiels, certaines fonctionnalités et caractéristiques de notre site pourraient ne pas fonctionner correctement et vous pourriez ne pas être en mesure de pouvoir accéder à certaines parties ou certains services de notre Plateforme.</p>
      <br />
      <p>Une liste des cookies connus sur ce site peut être obtenue en cliquant sur le lien suivant: [Politique en matière de cookies]. Cette liste est contrôlée et mise à jour régulièrement.  </p>
      <br />
      <p>Veuillez noter que vous êtes également libre de retirer votre consentement à tout moment, en changeant les paramètres sélectionnés sur la bannière. Le retrait de votre consentement n’affectera pas la légalité des données déjà traitées. </p>
      <br />
      <p><b>Article 4 -	Droits de la personne concernée</b></p>
      <br />
      <p>Smart Education a mis en place des dispositifs de protection des données personnelles adaptés pour veiller à ce que les données personnelles soient utilisées conformément aux finalités ci-dessus et pour assurer leur exactitude et leur mise à jour.</p>
      <br />
      <p>En vertu du règlement européen sur la protection des données personnelles, en abrégé, le « RGPD» 2016/679 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données, et abrogeant la directive 95/46/CE (règlement général sur la protection des données), Vous disposez des droits suivants :</p>
      <br />
      <ol style="padding-left: 40px; list-style: lower-alpha;">
        <li>Obtenir la confirmation de l’existence ou de l’absence de données personnelles vous concernant, et leur communication sous forme intelligible ;</li>
        <li>Obtenir la mise à jour, la rectification de vos données ou compléter ces données ;</li>
        <li>Obtenir la suppression de vos données dans certains cas (ex. : lorsque ces données ne sont plus nécessaires au regard des finalités pour lesquelles elles avaient été collectées ou lorsqu’elles ont fait l’objet d’un traitement illicite) ;</li>
        <li>Obtenir la limitation du traitement de vos données dans certaines circonstances ;</li>
        <li>Recevoir vos données dans un format structuré, couramment utilisé et lisible par une machine (« droit à la portabilité »).</li>
        <li>De vous opposer, pour tout ou une partie, au traitement des données personnelles vous concernant, lorsque leur traitement est fondé sur les intérêts légitimes du responsable de traitement ;</li>
        <li>De retirer votre consentement et de demander la suppression de votre compte. Vous pouvez exercer ce droit à tout moment en envoyant un Email à l’adresse suivante : contact@indil.education </li>
      </ol>
      <br />
      <p>En cas de demande manifestement infondée ou excessive, Smart Education se réserve le droit de facturer au demandeur un montant raisonnable pour couvrir les frais liés aux opérations d’accès, de rectification ou de suppression. Smart Education se réserve le droit de refuser l’accès aux Données dans les cas particuliers prévus par les législations et réglementations applicables.</p>
      <br />
      <p><b>Article 5 -	Sécurité des données </b></p>
      <br />
      <p>Les données personnelles visées par cette Politique de Protection des Données sont traitées de façon à garantir une sécurité appropriée des données à caractère personnel, y compris la protection contre le traitement non autorisé ou illicite et contre la perte, la destruction ou les dégâts d'origine accidentelle, à l'aide de mesures techniques ou organisationnelles appropriées.</p>
      <br />
      <p>Cependant, il est à rappeler qu’aucun mode de transmission sur Internet et aucun mode de stockage des informations électroniques n’est totalement fiable. En conséquence, Smart Education ne peut pas garantir ni la sécurité des données qui leur sont transmises ni celle des données personnelles qu’elle conserve. Les incidents de sécurité qui surviendraient malgré les précautions prises vous seront toutefois notifiés dans le strict respect de la réglementation applicable.</p>
      <br />
      <p>Toutes les données personnelles étant confidentielles, leur accès est limité aux collaborateurs, prestataires et agents de Smart Education qui en ont besoin dans le cadre de l’exécution de leur mission. Toutes les personnes ayant accès à vos données personnelles sont tenues par une obligation de confidentialité. </p>
      <br />
      <p>Toutefois, il importe que vous fassiez preuve de prudence pour empêcher tout accès non autorisé à ces données personnelles, Vous êtes responsable de la confidentialité du mot de passe et des informations figurant dans le compte utilisateur. Par conséquent, vous devez vous assurer de fermer votre session en cas d’utilisation partagée d’un même ordinateur.</p>
      <br />
      <p><b>Article 6 -	Partages et Transferts des Données</b></p>
      <br />
      <p>Smart Education ne transmet aucune donnée personnelle à des tiers, sauf si elles sont nécessaires à la dispense de l’enseignement et du fonctionnement de la Plateforme. L’accès régulier aux données des utilisateurs est réservé aux personnes (groupes) suivantes :</p>
      <br />
      <ol style="padding-left: 40px; list-style: auto;">
        <li>Les élèves concernant leurs propres données personnelles, paramètres et données de formation. </li>
        <li>Enseignants et administrateurs de l’école dans les données personnelles, les paramètres et les données de formation des élèves </li>
        <li>Auteurs, personnel en charge du développement et de l’adaptation des supports d’apprentissage axés sur la demande (vidéos pédagogiques, exercices interactifs) relevant du Ministère de l’Education Nationale, de la Jeunesse et de l’Enfance avec la coopération duquel le programme d’enseignement a été mis en place.</li>
        <li>dans les écoles : les directeurs d'école et les enseignants habilités ;</li>
        <li>dans les établissements scolaires du second degré : les chefs d'établissement et les personnels enseignants et administratifs habilités ;</li>
        <li>au niveau académique : les équipes académiques d'assistance ;</li>
        <li>Au niveau national : les équipes nationales d'assistance et les personnes habilitées au sein du CGIE ;</li>
        <li>Les services tiers en lien avec le Ministère de l’Éducation nationale, de l’Enfance et de la Jeunesse : pour les seules données strictement nécessaires au fonctionnement de leurs services ;</li>
        <li>Les sous-traitants habilités pour les missions de maintenance et d’exploitation de technologie de l’information :
          <ul>
            <li>- <b>OVH SAS</b>: 2 rue Kellermann 59100 Roubaix, France (Service de fourniture de réseau, d’hébergement de serveurs et de distribution de contenu)</li>
            <li>- <b>IT Craft YSA GmbH</b>: Panoramastrasse 1, 10178, Berlin, Germany, with registered company tax identification number HRB 18206. (Société de développement de logiciels)</li>
          </ul>
        </li>
        <li>Le sous-traitant fournisseur de cookies : 
          <ul>
            <li>- <b>Mamoto</b>: Große Bleichen 21, 20354 Hamburg, Deutschland</li>
          </ul>
        </li>
      </ol>

      <br />
      <p>En cas de transferts de données en dehors de l’Espace Economique Européen, Smart Education s’assure que des garanties appropriées sont en place (clauses contractuelles type, décisions d’adéquation, etc.), et que le transfert s’effectue en conformité avec les dispositions du chapitre V du RGPD. </p>
      <br />
      <p>Smart Education peut également être amené à transférer vos données personnelles à des tiers si Smart Education estime qu’un tel transfert est nécessaire pour des raisons techniques (par exemple pour des services de sous-traitance technique) ou pour protéger ses intérêts légaux (par· exemple en cas de vente ou changement de contrôle de Smart Education, ou de liquidation totale ou partielle de Smart Education).</p>
      <br />
      <p>Smart Education peut communiquer vos données personnelles si la loi l’y oblige ou si Smart Education estime de toute bonne foi qu’une telle divulgation est raisonnablement nécessaire pour se conformer à une procédure légale (par exemple un mandat, une citation à comparaître ou toute autre décision de justice) ou pour protéger les droits, les biens ou la sécurité personnelle de Smart Education, de nos clients ou du public.</p>
      <br />
      <p><b>Article 7 -	Date d’entrée en vigueur et révisions de la politique </b></p>
      <br />
      <p>La présente Politique peut être mise à jour en fonction des besoins de Smart Education et des circonstances ou si la loi l’exige. Nous vous invitons par conséquent à prendre régulièrement connaissance des mises à jour.</p>
      <br />
      <p>La présente politique de protection des données personnelles est en vigueur depuis le 10.09.2023.</p>
      <br />
      <p><b>Article 8 -	Conservation des données</b></p>
      <br />
      <p>À l'exception des données de connexion qui sont conservées douze (12) mois, l'ensemble des données recueillies sont conservées durant toute la scolarité de l'élève, et supprimées deux ans après la dernière connexion au compte de l’élève.</p>
      <br />
      <p><b>Article 9 -	Exercices de droits des personnes concernées</b></p>
      <br />
      <p>Pour toute question concernant la présente Politique, ou pour toute demande de rectification, de complément, de mise à jour ou de suppression de vos données personnelles, ou pour tout exercice des autres droits plus amplement décrits à l’article 7 de la présente Politique de Protection des Données, vous pouvez : </p>
      <br />
      <ul style="padding-left: 40px; list-style: disc;">
        <li>Adresser un courrier électronique à l’adresse électronique suivante: contact@indil.education</li>
        <li>Adresser un courrier postal à l’adresse: Smart Education SARL-S, 9, rue du Laboratoire, L-1911 Luxembourg  </li>
      </ul>
      <br />
      <p>Si vous estimez, même après avoir introduit une réclamation auprès de Smart Education, que vos droits en matière de protection des données à caractère personnel ne sont pas respectés, vous avez la possibilité d'introduire une réclamation auprès de la Commission nationale de la Protection des Données (CNPD) en suivant la procédure de réclamation à l’adresse suivante:</p>
      <br />
      <p><a style="color: #4183c4;" href="https://cnpd.public.lu/fr/particuliers/faire-valoir.html">https://cnpd.public.lu/fr/particuliers/faire-valoir.html</a></p>
      <br />
      <p>Dans le cadre de l'exercice de vos droits, vous devez justifier de votre identité par tout moyen. En cas de doute sur votre identité, les services chargés du droit d'accès et, s’il en a été désigné un, le délégué à la protection des données se réservent le droit de vous demander les informations supplémentaires qui leur apparaissent nécessaires, y compris la photocopie d’un titre d’identité portant votre signature.</p>
      `,
    },
    generalConditionsDetails: {
      menuItemTitle: 'Condictions Générales',
      title: "Condictions Générales d'utilisation",
      body: `
      <p><b>CONDITIONS GÉNÉRALES D’UTILISATION</b></p>
      <br />
      <p><i>Date de dernière mise à jour : 18.09.2023</i></p>
      <br />
      <p>Les présentes conditions générales d’utilisation (« <b>CGU</b> ») sont conclues entre Smart Education S.A.R.L.-S., ayant son siège d’activité à 9, rue du Laboratoire, L-1911, Luxembourg, inscrite au Registre de Commerce et des Sociétés, Luxembourg sous le numéro B278733 (« <b>Smart Education</b> ») et toute personne physique (y compris celle représentant ou agissant au nom d’une personne morale) souhaitant utiliser la plateforme d’enseignement.</p>
      <br />
      <br />
      <p><b>Article 1 - Préambule</b></p>
      <br />
      <p>Les présentes CGU décrivent les conditions dans lesquelles utilisées la plateforme élaborée pour servir de support au programme d’enseignement de formation continue en comptabilité. L’enseignement en question est destiné aux élèves de classes de l’enseignement secondaire avec la participation d’enseignants aux services du Ministère de l’Education Nationale, de l’Enfance et de la Jeunesse.</p>
      <br />
      <p>Ce service numérique permet aux élèves des classes spécifiquement désignées de suivre une formation en comptabilité lui permettant d’évoluer dans le système d’enseignement luxembourgeois.</p>
      <br />
      <br />
      <p><b>Article 2 - Définitions des termes</b></p>
      <br />
      <p>Les termes suivants commençant avec une majuscule, au singulier ou au pluriel, employés dans les présentes CGU auront la signification suivante :</p>
      <br />
      <p>« <b>Administrateur</b> » toute personne autre qu’un Utilisateur bénéficiant d’un accès autorisé à la Plateforme afin d’en améliorer le contenu, l’infrastructure ou la sécurité et rapportant à un prestataire d’information et de la technologie désigné par Smart Education.</p>
      <br />
      <p>«<b>Plateforme</b>» désigne la plateforme virtuelle utilisée comme support au programme d’enseignement de formation continue en comptabilité dont le contenu est élaboré par Smart Education tel qu’approuvé par le Ministère chargé de l'Éducation nationale, de l’Enfance et de la Jeunesse.</p>
      <br />
      <p>«<b>Support d’apprentissage</b>» désigne les supports de contenus digitaux réalisés et publiés par Smart Education (vidéos pédagogiques, exercices interactifs, images, pages web ou textes) disponibles sur la Plateforme accessibles par l’Utilisateur via son compte.</p>
      <br />
      <p>«<b>Utilisateur</b>» désigne la personne physique ayant un accès autorisé à la Plateforme soit comme élève, comme enseignant ou comme Administrateur.</p>
      <br />
      <p><b>Hébergeur de la Plateforme :</b></p>
      <p><b>OVH SAS</b> : 2, rue Kellermann 59100 Roubaix, France</p>
      <br />
      <p><b>Editeur de la Plateforme</b> :</p>
      <p><b>Smart Education</b> : 9, rue du Laboratoire, L-1911, Luxembourg</p>
      <br />
      <br />
      <p><b>Article 3 - Accès au service</b></p>
      <br />
      <p>L’accès et l’utilisation de la Plateforme emportent connaissance, consultation et acceptation des présentes CGU.</p>
      <br />
      <p>L’accès à la Plateforme est réservé aux élèves et aux enseignants des classes de 4 GCM, 3 GCM, 4TPCM, 3 TPCM, 3CD, 3CG, 3CN, 2CN 2GCM, 2TPCM, 2CD, 2CG, 1 GCM, 1TPCM, 1CD, 1CG et 1CN telles que définies par le Ministère de l’Education Nationale, de l’Enfance et de la Jeunesse.</p>
      <br />
      <p>Certaines applications proposées par défaut sur la page d’accueil sont des outils nécessitant un abonnement pour fonctionner.</p>
      <br />
      <br />
      <p><b>Article 4 - Création du compte</b></p>
      <br />
      <p>L’Utilisateur s’engage à fournir des informations exactes lors de son inscription et lors de la commande de son compte membre. Il s’engage aussi à mettre à jour régulièrement ses informations personnelles afin de garantir leur exactitude.</p>
      <br />
      <p>L’Utilisateur définit lui-même son mot de passe afin d’assurer la sécurité de son compte dans la session de connexion avec un mot de passe personnel, ou utilise son identifiant IAM pour se connecter par son compte IAM ou le compte de l’adolescent qu’il représente légalement.</p>
      <br />
      <p>Il est de la responsabilité des Utilisateurs inscrits de maintenir confidentiels leurs identifiants.</p>
      <br />
      <p>Smart Education ne sera pas tenu responsable en cas de violation du compte utilisateur si celui-ci est lié à l’utilisation d’un mot de passe trop faible, en cas de connexion avec un mot de passe personnel.</p>
      <br />
      <p>Tout accès à la Plateforme et au compte, toute utilisation de la Plateforme ou plus généralement toute transmission de données effectués à partir des identifiants de l’Utilisateur seront réputés avoir été effectués par celui-ci. A cet égard, l’Utilisateur est tenu de s’assurer qu’à l’issue de chaque session, il se déconnecte effectivement de son compte, en particulier lorsqu’il utilise la Plateforme à partir d’un ordinateur public.</p>
      <br />
      <br />
      <p><b>Article 5 - Compte unique</b></p>
      <br />
      <p>Les Utilisateurs de la Plateforme n’ont besoin et ne pourront disposer que d’un seul et même compte.</p>
      <br />
      <br />
      <p><b>Article 6 - Contenu du Site</b></p>
      <p>Smart Education assurera au mieux l’exactitude des informations ou services contenus sur la Plateforme. Cependant, sa responsabilité ne saurait être retenue notamment en cas d’omission de mise à jour d’une information ou d’un formulaire, en cas d’erreurs de manipulation du système ou d’encodage, d’inexactitudes, de lacunes dans les informations fournies ou d’éventuelles erreurs, inexactitudes ou omissions. En effet, l'objectif est de diffuser des informations exactes et à jour provenant de diverses sources, mais Smart Education ne saurait toutefois éviter tout risque d'erreur matérielle. Aucune des informations publiées sur la Plateforme n'ont une vocation d'exhaustivité ou constituent un engagement de la part de Smart Education.</p>
      <br />
      <p>Smart Education pourra à tout moment retirer, ajouter, compléter ou préciser tout ou partie des informations et services contenus ou proposés sur la Plateforme. Aucune responsabilité pour un quelconque dommage direct ou indirect en relation avec de telles modifications ne pourra être retenue à l’encontre de Smart Education.</p>
      <br />
      <br />
      <p><b>Article 7 - Disponibilité</b></p>
      <br />
      <p>Smart Education fera tout son possible pour assurer une disponibilité et accessibilité maximale de la Plateforme, néanmoins la société ne peut pas toujours garantir sa disponibilité continue.</p>
      <br />
      <p>En utilisant la Plateforme, l’Utilisateur reconnait et accepte que (i) l'accès est fourni en l'état et selon sa disponibilité, sans garantie d'aucune sorte, expresse ou implicite, et que (ii) son utilisation est régie par des conditions techniques et des restrictions d'utilisation en termes de compatibilité et d'interopérabilité. L’Utilisateur s’engage à se conformer à ces conditions techniques.</p>
      <br />
      <p>En utilisant la Plateforme, l’Utilisateur reconnait les caractéristiques d'Internet et accepte que Smart Education ne soit pas responsable des interruptions du système de protocole Internet résultant d'un acte du fournisseur de services Internet ou d'un acte indépendant de la volonté de Smart Education.</p>
      <br />
      <p>Plus généralement, Smart Education n’est pas responsable et ne peut faire l’objet d’une demande d’indemnisation pour toute interruption d'accès, tout dysfonctionnement de la Plateforme ou toute perte de données résultant du non-respect des présentes CGU, des conditions techniques ou de ses recommandations, d'une utilisation inappropriée ou illégale de la Plateforme, d'un cas de force majeure, d'événements dépendant de tiers tels que les opérateurs de télécommunications, d'actes ou d'omissions de la part de l’Utilisateur ou d'attaques contre le système informatique.</p>
      <br />
      <p>Lorsque l’Utilisateur utilise la Plateforme, il peut être amené à cliquer sur des liens qui le redirigeront vers des prestataires de services tiers. Smart Education ne peut être tenue responsable de l'indisponibilité de ces sites ou services tiers. D'une manière générale, Smart Education ne peut être tenue responsable du fonctionnement, du contenu ou de tout autre aspect des sites ou services tiers, ni des dommages directs ou indirects causés par le suivi d'un lien vers un site ou un service tiers. Smart Education n'est pas responsable du traitement des données (personnelles) qui a lieu sur ces sites web tiers.</p>
      <br />
      <br />
      <p><b>Article 8 - Maintenance</b></p>
      <br />
      <p>Smart Education se réserve le droit de changer, modifier ou interrompre, sans préavis, tout ou partie de la Plateforme pour des raisons de maintenance, de mise à niveau ou pour toute autre raison jugée nécessaire.</p>
      <br />
      <p>En utilisant la Plateforme, l’Utilisateur comprend et accepte que la Smart Education effectue des opérations de maintenance sur la Plateforme et que toute interruption de l'accès à la Plateforme résultant de cette maintenance ne constitue pas un manquement de la Plateforme aux présentes CGU. L'interruption de l'accès à la Plateforme ne donne pas droit à une indemnisation de l'Utilisateur.</p>
      <br />
      <br />
      <p><b>Article 9 - Propriété intellectuelle</b></p>
      <br />
      <p>Le contenu proposé sur la Plateforme est la propriété de la société Smart Education. Elle est propriétaire des droits de propriété intellectuelle et détient les droits d’usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logos, icônes, sons, logiciels sous réserve de droits qui appartiendraient à des tiers.</p>
      <p>Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments de la Plateforme, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de Smart Education.</p>
      <br />
      <p>Toute exploitation non autorisée de la Plateforme ou de l’un quelconque des éléments qu’elle contient sera considérée comme constitutive d’une contrefaçon et pourra être poursuivie devant les instances judiciaires.</p>
      <br />
      <br />
      <p><b>Article 10 - Politique de protection des données personnelles</b></p>
      <br>
      <p>La politique de protection des données personnelles de la société Smart Education est disponible sur cette page :  [Politique de protection des données personnelles]</p>
      <br />
      <br />
      <p><b>Article 11 - Configuration technique minimale requise et signalement des problèmes techniques</b></p>
      <br />
      <p>La Plateforme est accessible à la condition de disposer d’une connexion internet, mais la création d’un compte utilisateur est nécessaire pour accéder à la partie personnalisation de page d’accueil, compte élève, ou compte enseignant.</p>
      <br />
      <p>Pour bénéficier de toutes les fonctionnalités, JavaScript doit être activé dans le navigateur sans quoi, des dysfonctionnements importants de la Plateforme pourront être constatés.</p>
      <br />
      <p>En utilisant la Plateforme, l’Utilisateur confirme qu’il possède le matériel informatique approprié lui permettant d’utiliser la Plateforme et convient de n’utiliser aucun dispositif ou de n’entreprendre aucune action susceptible de causer un préjudice à Smart Education. L’Utilisateur entreprend toutes les actions nécessaires pour limiter les préjudices qui pourraient résulter d’une corruption éventuelle de son matériel informatique lors de l’accès à la Plateforme.</p>
      <br/>
      <p>Dans le cadre de l’utilisation de la Plateforme, l’Utilisateur peut ouvrir un ticket, par le biais d’envoyer un email au contact@indil.educaiton, afin de signaler des problèmes techniques en cas d’indisponibilité de la Plateforme consécutive à des défaillances techniques causées par Smart Education, et à défaut de leur résolution dans des délais brefs, Smart Education déploiera ses meilleurs efforts pour résoudre ces défaillances dès que possible, à condition que :</p>
      <br />
      <ol>
        <li>1.	l’Utilisateur les a décrites de manière précise lors de l’ouverture du ticket ;</li>
        <li>2.	l’Utilisateur coopère entièrement avec Smart Education ; et que</li>
        <li>3.	de telles défaillances ne découlent pas d’un usage abusif de la plateforme qui serait imputable à l’Utilisateur.</li>
      </ol>
      <br />
      <br />
      <p><b>Article 12 - Conditions d’âge et d’autorisation des représentants légaux </b></p>
      <br />
      <p>En utilisant cette plateforme, l’Utilisateur déclare être légalement majeur selon les lois du Grand-Duché du Luxembourg, ou avoir obtenu l'autorisation expresse de ses représentants légaux pour accéder à cette plateforme.</p>
      <br />
      <p>Le représentant légal d'un Utilisateur mineur reconnait et accepte la responsabilité de superviser l'utilisation de la Plateforme par le mineur et de donner son autorisation à son utilisation.</p>
      <br />
      <p>Smart Education se réserve le droit de demander la preuve de l'âge ou de l'autorisation des représentants légaux à tout moment et de restreindre ou de mettre fin à l'accès à la Plateforme en cas de non-respect de cette exigence.</p>
      <br />
      <p><b>Article 13 - Obligations de l’Utilisateur</b></p>
      <br />
      <p>L’Utilisateur s’engage à utiliser la Plateforme conformément à toutes les lois applicables et aux présentes CGU pendant toute la durée de son utilisation. L’Utilisateur garantit à tout moment qu’il n’utilisera pas la Plateforme afin de :</p>
      <br />
      <ul>
        <li>-	entreprendre ou promouvoir des activités illégales, ou des informations mensongères ou trompeuses ;</li>
        <li>-	copier de manière illicite ou non-autorisée des travaux protégés par des droits d’auteurs, des brevets ou des marques ;</li>
        <li>-	stimuler, inciter ou encourager l’envoi de courriels, de chaînes de lettres, l’envoi en masse de courriels non sollicités, l’envoi de messages instantanés, de messages publicitaires indésirables ou de courriers non sollicités ;</li>
        <li>-	promouvoir ou encourager tout acte ou activité criminelle, ou fournir des orientations ou des consignes sur la promotion d’activités criminelles, l’atteinte à la vie privée, la diffusion ou la création de virus informatiques ;</li>
        <li>-	solliciter les mots de passe ou les informations personnelles des autres Utilisateurs à des fins commerciales illicites ;</li>
        <li>-	distribuer, reproduire, publier ou modifier des matériels protégés par des droits d’auteurs, des marques de commerce ou d’autres droits exclusifs dont les tiers sont titulaires, de toute façon, sans leur accord préalable.</li>
      </ul>
      <br/>
      <br />
      <p><b>Article 14 - Durée et résiliation</b></p>
      <br />
      <p>Smart Education se réserve le droit de suspendre le compte de l’Utilisateur toute la durée pendant laquelle ce dernier se maintiendra en contravention avec les règles édictées aux présentes CGU et/ou aux lois applicables. L’Utilisateur n'aura droit à aucune indemnité de quelque nature que ce soit sur la base de la suspension, de l'interdiction ou de la résiliation de l'accès à la Plateforme résultant de sa seule faute.</p>
      <br />
      <p>Smart Education pourra aller jusqu’à supprimer sans mise en demeure préalable le compte de l’Utilisateur qui :</p>
      <br />
      <ul>
        <li>- Ouvre plus d’un compte ou ouvre un compte usurpant l’identité d’un tier,</li>
        <li>- Manque à l’une de ses obligations visées dans les présentes CGU,</li>
        <li>- Utilise le Service pour communiquer des contenus inappropriés (illégaux, portant atteinte à la dignité humaine, à caractère sexuels, diffamatoires, injurieux, racistes, portant atteinte aux droits des tiers ou tout autre contenu pouvant engager la responsabilité civile ou pénale de la personne qui le publie),</li>
        <li>- Utilise plus généralement la Plateforme ou son compte à des fins frauduleuses ou illégales,</li>
        <li>- ou provoque une situation d’une gravité similaire.</li>
      </ul>
      <br />
      <p>A l’exception des données de connexion, l'ensemble des données recueillies sont conservées durant toute la scolarité de l'élève, et supprimées deux ans après la dernière connexion au compte de l’élève.</p>
      <br />
      <br />
      <p><b>Article 15 - Modification</b></p>
      <br />
      <p>Les présentes CGU pourront être modifiées par Smart Education à tout moment et sans préavis.</p>
      <p>L’Utilisateur est donc invité à consulter régulièrement la dernière version mise à jour disponible.</p>
      <p>Les CGU modifiées entreront en vigueur à compter de leur mise en ligne, et s’appliqueront dès lors de plein droit.</p>
      <br />
      <br />
      <p><b>Article 16 - Contact</b></p>
      <br />
      <p>Pour toute question ou demande, merci d’adresser un email à cette adresse [contact@indil.education] ».</p>
      <br />
      <br />
      <p><b>Article 17 - Dispositions finales</b></p>
      <br />
      <p>Tout litige découlant de l'utilisation de la Plateforme sera régi par les lois du Grand-Duché du Luxembourg et sera soumis à la compétence exclusive des Tribunaux du Grand-duché de Luxembourg.</p>
      `,
    },
  },

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // FRENCH
  //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  fr: {
    I_agree_to: 'J’accepte les',
    I_have_16_and_I_agree_to:
      "Je confirme avoir plus de 16 ans et j'accepte les",
    I_agree_to_Privacy_and_Policy:
      "conditions d'utilisation et la politique de confidentialité",
    Privacy_and_Policy: 'Politique de protection de données',
    Download_user_guide: 'Téléchargez',
    General_conditions: 'Conditions générales',
    User_guide: 'Guide d‘utilisation',
    Resend_verification_email: "Renvoyer l'e-mail de vérification",
    Copy_to_clipboard: 'cliquez pour copier le texte',
    Your_request_has_been_accepted: 'Your request has been accepted',
    Your_request_has_been_sent_to_the_admin:
      "Votre demande a été envoyée à l'administrateur",
    It_might_take_up_to_48_hours_to_receive_a_response:
      'It might take up to 48 hours to receive a response',
    Please_check_your_email:
      "Veuillez vérifier votre courrier électronique pour une confirmation. N'oubliez pas de vérifier votre dossier « courrier indésirable » ou « spam ».Si vous ne voyez pas l'e-mail dans quelques minutes, veuillez nous envoyer un e-mail à contact@indil.education.",
    Videos: 'Vidéos',
    home: 'Accueil',
    about_us: 'À Propos',
    contact_us: 'Contact',
    login: 'Mon compte',
    sign_up: 'S’inscrire',
    we_give_u_knowledge:
      "Apprendre la comptabilité n'a jamais été aussi captivant",
    whether_you_are_a_high_school1:
      'Maîtrisez la comptabilité grâce à des activités engageantes et des vidéos explicatives.',
    whether_you_are_a_high_school2:
      'Que vous soyez lycéen, enseignant ou que vous poursuiviez un diplôme en finance, en commerce ou en comptabilité, cette plateforme est spécialement conçue pour vous.',
    whether_you_are_a_high_school3:
      "Remplacez vos livres scolaires par INDIL et découvrez une nouvelle manière passionnante d'apprendre. ",
    school: 'Lycées',
    quizzes: 'Exercices interactifs',
    about_indil: 'À Propos',
    whether_you_are_a_high1:
      "Nous mettons à disposition des apprenants et des enseignants un système tutoriel intelligent qui favorise l'apprentissage individuel et interactif pour apprendre la comptabilité luxembourgeoise.",
    whether_you_are_a_high2:
      "Notre plateforme vous permet d'explorer la théorie à travers des vidéos explicatives de qualité, puis de mettre en pratique vos connaissances grâce à des exercices pratiques adaptés à tous les niveaux.",
    whether_you_are_a_high3:
      'Des feedbacks pédagogiques et personnalisés permettent d’améliorer votre parcours d’apprentissage.',
    whether_you_are_a_high4:
      "Rejoignez notre communauté d'apprentissage et découvrez la comptabilité d'une manière passionnante et efficace.",
    why_indil: 'La meilleure solution pour apprendre ',
    finance_classes: 'Pour l’étudiant',
    finance_classes_p:
      'Accès illimité au contenu digital pour améliorer ainsi que faciliter ton apprentissage.',
    accounting_classes: 'Pour l’enseignant',
    accounting_classes_p:
      'Suivi continu du progrès de tous les apprenants pour une meilleure différenciation pédagogique au sein de votre classe.',
    Business_classes: 'Concept pédagogique',
    Business_classes_p:
      'Plus de 200 vidéos et exercices interactifs développés par des enseignants et adaptés à chaque niveau d’apprentissage. ',
    EXPLORE_OUR_SUBJECTS: 'Nos cours',
    TESTIMONIALS: 'Témoignages',
    My_Class: 'Ma classe',
    Student_room: 'Mon progrès',
    welcome: 'Bienvenue',
    Student: 'Élève',
    Add_voucher: 'Ajouter mon code d’accès',
    Logout: 'Se déconnecter',
    Delete: 'Supprimer',
    Enter_voucher_code:
      'Insérez le code d’accès pour s’inscrire à votre classe',
    Enter_voucher_code_placeHolder: 'Insérez le code d’accès',
    Submit: 'Soumettre',
    Cancel: 'Annuler',

    Class: 'Cours',
    Chapter: 'Chapitre',
    Chapters: 'Chapitres',
    ChaptersInteractives: 'Chapitres interactifs',

    Practic_Room: 'Ma théorie',
    Homework_room: 'Mes exercices',
    Section: 'Section',
    Skill_competency: 'Compétence',
    Video: 'Vidéo',
    PEX: 'Théorie',

    PEX_content: 'Contenu de l’exercice',
    Start_PEX: 'Commencer',
    Type: 'Type de question',
    Question: 'Question',
    Competency: 'Compétence',
    Are_you_sure_you_want_to_start: 'Commencer l’exercice ?',

    Cancel_exercice: 'Quitter',
    Correct_answer: 'Réponse correcte',
    Incomplete_answer: 'Réponse incomplète',
    Your_score_is: 'Votre score est de',
    Your_need_to_try_it_again: 'Vous devez réessayer l’exercice ',
    Question_finished: 'Exercice terminé !',
    Your_can_proceed_to_the_next_PEX:
      'Vous pouvez continuer avec le prochain exercice',

    Basic_Level: 'Niveau de base',
    Intermediate_level: 'Niveau intermédiaire',
    Advanced_level: 'Niveau avancé',
    WEX: 'Exercice',
    test: 'Test',
    You_need_to_pass: 'Vous devez réussir',
    to_unlock_this_exercice: 'pour débloquer cet exercice',

    WEX_content: 'Contenu de l’exercice',
    Start_WEX: 'Commencer',

    Student_chapters: 'Mes chapitres',
    Student_Exercices: 'Mes exercices',
    Chapters_name: 'Chapitre',
    Progress: 'Progrès',

    PEXs_completed: 'Théorie complétée',
    WEX_completed: ' Exercices complétés',
    Tests_completed: 'Tests complétés',
    Name: 'Nom',
    Level: 'Niveau',
    Score: 'Score',
    Duration: 'Durée',
    Hints: 'Hint',
    Not_done_yet: 'À faire',
    beginner: 'base',
    intermediate: 'intermédiaire',
    advanced: 'avancé',

    Log_in_to_your_account: 'Connexion à votre compte',
    Student_voucher: 'Élève avec code d’accès',
    Student_non_voucher: 'Élève sans code d’accès',
    I_am_student: 'Je suis élève',
    Instructor: 'Enseignant(e)',
    I_am_instructor: 'Je suis enseignant(e)',

    Email_Address: 'Adresse e-mail',
    Password: 'Mot de passe',
    Login_sign: 'Se connecter',
    Dont_have_an_account_on_INDIL_Register:
      'Vous n’avez pas encore de compte ? Enregistrez-vous !',
    Successfully_loged_in: 'Vous êtes connectés',

    Profile: 'Mon Profil',
    Search: 'Rechercher',
    Teacher: 'Enseignant',
    Create_new_class: 'Créer une nouvelle classe',
    Students_numbers: 'Élèves inscrits',
    Dashboard: 'Tableau de bord ',

    Students_info: 'Ma classe',
    Chapter_progress: 'Progrès par chapitre',
    Exercise: 'Exercices',
    Subject_content: 'Chapitres',
    PDFs: 'Les cours en version PDF',

    Students_name: 'Nom',
    Average_PEX_score: 'Score moyen de la théorie',
    Average_WEX_score: 'Score moyen des exercices',
    Average_test_score: 'Score moyen des tests',
    Action: 'Actions',

    teacher_chapter_progress_p_1:
      'Vous avez une vue globale sur la progression de votre classe.',
    teacher_chapter_progress_p_2:
      'Vous pouvez également consulter les 5 erreurs les plus fréquentes liées à ce chapitre',

    Exercice_name: 'Nom de l’exercice',
    Number_of_students: 'Nombre d’élèves',
    Avergae_score: 'Score moyen',
    Avergae_number_of_hints: 'Hints en moyenne',
    Avergae_time: 'Durée moyenne',

    Pex: 'Théorie',
    Test: 'Tests',

    Overview: 'Vue d’ensemble',
    Question_breakdown: 'Détail',

    Average_time_taken_by_students: 'Durée Moyenne',
    Status: 'Complété ?',

    Zero: 'Sans faute',
    One: 'Une faute',
    Two: 'Deux fautes',
    more: 'Plus que deux fautes',
    students: 'élèves',

    hint: 'Besoin d’aide',

    //amortization
    Fixed_Assets: 'Immobilisation corporelle',
    Acquistion_date: 'Date d’acquisition',
    Acquistion_date_value: '(JJ-MM-AAAA)',
    Original_value: 'Valeur à l’origine',
    Depreciation_rate: 'Taux d’amortissement',
    Year_column: 'Année',
    Net_value_at_beginning_of_the_year: 'VCN au début de l’exercice',
    Loss_in_value_per_year: 'Annuité',
    Accumulated_loss: ' Cumul des annuités',
    Net_value_at_the_end_of_the_year: 'VCN à la fin de l’exercice',
    Enter_year: 'Entrez l’année',
    Enter_net_value_beginning: 'Entrez la valeur au début de l’année',
    Enter_loss_in_value: 'Entrez l’annuité',
    Enter_accumulated_loss: 'Entrez le cumul des annuités',
    Enter_net_value_end: 'Entrez la valeur à la fin de l’année',

    //journal
    Account_Name: 'Compte',
    Account_Number: 'N° de compte',
    Debit_Value: 'Débit',
    Credit_Value: 'Crédit ',
    Account_name_on_debit: 'Compte à débiter',
    Account_name_on_credit: 'Compte à créditer',
    Enter_Date: 'Date',
    Enter_wording: 'Libellé',

    //t-account
    Active: 'Actif',
    Liabilities: 'Passif',
    Charges: 'Charges',
    Products: 'Produits',

    My_Portal: 'Mon portail',
    My_Profile: 'Mon profil',
    Edit_Profile: 'Changer profil',
    Personal_Information: 'Mes informations',
    First_Name: 'Prénom',
    Last_Name: 'Nom',
    School_Name: 'Mon lycée',
    Confirm_password: 'Confirmer mot de passe',

    Register: 'Régistrer',
    Have_an_account: 'Vous avez déjà un compte? Connectez-vous!',
    Subject: 'Cours',
    School: 'Lycée',
    University: 'Université',
    Country: 'Pays',

    testimonials_p_1:
      'J’ai bien aimé utiliser INDIL, cela nous permet de travailler à notre rythme et à mieux comprendre les exercices.',
    testimonials_p_2:
      'C’est simple à utiliser et les vidéos aident à mieux comprendre la matière.',
    testimonials_p_3: 'Je vais l’utiliser pour me préparer pour mes examens.',

    I_have_a_voucher: 'Élève au Grand-Duché',
    I_dont_have_a_voucher: 'sans code d’accès',
    Create_Account: 'Créer mon compte',
    Full_Name: 'Nom et prénom',

    //balance-sheet
    Balance_au: "Bilan de l'entreprise au",
    account_name: 'compte',
    value: 'valeur',

    register: 'Régistrer',
    fullName: 'Nom et prénom',
    email: 'adresse e-mail',
    confirmPassword: 'Confirmer mot de passe',
    required: 'Ce champ est obligatoire',
    requiredTerms: 'Vous devez accepter les conditions générales',
    notMatch: 'Le mot de passe ne correspond pas',
    userName: "nom d'utilisateur",
    studentRegisterCheckBox1:
      'Email me platform updates, recommended courses, and the latest news',
    studentRegisterCheckBox2:
      "En cliquant sur s'inscrire, j'accepte les conditions d'utilisation et la politique de confidentialité.",
    studentRegisterCheckBox3: 'I agree that I am over 12 years old',
    instructorDegree: 'Degree',
    university: 'university',
    faculty: 'faculty',
    specialization: 'Specialization',
    phoneNumber: 'Phone Number',
    homePage: 'Home Page',
    aboutUs: 'About us',
    contact: 'Contact',
    confirm_title: 'Delete confirmation',
    confirm_delete_account:
      'Êtes-vous sûr de vouloir supprimer définitivement votre compte ? Tous vos progrès seront supprimés?',
    account_delete_success: 'Votre compte est supprimé avec succès',
    account_delete_error: 'can not delete your account',
    yes: 'Oui',
    no: 'Non',
    privacyDetails: {
      menuItemTitle: 'Politique de Protection des Données',
      title: 'Politique de Protection des Données à Caractère Personnel',
      body: `
      <p><b>Smart Education S.A.R.L.-S.</b>, ayant son siège d’activité à L-1911, 9, rue du Laboratoire, Luxembourg, inscrite au Registre de Commerce et des Sociétés, Luxembourg sous le numéro B278733 (« <b>Smart Education</b> ») est responsable du traitement des données personnelles utilisées aux fins d’offrir les fonctionnalités de son programme d’enseignement dispensé via sa plateforme digitale (« <b>Plateforme</b> »)</p>
      <br />
      <p>Cette Politique de Protection des Données (« <b>Politique de Protection des Données</b> ») a pour objet d’informer toutes les personnes physiques concernées, notamment les élèves, le(s) représentant(s) des élèves, les enseignants, ci-après dénommées « utilisateurs », « Vous » ou « Votre/Vos », sur la manière dont Smart Education collecte, utilise et conserve de telles données personnelles dans le cadre de son programme d’enseignement à travers l’utilisation d’une plateforme digitale et sur les moyens dont chacune de ces personnes physiques concernées dispose pour contrôler cette utilisation.</p>
      <br />
      <p><b>Article 1 -	Données collectées</b></p>
      <br />
      <p>Les données collectées relatives à l'identification, à l'identité et à la scolarité sont collectées directement auprès des élèves ou de leurs représentants légaux et auprès des enseignants au moment de l’inscription à la Plateforme, à la création d’un compte, au cours de la connexion à ce compte respectivement compte utilisateur ou compte enseignant, lors de la mise à jour des informations et de la souscription à un service.</p>
      <br />
      <p>Les données personnelles peuvent inclure ce qui suit :</p>
      <br />
      <ul style="padding-left: 40px; list-style: disc;">
        <li>Pseudonyme / identifiant / User id</li>
        <li>Prénom et nom ;</li>
        <li>Adresse électronique ;</li>
        <li>Classe et cours suivis ;</li>
        <li>En cas d’organisation : type d’organisation (société, école, association, etc.), nom de l’organisation, taille de l’organisation, service et intitulé du poste ;</li>
        <li>Paramètres et mot de passe du compte,</li>
        <li>Données d’identification électronique collectées par les cookies présents sur la Plateforme dans le cas où vous les acceptez (adresse IP, moment de connexion, pages visitées, type de système d’exploitation, type de connexion, type d’équipement mobile) ; pour plus d’information sur les cookies, veuillez consulter la rubrique dédiée à ce sujet,  </li>
        <li>Données liées au contenu éducatif généré par les visiteurs</li>
        <li>Et toute autre donnée personnelle pouvant s’avérer pertinente aux fins énoncées ci-après .</li>
      </ul>
      <br />
      <p>Dans tous les cas, les données personnelles collectées se limitent aux données nécessaires aux finalités exposées dans cette Politique de Protection des Données. Ci-après, les « <b>Données</b> »</p>
      <br />
      <p>Certaines fonctionnalités et caractéristiques de notre Plateforme, ne peuvent être utilisées que si vous communiquez à Smart Education certaines données personnelles lorsque vous visitez ou utilisez notre Plateforme. Vous êtes libre de fournir ou non tout ou partie de vos données personnelles. Toutefois, si vous choisissez de ne pas les fournir, une telle décision pourrait empêcher la réalisation ou la réalisation satisfaisante des finalités, certains services et certaines fonctionnalités de notre Plateforme pourraient ne pas fonctionner correctement et/ou vous vous verrez refuser l’accès à certaines pages de notre Plateforme.</p>
      <br />
      <p><b>Article 2 -	Objectifs du traitement des données (finalités) et bases légales</b></p>
      <br />
      <p>La base légale d’un traitement de données est licite si au moins l’une des 6 conditions de l’article 6 (Licéité du traitement) RGPD est remplie. Selon le traitement, l’activité de Smart Education est fondée sur plusieurs bases légales distinctes:</p>
      <br />
      <p>– Le traitement des données personnelles de l’utilisateur qui « a consenti au traitement de ses données à caractère personnel pour [les] finalités spécifiques » suivantes:</p>
      <br />
      <ul style="padding-left: 40px; list-style: disc;">
        <li>Permettre à Smart Education de mesurer la fréquentation de la Plateforme via les données collectées par les cookies.</li>
      </ul>
      <br />
      <p>– Le traitement des données personnelles est réalisé pour les besoins de l’exécution du contrat de services à la base de la mise à disposition d’un outil pédagogique aux élèves :</p>
      <br />
      <ul style="padding-left: 40px; list-style: disc;">
        <li>Permettre aux élèves ainsi qu'à leurs responsables d'accéder, à un service numérique consistant en une plateforme virtuelle comme support à un programme d’enseignement en comptabilité avec l’accord du Ministère chargé de l'Éducation nationale, de l’Enfance et de la Jeunesse ;</li>
        <li>Permettre au personnel enseignant habilité par le Ministère chargé de l'Éducation nationale, de l’Enfance et de la Jeunesse d’administrer les comptes des utilisateurs en fonction des services auxquels ils peuvent prétendre, en accédant eux-mêmes à leur compte enseignant au moyen d’un identifiant unique IAM.</li>
        <li>Evaluer les compétences et les performances de l’élève ; </li>
        <li>Accéder à l’ensemble des caractéristiques et options offertes par la Plateforme (notamment les fonctionnalités disponibles sur abonnement);</li>
        <li>Bien administrer les services proposés via la Plateforme, </li>
        <li>Permettre Smart Education et les utilisateurs de sa Plateforme d’échanger, de demander et d’obtenir des informations sur les fonctionnalités de la Plateforme et les données produites par les élèves et les enseignants à l’occasion de l’utilisation de la Plateforme (exercices, évaluations, corrections);</li>
        <li>Permettre une utilisation interactive et personnalisée de notre Plateforme ;</li>
        <li>Permettre de vous inscrire (ou d’inscrire l’élève dont vous êtes l’enseignant ou le représentant) à des classes;</li>
      </ul>
      <br />
      <p>– Le traitement des données personnelles est basé sur les intérêts légitimes de Smart Education à améliorer l’expérience des utilisateurs de la Plateforme :</p>
      <br />
      <ul style="padding-left: 40px; list-style: disc;">
        <li>Mettre à disposition la documentation spécialisée et une assistance technique et pédagogique adaptée selon les informations collectées auprès des comptes des élèves et des enseignants Aux fins d’une bonne administration des services proposés via la Plateforme</li>
        <li>Mettre à disposition la documentation spécialisée et une assistance technique et pédagogique adaptée selon les informations collectées auprès des comptes des élèves et des enseignants;</li>
        <li>Permettre à Smart Education de gérer des enquêtes de satisfaction ;</li>
        <li>Permettre à Smart Education de gérer ses activités de recherche et développement : demandes de formations spécifiques, incidents, les questions de qualité, tests, etc. ;</li>
        <li>Utiliser l’adresse de courrier électronique à des fins administratives ou d’autres objectifs ne relevant pas du marketing (par exemple, pour vous notifier d’importants changements apportés à la Plateforme).</li>
      </ul>
      <br />
      <p><b>Article 3 -	Cookies</b></p>
      <br />
      <p>Notre Plateforme peut utiliser des cookies ou d’autres technologies susceptibles de collecter ou conserver des données personnelles. Les cookies sont des fichiers textes stockés et utilisés pour enregistrer des données personnelles et non personnelles concernant votre navigation sur notre Plateforme.  Les cookies peuvent être permanents (et rester sur votre ordinateur après votre déconnexion de notre Plateforme afin d’être utilisés lors de vos visites ultérieures sur notre site) ou temporaires (et disparaître dès que vous vous déconnectez de notre site).</p>
      <br />
      <p>Smart Education utilise des cookies afin d’améliorer votre expérience d’utilisateur, notamment en :</p>
      <br />
      <ul style="padding-left: 40px; list-style: disc;">
        <li>Permettant à un service de reconnaître votre matériel, ainsi vous n’aurez pas à fournir plusieurs fois les mêmes informations afin d’exécuter la même tâche ;</li>
        <li>Reconnaissant le nom d’utilisateur et le mot de passe que vous avez déjà fournis afin que vous n’ayez pas à les refournir sur chaque page web qui en ferait la demande ;</li>
        <li>Analyser le trafic et les données de connexion votre adresse IP, la date et l’heure à laquelle vous accédez à la Plateforme ou à laquelle l’utilisez, type de système d’exploitation, type de connexion, type d’équipement mobile.</li>
        <li>Décompter les utilisateurs à l’année. </li>
      </ul>
      <br />
      <p>Vous pouvez refuser systématiquement les cookies (y compris les cookies utilisés à des fins de mesure d’audience) en cliquant sur l’option « refuser tous les cookies » dans la bannière qui s’affiche en bas de votre écran, ou sélectionner les cookies que vous souhaitez accepter, en cliquant sur l’option « paramétrer les cookies ». Toutefois, si vous refusez les cookies strictement essentiels, certaines fonctionnalités et caractéristiques de notre site pourraient ne pas fonctionner correctement et vous pourriez ne pas être en mesure de pouvoir accéder à certaines parties ou certains services de notre Plateforme.</p>
      <br />
      <p>Une liste des cookies connus sur ce site peut être obtenue en cliquant sur le lien suivant: [Politique en matière de cookies]. Cette liste est contrôlée et mise à jour régulièrement.  </p>
      <br />
      <p>Veuillez noter que vous êtes également libre de retirer votre consentement à tout moment, en changeant les paramètres sélectionnés sur la bannière. Le retrait de votre consentement n’affectera pas la légalité des données déjà traitées. </p>
      <br />
      <p><b>Article 4 -	Droits de la personne concernée</b></p>
      <br />
      <p>Smart Education a mis en place des dispositifs de protection des données personnelles adaptés pour veiller à ce que les données personnelles soient utilisées conformément aux finalités ci-dessus et pour assurer leur exactitude et leur mise à jour.</p>
      <br />
      <p>En vertu du règlement européen sur la protection des données personnelles, en abrégé, le « RGPD» 2016/679 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données, et abrogeant la directive 95/46/CE (règlement général sur la protection des données), Vous disposez des droits suivants :</p>
      <br />
      <ol style="padding-left: 40px; list-style: lower-alpha;">
        <li>Obtenir la confirmation de l’existence ou de l’absence de données personnelles vous concernant, et leur communication sous forme intelligible ;</li>
        <li>Obtenir la mise à jour, la rectification de vos données ou compléter ces données ;</li>
        <li>Obtenir la suppression de vos données dans certains cas (ex. : lorsque ces données ne sont plus nécessaires au regard des finalités pour lesquelles elles avaient été collectées ou lorsqu’elles ont fait l’objet d’un traitement illicite) ;</li>
        <li>Obtenir la limitation du traitement de vos données dans certaines circonstances ;</li>
        <li>Recevoir vos données dans un format structuré, couramment utilisé et lisible par une machine (« droit à la portabilité »).</li>
        <li>De vous opposer, pour tout ou une partie, au traitement des données personnelles vous concernant, lorsque leur traitement est fondé sur les intérêts légitimes du responsable de traitement ;</li>
        <li>De retirer votre consentement et de demander la suppression de votre compte. Vous pouvez exercer ce droit à tout moment en envoyant un Email à l’adresse suivante : contact@indil.education </li>
      </ol>
      <br />
      <p>En cas de demande manifestement infondée ou excessive, Smart Education se réserve le droit de facturer au demandeur un montant raisonnable pour couvrir les frais liés aux opérations d’accès, de rectification ou de suppression. Smart Education se réserve le droit de refuser l’accès aux Données dans les cas particuliers prévus par les législations et réglementations applicables.</p>
      <br />
      <p><b>Article 5 -	Sécurité des données </b></p>
      <br />
      <p>Les données personnelles visées par cette Politique de Protection des Données sont traitées de façon à garantir une sécurité appropriée des données à caractère personnel, y compris la protection contre le traitement non autorisé ou illicite et contre la perte, la destruction ou les dégâts d'origine accidentelle, à l'aide de mesures techniques ou organisationnelles appropriées.</p>
      <br />
      <p>Cependant, il est à rappeler qu’aucun mode de transmission sur Internet et aucun mode de stockage des informations électroniques n’est totalement fiable. En conséquence, Smart Education ne peut pas garantir ni la sécurité des données qui leur sont transmises ni celle des données personnelles qu’elle conserve. Les incidents de sécurité qui surviendraient malgré les précautions prises vous seront toutefois notifiés dans le strict respect de la réglementation applicable.</p>
      <br />
      <p>Toutes les données personnelles étant confidentielles, leur accès est limité aux collaborateurs, prestataires et agents de Smart Education qui en ont besoin dans le cadre de l’exécution de leur mission. Toutes les personnes ayant accès à vos données personnelles sont tenues par une obligation de confidentialité. </p>
      <br />
      <p>Toutefois, il importe que vous fassiez preuve de prudence pour empêcher tout accès non autorisé à ces données personnelles, Vous êtes responsable de la confidentialité du mot de passe et des informations figurant dans le compte utilisateur. Par conséquent, vous devez vous assurer de fermer votre session en cas d’utilisation partagée d’un même ordinateur.</p>
      <br />
      <p><b>Article 6 -	Partages et Transferts des Données</b></p>
      <br />
      <p>Smart Education ne transmet aucune donnée personnelle à des tiers, sauf si elles sont nécessaires à la dispense de l’enseignement et du fonctionnement de la Plateforme. L’accès régulier aux données des utilisateurs est réservé aux personnes (groupes) suivantes :</p>
      <br />
      <ol style="padding-left: 40px; list-style: auto;">
        <li>Les élèves concernant leurs propres données personnelles, paramètres et données de formation. </li>
        <li>Enseignants et administrateurs de l’école dans les données personnelles, les paramètres et les données de formation des élèves </li>
        <li>Auteurs, personnel en charge du développement et de l’adaptation des supports d’apprentissage axés sur la demande (vidéos pédagogiques, exercices interactifs) relevant du Ministère de l’Education Nationale, de la Jeunesse et de l’Enfance avec la coopération duquel le programme d’enseignement a été mis en place.</li>
        <li>dans les écoles : les directeurs d'école et les enseignants habilités ;</li>
        <li>dans les établissements scolaires du second degré : les chefs d'établissement et les personnels enseignants et administratifs habilités ;</li>
        <li>au niveau académique : les équipes académiques d'assistance ;</li>
        <li>Au niveau national : les équipes nationales d'assistance et les personnes habilitées au sein du CGIE ;</li>
        <li>Les services tiers en lien avec le Ministère de l’Éducation nationale, de l’Enfance et de la Jeunesse : pour les seules données strictement nécessaires au fonctionnement de leurs services ;</li>
        <li>Les sous-traitants habilités pour les missions de maintenance et d’exploitation de technologie de l’information :
          <ul>
            <li>- <b>OVH SAS</b>: 2 rue Kellermann 59100 Roubaix, France (Service de fourniture de réseau, d’hébergement de serveurs et de distribution de contenu)</li>
            <li>- <b>IT Craft YSA GmbH</b>: Panoramastrasse 1, 10178, Berlin, Germany, with registered company tax identification number HRB 18206. (Société de développement de logiciels)</li>
          </ul>
        </li>
        <li>Le sous-traitant fournisseur de cookies : 
          <ul>
            <li>- <b>Mamoto</b>: Große Bleichen 21, 20354 Hamburg, Deutschland</li>
          </ul>
        </li>
      </ol>

      <br />
      <p>En cas de transferts de données en dehors de l’Espace Economique Européen, Smart Education s’assure que des garanties appropriées sont en place (clauses contractuelles type, décisions d’adéquation, etc.), et que le transfert s’effectue en conformité avec les dispositions du chapitre V du RGPD. </p>
      <br />
      <p>Smart Education peut également être amené à transférer vos données personnelles à des tiers si Smart Education estime qu’un tel transfert est nécessaire pour des raisons techniques (par exemple pour des services de sous-traitance technique) ou pour protéger ses intérêts légaux (par· exemple en cas de vente ou changement de contrôle de Smart Education, ou de liquidation totale ou partielle de Smart Education).</p>
      <br />
      <p>Smart Education peut communiquer vos données personnelles si la loi l’y oblige ou si Smart Education estime de toute bonne foi qu’une telle divulgation est raisonnablement nécessaire pour se conformer à une procédure légale (par exemple un mandat, une citation à comparaître ou toute autre décision de justice) ou pour protéger les droits, les biens ou la sécurité personnelle de Smart Education, de nos clients ou du public.</p>
      <br />
      <p><b>Article 7 -	Date d’entrée en vigueur et révisions de la politique </b></p>
      <br />
      <p>La présente Politique peut être mise à jour en fonction des besoins de Smart Education et des circonstances ou si la loi l’exige. Nous vous invitons par conséquent à prendre régulièrement connaissance des mises à jour.</p>
      <br />
      <p>La présente politique de protection des données personnelles est en vigueur depuis le 10.09.2023.</p>
      <br />
      <p><b>Article 8 -	Conservation des données</b></p>
      <br />
      <p>À l'exception des données de connexion qui sont conservées douze (12) mois, l'ensemble des données recueillies sont conservées durant toute la scolarité de l'élève, et supprimées deux ans après la dernière connexion au compte de l’élève.</p>
      <br />
      <p><b>Article 9 -	Exercices de droits des personnes concernées</b></p>
      <br />
      <p>Pour toute question concernant la présente Politique, ou pour toute demande de rectification, de complément, de mise à jour ou de suppression de vos données personnelles, ou pour tout exercice des autres droits plus amplement décrits à l’article 7 de la présente Politique de Protection des Données, vous pouvez : </p>
      <br />
      <ul style="padding-left: 40px; list-style: disc;">
        <li>Adresser un courrier électronique à l’adresse électronique suivante: contact@indil.education</li>
        <li>Adresser un courrier postal à l’adresse: Smart Education SARL-S, 9, rue du Laboratoire, L-1911 Luxembourg  </li>
      </ul>
      <br />
      <p>Si vous estimez, même après avoir introduit une réclamation auprès de Smart Education, que vos droits en matière de protection des données à caractère personnel ne sont pas respectés, vous avez la possibilité d'introduire une réclamation auprès de la Commission nationale de la Protection des Données (CNPD) en suivant la procédure de réclamation à l’adresse suivante:</p>
      <br />
      <p><a style="color: #4183c4;" href="https://cnpd.public.lu/fr/particuliers/faire-valoir.html">https://cnpd.public.lu/fr/particuliers/faire-valoir.html</a></p>
      <br />
      <p>Dans le cadre de l'exercice de vos droits, vous devez justifier de votre identité par tout moyen. En cas de doute sur votre identité, les services chargés du droit d'accès et, s’il en a été désigné un, le délégué à la protection des données se réservent le droit de vous demander les informations supplémentaires qui leur apparaissent nécessaires, y compris la photocopie d’un titre d’identité portant votre signature.</p>
      `,
    },
    generalConditionsDetails: {
      menuItemTitle: 'Condictions Générales',
      title: "Condictions Générales d'utilisation",
      body: `
      <p><b>CONDITIONS GÉNÉRALES D’UTILISATION</b></p>
      <br />
      <p><i>Date de dernière mise à jour : 18.09.2023</i></p>
      <br />
      <p>Les présentes conditions générales d’utilisation (« <b>CGU</b> ») sont conclues entre Smart Education S.A.R.L.-S., ayant son siège d’activité à 9, rue du Laboratoire, L-1911, Luxembourg, inscrite au Registre de Commerce et des Sociétés, Luxembourg sous le numéro B278733 (« <b>Smart Education</b> ») et toute personne physique (y compris celle représentant ou agissant au nom d’une personne morale) souhaitant utiliser la plateforme d’enseignement.</p>
      <br />
      <br />
      <p><b>Article 1 - Préambule</b></p>
      <br />
      <p>Les présentes CGU décrivent les conditions dans lesquelles utilisées la plateforme élaborée pour servir de support au programme d’enseignement de formation continue en comptabilité. L’enseignement en question est destiné aux élèves de classes de l’enseignement secondaire avec la participation d’enseignants aux services du Ministère de l’Education Nationale, de l’Enfance et de la Jeunesse.</p>
      <br />
      <p>Ce service numérique permet aux élèves des classes spécifiquement désignées de suivre une formation en comptabilité lui permettant d’évoluer dans le système d’enseignement luxembourgeois.</p>
      <br />
      <br />
      <p><b>Article 2 - Définitions des termes</b></p>
      <br />
      <p>Les termes suivants commençant avec une majuscule, au singulier ou au pluriel, employés dans les présentes CGU auront la signification suivante :</p>
      <br />
      <p>« <b>Administrateur</b> » toute personne autre qu’un Utilisateur bénéficiant d’un accès autorisé à la Plateforme afin d’en améliorer le contenu, l’infrastructure ou la sécurité et rapportant à un prestataire d’information et de la technologie désigné par Smart Education.</p>
      <br />
      <p>«<b>Plateforme</b>» désigne la plateforme virtuelle utilisée comme support au programme d’enseignement de formation continue en comptabilité dont le contenu est élaboré par Smart Education tel qu’approuvé par le Ministère chargé de l'Éducation nationale, de l’Enfance et de la Jeunesse.</p>
      <br />
      <p>«<b>Support d’apprentissage</b>» désigne les supports de contenus digitaux réalisés et publiés par Smart Education (vidéos pédagogiques, exercices interactifs, images, pages web ou textes) disponibles sur la Plateforme accessibles par l’Utilisateur via son compte.</p>
      <br />
      <p>«<b>Utilisateur</b>» désigne la personne physique ayant un accès autorisé à la Plateforme soit comme élève, comme enseignant ou comme Administrateur.</p>
      <br />
      <p><b>Hébergeur de la Plateforme :</b></p>
      <p><b>OVH SAS</b> : 2, rue Kellermann 59100 Roubaix, France</p>
      <br />
      <p><b>Editeur de la Plateforme</b> :</p>
      <p><b>Smart Education</b> : 9, rue du Laboratoire, L-1911, Luxembourg</p>
      <br />
      <br />
      <p><b>Article 3 - Accès au service</b></p>
      <br />
      <p>L’accès et l’utilisation de la Plateforme emportent connaissance, consultation et acceptation des présentes CGU.</p>
      <br />
      <p>L’accès à la Plateforme est réservé aux élèves et aux enseignants des classes de 4 GCM, 3 GCM, 4TPCM, 3 TPCM, 3CD, 3CG, 3CN, 2CN 2GCM, 2TPCM, 2CD, 2CG, 1 GCM, 1TPCM, 1CD, 1CG et 1CN telles que définies par le Ministère de l’Education Nationale, de l’Enfance et de la Jeunesse.</p>
      <br />
      <p>Certaines applications proposées par défaut sur la page d’accueil sont des outils nécessitant un abonnement pour fonctionner.</p>
      <br />
      <br />
      <p><b>Article 4 - Création du compte</b></p>
      <br />
      <p>L’Utilisateur s’engage à fournir des informations exactes lors de son inscription et lors de la commande de son compte membre. Il s’engage aussi à mettre à jour régulièrement ses informations personnelles afin de garantir leur exactitude.</p>
      <br />
      <p>L’Utilisateur définit lui-même son mot de passe afin d’assurer la sécurité de son compte dans la session de connexion avec un mot de passe personnel, ou utilise son identifiant IAM pour se connecter par son compte IAM ou le compte de l’adolescent qu’il représente légalement.</p>
      <br />
      <p>Il est de la responsabilité des Utilisateurs inscrits de maintenir confidentiels leurs identifiants.</p>
      <br />
      <p>Smart Education ne sera pas tenu responsable en cas de violation du compte utilisateur si celui-ci est lié à l’utilisation d’un mot de passe trop faible, en cas de connexion avec un mot de passe personnel.</p>
      <br />
      <p>Tout accès à la Plateforme et au compte, toute utilisation de la Plateforme ou plus généralement toute transmission de données effectués à partir des identifiants de l’Utilisateur seront réputés avoir été effectués par celui-ci. A cet égard, l’Utilisateur est tenu de s’assurer qu’à l’issue de chaque session, il se déconnecte effectivement de son compte, en particulier lorsqu’il utilise la Plateforme à partir d’un ordinateur public.</p>
      <br />
      <br />
      <p><b>Article 5 - Compte unique</b></p>
      <br />
      <p>Les Utilisateurs de la Plateforme n’ont besoin et ne pourront disposer que d’un seul et même compte.</p>
      <br />
      <br />
      <p><b>Article 6 - Contenu du Site</b></p>
      <p>Smart Education assurera au mieux l’exactitude des informations ou services contenus sur la Plateforme. Cependant, sa responsabilité ne saurait être retenue notamment en cas d’omission de mise à jour d’une information ou d’un formulaire, en cas d’erreurs de manipulation du système ou d’encodage, d’inexactitudes, de lacunes dans les informations fournies ou d’éventuelles erreurs, inexactitudes ou omissions. En effet, l'objectif est de diffuser des informations exactes et à jour provenant de diverses sources, mais Smart Education ne saurait toutefois éviter tout risque d'erreur matérielle. Aucune des informations publiées sur la Plateforme n'ont une vocation d'exhaustivité ou constituent un engagement de la part de Smart Education.</p>
      <br />
      <p>Smart Education pourra à tout moment retirer, ajouter, compléter ou préciser tout ou partie des informations et services contenus ou proposés sur la Plateforme. Aucune responsabilité pour un quelconque dommage direct ou indirect en relation avec de telles modifications ne pourra être retenue à l’encontre de Smart Education.</p>
      <br />
      <br />
      <p><b>Article 7 - Disponibilité</b></p>
      <br />
      <p>Smart Education fera tout son possible pour assurer une disponibilité et accessibilité maximale de la Plateforme, néanmoins la société ne peut pas toujours garantir sa disponibilité continue.</p>
      <br />
      <p>En utilisant la Plateforme, l’Utilisateur reconnait et accepte que (i) l'accès est fourni en l'état et selon sa disponibilité, sans garantie d'aucune sorte, expresse ou implicite, et que (ii) son utilisation est régie par des conditions techniques et des restrictions d'utilisation en termes de compatibilité et d'interopérabilité. L’Utilisateur s’engage à se conformer à ces conditions techniques.</p>
      <br />
      <p>En utilisant la Plateforme, l’Utilisateur reconnait les caractéristiques d'Internet et accepte que Smart Education ne soit pas responsable des interruptions du système de protocole Internet résultant d'un acte du fournisseur de services Internet ou d'un acte indépendant de la volonté de Smart Education.</p>
      <br />
      <p>Plus généralement, Smart Education n’est pas responsable et ne peut faire l’objet d’une demande d’indemnisation pour toute interruption d'accès, tout dysfonctionnement de la Plateforme ou toute perte de données résultant du non-respect des présentes CGU, des conditions techniques ou de ses recommandations, d'une utilisation inappropriée ou illégale de la Plateforme, d'un cas de force majeure, d'événements dépendant de tiers tels que les opérateurs de télécommunications, d'actes ou d'omissions de la part de l’Utilisateur ou d'attaques contre le système informatique.</p>
      <br />
      <p>Lorsque l’Utilisateur utilise la Plateforme, il peut être amené à cliquer sur des liens qui le redirigeront vers des prestataires de services tiers. Smart Education ne peut être tenue responsable de l'indisponibilité de ces sites ou services tiers. D'une manière générale, Smart Education ne peut être tenue responsable du fonctionnement, du contenu ou de tout autre aspect des sites ou services tiers, ni des dommages directs ou indirects causés par le suivi d'un lien vers un site ou un service tiers. Smart Education n'est pas responsable du traitement des données (personnelles) qui a lieu sur ces sites web tiers.</p>
      <br />
      <br />
      <p><b>Article 8 - Maintenance</b></p>
      <br />
      <p>Smart Education se réserve le droit de changer, modifier ou interrompre, sans préavis, tout ou partie de la Plateforme pour des raisons de maintenance, de mise à niveau ou pour toute autre raison jugée nécessaire.</p>
      <br />
      <p>En utilisant la Plateforme, l’Utilisateur comprend et accepte que la Smart Education effectue des opérations de maintenance sur la Plateforme et que toute interruption de l'accès à la Plateforme résultant de cette maintenance ne constitue pas un manquement de la Plateforme aux présentes CGU. L'interruption de l'accès à la Plateforme ne donne pas droit à une indemnisation de l'Utilisateur.</p>
      <br />
      <br />
      <p><b>Article 9 - Propriété intellectuelle</b></p>
      <br />
      <p>Le contenu proposé sur la Plateforme est la propriété de la société Smart Education. Elle est propriétaire des droits de propriété intellectuelle et détient les droits d’usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logos, icônes, sons, logiciels sous réserve de droits qui appartiendraient à des tiers.</p>
      <p>Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments de la Plateforme, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de Smart Education.</p>
      <br />
      <p>Toute exploitation non autorisée de la Plateforme ou de l’un quelconque des éléments qu’elle contient sera considérée comme constitutive d’une contrefaçon et pourra être poursuivie devant les instances judiciaires.</p>
      <br />
      <br />
      <p><b>Article 10 - Politique de protection des données personnelles</b></p>
      <br>
      <p>La politique de protection des données personnelles de la société Smart Education est disponible sur cette page :  [Politique de protection des données personnelles]</p>
      <br />
      <br />
      <p><b>Article 11 - Configuration technique minimale requise et signalement des problèmes techniques</b></p>
      <br />
      <p>La Plateforme est accessible à la condition de disposer d’une connexion internet, mais la création d’un compte utilisateur est nécessaire pour accéder à la partie personnalisation de page d’accueil, compte élève, ou compte enseignant.</p>
      <br />
      <p>Pour bénéficier de toutes les fonctionnalités, JavaScript doit être activé dans le navigateur sans quoi, des dysfonctionnements importants de la Plateforme pourront être constatés.</p>
      <br />
      <p>En utilisant la Plateforme, l’Utilisateur confirme qu’il possède le matériel informatique approprié lui permettant d’utiliser la Plateforme et convient de n’utiliser aucun dispositif ou de n’entreprendre aucune action susceptible de causer un préjudice à Smart Education. L’Utilisateur entreprend toutes les actions nécessaires pour limiter les préjudices qui pourraient résulter d’une corruption éventuelle de son matériel informatique lors de l’accès à la Plateforme.</p>
      <br/>
      <p>Dans le cadre de l’utilisation de la Plateforme, l’Utilisateur peut ouvrir un ticket, par le biais d’envoyer un email au contact@indil.educaiton, afin de signaler des problèmes techniques en cas d’indisponibilité de la Plateforme consécutive à des défaillances techniques causées par Smart Education, et à défaut de leur résolution dans des délais brefs, Smart Education déploiera ses meilleurs efforts pour résoudre ces défaillances dès que possible, à condition que :</p>
      <br />
      <ol>
        <li>1.	l’Utilisateur les a décrites de manière précise lors de l’ouverture du ticket ;</li>
        <li>2.	l’Utilisateur coopère entièrement avec Smart Education ; et que</li>
        <li>3.	de telles défaillances ne découlent pas d’un usage abusif de la plateforme qui serait imputable à l’Utilisateur.</li>
      </ol>
      <br />
      <br />
      <p><b>Article 12 - Conditions d’âge et d’autorisation des représentants légaux </b></p>
      <br />
      <p>En utilisant cette plateforme, l’Utilisateur déclare être légalement majeur selon les lois du Grand-Duché du Luxembourg, ou avoir obtenu l'autorisation expresse de ses représentants légaux pour accéder à cette plateforme.</p>
      <br />
      <p>Le représentant légal d'un Utilisateur mineur reconnait et accepte la responsabilité de superviser l'utilisation de la Plateforme par le mineur et de donner son autorisation à son utilisation.</p>
      <br />
      <p>Smart Education se réserve le droit de demander la preuve de l'âge ou de l'autorisation des représentants légaux à tout moment et de restreindre ou de mettre fin à l'accès à la Plateforme en cas de non-respect de cette exigence.</p>
      <br />
      <p><b>Article 13 - Obligations de l’Utilisateur</b></p>
      <br />
      <p>L’Utilisateur s’engage à utiliser la Plateforme conformément à toutes les lois applicables et aux présentes CGU pendant toute la durée de son utilisation. L’Utilisateur garantit à tout moment qu’il n’utilisera pas la Plateforme afin de :</p>
      <br />
      <ul>
        <li>-	entreprendre ou promouvoir des activités illégales, ou des informations mensongères ou trompeuses ;</li>
        <li>-	copier de manière illicite ou non-autorisée des travaux protégés par des droits d’auteurs, des brevets ou des marques ;</li>
        <li>-	stimuler, inciter ou encourager l’envoi de courriels, de chaînes de lettres, l’envoi en masse de courriels non sollicités, l’envoi de messages instantanés, de messages publicitaires indésirables ou de courriers non sollicités ;</li>
        <li>-	promouvoir ou encourager tout acte ou activité criminelle, ou fournir des orientations ou des consignes sur la promotion d’activités criminelles, l’atteinte à la vie privée, la diffusion ou la création de virus informatiques ;</li>
        <li>-	solliciter les mots de passe ou les informations personnelles des autres Utilisateurs à des fins commerciales illicites ;</li>
        <li>-	distribuer, reproduire, publier ou modifier des matériels protégés par des droits d’auteurs, des marques de commerce ou d’autres droits exclusifs dont les tiers sont titulaires, de toute façon, sans leur accord préalable.</li>
      </ul>
      <br/>
      <br />
      <p><b>Article 14 - Durée et résiliation</b></p>
      <br />
      <p>Smart Education se réserve le droit de suspendre le compte de l’Utilisateur toute la durée pendant laquelle ce dernier se maintiendra en contravention avec les règles édictées aux présentes CGU et/ou aux lois applicables. L’Utilisateur n'aura droit à aucune indemnité de quelque nature que ce soit sur la base de la suspension, de l'interdiction ou de la résiliation de l'accès à la Plateforme résultant de sa seule faute.</p>
      <br />
      <p>Smart Education pourra aller jusqu’à supprimer sans mise en demeure préalable le compte de l’Utilisateur qui :</p>
      <br />
      <ul>
        <li>- Ouvre plus d’un compte ou ouvre un compte usurpant l’identité d’un tier,</li>
        <li>- Manque à l’une de ses obligations visées dans les présentes CGU,</li>
        <li>- Utilise le Service pour communiquer des contenus inappropriés (illégaux, portant atteinte à la dignité humaine, à caractère sexuels, diffamatoires, injurieux, racistes, portant atteinte aux droits des tiers ou tout autre contenu pouvant engager la responsabilité civile ou pénale de la personne qui le publie),</li>
        <li>- Utilise plus généralement la Plateforme ou son compte à des fins frauduleuses ou illégales,</li>
        <li>- ou provoque une situation d’une gravité similaire.</li>
      </ul>
      <br />
      <p>A l’exception des données de connexion, l'ensemble des données recueillies sont conservées durant toute la scolarité de l'élève, et supprimées deux ans après la dernière connexion au compte de l’élève.</p>
      <br />
      <br />
      <p><b>Article 15 - Modification</b></p>
      <br />
      <p>Les présentes CGU pourront être modifiées par Smart Education à tout moment et sans préavis.</p>
      <p>L’Utilisateur est donc invité à consulter régulièrement la dernière version mise à jour disponible.</p>
      <p>Les CGU modifiées entreront en vigueur à compter de leur mise en ligne, et s’appliqueront dès lors de plein droit.</p>
      <br />
      <br />
      <p><b>Article 16 - Contact</b></p>
      <br />
      <p>Pour toute question ou demande, merci d’adresser un email à cette adresse [contact@indil.education] ».</p>
      <br />
      <br />
      <p><b>Article 17 - Dispositions finales</b></p>
      <br />
      <p>Tout litige découlant de l'utilisation de la Plateforme sera régi par les lois du Grand-Duché du Luxembourg et sera soumis à la compétence exclusive des Tribunaux du Grand-duché de Luxembourg.</p>
      `,
    },
  },
}
