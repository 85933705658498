import React from 'react'
import {useParams} from 'react-router-dom'
import {Dimmer, Message, Segment} from 'semantic-ui-react'
import {authAxios} from '../../../config/axiosConfig'
import {useLanguage} from '../../../context/languageContext'
import useAsync from '../../../hooks/useAsync'
import {content} from '../../../localization/content'
import { formatResponseError } from '../../../utils/format-response'
import {truncate} from '../../../utils/string'
import TeacherChapterTabs from './teacher-chapter-tabs'
import TEacherHomeWorkRoom from './teacher-homework-room'
import TeacherPdf from './teacher-pdf'
import TeacherPracticeRoom from './teacher-practice-room'

const ChapterContent = () => {
  const [activeMenu, setActiveMenu] = React.useState('practicRoom')
  const [chapterData, setChapterData] = React.useState()

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  const {classid, subjectid, chapterid} = useParams()

  const {
    run: getchapter,
    error: errorGettingchapter,
    isLoading: gettingchapter,
  } = useAsync()

  React.useEffect(() => {
    if (chapterid)
      getchapter(authAxios.get(`/chapter/${chapterid}`)).then(({data}) => {
        setChapterData(data)
      })
  }, [chapterid, getchapter])

  let chName = chapterData?.chapterName || ''

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent w-full">
        {errorGettingchapter ? (
          <Message error list={formatResponseError(errorGettingchapter)} />
        ) : null}
        <Dimmer active={gettingchapter} inverted>
          {/* <Loader active={true} /> */}
        </Dimmer>

        <p className="my-2 text-3xl font-semibold  ">
          {langContent.Chapter}: {truncate(chName, 55)}
        </p>

        <div className="flex justify-between items-center">
          <TeacherChapterTabs
            activeMenu={activeMenu}
            setActiveMenu={value => setActiveMenu(value)}
          />
        </div>
        {/* <hr /> */}
        <div className="mt-2">
          {activeMenu === 'practicRoom' && <TeacherPracticeRoom />}
          {activeMenu === 'homeworkRoom' && <TEacherHomeWorkRoom />}
          {activeMenu === 'studentPdf' && <TeacherPdf />}
        </div>
      </Segment>
    </div>
  )
}

export default ChapterContent
