import React from 'react'
import {FaLink, FaSchool, FaUserGraduate} from 'react-icons/fa'
import {Link} from 'react-router-dom'
import routes from '../../routes'

const VoucherClassCard = ({
  classname,
  school,
  voucherCode,
  classid,
  subjectId,
}) => {
  return (
    <Link
      className="mt-1 cursor-pointer hover:text-current animate__animated animate__fadeInUp"
      to={routes.voucherStudent.chapters.all(classid, subjectId)}
    >
      <div className="w-72 shadow-md hover:opacity-90 rounded-md">
        <div className="bg-indigo-200 h-40 border-b-4 rounded-md border-indigo-400 flex justify-center items-center">
          <p className="font-semibold text-lg">{classname}</p>
        </div>

        {/* <div className="py-4 px-2">
          <div className="flex items-center">
            <FaUserGraduate />
            <p className="ml-2">Students numbers: 30</p>
          </div>

          <div className="flex items-center my-2">
            <FaSchool />
            <p className="ml-2">{school}</p>
          </div>

          <div className="flex items-center">
            <FaLink />
            <p className="ml-2"> {voucherCode}</p>
          </div>
        </div> */}
      </div>
    </Link>
  )
}

export default VoucherClassCard
