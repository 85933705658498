import React from 'react'
import { useHistory, useLocation,useParams} from 'react-router-dom'
import {Dimmer, Loader, Message, Segment, Pagination} from 'semantic-ui-react'
import PdfCard from '../../../components/shared/pdf-card'
import {authAxios} from '../../../config/axiosConfig'
import useAsync from '../../../hooks/useAsync'
import {formatResponseError} from '../../../utils/format-response'
import ChapterPdfCard from './chapter-pdf-card'
import queryString from 'query-string'

const StudentPdf = () => {
  const [chapterPdfs, setChapterPdfs] = React.useState([])
  const [contentPdfsData, setContentPdfsData] = React.useState([])

  const {chapterid, classid, subjectid} = useParams()

  const [page, setPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(1)
  const [limit, setLimit] = React.useState(10)

  const history = useHistory()
  const location = useLocation()
  

  const {
    run: getContentPdfs,
    error: errorGettingContentPdfs,
    isLoading: gettingContentPdfs,
  } = useAsync()

  const {
    run: getChapterPdf,
    error: errorGettingChapterPdf,
    isLoading: gettingChapterPdf,
  } = useAsync()

  // React.useEffect(() => {
  //   getContentPdfs(
  //     authAxios.get(`/school/subject/pdf/all?sid=${subjectid}`),
  //   ).then(({data}) => {
  //     console.log(data)
  //     setContentPdfsData(data.docs)
  //   })
  // }, [getContentPdfs, subjectid])

  React.useEffect(() => {
    getChapterPdf(
      authAxios.get(`/pdf/all/?class=${classid}&chapter=${chapterid}`),
    ).then(({data}) => {
      console.log(data)
      setChapterPdfs(data)
    })
  }, [chapterid, classid, getChapterPdf])


  React.useEffect(() => {
    const {
      page = 1,
      limit = 10,
      search = '',
    } = queryString.parse(location.search)

    setPage(page)
    setLimit(limit)

    getContentPdfs(
        authAxios.get(
          `/school/subject/pdf/all?sid=${subjectid}&page=${page}&limit=${limit}`,
        ),
      ).then(({data}) => {
        setContentPdfsData(data.docs)
        setTotalPages(data.totalPages)
      })
  }, [getContentPdfs, location.search, subjectid])

  const handleOnPageChange = (e, {activePage}) => {
    history.push(
      `${location.pathname}?page=${activePage}&limit=${limit}`,
    )
  }



  return (
    <div>
      <Segment className="border-none shadow-none  bg-transparent h-screen relative mb-10">
        {errorGettingChapterPdf ? (
          <Message error list={formatResponseError(errorGettingChapterPdf)} />
        ) : null}

        {errorGettingContentPdfs ? (
          <Message error list={formatResponseError(errorGettingContentPdfs)} />
        ) : null}

        <Dimmer active={gettingChapterPdf || gettingContentPdfs} inverted>
          <Loader active={true} />
        </Dimmer>


        <h3 className='mt-12 mb-6'>Exercises pdfs : </h3>

        <div className="flex flex-wrap gap-x-5 gap-y-5 items-center justify-start">
          {chapterPdfs?.map((a, idx) => (
            <ChapterPdfCard data={a} key={a?._id} id={a?._id} />
          ))}
        </div>

        <hr className='mt-10'/>
        <h3 className='mt-12 mb-6'>Content pdfs : </h3>
        <div className="flex flex-wrap gap-x-5 gap-y-5 items-center justify-start">
          {contentPdfsData?.map((a, idx) => (
            <PdfCard key={a?._id} title={a?.title} pdfLink={a?.link} />
          ))}
        </div>

        {contentPdfsData?.length > 0 ?(
          <div className="my-8 flex justify-end ">
          <Pagination
            className="shadow-md my-8"
            activePage={page}
            onPageChange={handleOnPageChange}
            totalPages={totalPages}
          />
        </div>
        ):(null)}

      </Segment>
    </div>
  )
}

export default StudentPdf
