import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Dimmer, Loader, Message, Pagination, Segment } from 'semantic-ui-react'
import queryString from 'query-string'

import PdfCard from '../../../components/shared/pdf-card'
import { authAxios } from '../../../config/axiosConfig'
import useAsync from '../../../hooks/useAsync'
import { formatResponseError } from '../../../utils/format-response'

const ClassPdfs = () => {
  const {classid, subjectid} = useParams()
  const [pdfsData, setPdfsData] = React.useState([])


  const [page, setPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(1)
  const [limit, setLimit] = React.useState(10)

  const history = useHistory()
  const location = useLocation()
  

  const {
    run: getPdfs,
    error: errorGettingPdfs,
    isLoading: gettingPdfs,
  } = useAsync()

  // React.useEffect(() => {
  //   getPdfs(authAxios.get(`/school/subject/pdf/all?sid=${subjectid}`)).then(
  //     ({data}) => {
  //       console.log(data)
  //       setPdfsData(data.docs)
  //     },
  //   )
  // }, [getPdfs, subjectid])



  React.useEffect(() => {
    const {
      page = 1,
      limit = 10,
      search = '',
    } = queryString.parse(location.search)

    setPage(page)
    setLimit(limit)

      getPdfs(
        authAxios.get(
          `/school/subject/pdf/all?sid=${subjectid}&page=${page}&limit=${limit}`,
        ),
      ).then(({data}) => {
        setPdfsData(data.docs)
        setTotalPages(data.totalPages)
      })
  }, [getPdfs, location.search, subjectid])

  const handleOnPageChange = (e, {activePage}) => {
    history.push(
      `${location.pathname}?page=${activePage}&limit=${limit}`,
    )
  }


  return (
    <div className='px-10  h-screen'>
      <Segment className="border-none shadow-none  bg-transparent h-screen relative ">
        {errorGettingPdfs ? (
          <Message error list={formatResponseError(errorGettingPdfs)} />
        ) : null}
        
        <Dimmer active={gettingPdfs} inverted>
          <Loader active={true} />
        </Dimmer>
        

        <div className="mt-12 flex flex-wrap gap-x-5 gap-y-5 items-center justify-start">
          {pdfsData?.map((a, idx) => (
            <PdfCard
              key={a?._id}
              title={a?.title}
              pdfLink={a?.link}
            />
          ))}
        </div>

        {pdfsData?.length > 0 ?(
          <div className="my-8 flex justify-end ">
          <Pagination
            className="shadow-md my-8"
            activePage={page}
            onPageChange={handleOnPageChange}
            totalPages={totalPages}
          />
        </div>
        ):(null)}
        
      </Segment>
    </div>
  )
}

export default ClassPdfs
