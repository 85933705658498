import React from 'react'
import {AiOutlineCaretDown, AiOutlineCaretRight} from 'react-icons/ai'
import {Dimmer, Loader, Message, Segment, Table} from 'semantic-ui-react'
import {truncate} from '../../../utils/string'
import ReactApexChart from 'react-apexcharts'
import {useParams} from 'react-router-dom'
import useAsync from '../../../hooks/useAsync'
import {authAxios} from '../../../config/axiosConfig'
import {formatResponseError} from '../../../utils/format-response'
import QuestionBreakDownDetails from './question-breakdown-details'
import {Fragment} from 'react/cjs/react.production.min'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'

const TeacherExerciseQuestionBreakdown = () => {
  const [rowToExpand, setRowToExpand] = React.useState(null)
  const {classid, subjectid, chapterid, exerciseid} = useParams()
  const [exerciseData, setExerciseData] = React.useState([])

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  const [state, setState] = React.useState({
    series: [44, 55, 13, 43, 22],
    options: {
      chart: {
        width: '100%',
        type: 'pie',
      },
      labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  })

  const {
    run: getExerciseDetails,
    error: errorGettingExerciseDetails,
    isLoading: gettingExerciseDetails,
  } = useAsync()

  React.useEffect(() => {
    getExerciseDetails(
      authAxios.get(
        `/instructor/class/chapter/exercise/questions/?classId=${classid}&chapter=${chapterid}&exercise=${exerciseid}`,
      ),
    ).then(({data}) => {
      console.log(data)
      setExerciseData(data)
    })
  }, [chapterid, classid, exerciseid, getExerciseDetails])

  let fakeData = [
    {_id: '61e048ce84408f1fb0c0f7a5', name: 'ACHAT 3', __v: 0},
    {_id: '61b087d604e0a6920bcbeff3', name: 'Achats 2', __v: 0},
    {
      _id: '61a8ee0473542a10309ae931',
      name: 'Achats marchandises fournisseur',
      __v: 0,
    },
    {
      _id: '61af83de95bf31487bf42501',
      name: 'AchatsElectriciteFournisseur',
      __v: 0,
    },
    {_id: '61b0c55a61d41b263b36884d', name: 'AchatsMsesFournisseur', __v: 0},
    {
      _id: '61af83bb95bf31487bf424f1',
      name: 'AchatsVehiculeFournisseur',
      __v: 0,
    },
    {
      _id: '61b0813004e0a6920bcbef96',
      name: 'ChargeElectriciteFournisseur',
      __v: 0,
    },
    {
      _id: '61deb096836ffe6fd9c4c07a',
      name: 'ChargeFraisPersonnelBanque',
      __v: 0,
    },
    {_id: '61af7f2595bf31487bf424ac', name: 'ChargeInterestsBanque', __v: 0},
    {
      _id: '61af87b395bf31487bf4255e',
      name: 'ChargeReparationFournisseur',
      __v: 0,
    },
  ]

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent ">
        {errorGettingExerciseDetails ? (
          <Message
            error
            list={formatResponseError(errorGettingExerciseDetails)}
          />
        ) : null}
        <Dimmer active={gettingExerciseDetails} inverted>
          <Loader active={true} />
        </Dimmer>
        <Table striped className="shadow-lg my-5">
          <Table.Header>
            <Table.Row className="text-sm">
              <Table.HeaderCell className="bg-tableHeader">#</Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader">
                Question
              </Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader">
                {langContent.Number_of_students}
              </Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader">
                {langContent.Avergae_score}
              </Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader">
                {langContent.Avergae_number_of_hints}
              </Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader">
                {langContent.Avergae_time}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {exerciseData.map((a, idx) => (
              <Fragment key={idx}>
                <Table.Row textAlign="left" verticalAlign="top">
                  <Table.Cell
                    textAlign="center"
                    selectable
                    onClick={() =>
                      rowToExpand?.questionId === a?.questionId
                        ? setRowToExpand(null)
                        : setRowToExpand(a)
                    }
                  >
                    {rowToExpand?.questionId === a.questionId ? (
                      <AiOutlineCaretDown className="mx-auto mt-4" size="16" />
                    ) : (
                      <AiOutlineCaretRight className="mx-auto mt-4" size="16" />
                    )}
                  </Table.Cell>
                  <Table.Cell>{a?.questionTitle}</Table.Cell>
                  <Table.Cell>{a?.studentsNumber}</Table.Cell>
                  <Table.Cell>
                    {a?.averageScore && parseFloat(a?.averageScore).toFixed(0)}%
                  </Table.Cell>
                  <Table.Cell>{parseInt(a?.averageHints)}</Table.Cell>
                  <Table.Cell>
                    {parseInt(a?.averageTime?.hours)} :{' '}
                    {parseInt(a?.averageTime?.minutes)} :{' '}
                    {parseInt(a?.averageTime?.seconds)}
                  </Table.Cell>
                </Table.Row>

                {rowToExpand?.questionId === a.questionId && (
                  <Fragment key={a?.questionId}>
                    <QuestionBreakDownDetails rowToExpand={rowToExpand} />
                  </Fragment>
                )}
              </Fragment>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    </div>
  )
}

export default TeacherExerciseQuestionBreakdown
