import {Formik} from 'formik'
import {Link, useHistory} from 'react-router-dom'
import {Button, Checkbox, Form, Message} from 'semantic-ui-react'
import FormikControl from '../formik/FormikControl'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'
import routes from '../../routes'
import * as Yup from 'yup'
import useAsync from '../../hooks/useAsync'
import {axios} from '../../config/axiosConfig'
import {useEffect, useState} from 'react'
import {useToasts} from 'react-toast-notifications'
import {formatResponseError} from '../../utils/format-response'
import Privacy from '../shared/privacy'

const StdRegisterWithoutVoucher = () => {
  const [lang] = useLanguage()
  const langContent = content[lang]
  const history = useHistory()
  const [subjects, setSubjects] = useState([])
  const [countries, setCountries] = useState([])

  const [checkPrivacy, setCheckPrivacy] = useState(false)
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false)

  const {run, isLoading, isError, error} = useAsync()
  const {run: getSubjects, isLoading: gettingSubjects} = useAsync()
  const {run: getCountries, isLoading: gettingCountries} = useAsync()

  const {addToast} = useToasts()

  const registerSchema = Yup.object({
    firstname: Yup.string()
      .trim()
      .min(3, 'must be more than 3 characters')
      .max(25, 'must be less than 25 characters')
      .required(langContent.required),
    lastname: Yup.string()
      .trim()
      .min(3, 'must be more than 3 characters')
      .max(25, 'must be less than 25 characters')
      .required(langContent.required),
    // subject: Yup.string().required('You have to select one subject'),
    country: Yup.string().required('You have to select one country'),
    university: Yup.string()
      .trim()
      .min(5, 'must be more than 5 characters')
      .max(50, 'must be less than 25 characters')
      .required(langContent.required),
    email: Yup.string()
      .trim()
      .email(langContent.required)
      .required(langContent.required)
      .trim(),
    password: Yup.string()
      .trim()
      .min(8, 'password must be more than 8 characters')
      .max(20, 'password must be less than 20 characters')
      .required(langContent.required),
    confirmPassword: Yup.string()
      .trim()
      .oneOf([Yup.ref('password'), null], langContent.notMatch)
      .required(langContent.required),
  })

  const handleOnSubmit = values => {
    console.log(values)
    run(axios.post('/user/signup', values)).then(() => {
      addToast(langContent.Please_check_your_email, {
        appearance: 'info',
        autoDismiss: false,
      })
      history.push(routes.home)
    })
  }

  useEffect(() => {
    getSubjects(axios.get('/school/subject/all')).then(({data}) => {
      // console.log(data)
      // console.log(data?.subjects[0].name)
      let subjectsOptions = []

      data?.subjects?.map(d =>
        subjectsOptions.push({
          key: d._id,
          text: d.name,
          value: d._id,
        }),
      )
      setSubjects(subjectsOptions)
    })

    getCountries(axios.get('school/country/all')).then(({data}) => {
      console.log(data)
      let countryOptions = []

      data?.countries?.map(d =>
        countryOptions.push({
          key: d._id,
          text: d.name,
          value: d._id,
        }),
      )
      setCountries(countryOptions)
    })
  }, [getSubjects, getCountries])

  return (
    <div>
      {isError ? <Message error list={formatResponseError(error)} /> : null}
      <Formik
        initialValues={{
          firstname: '',
          lastname: '',
          email: '',
          password: '',
          confirmPassword: '',
          university: '',
          country: '',
          subject: '',
        }}
        validationSchema={registerSchema}
        onSubmit={handleOnSubmit}
      >
        {formik => (
          <Form loading={isLoading} onSubmit={formik.submitForm}>
            <Form.Field required>
              <FormikControl
                name="firstname"
                className="font-semibold"
                label={langContent.First_Name}
                control="input"
              />
            </Form.Field>

            <Form.Field required>
              <FormikControl
                name="lastname"
                className="font-semibold"
                label={langContent.Last_Name}
                control="input"
              />
            </Form.Field>

            <Form.Field required>
              <FormikControl
                name="email"
                label={langContent.Email_Address}
                control="input"
                type="email"
              />
            </Form.Field>

            <Form.Field required>
              <FormikControl
                name="password"
                label={langContent.Password}
                control="input"
                type="password"
              />
            </Form.Field>

            <Form.Field required>
              <FormikControl
                name="confirmPassword"
                label={langContent.Confirm_password}
                control="input"
                type="password"
              />
            </Form.Field>

            <Form.Field>
              <FormikControl
                name="university"
                control="input"
                label={langContent.University}
              />
            </Form.Field>

            {/* <Form.Field>
              <FormikControl
                name="subject"
                control="dropdown"
                label={langContent.subject}
                options={subjects}
                fluid
                selection
                loading={gettingSubjects}
              />
            </Form.Field> */}

            <Form.Field>
              <FormikControl
                name="country"
                control="dropdown"
                label={langContent.Country}
                options={countries}
                fluid
                selection
                loading={gettingCountries}
              />
            </Form.Field>
            {/* <div className="flex mb-4">
              <Checkbox
                label="I agree to"
                onClick={() => {
                  setCheckPrivacy(p => !p)
                }}
              />

              <div
                className="ml-1 -mt-0.5 underline cursor-pointer hover:text-blue-700"
                onClick={() => setOpenPrivacyModal(true)}
              >
                Privacy and Policy
              </div>
            </div> */}

            <div className="text-center">
              <Button
                // disabled={!checkPrivacy}
                content={langContent.Register}
                type="submit"
                fluid
                className="bg-primary px-28 text-white"
              />
            </div>
          </Form>
        )}
      </Formik>

      <Privacy
        open={openPrivacyModal}
        onClose={() => setOpenPrivacyModal(false)}
      />
    </div>
  )
}

export default StdRegisterWithoutVoucher
