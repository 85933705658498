import React from 'react'
import {Switch, Route} from 'react-router-dom'
import {useHistory} from 'react-router'
import {useUser} from '../context/user-context'

import Login from '../pages/auth/Login'
import Register from '../pages/auth/Register'
import routes from '../routes'
import Privacy from '../components/shared/privacy'

const AuthLayout = () => {
  const {getUser} = useUser()
  const user = getUser()
  const history = useHistory()

  React.useEffect(() => {
    if (user?.email) history.push(routes.home)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email])

  return (
    <div>
      <Switch>
        <Route path={routes.login} component={Login} />
        <Route path={routes.register} component={Register} />
      </Switch>
    </div>
  )
}

export default AuthLayout
