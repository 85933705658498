import React, {useState} from 'react'
import NonVoucherStudentChapter from './non-voucher-student-chapters'
import NonVoucherStudentExercise from './non-voucher-student-exercise'
import NonCoucherStudentRoomTabs from './non-voucher-student-room-tabs'


const NonVoucherStudentRoom = () => {
  const [activeMenu, setActiveMenu] = useState('studentChapters')

  // const [activeMenu, setActiveMenu] = useLocalStorage(
  //   'activeMenu',
  //   'studentChapters',
  // )

  return (
    <div>
      <div className="">
        <NonCoucherStudentRoomTabs
          activeMenu={activeMenu}
          setActiveMenu={value => setActiveMenu(value)}
        />
      </div>

      <div className="mt-2 ">
        {activeMenu === 'studentChapters' && <NonVoucherStudentChapter />}
        {activeMenu === 'studentExercises' && <NonVoucherStudentExercise />}
      </div>
    </div>
  )
}

export default NonVoucherStudentRoom
