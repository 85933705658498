import React from 'react'
import {useHistory} from 'react-router-dom'
import {Button} from 'semantic-ui-react'
import routes from '../../routes'

import logo from '../../assets/images/logo.png'
import pendingRequest from '../../assets/images/pendingRequest.svg'
import { useLanguage } from '../../context/languageContext'
import { content } from '../../localization/content'

const PendingRequest = () => {
  const history = useHistory()
  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  return (
    <div className=" h-screen flex justify-center items-center py-6 md:py-20">
      <div className=" flex flex-col items-center">
        <img src={logo} alt="Indil logo" className="w-28 md:w-36 py-4" />

        <img src={pendingRequest} alt="Pending Request" className="my-8" />

        <p className="text-xl">{langContent.Your_request_has_been_sent_to_the_admin}</p>
        <p className="text-xl">
          {langContent.It_might_take_up_to_48_hours_to_receive_a_response}
        </p>

        <Button
          primary
          className="rounded-md px-16 mt-4"
          onClick={() => history.push(routes.home)}
        >
          {' '}
          Back to landing page{' '}
        </Button>
      </div>
    </div>
  )
}

export default PendingRequest
