import React, {useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {Breadcrumb, Button, Dimmer, Loader, Message, Segment} from 'semantic-ui-react'

import { authAxios } from '../../../config/axiosConfig'
import useAsync from '../../../hooks/useAsync'
import routes from '../../../routes'
import { formatResponseError } from '../../../utils/format-response'
import { truncate } from '../../../utils/string'
import NonVoucherChapterTabs from './nonVoucher-chapter-tabs'
import NonVoucherHomework from './nonVoucher-homework'
import NonVoucherPracticeRoom from './nonVoucher-practice'



const NonVoucherChapterContent = () => {
  const [activeMenu, setActiveMenu] = useState('practicRoom')
  const [chapterData, setChapterData] = React.useState()
  const [forceRelood, setForceRelood] = React.useState(false)
  const history = useHistory()

  const {run: archieveChapter, isLoading: archievingChapter} = useAsync()

  const { classid,subjectid, chapterid} = useParams()

  const {
    run: getchapter,
    error: errorGettingchapter,
    isLoading: gettingchapter,
  } = useAsync()

  React.useEffect(() => {
    if (chapterid)
      getchapter(authAxios.get(`/chapter/${chapterid}`)).then(({data}) => {
        setChapterData(data)
      })
  }, [chapterid, getchapter, forceRelood])

  let chName = chapterData?.chapterName || ''

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent w-full">

      {errorGettingchapter ? (
        <Message error list={formatResponseError(errorGettingchapter)} />
      ) : null}
      <Dimmer active={archievingChapter || gettingchapter} inverted>
        {/* <Loader active={true} /> */}
      </Dimmer>
      <p className="my-2 text-3xl font-semibold  ">
        Chapter: {truncate(chName, 55)}
      </p>

      <Breadcrumb className="my-4">
          <Breadcrumb.Section onClick={() => history.push('/')} link>
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider>/</Breadcrumb.Divider>
          <Breadcrumb.Section
            onClick={() => history.push(routes.nonVoucherStudent.dashboard)}
            link
          >
            {' '}
            Subject{' '}
          </Breadcrumb.Section>
          <Breadcrumb.Divider>/</Breadcrumb.Divider>
          <Breadcrumb.Section onClick={() => history.push(routes.nonVoucherStudent.chapters.all(subjectid))}> Chapters </Breadcrumb.Section>
          <Breadcrumb.Divider>/</Breadcrumb.Divider>
          <Breadcrumb.Section active>{truncate(chName, 55)} </Breadcrumb.Section>
        </Breadcrumb>

      <div className="flex justify-between items-center">
        <NonVoucherChapterTabs
          activeMenu={activeMenu}
          setActiveMenu={value => setActiveMenu(value)}
        />
       
        {/* <Button
          className="text-primary bg-transparent border-solid hover:text-white hover:bg-primary rounded-md"
          onClick={() => {
            history.push(routes.subjects.chapters.review(subjectid, chapterid))
          }}
        >
          Review Chapter
        </Button> */}
      </div>
      {/* <hr /> */}
      <div className="mt-2">
        {activeMenu === 'practicRoom' && <NonVoucherPracticeRoom />}
        {activeMenu === 'homeworkRoom' && <NonVoucherHomework />}
      </div>
      </Segment>

    </div>
  )
}

export default NonVoucherChapterContent
