import {FieldArray, Formik} from 'formik'
import React from 'react'
import {BsLightbulb} from 'react-icons/bs'
import {useHistory} from 'react-router-dom'
import {useParams} from 'react-router-dom'
import {useToasts} from 'react-toast-notifications'
import {
  Button,
  Dimmer,
  Form,
  Icon,
  Image,
  Label,
  Loader,
  Message,
  Popup,
  Segment,
  Table,
} from 'semantic-ui-react'
import FormikDate from '../../../../components/formik/FormikDate'
import FormikDropdown from '../../../../components/formik/FormikDropdown'
import FormikInput from '../../../../components/formik/FormikInput'
import { authAxios } from '../../../../config/axiosConfig'
import useAsync from '../../../../hooks/useAsync'
import { formatResponseError } from '../../../../utils/format-response'
import Time from '../../../../utils/time'
import FeedbackMessage from '../../../student/exercise/feedback-message'

const NonVoucherAmortization = ({examType, data, setQuestionSuccess, setExamSuccess, setExamSuccessScore}) => {
  const [hintData, setHintData] = React.useState()
  const [uncompeletAnswer, setUncompeletAnswer] = React.useState(false)
  const [popupOpen, setPopupOpen] = React.useState(false)
  const [feedback, setFeedback] = React.useState()
  const {addToast} = useToasts()

  const [assets, setAssets] = React.useState([])

  const {classid, subjectid, chapterid, sectionid, pexid, exerciseAttempt} =
    useParams()
  const history = useHistory()

  const {
    run: getAssets,
    error: errorGettingAssets,
    isLoading: gettingAssets,
  } = useAsync()

  const {
    run: getHint,
    error: errorGettingHint,
    isLoading: gettingHint,
  } = useAsync()

  const {
    run: submitQuestion,
    error: errorSubmittingQuestion,
    isLoading: submittingQuestion,
  } = useAsync()

  React.useEffect(() => {
    getAssets(authAxios.get('/question/amortization/fixedasset')).then(
      ({data}) => {
        let options = []
        data?.forEach(s =>
          options.push({text: s.name, key: s._id, value: s._id}),
        )
        setAssets(options)
      },
    )
  }, [data, getAssets, data?.attemptId])

  function onSubmit(values, {resetForm}) {
    let timer = JSON.parse(
      window.localStorage.getItem(
        `questionId-${data?._id}-attemptId-${data?.attemptId}`,
      ),
    )
    const timeData = Time.getTimeFromSeconds(timer.seconds)

    let obj = {
      ...values,
      time: {
        hours: timeData?.hours,
        minutes: timeData?.minutes,
        seconds: timeData?.seconds,
      },
      attemptId: data?.attemptId,
      questionId: data?._id,
    }

    submitQuestion(
      authAxios.post(
        `/correct/exercise/${exerciseAttempt}?qtype=amortization`,
        obj,
      ),
    ).then(({data}) => {
      if (examType === 'test') {
        resetForm()
        setFeedback(null)
        setQuestionSuccess(p => !p)
        setUncompeletAnswer(false)
        setHintData(null)
        return
      }
      if (data?.examSuccessfull) {
        setExamSuccess(true)
        setExamSuccessScore(data?.score)
      }
      if (!data?.success) {
        setUncompeletAnswer(true)
        setFeedback(data)
      } else {
        resetForm()
        setFeedback(null)
        setQuestionSuccess(p => !p)
        addToast('Correct answer ', {appearance: 'success'})
        setUncompeletAnswer(false)
        setHintData(null)
      }
      //else if(data?.success && !data?.score)
      // else{
      //   history.push(routes.voucherStudent.chapters.content(classid, subjectid, chapterid))
      // }
    })
  }

  const reqHint = () => {
    // setPopupOpen(prevOpen => !prevOpen)
    if (hintData) {
      setPopupOpen(prevOpen => !prevOpen)
    } else {
      getHint(
        authAxios.get(
          `/correct/exercise/question/hint/${data?.attemptId}?attempt=${exerciseAttempt}`,
        ),
      ).then(({data}) => {
        setPopupOpen(true)
        console.log(data)
        setHintData(data)
      })
    }
  }

  /**
   *  <div className='flex flex-col'>

            <Button className='bg-white border-2 border-yellow-500 ' loading={gettingHint} onClick={reqHint}>
              Hint
            </Button>
            {hintData && 
            <Label pointing>
           
            <div className='text-center  rounded-md'>{hintData}</div>
            
            </Label>
            }

            </div>

            //////////
            <Popup disabled={!hintData} content={hintData} trigger={<Button  loading={gettingHint} onClick={()=> reqHint()} color='yellow' className='border-2 solid border-yellow-400' >Hint</Button>} />

   */

  return (
    <div className=" my-10">
      <Segment className="border-none shadow-none bg-transparent">
        {errorGettingAssets ? (
          <Message error list={formatResponseError(errorGettingAssets)} />
        ) : null}

        {uncompeletAnswer ? (
          <Message error content="Incompleted Answer!" />
        ) : null}

        {errorSubmittingQuestion ? (
          <Message error list={formatResponseError(errorSubmittingQuestion)} />
        ) : null}

        <Dimmer active={gettingAssets || submittingQuestion} inverted>
          {/* <Loader active={true} /> */}
        </Dimmer>
        <div>
          <p className="text-primary text-xl">Instructions:</p>
          <p className="break-all">{data?.exerciseInstructions}</p>
        </div>
        <div>
          <div className="flex my-4">
            {/* <p className="text-primary font-semibold">{data?.questionTitle}</p> */}
            <Popup
              disabled={!hintData}
              content={hintData}
              open={popupOpen}
              position="bottom right"
              className="border border-primaryYellow shadow-none break-all mb-4 "
              trigger={
                <Button
                  loading={gettingHint}
                  className={`${
                    popupOpen
                      ? 'bg-white border border-solid  flex items-center ml-auto justify-center text-primaryYellow border-primaryYellow'
                      : 'bg-primaryYellow flex items-center justify-center text-white ml-auto'
                  }`}
                  onClick={() => reqHint()}
                >
                  Hint
                  <BsLightbulb className="-mr-2 ml-2 -mt-0.5" />
                </Button>
              }
            />
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="flex-1 flex-col custom-min-width">
            <p className="text-primary font-semibold mb-4">
              {data?.questionTitle}
            </p>
            <p>{data?.question}</p>
          </div>
          {data?.image && (
            <div className="flex-1 flex justify-center custom-min-width">
            <Image size="medium" className="" src={data?.image} rounded as='a' href={data?.image} target="_blank" />
            </div>
          )}
        </div>

        {/* <div className="flex items-center justify-between my-4">
          <p className="text-primary font-semibold">{data?.questionTitle}</p>
          <Button
            className="bg-white border-2 border-solid  flex items-center justify-center text-yellow-400 border-yellow-400"
            onClick={() => reqHint()}
          >
            Hint
            <BsLightbulb className="-mr-2 ml-2 -mt-0.5" />
          </Button>
        </div>
        <div>{data?.question}</div> */}

        {/* <Segment className="border-none shadow-none bg-transparent">
          <Dimmer active={gettingHint} inverted>
            <Loader size="tiny" active={true} />
          </Dimmer>
          {hintData && (
            <div className="text-center text-gray-500 bg-white  border border-solid  border-yellow-400 p-3 rounded-md">
              {hintData}
            </div>
          )}
        </Segment> */}
        {/* <div>{data?.question}</div> */}

        <Formik
          initialValues={{
            fixedAsset: '',
            originalValue: '',
            depreciationRate: '',
            date: '',
            answers: [
              {
                year: '',
                netValueBegin: '',
                netValueEnd: '',
                lossValue: '',
                accumulatedLoss: '',
              },
            ],
          }}
          onSubmit={onSubmit}
        >
          {formik => (
            <Form
              autocomplete="off"
              // onSubmit={formik.handleSubmit}
              loading={submittingQuestion}
            >
              <div className="my-4 overflow-x-auto  py-8">
                <h3 className="mb-5">Amortization Table</h3>
                <Table celled structured className="shadow-sm" size="small">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell colSpan="2">
                        Fixed assets
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan="1">
                        <div className="flex  items-center">
                          {/* dropdown for assets */}
                          <FormikDropdown
                            loading={gettingAssets}
                            name="fixedAsset"
                            placeholder="Assets"
                            options={assets}
                            className="w-full"
                          />

                          {feedback?.fixedAsssetFeedback && (
                            <Popup
                              trigger={
                                <Icon
                                  name="warning"
                                  className=" ml-2 -mt-1"
                                  color="red"
                                  circular
                                />
                              }
                              content={feedback?.fixedAsssetFeedback}
                              className="border border-red-600 text-red-600"
                              // position='top left'
                            />
                          )}
                        </div>

                        {/* <FeedbackMessage
                          value={feedback?.fixedAsssetFeedback}
                        /> */}
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan="1">
                        Acquistion date
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan="1">
                        <div className="flex  items-center ">
                          {/* date input */}
                          <FormikDate
                            placeholder="Enter date: (dd-mm-yyyy)"
                            name="date"
                            className="w-full"
                          />

                          {feedback?.dateFeedback && (
                            <Popup
                              trigger={
                                <Icon
                                  name="warning"
                                  className=" ml-2 -mt-1"
                                  color="red"
                                  circular
                                />
                              }
                              content={feedback?.dateFeedback}
                              className="border border-red-600 text-red-600"
                              // position='top left'
                            />
                          )}
                        </div>

                        {/* {feedback?.dateFeedback && <p className='text-red-600 bg-red-50 p-2'>{feedback?.dateFeedback}</p>} */}
                      </Table.HeaderCell>
                      <Table.HeaderCell rowSpan="3">Actions</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell colSpan="2">
                        Original value
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan="1">
                        {/* original value input */}
                        <div className="flex items-center">
                          <FormikInput
                            name="originalValue"
                            placeholder="Original Value"
                            isNumber
                            className="w-full"
                          />

                          {feedback?.originalValueFeedback && (
                            <Popup
                              trigger={
                                <Icon
                                  name="warning"
                                  className=" ml-2 -mt-1"
                                  color="red"
                                  circular
                                />
                              }
                              content={feedback?.originalValueFeedback}
                              className="border border-red-600 text-red-600"
                              // position='top left'
                            />
                          )}
                        </div>
                        {/* <FeedbackMessage
                          value={feedback?.originalValueFeedback}
                        /> */}
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan="1">
                        Depreciation rate
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan="1">
                        <div className="flex  items-center ">
                          {/* depreciation rate input */}
                          <FormikInput
                            name="depreciationRate"
                            placeholder="Depreciation rate"
                            icon="percent"
                            className="w-full"
                          />
                          {feedback?.deprectionRateFeedback && (
                            <Popup
                              trigger={
                                <Icon
                                  name="warning"
                                  className=" ml-2 -mt-1"
                                  color="red"
                                  circular
                                />
                              }
                              content={feedback?.deprectionRateFeedback}
                              className="border border-red-600 text-red-600"
                              // position='top left'
                            />
                          )}
                        </div>
                      </Table.HeaderCell>
                    </Table.Row>

                    <Table.Row>
                      <Table.HeaderCell rowSpan="1">Year</Table.HeaderCell>
                      <Table.HeaderCell rowSpan="1">
                        Net value at beginning of the year
                      </Table.HeaderCell>
                      <Table.HeaderCell rowSpan="1">
                        Loss in value per year
                      </Table.HeaderCell>
                      <Table.HeaderCell rowSpan="1">
                        Accumulated loss
                      </Table.HeaderCell>
                      <Table.HeaderCell rowSpan="1">
                        Net value at the end of the year.
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <FieldArray name="answers">
                      {({insert, remove, push}) => (
                        <>
                          {formik.values.answers.length > 0 &&
                            formik.values.answers.map((choice, index, arr) => {
                              return (
                                <Table.Row>
                                  <>
                                    <Table.Cell>
                                      <FormikInput
                                        name={`answers.${index}.year`}
                                        placeholder="Enter year"
                                      />

                                      <FeedbackMessage
                                        value={
                                          feedback &&
                                          feedback?.table[index]?.yearFeedback
                                        }
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <FormikInput
                                        name={`answers.${index}.netValueBegin`}
                                        isNumber
                                        placeholder="Enter net value beginning"
                                      />
                                      <FeedbackMessage
                                        value={
                                          feedback &&
                                          feedback?.table[index]
                                            ?.netValueBeginFeedback
                                        }
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <FormikInput
                                        name={`answers.${index}.lossValue`}
                                        isNumber
                                        placeholder="Enter loss in value"
                                      />
                                      <FeedbackMessage
                                        value={
                                          feedback &&
                                          feedback?.table[index]
                                            ?.lossValueFeedback
                                        }
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <FormikInput
                                        name={`answers.${index}.accumulatedLoss`}
                                        isNumber
                                        placeholder="Enter accumulated loss"
                                      />
                                      <FeedbackMessage
                                        value={
                                          feedback &&
                                          feedback?.table[index]
                                            ?.accumulatedLossFeedback
                                        }
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <FormikInput
                                        name={`answers.${index}.netValueEnd`}
                                        isNumber
                                        placeholder="Enter net value end"
                                      />
                                      <FeedbackMessage
                                        value={
                                          feedback &&
                                          feedback?.table[index]
                                            ?.netValueEndFeedback
                                        }
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <div className="flex">
                                        {index === 0 ? null : (
                                          <Button
                                            size="tiny"
                                            negative
                                            basic
                                            onClick={() => remove(index)}
                                            type="button"
                                          >
                                            -
                                          </Button>
                                        )}

                                        {index ===
                                          formik.values.answers.length - 1 &&
                                        formik.values.answers.length < 6 ? (
                                          <Button
                                            size="tiny"
                                            className="bg-white border-2 border-solid  items-center  border-blue-400"
                                            onClick={() =>
                                              push({
                                                year: '',
                                                netValueBegin: '',
                                                netValueEnd: '',
                                                lossValue: '',
                                                accumulatedLoss: '',
                                              })
                                            }
                                            type="button"
                                          >
                                            +
                                          </Button>
                                        ) : null}
                                      </div>
                                    </Table.Cell>
                                  </>
                                </Table.Row>
                              )
                            })}
                        </>
                      )}
                    </FieldArray>
                  </Table.Body>
                </Table>
              </div>

              <div className="my-5 mt-10 flex ">
                <Button className="ml-auto" primary type="button" onClick={formik.submitForm}>
                  Submit
                </Button>
                {/* <Button
                className="mr-2"
                size="tiny"
                onClick={() => history.push(routes.amortizationQuestion.all)}
              >
                Cancel
              </Button> */}
              </div>
            </Form>
          )}
        </Formik>
      </Segment>
    </div>
  )
}

export default React.memo(NonVoucherAmortization)
