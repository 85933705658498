import React from 'react'
import {useParams} from 'react-router-dom'
import {Dimmer, Loader, Message, Segment} from 'semantic-ui-react'

import ClassChapterCard from '../../../components/shared/class-chapter-card'
import {authAxios} from '../../../config/axiosConfig'
import useAsync from '../../../hooks/useAsync'
import {formatResponseError} from '../../../utils/format-response'
import {truncate} from '../../../utils/string'

const ClassSubjectContent = () => {
  const [chapters, setChapters] = React.useState([])
  const {classid, subjectid} = useParams()

  const {
    run: getChapters,
    error: errorGettingChapters,
    isLoading: gettingChapters,
  } = useAsync()

  React.useEffect(() => {
    getChapters(authAxios.get(`/chapter/all/?sid=${subjectid}`)).then(
      ({data}) => {
        console.log(data)
        setChapters(data)
      },
    )
  }, [getChapters, subjectid])

  return (
    <div className=" h-screen">
      <Segment className="border-none shadow-none bg-transparent w-full">
        {errorGettingChapters ? (
          <Message error list={formatResponseError(errorGettingChapters)} />
        ) : null}
        <Dimmer active={gettingChapters} inverted>
          <Loader active={true} />
        </Dimmer>
        <div className="flex flex-wrap gap-x-5 gap-y-5 items-center justify-start  my-5">
          {chapters?.map((a, i) => (
            <ClassChapterCard
              key={a?._id}
              chapterid={a?._id}
              name={a?.chapterName}
              brief={a?.brief}
              chapterSrc={a?.image}
              order={a?.order}
            />
          ))}
          {/* <ClassChapterCard
          name="Chapter Name"
          brief="brief brief brief brief"
          chapterSrc={`https://firebasestorage.googleapis.com/v0/b/nuber-211116.appspot.com/o/6175820fcf61f65c149c6f00-61d5ad91095ff5112f8438c7-61d5ad91095ff5112f8438c9?alt=media&token=61d5ad91095ff5112f8438c9`}
          order={1}
        />
        */}
        </div>
      </Segment>
    </div>
  )
}

export default ClassSubjectContent
