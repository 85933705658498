export const keys = {
  SERVER_URL:
    process.env.NODE_ENV !== 'prod'
      ? process.env.REACT_APP_DEV_URL
      : process.env.REACT_APP_SERVER_URL,
  ROLES: {
    student: 'student',
    admin: 'admin',
  },
}
