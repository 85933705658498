import React, { useState } from 'react'
import { useHistory } from 'react-router'
import {
  Breadcrumb,
  Button,
  Dimmer,
  Form,
  Loader,
  Message,
  Modal,
  Segment,
} from 'semantic-ui-react'
import useAsync from '../hooks/useAsync'
import * as Yup from 'yup'
import { authAxios } from '../config/axiosConfig'
import { Formik } from 'formik'
import FormikInput from '../components/formik/FormikInput'
import useSubject from '../hooks/use-subjects'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { truncate } from '../utils/string'
import { formatResponseError } from '../utils/format-response'
import AvatarDropdown from '../components/shared/avatar-dropdown'
import VoucherClassCard from '../components/shared/voucher-class-card'
import { useUser } from '../context/user-context'
import { useLanguage } from '../context/languageContext'
import { content } from '../localization/content'

const StudentVoucherDashboard = () => {
  const [classData, setClassData] = React.useState([])
  const { subjects, errorGettingSubjects, gettingSubjects } = useSubject()
  const [truncateSubject, setTruncateSubject] = React.useState([])

  const { getUser } = useUser()
  const [forceReload, setForceReload] = React.useState(false)
  const [addingVoucher, setAddingVoucher] = React.useState(false)
  const [currentSubject, setCurrentSubject] = useState()
  const [clickedSubject, setClickedSubject] = useState()

  const location = useLocation()
  const history = useHistory()

  const [state] = React.useState(location?.state)

  const [lang] = useLanguage()
  const langContent = content[lang]


  const {
    run: getClasses,
    error: errorGettingClasses,
    isLoading: gettingclasses,
  } = useAsync()

  React.useEffect(() => {
    const {currentSubjectid} = queryString.parse(location.search)

    setCurrentSubject(currentSubjectid)

    if (currentSubject) {
      getClasses(
        authAxios.get(`/student/class/all?sid=${currentSubject}`),
      ).then(({data}) => {
        setClassData(data)
        if (data?.length < 1) {
          // setAddingVoucher(true)
          console.log('aaaaaaa')
        }
        console.log(data)
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, currentSubject, forceReload])

  React.useEffect(() => {
    // for truncate text on dropdown
    if (subjects.length > 0) {
      let arr = []
      subjects?.forEach(s =>
        arr.push({
          text: truncate(s?.text, 20),
          key: s?.key,
          value: s?.value,
        }),
      )
      setTruncateSubject(arr)
    }

    if (subjects.length && !currentSubject) {
      if (state?.currentSubject) {
        setCurrentSubject(state?.currentSubject)
        history.replace(
          `${location.pathname}?currentSubjectid=${state?.currentSubject}`,
        )
      } else {
        setCurrentSubject(subjects[0]?.value)
        history.replace(
          `${location.pathname}?currentSubjectid=${subjects[0]?.value}`,
        )
      }
    }
    // else {
    //   setCurrentSubject(currentSubject)
    // }
  }, [
    currentSubject,
    history,
    location.pathname,
    state?.currentSubject,
    subjects,
  ])

  const onSubjectChange = (e, { value }) => {
    setCurrentSubject(value)
    history.replace(`${location.pathname}?&currentSubjectid=${value}`)
  }

  const handleOnSubjectClick = k => {
    setCurrentSubject(k?.value)
    console.log(k)
    setClickedSubject(k?.value)
    history.replace(`${location.pathname}?&currentSubjectid=${k?.value}`)
  }

  return (
    <div className="relative h-screen px-2 md:px-10">
      <Segment className="border-none shadow-none bg-transparent w-full">
        {/* <AvatarDropdown /> */}
        {errorGettingSubjects ? (
          <Message error list={formatResponseError(errorGettingSubjects)} />
        ) : null}
        {errorGettingClasses ? (
          <Message error list={formatResponseError(errorGettingClasses)} />
        ) : null}

        <Dimmer active={gettingSubjects || gettingclasses} inverted>
          <Loader active={true} inverted />
        </Dimmer>
        <div className="flex flex-wrap-reverse justify-center sm:justify-between align-center items-center w-full ">
          <h3 className="text-primary animate__animated animate__fadeInUp ">
            {langContent.welcome} {getUser()?.name}
          </h3>

          {/* <Dropdown
            placeholder="Select a subject"
            search
            selection
            value={currentSubject}
            loading={gettingSubjects}
            options={truncateSubject}
            onChange={onSubjectChange}
            className="drop-shadow-md shadow-md  "
          /> */}
          <AvatarDropdown />
        </div>
        <div className="flex justify-between  mt-8">
          <Breadcrumb className="mt-4">
            <Breadcrumb.Section onClick={() => history.push('/')} link>
              {langContent?.home}
            </Breadcrumb.Section>
            <Breadcrumb.Divider>/</Breadcrumb.Divider>
            <Breadcrumb.Section active> {langContent.My_Class} </Breadcrumb.Section>
          </Breadcrumb>
          <div className="flex">
            <Button
              className="ml-auto"
              primary
              onClick={() => setAddingVoucher(p => !p)}
            >
              {langContent.Add_voucher}
            </Button>
          </div>
        </div>
        <div className="mt-4">
          {truncateSubject?.map((k, i) => (
            <Button
              key={`button_${i}`}
              type="button"
              primary={currentSubject === k?.value}
              className={`my-2 mr-2 rounded-full font-semibold px-8 shadow-md ${currentSubject !== k?.value
                ? 'border border-solid border-primary bg-transparent text-primary'
                : ''
                }`}
              // basic={clickedSubject !== k?.value}
              onClick={() => handleOnSubjectClick(k)}
            >
              {k?.text}
            </Button>
          ))}
          {/* <Dropdown
              placeholder="Select a subject"
              search
              selection
              value={currentSubject}
              loading={gettingSubjects}
              options={truncateSubject}
              onChange={onSubjectChange}
              className="drop-shadow-md shadow-md mr-2 "
            /> */}
        </div>

        <div className="mt-12 flex flex-wrap gap-x-4 gap-y-4  items-center justify-start ">
          {classData?.map((a, idx) => (
            <VoucherClassCard
              key={a?._id}
              classname={a?.name}
              school={a?.school?.name}
              voucherCode={a?.voucher}
              classid={a?._id}
              subjectId={a?.subject?._id}

            // reload={() => setForceReload(p => !p)}
            />
          ))}
        </div>

        <NewVoucherModal
          open={addingVoucher}
          onClose={() => setAddingVoucher(false)}
          reload={() => setForceReload(p => !p)}
        />
      </Segment>
    </div>
  )
}

export default StudentVoucherDashboard

const NewVoucherModal = ({ open, onClose, reload, dataToSend }) => {
  const history = useHistory()

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  const {
    run: submitVoucher,
    error: creatingVoucherError,
    isLoading: creatingVoucher,
  } = useAsync()
  const newCategorySchema = Yup.object({
    voucher: Yup.string().trim().required('Required'),
  })

  const onCreate = values => {
    submitVoucher(
      authAxios.post('/student/class/voucher', values).then(() => {
        reload()
        onClose()
      }),
    ).catch((e) => {
      console.log(e)
    })
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      size="mini"
      className="animate__animated animate__fadeInDown"
    >
      {/* <Modal.Header>New category name</Modal.Header> */}
      <Modal.Content>
        {creatingVoucherError ? (
          <Message error list={formatResponseError(creatingVoucherError)} />
        ) : null}
        <Formik
          initialValues={{
            voucher: '',
          }}
          onSubmit={onCreate}
          validationSchema={newCategorySchema}
        >
          {formik => (
            <Form onSubmit={formik.submitForm} loading={creatingVoucher}>
              <FormikInput
                label={langContent.Enter_voucher_code}
                placeholder={langContent.Enter_voucher_code_placeHolder}
                name="voucher"
              />
              <div className="flex justify-between mt-4">
                <Button
                  onClick={() => {
                    onClose()
                  }}
                >
                  {langContent.Cancel}
                </Button>
                <Button primary>{langContent.Submit}</Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}
