import React from 'react'
import {Link} from 'react-router-dom'
import {
  Dimmer,
  Icon,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react'
import {authAxios} from '../../../config/axiosConfig'
import useAsync from '../../../hooks/useAsync'
import {formatResponseError} from '../../../utils/format-response'
import {truncate} from '../../../utils/string'

const ChapterPdfCard = ({data, id, reload}) => {
  const {
    run: getLink,
    error: errorGettingLink,
    isLoading: gettingLink,
  } = useAsync()

  const handleClick = () => {
    console.log({id})
    getLink(authAxios.get(`/pdf/${id}`)).then(({data}) => {
      // console.log(data)
      window.open(data?.link, '_blank')
    })
  }

  return (
    <div
      onClick={() => handleClick()}
      className="rounded-md w-56 h-52 shadow-md hover:shadow-xl cursor-pointer hover:opacity-95  flex  flex-col border solid border-gray-300 relative"
    >
      <Segment className="border-none shadow-none bg-transparent m-0 p-0">
        <Dimmer active={gettingLink} inverted>
          <Loader active={true} />
        </Dimmer>

        {errorGettingLink ? (
          <Message
            error
            list={formatResponseError(errorGettingLink)}
            className="m-1"
          />
        ) : null}

        <div className="flex justify-center items-center mt-8">
          <Icon name="file pdf outline" size="huge" />
        </div>

        <div className="flex justify-center items-center mt-4">
          <p className="text-center px-2 break-all">
            {truncate(data?.exercise?.name, 44)}
          </p>
        </div>

      </Segment>
    </div>
  )
}

export default ChapterPdfCard
