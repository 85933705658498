import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {Grid} from 'semantic-ui-react'
import { useToasts } from 'react-toast-notifications'
import InstructorRegister from '../../components/RegisterComponents/InstructorRegister'
import RegisterRequestTabs from '../../components/RegisterComponents/RegisterRequestTabs'
import routes from '../../routes'
import { FaArrowCircleLeft } from "react-icons/fa";
import StudentMainRegisiter from '../../components/RegisterComponents/StudentMainRegisiter'
import { useLanguage } from '../../context/languageContext'
import { content } from '../../localization/content'

const Register = () => {
  const [activeMenu, setActiveMenu] = useState('student')

  const [lang] = useLanguage()
  const langContent = content[lang]

  const { removeAllToasts } = useToasts();

  return (
    <Grid className="h-screen m-0">
      <Grid.Row className="p-0">
        <Grid.Column
          computer={8}
          className="hidden lg:block w-full h-full bg-primary login-sidebar-img"
        >
          {/* <Image
                className="w-full h-screen ml-2 login-sidebar-img"
                src={loginBackgound}
              ></Image> */}
        </Grid.Column>

        <Grid.Column
          tablet={16}
          computer={8}
          mobile={16}
          className="place-self-center"
        >
            <div className="px-4 ml-5 -mb-20 mt-5" onClick={() => removeAllToasts()} >
              <Link to={routes.home} className="text-primary">
              <FaArrowCircleLeft  className='w-20 h-8'/>
              </Link>
            </div>
          <div className="p-3 max-w-xl mx-auto mt-10">

            <div className="items-center text-center p-8 text-primaryBlueColor-default font-extrabold text-3xl -mb-10">
              {langContent.Register}
            </div>
            <div className="items-center text-center p-8 mt-3 font-semibold">
              {" "}
            </div>
            <RegisterRequestTabs
              activeMenu={activeMenu}
              setActiveMenu={value => setActiveMenu(value)}
            />
            <div className="lg:p-6 relative">
              {/* {activeMenu === 'student' && <StudentRegisterWithVoucher />} */}
              {activeMenu === 'student' && <StudentMainRegisiter />}
              {activeMenu === 'instructor' && <InstructorRegister />}
            </div>

            <div className="items-center text-center mt-3 font-semibold">
              {/* {authContent.haveAnAccount}{' '} */}
             {langContent.Have_an_account}
              <Link to={routes.login} className="text-red-600">
                {' '}
                {langContent.login}
              </Link>
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default Register
