import React from 'react'
import {useParams} from 'react-router-dom'
import {
  Dimmer,
  Dropdown,
  Image,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react'
import {FaUserAlt} from 'react-icons/fa'

import {authAxios} from '../../../config/axiosConfig'
import useAsync from '../../../hooks/useAsync'
import {formatResponseError} from '../../../utils/format-response'
import { useLanguage } from '../../../context/languageContext'
import { content } from '../../../localization/content'

const TeacherStudentBasicInfo = () => {
  const [userData, setUserData] = React.useState()
  const {classid, studentid} = useParams()

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  const {
    run: getStudentInfo,
    error: errorGettingStudentInfo,
    isLoading: gettingStudentInfo,
  } = useAsync()

  React.useEffect(() => {
    getStudentInfo(
      authAxios.get(
        `/instructor/class/studentsinfo/${studentid}/about?class=${classid}`,
      ),
    ).then(({data}) => {
      setUserData(data)
      console.log(data)
    })
  }, [getStudentInfo, studentid, classid])

  return (
    <div className="mt-12">
      <Segment className="border-none shadow-none bg-transparent flex flex-col">
        {errorGettingStudentInfo ? (
          <Message error list={formatResponseError(errorGettingStudentInfo)} />
        ) : null}

        <Dimmer active={gettingStudentInfo} inverted>
          <Loader active={true} />
        </Dimmer>

        <div className="flex justify-between">
          <div className="flex justify-center items-center">
            {userData?.profileImage ? (
              <Image
                className="w-20 h-20 bg-cover rounded-full"
                src={userData?.profileImage}
                circular
                size="tiny"
              />
            ) : (
              <FaUserAlt className="w-10 h-10 ml-1 bg-cover rounded-full" />
            )}

            <div className="ml-4">
              <p className="font-semibold">
                {userData?.firstname} {userData?.lastname}
              </p>
              <p className="text-gray-500">{langContent.students} </p>
            </div>
          </div>
          <div>
            <Dropdown icon="ellipsis vertical" floating>
              <Dropdown.Menu className="" as="div" direction="left">
                <Dropdown.Item
                  className=""
                  text="Remove Student"
                  icon="trash alternate outline"
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="my-10">
          <h3 className="px-3 my-3">About Student</h3>
          <div className="border-2 border-gray-200 solid p-3 rounded-lg">
            <p className="m-2 font-semibold">{langContent.school} : {userData?.school}</p>
            <p className="m-2 font-semibold">{langContent.Email_Address} : {userData?.email}</p>
            <p className="m-2 font-semibold">Class : {userData?.class}</p>
            <p className="m-2 font-semibold">Level : {userData?.level}</p>
            {/* <p className="m-2 font-semibold">Rank on class: {userData?.firstname}</p> */}
          </div>
        </div>
      </Segment>
    </div>
  )
}

export default TeacherStudentBasicInfo
