import {Menu} from 'semantic-ui-react'

const TeacherStudentInfoTabs = ({activeMenu, setActiveMenu}) => {
  return (
    <div class="flex">
        <Menu
          className="mt-2 border-none shadow-none  bg-transparent text-xl  m-2"
          stackable
        >
            <>
              <Menu.Item
                active={activeMenu === 'basicInfo'}
                onClick={() => setActiveMenu('basicInfo')}
                 className={`${
                      activeMenu === 'basicInfo' ? 'border-b-4 border-primary text-primary bg-transparent' : 'bg-gray-50'
                    }`}
              >
                <div className="flex flex-col w-full ">
                 
                  <p className="font-medium text-labelColor">Basic info</p>
                  <div
                    // className={` ${
                    //   activeMenu === 'basicInfo' ? 'border-t-2 border-red-800' : 'hidden'
                    // }`}
                  ></div>
                </div>
              </Menu.Item>
              <Menu.Item
                active={activeMenu === 'studentProgress'}
                onClick={() => setActiveMenu('studentProgress')}
                 className={` ${
                      activeMenu === 'studentProgress' ? 'border-b-4 border-primary text-primary bg-transparent' : 'bg-gray-50'
                    }`}
              >
                <div className="flex flex-col w-full ">
                 
                  <p className="font-medium text-labelColor">Student's progress</p>
                  <div
                    // className={`bottom-arrow ${
                    //   activeMenu === 'studentProgress' ? 'border-t-2 border-red-800' : 'hidden'
                    // }`}
                  ></div>
                </div>
              </Menu.Item>
            </>
        </Menu>
    </div>
  )
}

export default TeacherStudentInfoTabs
