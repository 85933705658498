import React from 'react'
import { authAxios } from '../config/axiosConfig'
import useAsync from './useAsync'

function useAccountNames() {
  const [accountNames, setAccountNames] = React.useState([])
  const [accountNumbers, setAccountNumbers] = React.useState([])

  const {
    run: getAccountNames,
    error: errorGettingAccountNames,
    isLoading: gettingAccountNames,
  } = useAsync()

  React.useEffect(() => {
    getAccountNames(authAxios.get('/question/accountname')).then(({data}) => {
      let accountNames = [{text: '', key: '1', value: ''}]
      let accountNumbers = [{text: '', key: '1', value: ''}]
      data?.forEach(s => {
        accountNames.push({
          text: `${s.accountNumber}-${s.accountName}`,
          key: s._id,
          value: s._id,
        })
        accountNumbers.push({
          text: s.accountNumber,
          key: s._id,
          value: s._id,
        })
      })
      setAccountNames(accountNames)
      setAccountNumbers(accountNumbers)
    })
  }, [getAccountNames])

  return {
    accountNames,
    accountNumbers,
    errorGettingAccountNames,
    gettingAccountNames,
  }
}

export default useAccountNames
