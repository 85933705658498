import {Formik} from 'formik'
import React from 'react'
import {useHistory} from 'react-router-dom'
import {useToasts} from 'react-toast-notifications'
import {Button, Form, Message} from 'semantic-ui-react'
import * as Yup from 'yup'
import {authAxios} from '../../../config/axiosConfig'
import { useLanguage } from '../../../context/languageContext'
import useSubject from '../../../hooks/use-subjects'
import useAsync from '../../../hooks/useAsync'
import { content } from '../../../localization/content'
import routes from '../../../routes'
import {formatResponseError} from '../../../utils/format-response'
import FormikDropdown from '../../formik/FormikDropdown'

const CreateClassForm = ({nextStep, reload}) => {
  const history = useHistory()
  const {subjects, errorGettingSubjects, gettingSubjects} = useSubject()
  // const [schools, setSchools] = React.useState([])
  const [levels, setLevels] = React.useState([])
  // const [countries, setCountries] = React.useState([])
  const {addToast} = useToasts()

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  const {
    run: submitClass,
    error: errorSubmitingClass,
    isLoading: submitingClass,
  } = useAsync()

  // const {
  //   run: getSchools,
  //   error: errorGettingSchools,
  //   isLoading: gettingSchools,
  // } = useAsync()

  const {
    run: getLevels,
    error: errorGettingLevels,
    isLoading: gettingLevels,
  } = useAsync()

  // const {
  //   run: getCountries,
  //   error: errorGettingCountries,
  //   isLoading: gettingCountries,
  // } = useAsync()

  const newClassSchema = Yup.object({
    subject: Yup.string().trim().required(),
    // school: Yup.string().trim().required(),
    level: Yup.string().trim().required(),
    // country: Yup.string().trim().required(),
  })

  // React.useEffect(() => {
  //   getSchools(authAxios.get(`/school/all`)).then(({data}) => {
  //     let options = []
  //     data?.schools?.forEach(s =>
  //       options.push({text: s.name, key: s._id, value: s._id}),
  //     )
  //     setSchools(options)
  //   })
  // }, [getSchools])

  React.useEffect(() => {
    getLevels(authAxios.get(`/class/level/all`)).then(({data}) => {
      let options = []
      data?.levels?.forEach(s =>
        options.push({text: s.name, key: s._id, value: s._id}),
      )
      setLevels(options)
    })
  }, [getLevels])

  // React.useEffect(() => {
  //   getCountries(authAxios.get(`/school/country/all/`)).then(({data}) => {
  //     let options = []
  //     data?.countries?.forEach(s =>
  //       options.push({text: s.name, key: s._id, value: s._id}),
  //     )
  //     setCountries(options)
  //   })
  // }, [getCountries])

  const onSubmitClass = values => {
    submitClass(authAxios.post(`/class/new`, values)).then(({data}) => {
      console.log({resposneDataOnSubmit: data})
      nextStep({data: data, step: 'next'})
      reload()
      addToast('Class created', {appearance: 'success'})
    })
  }

  return (
    <div>
      {errorGettingSubjects ? (
        <Message error list={formatResponseError(errorGettingSubjects)} />
      ) : null}
      {errorSubmitingClass ? (
        <Message error list={formatResponseError(errorSubmitingClass)} />
      ) : null}
      {/* {errorGettingSchools ? (
        <Message error list={formatResponseError(errorGettingSchools)} />
      ) : null} */}
      {errorGettingLevels ? (
        <Message error list={formatResponseError(errorGettingLevels)} />
      ) : null}
      {/* {errorGettingCountries ? (
        <Message error list={formatResponseError(errorGettingCountries)} />
      ) : null} */}
      <p className=" text-center font-semibold text-2xl text-labelColor mb-1">
        {langContent.Create_new_class}
      </p>
      <div className="px-4 md:px-16">
        <Formik
          initialValues={{
            subject: '',
            // school: '',
            level: '',
            // country: 'Luxembourg',
          }}
          validationSchema={newClassSchema}
          onSubmit={onSubmitClass}
          enableReinitialize
        >
          {formik => {
            return (
              <Form onSubmit={formik.handleSubmit} loading={submitingClass}>
                <FormikDropdown
                  label={langContent.Subject}
                  name="subject"
                  loading={gettingSubjects}
                  options={subjects}
                />
                <FormikDropdown
                  label={langContent.Level}
                  name="level"
                  loading={gettingLevels}
                  options={levels}
                />
                {/* <FormikDropdown
                  label={langContent.School}
                  name="school"
                  loading={gettingSchools}
                  options={schools}
                />
                <FormikDropdown
                  label={langContent.Country}
                  name="country"
                  loading={gettingCountries}
                  options={countries}
                /> */}

                <div className="flex justify-between">
                  <Button
                    className="mt-4 mb-8 mr-2"
                    type="submit"
                    primary
                    fluid
                  >
                    {langContent.Create_new_class}
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default CreateClassForm
