import {Formik} from 'formik'
import React from 'react'
import {useHistory} from 'react-router-dom'
import {
  Breadcrumb,
  Button,
  Dimmer,
  Form,
  Image,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react'
import * as Yup from 'yup'
import {FaUserAlt} from 'react-icons/fa'

import FormikInput from '../../components/formik/FormikInput'
import ImageAttachment from '../../components/shared/image-attachment'
import {authAxios} from '../../config/axiosConfig'
import useAsync from '../../hooks/useAsync'
import {formatResponseError} from '../../utils/format-response'
import FormikDropdown from '../../components/formik/FormikDropdown'
import { useUser } from '../../context/user-context'

const NonVoucherStudentProfile = () => {
  const [isEditting, setIsEditting] = React.useState(false)
  const [attachmentData, setAttachmentData] = React.useState(null)
  const [imagePath, setImagePath] = React.useState(null)
  const history = useHistory()
  const formRef = React.useRef()

  const [countries, setCountries] = React.useState([])

  const [userData, setUserData] = React.useState()

  const {getUser,setUser} = useUser()
  const user = getUser()


  const userSchema = Yup.object({
    firstname: Yup.string().trim().required('Required'),
    lastname: Yup.string().trim().required('Required'),
    university: Yup.string().trim().required('Required'),
    country: Yup.string().trim().required('Required'),
  })

  const {
    run: getStudentInfo,
    error: errorGettingStudentInfo,
    isLoading: gettingStudentInfo,
  } = useAsync()

  const {
    run: updateStudentInfo,
    error: errorUpdatingStudentInfo,
    isLoading: updateingStudentInfo,
  } = useAsync()

  const {
    run: getCountries,
    isLoading: gettingCountries,
    error: errorgettingCountries,
  } = useAsync()

  React.useEffect(() => {
    getStudentInfo(authAxios.get(`/user/profile`)).then(({data}) => {
      setUserData(data)
      console.log(data)
    })
  }, [getStudentInfo, isEditting])

  React.useEffect(() => {
    getCountries(authAxios.get('school/country/all')).then(({data}) => {
      let countryOptions = []

      data?.countries?.map(d =>
        countryOptions.push({
          key: d._id,
          text: d.name,
          value: d._id,
        }),
      )
      setCountries(countryOptions)
    })
  }, [getCountries])

  const editProfile = () => {
    if (formRef.current) {
      formRef.current.handleSubmit()
    }
  }

  const handleOnSubmit = values => {
    const formData = new FormData()
    for (let i in values) {
      formData.append(i, values[i])
    }
    if (attachmentData) formData.append('img', attachmentData)

    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1])
    // }

    updateStudentInfo(authAxios.put(`/user/profile`, formData)).then(
      ({data}) => {
        console.log(data)
        setUserData(data)
        setIsEditting(p => !p)
        setUser({...user,name: `${data?.firstname} ${data?.lastname}`})
      },
    )
  }

  // console.log(attachmentData)

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent flex flex-col">
        {errorGettingStudentInfo ? (
          <Message error list={formatResponseError(errorGettingStudentInfo)} />
        ) : null}
        {errorUpdatingStudentInfo ? (
          <Message error list={formatResponseError(errorUpdatingStudentInfo)} />
        ) : null}
        {errorgettingCountries ? (
          <Message error list={formatResponseError(errorgettingCountries)} />
        ) : null}

        <Dimmer active={gettingStudentInfo || updateingStudentInfo} inverted>
          <Loader active={true} />
        </Dimmer>
        <Breadcrumb className="mt-4">
          <Breadcrumb.Section onClick={() => history.push('/')} link>
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider>/</Breadcrumb.Divider>
          <Breadcrumb.Section active> My classes </Breadcrumb.Section>
        </Breadcrumb>

        <div className="flex items-center justify-between  mt-8">
          <h3 className="text-primary mt-8">Personal information</h3>
          <div className="">
            <Button
              type="submit"
              className={`${
                isEditting
                  ? 'border border-solid border-primary bg-transparent text-primary'
                  : 'bg-primary text-white'
              }`}
              onClick={() => {
                if (isEditting) {
                  editProfile()
                } else {
                  setIsEditting(p => !p)
                }
              }}
            >
              {isEditting ? 'Save' : 'Edit Profile'}
            </Button>
          </div>
        </div>

        {isEditting ? (
          <div className="border-2 border-gray-200 solid px-16 py-10 rounded-lg mt-8 flex flex-col md:flex-row">
            <div className="w-2/6">
              <div className="w-72 h-60 relative ">
                {gettingStudentInfo ? (
                  <Dimmer
                    active={gettingStudentInfo || updateingStudentInfo}
                    inverted
                  >
                    <Loader active={true} />
                  </Dimmer>
                ) : userData?.profileImage || attachmentData?.name ? (
                  <Image
                    className="w-72 h-60 bg-cover sm:bg-primaryLight"
                    // src="https://image.shutterstock.com/image-photo/portrait-smiling-young-college-student-260nw-1192615495.jpg"
                    src={
                      attachmentData?.name ? imagePath : userData?.profileImage
                    }
                  ></Image>
                ) : (
                  <div className=" my-auto flex">
                    <FaUserAlt size={96} className="mx-auto mt-20"></FaUserAlt>
                  </div>
                )}
                {/* <Image
                  className="w-72 h-60 bg-cover sm:bg-primaryLight"
                  // src="https://image.shutterstock.com/image-photo/portrait-smiling-young-college-student-260nw-1192615495.jpg"
                  src={
                    attachmentData?.name
                      ? imagePath
                      : userData?.profileImage
                      ? userData?.profileImage
                      : 'https://image.shutterstock.com/image-photo/portrait-smiling-young-college-student-260nw-1192615495.jpg'
                  }
                ></Image> */}
                <ImageAttachment
                  className="absolute top-4 right-4 "
                  setAttachmentData={setAttachmentData}
                  setImagePath={setImagePath}
                />
              </div>
            </div>
            <div className="w-4/6 my-auto ml-10 lg:ml-0">
              <div>
                <div className="w-96">
                  <Formik
                    initialValues={{
                      firstname: userData?.firstname || '',
                      lastname: userData?.lastname || '',
                      university: userData?.university || '',
                      country: userData?.country?._id || '',
                    }}
                    validationSchema={userSchema}
                    onSubmit={handleOnSubmit}
                    innerRef={formRef}
                    enableReinitialize
                  >
                    {formik => (
                      <Form
                        onSubmit={formik.handleSubmit}
                        //   loading={creatingQuestion}
                      >
                        <div className="flex items-center text-primary ">
                          <FormikInput
                            name="firstname"
                            label="First name"
                            className="flex flex-col bg-transparent ml-8 w-64"
                          />
                        </div>

                        <div className="flex items-center my-4 text-primary">
                          <FormikInput
                            name="lastname"
                            label="Last name"
                            className="flex flex-col bg-transparent ml-8 w-64 mb-2"
                          />
                        </div>

                        <div className="flex items-center my-4 text-primary">
                          <FormikInput
                            name="university"
                            label="University"
                            className="flex flex-col bg-transparent ml-9 w-64 mb-2"
                          />
                        </div>

                        <div className="flex items-center my-4 text-primary">
                          <FormikDropdown
                            loading={gettingCountries}
                            label="Country"
                            name="country"
                            placeholder="country"
                            options={countries}
                            className="flex flex-col bg-transparent ml-12 w-64 mb-2"
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="border-2 border-gray-200 solid px-16 py-10 rounded-lg mt-8 flex flex-col md:flex-row">
            <div className="w-2/6">
              {gettingStudentInfo ? (
                <Dimmer
                  active={gettingStudentInfo || updateingStudentInfo}
                  inverted
                >
                  <Loader active={true} />
                </Dimmer>
              ) : userData?.profileImage ? (
                <Image
                  className="w-72 h-60 bg-cover"
                  src={userData?.profileImage}
                  // src="https://image.shutterstock.com/image-photo/portrait-smiling-young-college-student-260nw-1192615495.jpg"
                />
              ) : (
                <div className="mt-10">
                  <FaUserAlt size={96} className="mx-auto"></FaUserAlt>
                </div>
              )}

              {/* <Image
                className="w-72 h-60 bg-cover"
                src={
                  userData?.profileImage
                    ? userData?.profileImage
                    : 'https://image.shutterstock.com/image-photo/portrait-smiling-young-college-student-260nw-1192615495.jpg'
                }
                // src="https://image.shutterstock.com/image-photo/portrait-smiling-young-college-student-260nw-1192615495.jpg"
              /> */}
            </div>
            <div className="w-4/6 my-auto ml-10 lg:ml-0">
              <div>
                <div className="flex ">
                  <p className="text-primary">First name</p>
                  <p className="ml-8">{userData?.firstname}</p>
                </div>

                <div className="flex  my-8">
                  <p className="text-primary">Last name</p>
                  <p className="ml-8">{userData?.lastname}</p>
                </div>

                <div className="flex ">
                  <p className="text-primary">University</p>
                  <p className="ml-8">{userData?.university}</p>
                </div>

                <div className="flex my-8">
                  <p className="text-primary ">Country</p>
                  <p className="ml-8">{userData?.country?.name}</p>
                </div>
                {/*                 
                <div className="flex ">
                  <p className="text-primary">School name</p>
                  <p className="ml-8">{userData?.school}</p>
                </div> */}
              </div>
            </div>
          </div>
        )}
      </Segment>
    </div>
  )
}

export default NonVoucherStudentProfile
