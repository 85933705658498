import React from 'react'
import {BsPen} from 'react-icons/bs'
import {Link, useParams} from 'react-router-dom'
import {Image} from 'semantic-ui-react'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'
import routes from '../../routes'
import {truncate} from '../../utils/string'

const ClassChapterCard = ({chapterSrc, chapterid, name, brief, order}) => {
  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  const {classid, subjectid} = useParams()


  return (
    <div className="rounded-xl shadow-sm hover:shadow-md hover:opacity-95 flex flex-col border-2 solid">
      <Link to={routes.instructor.chapterContent(classid, subjectid,chapterid)}>
        <Image
          className="w-80 h-44 bg-primaryLight rounded-t-md"
          src={chapterSrc}
          alt="no image provided"
        ></Image>
      </Link>

      <div className="flex flex-col p-3 w-80 h-32">
        <div className="flex justify-between items-center">
          <p className="text-primary text-lg font-semibold">
            {langContent.Chapter} {order}: {truncate(name, 20)}
          </p>
        </div>
        <p className="font-semibold h-full overflow-y-auto ">{brief}</p>
      </div>
    </div>
  )
}

export default ClassChapterCard
