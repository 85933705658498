import {Formik} from 'formik'
import React from 'react'
import {useHistory} from 'react-router-dom'
import {
  Breadcrumb,
  Button,
  Dimmer,
  Form,
  Image,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react'
import * as Yup from 'yup'
import {FaUserAlt} from 'react-icons/fa'

import FormikInput from '../../components/formik/FormikInput'
import ImageAttachment from '../../components/shared/image-attachment'
import {authAxios} from '../../config/axiosConfig'
import useAsync from '../../hooks/useAsync'
import {formatResponseError} from '../../utils/format-response'
import {useUser} from '../../context/user-context'
import { useLanguage } from '../../context/languageContext'
import { content } from '../../localization/content'

const VoucherStudentProfile = () => {
  const [isEditting, setIsEditting] = React.useState(false)
  const [attachmentData, setAttachmentData] = React.useState(null)
  const [imagePath, setImagePath] = React.useState(null)
  const history = useHistory()
  const formRef = React.useRef()

  const [userData, setUserData] = React.useState()

  const {getUser,setUser} = useUser()
  const user = getUser()

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]


  const userSchema = Yup.object({
    firstname: Yup.string().trim().required('Required'),
    lastname: Yup.string().trim().required('Required'),
  })

  const {
    run: getStudentInfo,
    error: errorGettingStudentInfo,
    isLoading: gettingStudentInfo,
  } = useAsync()

  const {
    run: updateStudentInfo,
    error: errorUpdatingStudentInfo,
    isLoading: updateingStudentInfo,
  } = useAsync()

  React.useEffect(() => {
    getStudentInfo(authAxios.get(`/student/profile`)).then(({data}) => {
      // console.log(data)
      setUserData(data)
    })
  }, [getStudentInfo, isEditting])

  const editProfile = () => {
    if (formRef.current) {
      // console.log(formRef.current)
      formRef.current.handleSubmit()
    }
  }

  const handleOnSubmit = values => {
    const formData = new FormData()
    for (let i in values) {
      formData.append(i, values[i])
    }
    if (attachmentData) formData.append('img', attachmentData)

    //   for (var pair of formData.entries()) {
    //     console.log(pair[0]+ ', ' + pair[1]);
    // }

    updateStudentInfo(authAxios.put(`/student/profile`, formData)).then(
      ({data}) => {
        console.log(data)
        setUserData(data)
        setUser({...user,name: `${data?.firstname} ${data?.lastname}`})
        setIsEditting(p => !p)
      },
    )
  }

  // console.log(attachmentData)

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent flex flex-col">
        {errorGettingStudentInfo ? (
          <Message error list={formatResponseError(errorGettingStudentInfo)} />
        ) : null}
        {errorUpdatingStudentInfo ? (
          <Message error list={formatResponseError(errorUpdatingStudentInfo)} />
        ) : null}

        <Dimmer active={gettingStudentInfo || updateingStudentInfo} inverted>
          <Loader active={true} />
        </Dimmer>
        <Breadcrumb className="mt-4">
          <Breadcrumb.Section onClick={() => history.push('/')} link>
          {langContent.home}
          </Breadcrumb.Section>
          <Breadcrumb.Divider>/</Breadcrumb.Divider>
          <Breadcrumb.Section active> {langContent.My_Profile}</Breadcrumb.Section>
        </Breadcrumb>

        <div className="flex items-center justify-between  mt-8">
          <h3 className="text-primary mt-8">{langContent.Personal_Information}</h3>
          <div className="">
            <Button
              type="submit"
              className={`${
                isEditting
                  ? 'border border-solid border-primary bg-transparent text-primary'
                  : 'bg-primary text-white'
              }`}
              onClick={() => {
                if (isEditting) {
                  editProfile()
                } else {
                  setIsEditting(p => !p)
                }
              }}
            >
              {isEditting ? langContent.Submit : langContent.Edit_Profile}
            </Button>
          </div>
        </div>

        {isEditting ? (
          <div className="border-2 border-gray-200 solid px-16 py-10 rounded-lg mt-8 flex flex-col md:flex-row">
            <div className="w-2/6">
              <div className="w-72 h-60 relative">
                {gettingStudentInfo ? (
                  <Dimmer
                    active={gettingStudentInfo || updateingStudentInfo}
                    inverted
                  >
                    <Loader active={true} />
                  </Dimmer>
                ) : userData?.profileImage || attachmentData?.name ? (
                  <Image
                    className="w-72 h-60 bg-cover sm:bg-primaryLight"
                    // src="https://image.shutterstock.com/image-photo/portrait-smiling-young-college-student-260nw-1192615495.jpg"
                    src={
                      attachmentData?.name ? imagePath : userData?.profileImage
                    }
                  ></Image>
                ) : (
                  <FaUserAlt size={96} className="mx-auto w-72 h-60 p-10"></FaUserAlt>
                )}
                {/* <Image
                  className="w-72 h-60 bg-cover sm:bg-primaryLight"
                  // src="https://image.shutterstock.com/image-photo/portrait-smiling-young-college-student-260nw-1192615495.jpg"
                  src={
                    attachmentData?.name
                      ? imagePath
                      : userData?.profileImage
                      ? userData?.profileImage
                      : 'https://image.shutterstock.com/image-photo/portrait-smiling-young-college-student-260nw-1192615495.jpg'
                  }
                ></Image> */}
                <ImageAttachment
                  className="absolute top-4 right-4 "
                  setAttachmentData={setAttachmentData}
                  setImagePath={setImagePath}
                />
              </div>
            </div>
            <div className="w-4/6 my-auto ml-10 lg:ml-0">
              <div>
                <div className="w-96">
                  <Formik
                    initialValues={{
                      firstname: userData?.firstname || '',
                      lastname: userData?.lastname || '',
                    }}
                    validationSchema={userSchema}
                    onSubmit={handleOnSubmit}
                    innerRef={formRef}
                    enableReinitialize
                  >
                    {formik => (
                      <Form
                        onSubmit={formik.handleSubmit}
                        //   loading={creatingQuestion}
                      >
                        <div className="flex items-center text-primary ">
                          <FormikInput
                            name="firstname"
                            label={langContent.First_Name}
                            className="flex flex-col bg-transparent ml-8 w-64"
                          />
                        </div>

                        <div className="flex items-center my-4 text-primary">
                          <FormikInput
                            name="lastname"
                            label={langContent.Last_Name}
                            className="flex flex-col bg-transparent ml-8 w-64 mb-2"
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>

                <div className="flex ">
                  <p className="text-primary">{langContent.School_Name}</p>
                  <p className="ml-8">{userData?.school?.name}</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="border-2 border-gray-200 solid px-16 py-10 rounded-lg mt-8 flex flex-col md:flex-row">
            <div className="w-2/6">
              {gettingStudentInfo ? (
                <Dimmer
                  active={gettingStudentInfo || updateingStudentInfo}
                  inverted
                >
                  <Loader active={true} />
                </Dimmer>
              ) : userData?.profileImage ? (
                <Image
                  className="w-72 h-60 bg-cover"
                  src={userData?.profileImage}
                  // src="https://image.shutterstock.com/image-photo/portrait-smiling-young-college-student-260nw-1192615495.jpg"
                />
              ) : (
                <FaUserAlt size={96} className="mx-auto w-72 h-60 p-10"></FaUserAlt>
              )}

              {/* <Image
                className="w-72 h-60 bg-cover"
                src={
                  userData?.profileImage
                    ? userData?.profileImage
                    : 'https://image.shutterstock.com/image-photo/portrait-smiling-young-college-student-260nw-1192615495.jpg'
                }
                // src="https://image.shutterstock.com/image-photo/portrait-smiling-young-college-student-260nw-1192615495.jpg"
              /> */}
            </div>
            <div className="w-4/6 my-auto ml-10 lg:ml-0">
              <div>
                <div className="flex ">
                  <p className="text-primary">{langContent.First_Name}</p>
                  <p className="ml-8">{userData?.firstname}</p>
                </div>

                <div className="flex  my-8">
                  <p className="text-primary">{langContent.Last_Name}</p>
                  <p className="ml-8">{userData?.lastname}</p>
                </div>

                <div className="flex ">
                  <p className="text-primary">{langContent.School_Name}</p>
                  <p className="ml-8">{userData?.school?.name}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </Segment>
    </div>
  )
}

export default VoucherStudentProfile
