import React from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import {
  Button,
  Dimmer,
  Loader,
  Message,
  Modal,
  Segment,
} from 'semantic-ui-react'
import {
  AiOutlineCheckCircle,
  AiOutlineClose,
  AiOutlineCloseCircle,
} from 'react-icons/ai'
import {FaCalculator} from 'react-icons/fa'

import useAsync from '../../../../hooks/useAsync'
import {authAxios} from '../../../../config/axiosConfig'
import {formatResponseError} from '../../../../utils/format-response'
import StopWatchTimer from '../../../../components/shared/stop-watch-timer'
import routes from '../../../../routes'
import Choice from '../questions/choice'
import Blank from '../questions/blank'
import Journal from '../questions/Journal'
import Amortization from '../questions/amortization'
import TAccount from '../questions/taccount'
import CountDownTimer from './countDownTimer/countdown-timer'
import NewCountDownTimer from './countDownTimer/new-countdown-timer'
import Calculator from '../../../../components/shared/calculator/calculator'
import {useLanguage} from '../../../../context/languageContext'
import {content} from '../../../../localization/content'
import Draggable from 'react-draggable'
import BalanceSheet from '../questions/balance-sheet'

const Test = () => {
  const history = useHistory()
  const {classid, subjectid, chapterid, testid, exerciseAttempt} = useParams()

  const [questionData, setQuestionData] = React.useState({})
  const [testData, setTestData] = React.useState({})
  const [testDataONTimeOut, setTestDataONTimeOut] = React.useState({})

  const [questionSuccess, setQuestionSuccess] = React.useState(false)
  const [examSuccess, setExamSuccess] = React.useState(false)
  const [examSuccessScore, setExamSuccessScore] = React.useState()

  const [examFalid, setExamFalid] = React.useState(false)
  const [examTimeOutModal, setExamTimeOutModal] = React.useState(false)
  const [timeExpire, setTimeExpire] = React.useState(false)
  const [examTimeOnSeconds, setExamTimeOnSeconds] = React.useState(false)
  const [openCalc, setOpenCalc] = React.useState(false)

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  const {
    run: getQuestion,
    error: errorGettingQuestion,
    isLoading: gettingQuestion,
  } = useAsync()

  const {
    run: getTest,
    error: errorGettingTest,
    isLoading: gettingTest,
  } = useAsync()

  const {
    run: getTimeOut,
    error: errorGettingTimeOut,
    isLoading: gettingTimeOut,
  } = useAsync()

  React.useEffect(() => {
    if (!questionData?.lastQuestion && timeExpire) {
      console.log('timeExpire')
      setExamTimeOutModal(true)
      getTimeOut(
        authAxios.get(`/correct/exercise/test/${exerciseAttempt}`),
      ).then(({data}) => {
        console.log(data)
        setTestDataONTimeOut(data)
      })
    }
  }, [exerciseAttempt, getTimeOut, questionData?.lastQuestion, timeExpire])

  React.useEffect(() => {
    if (testid)
      getTest(authAxios.get(`/exercise/${testid}`)).then(({data}) => {
        setTestData(data)
      })
  }, [getTest, testid])

  React.useEffect(() => {
    getQuestion(
      authAxios.get(`/correct/exercise/next/${exerciseAttempt}`),
    ).then(({data}) => {
      console.log({questionData: data})
      setExamTimeOnSeconds(
        data?.duration?.hour * 60 * 60 + data?.duration?.miniute * 60,
      )
      if (data?.examSuccessfull) {
        // history.push(routes.voucherStudent.chapters.content(classid, subjectid, chapterid))
        setExamSuccess(true)
      }
      if (data?.lastQuestion && !data?.examSuccessfull) {
        // history.push(routes.voucherStudent.chapters.content(classid, subjectid, chapterid))
        setExamFalid(true)
      }
      setQuestionData(data)
    })
  }, [
    chapterid,
    classid,
    exerciseAttempt,
    getQuestion,
    history,
    questionSuccess,
    subjectid,
  ])

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent">
        {errorGettingQuestion ? (
          <Message error list={formatResponseError(errorGettingQuestion)} />
        ) : null}

        {errorGettingTest ? (
          <Message error list={formatResponseError(errorGettingTest)} />
        ) : null}

        <Dimmer active={gettingQuestion || gettingTest} inverted>
          <Loader active={true} />
        </Dimmer>
        <StopWatchTimer
          attemptId={questionData?.attemptId}
          questionId={questionData?._id}
        />

        <div className="flex my-3">
          <Link
            className="ml-auto"
            to={routes.voucherStudent.chapters.content(
              classid,
              subjectid,
              chapterid,
            )}
          >
            {langContent.Cancel_exercice}
          </Link>
        </div>



        {/* <div className="absolute right-4 bottom-44 z-1">
          <Button
            primary
            onClick={() => setOpenCalc(p => !p)}
            className="rounded-2xl p-3"
          >
            <FaCalculator className="text-3xl" />
          </Button>
        </div>
        <div
          className={`absolute right-20 bottom-44 z-1 w-64 h-72   ${
            openCalc ? '' : 'hidden'
          }`}
        >
          <Calculator />
        </div> */}



        <Draggable bounds="parent">
          <div className="absolute right-4 bottom-44 z-1">
            <Button
              primary
              onClick={() => setOpenCalc(p => !p)}
              className="rounded-2xl p-3"
            >
              <FaCalculator id="calcIcon" className="text-3xl" />
            </Button>
            <div
              className={`absolute right-16 bottom-0 w-64 h-72 z-1  ${
                openCalc ? '' : 'hidden'
              }`}
            >
              <Calculator />
            </div>
          </div>
        </Draggable>



        <div className="bg-primary p-4 rounded-sm text-white flex items-center justify-evenly">
          <p className="mr-4">
            {langContent.test}: {testData?.name}
          </p>

          <NewCountDownTimer
            exerciseAttempt={exerciseAttempt}
            setTimeExpire={setTimeExpire}
            expiryTimestamp={examTimeOnSeconds}
          />
        </div>
        {!questionData?.lastQuestion && timeExpire && (
          <div className="flex justify-center items-center bg-yellow-100 my-4">
            <p className="text-4xl my-4">Time Out !</p>
          </div>
        )}

        {questionData?.lastQuestion ? (
          <div className="flex justify-center items-center bg-yellow-100 my-4">
            <p className="text-4xl my-4">{langContent.Question_finished}!</p>
          </div>
        ) : (
          <>
            {questionData?.type === 'blank' && (
              <Blank
                examType="test"
                data={questionData}
                setQuestionSuccess={setQuestionSuccess}
                setExamSuccess={setExamSuccess}
                setExamSuccessScore={setExamSuccessScore}
              />
            )}
            {questionData?.type === 'choice' && (
              <Choice
                examType="test"
                data={questionData}
                setQuestionSuccess={setQuestionSuccess}
                setExamSuccess={setExamSuccess}
                setExamSuccessScore={setExamSuccessScore}
              />
            )}
            {questionData?.type === 'journal' && (
              <Journal
                examType="test"
                data={questionData}
                setQuestionSuccess={setQuestionSuccess}
                setExamSuccess={setExamSuccess}
                setExamSuccessScore={setExamSuccessScore}
              />
            )}
            {questionData?.type === 'amortization' && (
              <Amortization
                examType="test"
                data={questionData}
                setQuestionSuccess={setQuestionSuccess}
                setExamSuccess={setExamSuccess}
                setExamSuccessScore={setExamSuccessScore}
              />
            )}
            {questionData?.type === 'taccount' && (
              <TAccount
                examType="test"
                data={questionData}
                setQuestionSuccess={setQuestionSuccess}
                setExamSuccess={setExamSuccess}
                setExamSuccessScore={setExamSuccessScore}
              />
            )}
            {questionData?.type === 'balanceSheet' && (
              <BalanceSheet
                examType="test"
                data={questionData}
                setQuestionSuccess={setQuestionSuccess}
                setExamSuccess={setExamSuccess}
                setExamSuccessScore={setExamSuccessScore}
              />
            )}
          </>
        )}
        <ExamSuccessModal
          open={examSuccess}
          onClose={() => setExamSuccess(false)}
          score={questionData?.score || examSuccessScore}
        />

        <ExamFailedModal
          open={examFalid}
          onClose={() => setExamFalid(false)}
          score={questionData?.score}
        />
        <ExamTimeOutModal
          open={examTimeOutModal}
          onClose={() => setExamTimeOutModal(false)}
          // score={60}
          testDataONTimeOut={testDataONTimeOut}
        />
      </Segment>
    </div>
  )
}

export default Test

const ExamSuccessModal = ({open, onClose, score}) => {
  const {classid, subjectid, chapterid, sectionid, pexid, exerciseAttempt} =
    useParams()
  const history = useHistory()
  const [lang, setLang] = useLanguage()
  const langContent = content[lang]
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      size="tiny"
      // closeIcon
    >
      <Modal.Content className="flex  flex-col h-60 items-center relative">
        <AiOutlineClose
          onClick={() => {
            onClose()
            history.push(
              routes.voucherStudent.chapters.content(
                classid,
                subjectid,
                chapterid,
              ),
            )
          }}
          className="absolute top-4 right-4 bg-transparent text-2xl font-semibold cursor-pointer"
        />

        <div>
          <AiOutlineCheckCircle className="text-8xl text-green-600 " />
        </div>
        <div>
          <p className="text-green-600 my-3 font-semibold">
            {langContent.Your_score_is} {parseFloat(score)?.toFixed(0)}%!{' '}
          </p>
        </div>
        <div>
          <p className="text-gray-600 my-3 font-semibold">
            {langContent.Your_can_proceed_to_the_next_PEX}
          </p>
        </div>
      </Modal.Content>
    </Modal>
  )
}

const ExamFailedModal = ({open, onClose, score}) => {
  const {classid, subjectid, chapterid, sectionid, pexid, exerciseAttempt} =
    useParams()
  const history = useHistory()
  const [lang, setLang] = useLanguage()
  const langContent = content[lang]
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      size="tiny"
    >
      <Modal.Content className="flex  flex-col h-60 items-center relative">
        <AiOutlineClose
          onClick={() => {
            onClose()
            history.push(
              routes.voucherStudent.chapters.content(
                classid,
                subjectid,
                chapterid,
              ),
            )
          }}
          className="absolute top-4 right-4 bg-transparent text-2xl font-semibold cursor-pointer"
        />

        <div>
          <AiOutlineCloseCircle className="text-8xl text-red-600 " />
        </div>
        <div>
          <p className="text-red-600 my-3 font-semibold">
          {langContent.Your_score_is} {parseFloat(score)?.toFixed(2)}%!{' '}
          </p>
        </div>
        <div>
          <p className="text-gray-600 my-3 font-semibold">
          {langContent.Your_need_to_try_it_again}
          </p>
        </div>
      </Modal.Content>
    </Modal>
  )
}

const ExamTimeOutModal = ({open, onClose, score, testDataONTimeOut}) => {
  const {classid, subjectid, chapterid, sectionid, pexid, exerciseAttempt} =
    useParams()
  const history = useHistory()
  const [lang, setLang] = useLanguage()
  const langContent = content[lang]
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      size="tiny"
    >
      {testDataONTimeOut?.successfull ? (
        <Modal.Content className="flex  flex-col h-60 items-center relative">
          <AiOutlineClose
            onClick={() => {
              onClose()
              history.push(
                routes.voucherStudent.chapters.content(
                  classid,
                  subjectid,
                  chapterid,
                ),
              )
            }}
            className="absolute top-4 right-4 bg-transparent text-2xl font-semibold cursor-pointer"
          />

          <div>
            <AiOutlineCheckCircle className="text-8xl text-green-600 " />
          </div>
          <div>
            <p className="text-green-600 my-3 font-semibold">
            {langContent.Your_score_is} {testDataONTimeOut?.exercisePercent?.toFixed(0)}%!{' '}
            </p>
          </div>
          <div>
            <p className="text-gray-600 my-3 font-semibold">
              {langContent.Your_can_proceed_to_the_next_PEX}
            </p>
          </div>
        </Modal.Content>
      ) : (
        <Modal.Content className="flex  flex-col h-60 items-center relative">
          <AiOutlineClose
            onClick={() => {
              onClose()
              history.push(
                routes.voucherStudent.chapters.content(
                  classid,
                  subjectid,
                  chapterid,
                ),
              )
            }}
            className="absolute top-4 right-4 bg-transparent text-2xl font-semibold cursor-pointer"
          />

          <div>
            <AiOutlineCloseCircle className="text-8xl text-red-600 " />
          </div>
          <div>
            <p className="text-red-600 my-3 font-semibold">
              {langContent.Your_score_is} {testDataONTimeOut?.exercisePercent?.toFixed(0)}%!{' '}
            </p>
          </div>
          <div>
            <p className="text-gray-600 my-3 font-semibold">
              {langContent.Your_need_to_try_it_again}
            </p>
          </div>
        </Modal.Content>
      )}
    </Modal>
  )
}
