import React from 'react'
import {
  Icon,
  
} from 'semantic-ui-react'
import {truncate} from '../../utils/string'

const PdfCard = ({title, pdfLink}) => {

  
  

  return (
    <div className="rounded-md w-56 h-44 shadow-md hover:shadow-xl  hover:opacity-95  flex  flex-col border-2 solid relative">
      <a target="_blank" href={pdfLink} rel="noreferrer" className="">
        <div className="flex justify-center items-center mt-8">
          <Icon name="file pdf outline" size="huge" />
        </div>

        <div className="flex justify-center items-center mt-4">
          <p className="text-center px-2 break-all">{truncate(title, 44)}</p>
        </div>
        
      </a>
    </div>
  )
}

export default PdfCard
