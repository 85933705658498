import React from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {
  Breadcrumb,
  Button,
  Confirm,
  Dimmer,
  Icon,
  Image,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react'
import {authAxios} from '../../../config/axiosConfig'
import useAsync from '../../../hooks/useAsync'
import routes from '../../../routes'
import {formatResponseError} from '../../../utils/format-response'

const Enrollment = () => {
  const history = useHistory()
  const [confirmUnsubscribe, setConfirmUnsubscribe] = React.useState(false)
  const {subjectid, planid} = useParams()
  const [enrollmentsData, setEnrollmentsData] = React.useState([])
  const [currentSubject, setcurrentSubject] = React.useState()
  const [forceReload, setforceReload] = React.useState(false)

  const {
    run: getEnrollments,
    isLoading: loadingEnrollments,
    error: errorEnrollments,
  } = useAsync()

  const {
    run: deletesubject,
    isLoading: loadingDeleteSubject,
    error: erroDeleteingSubject,
  } = useAsync()

  React.useEffect(() => {
    getEnrollments(
      authAxios.get('/user/subscribe', {
        subject: subjectid,
        plan: planid,
      }),
    ).then(({data}) => {
      console.log(data)
      setEnrollmentsData(data || [])
    })
  }, [getEnrollments, planid, subjectid, forceReload])

  const handleUnsubscribr = () => {
    deletesubject(authAxios.delete(`/user/subscribe/${currentSubject}`)).then(
      ({data}) => {
        console.log(data)
        setforceReload(p => !p)
        setConfirmUnsubscribe(false)
        // setEnrollmentsData(data || [])
      },
    )
  }

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent w-full ">
        {errorEnrollments ? (
          <Message error list={formatResponseError(errorEnrollments)} />
        ) : null}
        {erroDeleteingSubject ? (
          <Message error list={formatResponseError(erroDeleteingSubject)} />
        ) : null}

        <Dimmer active={loadingDeleteSubject || loadingEnrollments} inverted>
          <Loader active={true} />
        </Dimmer>

        <div className="mt-8">
          <Breadcrumb className="my-10">
            <Breadcrumb.Section onClick={() => history.push('/')} link>
              Home
            </Breadcrumb.Section>
            <Breadcrumb.Divider>/</Breadcrumb.Divider>

            <Breadcrumb.Section active> Enrollment </Breadcrumb.Section>
          </Breadcrumb>
        </div>

        {enrollmentsData.map((k, i) => (
          <div className="rounded-md border-2 border-solid border-pr mr-16 h-60 w-11/12 flex enroll_card my-4 shadow hover:shadow-md">
            <Image
              className="rounded-l-md w-4/12 py-0.5 -mt-0.5 bg-primaryLight"
              src={k?.subject?.image}
              alt={'No Image Provided'}
            ></Image>

            <div className="px-6 py-8  w-8/12">
              <div className="text-xl font-semibold">
                <p>{k?.subject?.name}</p>
              </div>
              <div className="text-lg text-primary font-medium my-3">
                <p>Indil's {k?.plan} subscription</p>
              </div>
              <div className="flex items-center text-xl font-extrabold -ml-2 my-2">
                <Icon name="eur"></Icon>
                <p className="mt-2">
                  {k?.plan === 'monthly' ? '4.99' : '39.99'}
                </p>
              </div>

              <div className="w-full flex mt-6">
                <Button
                  // disabled
                  className="ml-auto bg-transparent text-gray-400 p-0 mr-10 text-base"
                  onClick={() => {
                    setConfirmUnsubscribe(true)
                    setcurrentSubject(k?.subject?._id)
                  }}
                >
                  Unsubscribe
                </Button>
                <Button
                  disabled={k?.plan !== 'monthly'}
                  className="px-10"
                  primary
                  onClick={() =>
                    history.push(
                      routes.nonVoucherStudent.chapters.subscribe.checkout(
                        k?.subject._id,
                        'annual',
                      ),
                    )
                  }
                >
                  Upgrade
                </Button>
              </div>
            </div>
          </div>
        ))}

        {/* <div className="rounded-md border-2 border-solid border-pr mr-16 h-60 w-11/12 flex enroll_card">
        <Image
          className="h-60 rounded-l-md w-96 py-0.5 -mt-0.5"
          src={
            'https://i0.wp.com/thewriteress.com/wp-content/uploads/2020/12/learn-accounting.jpg?fit=1200%2C648&ssl=1'
          }
        ></Image>

        <div className="px-6 py-8 w-full ">
          <div className="text-xl font-semibold">
            <p>Accounting Fundamentals</p>
          </div>
          <div className="text-lg text-primary font-medium my-3">
            <p>Indil's Monthly subscription</p>
          </div>
          <div className="flex items-center text-xl font-extrabold -ml-2 my-2">
            <Icon name="eur"></Icon>
            <p className="mt-2">4.99</p>
          </div>

          <div className="w-full flex mt-6">
            <Button
            disabled
              className="ml-auto bg-transparent text-gray-400 p-0 mr-10 text-base"
              onClick={() => setConfirmUnsubscribe(true)}
            >
              Unsubscribe
            </Button>
            <Button
              className="px-10"
              primary
              onClick={() =>
                history.push(
                  routes.nonVoucherStudent.chapters.subscribe.checkout(
                    subjectid,
                    'annual',
                  ),
                )
              }
            >
              Upgrade
            </Button>
          </div>
        </div>
      </div> */}

        <Confirm
          className="font-medium text-center confirm_unsubscribe"
          size="tiny"
          content="Are you sure you want to unsubscribe from this subject ?"
          open={confirmUnsubscribe}
          cancelButton={
            <Button className="border border-solid border-primary bg-transparent px-10 mr-4 -mt-2 mb-2">
              No
            </Button>
          }
          confirmButton={
            <Button primary className="px-11 -mt-2 mb-2">
              Yes
            </Button>
          }
          onCancel={() => setConfirmUnsubscribe(false)}
          onConfirm={() => {
            setConfirmUnsubscribe(false)
            handleUnsubscribr()}}
        />
      </Segment>
    </div>
  )
}

export default Enrollment
