import React from 'react'
import ReactApexChart from 'react-apexcharts'
import {Table} from 'semantic-ui-react'
import { useLanguage } from '../../../context/languageContext'
import { content } from '../../../localization/content'

const QuestionBreakDownDetails = ({rowToExpand}) => {

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]


  let optionsLabels = []
  let seriesLabels = []
  for (let iterator in rowToExpand?.questionChart) {
    // optionsLabels.push(iterator)
    // optionsLabels.push(iterator)
    if(iterator === 'zero'){
      optionsLabels.push(langContent.Zero)
    } else if(iterator === 'one'){
      optionsLabels.push(langContent.One)

    }else if(iterator === 'two'){
      optionsLabels.push(langContent.Two)
      
    }else if(iterator === 'more'){
      optionsLabels.push(langContent.more)
      
    }



    console.log(iterator)
    seriesLabels.push(rowToExpand?.questionChart[iterator])
  }

  const [state, setState] = React.useState({
    // series: [44, 55, 43, 22],
    series: seriesLabels,
    options: {
      chart: {
        width: '100%',
        type: 'pie',
      },
      labels: optionsLabels,
      // labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  })

  return (
    <Table.Row key={rowToExpand?.questionId}>
      <Table.Cell colSpan={16} selectable={false}>
        <div className="flex">
          <div className="flex-1 flex">
            <div id="chart">
              <ReactApexChart
                options={state.options}
                series={state.series}
                type="pie"
                width={350}
              />
            </div>
            <div className="mt-7 pt-0.5 text-gray-500 text-base">
              <p className="flex flex-row">
                <p>{rowToExpand?.questionChart?.zero}</p>
                <p className="ml-1">{langContent.students}</p>
              </p>
              <p>{rowToExpand?.questionChart?.one} {langContent.students}</p>
              <p>{rowToExpand?.questionChart?.two} {langContent.students}</p>
              <p>{rowToExpand?.questionChart?.more} {langContent.students}</p>
            </div>
          </div>

          <div className="flex-1">
            {rowToExpand?.questionFeedbacks?.length > 0 ? (
              <h3 className="text-primary mb-2">Frequent errors</h3>
            ) : null}
            <ul className="list-disc list-inside">
              {rowToExpand?.questionFeedbacks?.map((s, i) => (
                <li key={i}>{s?.feedbackMessage}</li>
              ))}
            </ul>
          </div>
        </div>
      </Table.Cell>
    </Table.Row>
  )
}

export default QuestionBreakDownDetails
