import React from 'react'
import {
  Accordion,
  Dimmer,
  Icon,
  Loader,
  Message,
  Progress,
  Segment,
  Table,
} from 'semantic-ui-react'
import {AiOutlineCaretDown, AiOutlineCaretRight} from 'react-icons/ai'
import 'react-circular-progressbar/dist/styles.css'
import {BsCircleFill} from 'react-icons/bs'

import {truncate} from '../../../utils/string'
import AnimatedProgressProvider from '../../../components/shared/AnimatedProgressProvider'
import useAsync from '../../../hooks/useAsync'
import {authAxios} from '../../../config/axiosConfig'
import {useParams} from 'react-router-dom'
import {formatResponseError} from '../../../utils/format-response'
import {Fragment} from 'react/cjs/react.production.min'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'

const ClassChapterProgress = () => {
  const [rowToExpand, setRowToExpand] = React.useState(null)
  const [activeIndex, setActiveIndex] = React.useState(-1)
  const [chapterProgressData, setChapterProgressData] = React.useState()
  const {classid, subjectid} = useParams()

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  const {
    run: getChapterProgress,
    error: errorGettingChapterProgress,
    isLoading: gettingChapterProgress,
  } = useAsync()

  React.useEffect(() => {
    getChapterProgress(
      authAxios.get(`/instructor/class/chapter/progress?class=${classid}`),
    ).then(({data}) => {
      console.log(data)
      setChapterProgressData(data)
    })
  }, [classid, getChapterProgress])

  const handleClickAccordion = (e, titleProps) => {
    const {index} = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }

  return (
    <div>
      {errorGettingChapterProgress ? (
        <Message
          error
          list={formatResponseError(errorGettingChapterProgress)}
        />
      ) : null}
      <Segment className="border-none shadow-none bg-transparent flex flex-col">
        <Dimmer active={gettingChapterProgress} inverted>
          <Loader active={true} />
        </Dimmer>
        <Accordion fluid styled>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={handleClickAccordion}
            className="flex items-center"
          >
            <Icon name="dropdown" />
            <p className="text-primary text-center text-xl flex-1">
              Instructions
            </p>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <div className="flex text-gray-500  items-center">
              <BsCircleFill size={8} />
              <p className="ml-2">{langContent.teacher_chapter_progress_p_1}</p>
            </div>
            {/* <div className="flex text-gray-500 items-center">
              <BsCircleFill size={8} />
              <p className="ml-2">
                You can also check the top 5 frequent errors related to this
                chapter
              </p>
            </div> */}
          </Accordion.Content>
        </Accordion>

        <Table className="shadow-lg my-5">
          <Table.Header>
            <Table.Row className="text-sm">
              <Table.HeaderCell
                textAlign="center"
                className="bg-tableHeader"
              ></Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader">
              {langContent.Chapters_name} 
              </Table.HeaderCell>

              <Table.HeaderCell className="bg-tableHeader">
                {langContent.Progress}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {chapterProgressData?.map((a, idx) => (
              <Fragment key={idx}>
                <Table.Row textAlign="left" verticalAlign="top">
                  <Table.Cell
                    width={1}
                    textAlign="center"
                    selectable
                    onClick={() =>
                      rowToExpand?.chapterId === a?.chapterId
                        ? setRowToExpand(null)
                        : setRowToExpand(a)
                    }
                  >
                    {rowToExpand?.chapterId === a.chapterId ? (
                      <AiOutlineCaretDown className="mx-auto mt-4" size="16" />
                    ) : (
                      <AiOutlineCaretRight className="mx-auto mt-4" size="16" />
                    )}
                  </Table.Cell>
                  <Table.Cell width={1}>{a?.chapter}</Table.Cell>
                  <Table.Cell width={5}>
                    <div className="flex items-start  h-8">
                      <Progress
                        className="w-96 mt-0"
                        size="small"
                        percent={a?.progress}
                        // {a?.progress >= 80 ?(success):(error)
                        // }
                        success
                      >
                        {/* {a?.progress} */}
                      </Progress>
                      <p className="ml-4 -mt-1">{parseFloat(a?.progress).toFixed(0)}%</p>
                    </div>
                  </Table.Cell>
                </Table.Row>

                {rowToExpand?.chapterId === a.chapterId ? (
                  <Table.Row key={a.chapterId}>
                    <Table.Cell colSpan={16} selectable={false}>
                      <div className="flex">
                        <div className="flex-1 max-w-xl flex flex-wrap">
                          {rowToExpand?.skills?.map((s, i) => (
                            <div
                              key={i}
                              className="w-36  m-2 flex  flex-col items-center"
                            >
                              <div>
                                <AnimatedProgressProvider
                                  valueEnd={Number.parseFloat(`${s?.progress}`).toFixed(0)}
                                ></AnimatedProgressProvider>
                              </div>
                              <div className="h-9">
                                <p className="-mt-4 text-center text-sm text-gray-500">
                                  {truncate(s?.skill, 30)}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className="flex-1">
                          {rowToExpand?.feedbacks?.length > 0 ? (
                            <h3 className="text-primary mb-2">
                              Frequent errors
                            </h3>
                          ) : null}
                          <ul className="list-disc list-inside">
                            {rowToExpand?.feedbacks?.map((s, i) => (
                              <li>{s?.feedbackMessage}</li>
                            ))}
                            {/* <li>Frequent error 1</li>
                            <li>Frequent error 2</li>
                            <li>Frequent error 3</li> */}
                          </ul>
                        </div>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ) : null}
              </Fragment>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    </div>
  )
}

export default ClassChapterProgress
