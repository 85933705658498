import React from 'react'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import {
  Button,
  Dimmer,
  Dropdown,
  Loader,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react'
import {
  AiOutlineCaretDown,
  AiOutlineCaretRight,
  AiOutlineEye,
  AiOutlineMinusCircle,
} from 'react-icons/ai'
import queryString from 'query-string'

import {truncate} from '../../../utils/string'
import routes from '../../../routes'
import useAsync from '../../../hooks/useAsync'
import {authAxios} from '../../../config/axiosConfig'
import {formatResponseError} from '../../../utils/format-response'
import {Fragment} from 'react/cjs/react.production.min'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'

const ClassStudentinfo = () => {
  const [rowToExpand, setRowToExpand] = React.useState(null)
  const [chapters, setChapters] = React.useState([])
  const [currentChapter, setCurrentChapter] = React.useState()
  const [studentsInfo, setStudentsInfo] = React.useState()
  const [studentInfoDetails, setStudentInfoDetails] = React.useState()

  const {classid, subjectid} = useParams()
  const location = useLocation()
  const [state] = React.useState(location?.state)

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  const history = useHistory()

  const {
    run: getChapters,
    error: errorGettingChapters,
    isLoading: gettingChapters,
  } = useAsync()

  const {
    run: getStudentInfo,
    error: errorGettingStudentInfo,
    isLoading: gettingStudentInfo,
  } = useAsync()

  const {
    run: getStudentDetails,
    error: errorGettingStudentDetails,
    isLoading: gettingStudentDetails,
  } = useAsync()

  React.useEffect(() => {
    const {currentChapterid} = queryString.parse(location.search)

    if (currentChapterid !== 'undefined') {
      // setCurrentExerciseType(currentexerciseType)
      setCurrentChapter(currentChapterid)
    }
    // setCurrentChapter(currentChapterid)

    if (currentChapter) {
      getStudentInfo(
        authAxios.get(
          `/instructor/class/studentsinfo?class=${classid}&chapter=${currentChapter}`,
        ),
      ).then(({data}) => {
        console.log(data)
        setStudentsInfo(data)
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getStudentInfo, currentChapter])

  React.useEffect(() => {
    getChapters(authAxios.get(`/chapter/all/publish?subject=${subjectid}`)).then(
      ({data}) => {
        let options = []
        data?.forEach(s =>
          options.push({
            text: truncate(s.chapterName, 20),
            key: s._id,
            value: s._id,
          }),
        )
        setChapters(options)
      },
    )
  }, [getChapters, subjectid])

  React.useEffect(() => {
    if (chapters.length && !currentChapter) {
      if (state?.currentChapter) {
        setCurrentChapter(state?.currentChapter)
        history.replace(
          `${location.pathname}?currentChapterid=${state?.currentChapter}`,
        )
      } else {
        setCurrentChapter(chapters[0]?.value)
        history.replace(
          `${location.pathname}?currentChapterid=${chapters[0]?.value}`,
        )
      }
    }
  }, [chapters, currentChapter, history, location.pathname, state])

  React.useEffect(() => {
    if (rowToExpand?.studentId)
      getStudentDetails(
        authAxios.get(
          `/student/chapter/exercise/info/${rowToExpand?.studentId}?class=${classid}&chapter=${currentChapter}`,
        ),
      ).then(({data}) => {
        console.log(data)
        setStudentInfoDetails(data)
      })
  }, [classid, currentChapter, getStudentDetails, rowToExpand?.studentId])

  const onChapterChange = (e, {value}) => {
    setCurrentChapter(value)
    history.push(`${location.pathname}?currentChapterid=${value}`)
  }

  return (
    <div className="">
      <Segment className="border-none shadow-none bg-transparent flex flex-col">
        {errorGettingChapters ? (
          <Message error list={formatResponseError(errorGettingChapters)} />
        ) : null}
        {errorGettingStudentInfo ? (
          <Message error list={formatResponseError(errorGettingStudentInfo)} />
        ) : null}
        {errorGettingStudentDetails ? (
          <Message
            error
            list={formatResponseError(errorGettingStudentDetails)}
          />
        ) : null}
        <Dimmer active={gettingChapters || gettingStudentInfo} inverted>
          <Loader active={true} />
        </Dimmer>
        <Dropdown
          placeholder="Select a chapter"
          search
          selection
          value={currentChapter}
          loading={gettingChapters}
          options={chapters}
          onChange={onChapterChange}
          className=" drop-shadow-md shadow-md  ml-auto"
        />
        <div className="mt-4">
          <Table className="shadow-lg my-5">
            <Table.Header>
              <Table.Row className="text-sm">
                <Table.HeaderCell textAlign="center" className="bg-tableHeader">
                  #
                </Table.HeaderCell>
                {/* <Table.HeaderCell className="bg-tableHeader">
                Rank
              </Table.HeaderCell> */}
                <Table.HeaderCell className="bg-tableHeader">
                  {langContent.Students_name}
                </Table.HeaderCell>
                {/* <Table.HeaderCell className="bg-tableHeader">
                  Total points
                </Table.HeaderCell> */}
                <Table.HeaderCell className="bg-tableHeader">
                  {langContent.Average_PEX_score}
                </Table.HeaderCell>
                <Table.HeaderCell className="bg-tableHeader">
                  {langContent.Average_WEX_score}
                </Table.HeaderCell>
                <Table.HeaderCell className="bg-tableHeader">
                  {langContent.Average_test_score}
                </Table.HeaderCell>
                <Table.HeaderCell className="bg-tableHeader">
                  {langContent.Action}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {studentsInfo?.map((a, idx) => (
                <Fragment key={idx}>
                  <Table.Row textAlign="left" verticalAlign="top">
                    <Table.Cell
                      textAlign="center"
                      selectable
                      onClick={() =>
                        rowToExpand?.studentId === a?.studentId
                          ? setRowToExpand(null)
                          : setRowToExpand(a)
                      }
                    >
                      {rowToExpand?.studentId === a.studentId ? (
                        <AiOutlineCaretDown
                          className="mx-auto mt-4"
                          size="16"
                        />
                      ) : (
                        <AiOutlineCaretRight
                          className="mx-auto mt-4"
                          size="16"
                        />
                      )}
                    </Table.Cell>
                    {/* <Table.Cell>{truncate(a?.name, 15)}</Table.Cell> */}
                    <Table.Cell>{truncate(a?.name, 15)}</Table.Cell>
                    {/* <Table.Cell>{a?.totalScore}</Table.Cell> */}
                    <Table.Cell>{parseFloat(a?.pexsAverage).toFixed(0)} %</Table.Cell>
                    <Table.Cell>{parseFloat(a?.wexsAverage).toFixed(0)} %</Table.Cell>
                    <Table.Cell>{parseFloat(a?.testsAverage).toFixed(0)} %</Table.Cell>

                    <Table.Cell className="flex items-center">
                      <Button size="small" className=" bg-transparent p-0 mr-3">
                        <AiOutlineEye
                          size="16"
                          onClick={() =>
                            history.push(
                              routes.instructor.studetInfo(
                                classid,
                                a?.studentId,
                                currentChapter,
                              ),
                            )
                          }
                        />
                      </Button>
                      <Button size="small" className=" bg-transparent p-0 ">
                        <AiOutlineMinusCircle size="14" />
                      </Button>
                    </Table.Cell>
                  </Table.Row>

                  {rowToExpand?.studentId === a.studentId ? (
                    <Table.Row
                      key={a.studentId}
                      className="bg-transparent shadow-none border-none"
                    >
                      <Table.Cell
                        colSpan={16}
                        selectable={false}
                        className="bg-gray-50"
                      >
                        {gettingStudentDetails ? (
                          <div class="py-4 flex items-center justify-center">
                            <Loader inline active={true} />
                          </div>
                        ) : (
                          <div className="flex">
                            <Table.Cell
                              selectable={false}
                              className="border solid border-gray-300 bg-primaryLight rounded-md mr-2 flex-1 shadow-none"
                            >
                              <div>
                                <p className="text-center text-primary font-semibold">
                                  {langContent.PEXs_completed}
                                </p>

                                <Table
                                  celled
                                  basic="very"
                                  collapsing
                                  size="small"
                                >
                                  <Table.Header>
                                    <Table.Row>
                                      <Table.HeaderCell width={2}>
                                        {langContent.Name}
                                      </Table.HeaderCell>
                                      <Table.HeaderCell width={2}>
                                        {langContent.Score}
                                      </Table.HeaderCell>
                                      <Table.HeaderCell width={2}>
                                        {langContent.Duration}
                                      </Table.HeaderCell>
                                      <Table.HeaderCell width={2}>
                                        {langContent.Hints}
                                      </Table.HeaderCell>
                                    </Table.Row>
                                  </Table.Header>
                                  <Table.Body>
                                    {studentInfoDetails?.pexs?.map(c => (
                                      <Table.Row>
                                        <Table.Cell>{c?.pex}</Table.Cell>
                                        <Table.Cell>
                                          {c?.message ? (
                                            <p>{c?.message}</p>
                                          ) : (
                                            <p>{c?.score?.toFixed(0)}%</p>
                                          )}
                                        </Table.Cell>

                                        <Table.Cell>
                                          {!c?.message && (
                                            <p>
                                              {parseInt(c?.duration?.hours)} :{' '}
                                              {parseInt(c?.duration?.minutes)} :{' '}
                                              {parseInt(c?.duration?.seconds)}
                                            </p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>{c?.hint}</Table.Cell>
                                      </Table.Row>
                                    ))}
                                  </Table.Body>
                                </Table>
                              </div>
                            </Table.Cell>

                            {/* <div class="border-l-2 m-2 h-40 border-gray-300 p-0 w-5 place-self-center "/> */}

                            <Table.Cell
                              selectable={false}
                              className="border solid border-gray-300 rounded-md bg-primaryLight flex-1 shadow-none"
                            >
                              <p className="text-center text-primary font-semibold">
                                {langContent.WEX_completed}
                              </p>
                              <Table celled basic="very" size="small">
                                <Table.Header>
                                  <Table.Row>
                                    <Table.HeaderCell width={2}>
                                      {langContent.Name}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                      {langContent.Level}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                      {langContent.Score}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                      {langContent.Duration}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                      {langContent.Hints}
                                    </Table.HeaderCell>
                                  </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                  {studentInfoDetails?.wexs?.beginner?.map(
                                    c => (
                                      <Table.Row>
                                        <Table.Cell>{c?.wex}</Table.Cell>
                                        <Table.Cell>beginner</Table.Cell>
                                        <Table.Cell>
                                          {c?.message ? (
                                            <p>{c?.message}</p>
                                          ) : (
                                            <p>{c?.score?.toFixed(0)}%</p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {!c?.message && (
                                            <p>
                                              {parseInt(c?.duration?.hours)} :{' '}
                                              {parseInt(c?.duration?.minutes)} :{' '}
                                              {parseInt(c?.duration?.seconds)}
                                            </p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>{c?.hint}</Table.Cell>
                                      </Table.Row>
                                    ),
                                  )}
                                  {studentInfoDetails?.wexs?.intermidate?.map(
                                    c => (
                                      <Table.Row>
                                        <Table.Cell>{c?.wex}</Table.Cell>
                                        <Table.Cell>intermediate</Table.Cell>
                                        <Table.Cell>
                                          {c?.message ? (
                                            <p>{c?.message}</p>
                                          ) : (
                                            <p>{c?.score?.toFixed(0)}%</p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {!c?.message && (
                                            <p>
                                              {parseInt(c?.duration?.hours)} :{' '}
                                              {parseInt(c?.duration?.minutes)} :{' '}
                                              {parseInt(c?.duration?.seconds)}
                                            </p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>{c?.hint}</Table.Cell>
                                      </Table.Row>
                                    ),
                                  )}
                                  {studentInfoDetails?.wexs?.advanced?.map(
                                    c => (
                                      <Table.Row>
                                        <Table.Cell>{c?.wex}</Table.Cell>
                                        <Table.Cell>advanced</Table.Cell>
                                        <Table.Cell>
                                          {c?.message ? (
                                            <p>{c?.message}</p>
                                          ) : (
                                            <p>{c?.score?.toFixed(0)}%</p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {!c?.message && (
                                            <p>
                                              {parseInt(c?.duration?.hours)} :{' '}
                                              {parseInt(c?.duration?.minutes)} :{' '}
                                              {parseInt(c?.duration?.seconds)}
                                            </p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>{c?.hint}</Table.Cell>
                                      </Table.Row>
                                    ),
                                  )}
                                </Table.Body>
                              </Table>
                            </Table.Cell>

                            <Table.Cell
                              selectable={false}
                              className="border solid ml-2 border-gray-300 bg-primaryLight  rounded-md flex-1 shadow-none"
                            >
                              <p className="text-center text-primary font-semibold">
                                {langContent.Tests_completed}
                              </p>
                              <Table celled basic="very" size="small">
                                <Table.Header>
                                  <Table.Row>
                                    <Table.HeaderCell width={2}>
                                      {langContent.Name}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                      {langContent.Level}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                      {langContent.Score}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                      {langContent.Duration}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                      {langContent.Hints}
                                    </Table.HeaderCell>
                                  </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                  {studentInfoDetails?.tests?.beginner?.map(
                                    c => (
                                      <Table.Row>
                                        <Table.Cell>{c?.test}</Table.Cell>
                                        <Table.Cell>beginner</Table.Cell>
                                        <Table.Cell>
                                          {c?.message ? (
                                            <p>{c?.message}</p>
                                          ) : (
                                            <p>{c?.score?.toFixed(0)}%</p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {!c?.message && (
                                            <p>
                                              {parseInt(c?.duration?.hours)} :{' '}
                                              {parseInt(c?.duration?.minutes)} :{' '}
                                              {parseInt(c?.duration?.seconds)}
                                            </p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>{c?.hint}</Table.Cell>
                                      </Table.Row>
                                    ),
                                  )}
                                  {studentInfoDetails?.tests?.intermidate?.map(
                                    c => (
                                      <Table.Row>
                                        <Table.Cell>{c?.test}</Table.Cell>
                                        <Table.Cell>intermediate</Table.Cell>
                                        <Table.Cell>
                                          {c?.message ? (
                                            <p>{c?.message}</p>
                                          ) : (
                                            <p>{c?.score?.toFixed(0)}%</p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {!c?.message && (
                                            <p>
                                              {parseInt(c?.duration?.hours)} :{' '}
                                              {parseInt(c?.duration?.minutes)} :{' '}
                                              {parseInt(c?.duration?.seconds)}
                                            </p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>{c?.hint}</Table.Cell>
                                      </Table.Row>
                                    ),
                                  )}
                                  {studentInfoDetails?.tests?.advanced?.map(
                                    c => (
                                      <Table.Row>
                                        <Table.Cell>{c?.test}</Table.Cell>
                                        <Table.Cell>advanced</Table.Cell>
                                        <Table.Cell>
                                          {c?.message ? (
                                            <p>{c?.message}</p>
                                          ) : (
                                            <p>{c?.score?.toFixed(0)}%</p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {!c?.message && (
                                            <p>
                                              {parseInt(c?.duration?.hours)} :{' '}
                                              {parseInt(c?.duration?.minutes)} :{' '}
                                              {parseInt(c?.duration?.seconds)}
                                            </p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>{c?.hint}</Table.Cell>
                                      </Table.Row>
                                    ),
                                  )}
                                </Table.Body>
                              </Table>
                            </Table.Cell>
                          </div>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                </Fragment>
              ))}
            </Table.Body>
          </Table>
        </div>
      </Segment>
    </div>
  )
}

export default ClassStudentinfo
