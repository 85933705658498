import React from 'react'
import useLocalStorage from '../hooks/use-local-storage'
import { USER_STORAGE_KEY } from '../constants'

const UserContext = React.createContext()
UserContext.displayName = 'UserContext'

function UserProvider({ children }) {
  const [user, setLocalStorageUser] = useLocalStorage(USER_STORAGE_KEY)

  function setUser(data) {
    setLocalStorageUser(JSON.stringify(data))
    return
  }

  function getUser() {
    if (user) {
      return JSON.parse(user) || null
    }
    else return null
  }

  return (
    <UserContext.Provider
      value={{
        setUser,
        getUser
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

function useUser() {
  const context = React.useContext(UserContext)

  if (!context) {
    throw new Error('useUser must be used within a UserProvider')
  }

  return context
}

export { useUser, UserProvider }
