import React, {useContext, useState} from 'react'
import {
  Button,
  Dimmer,
  Dropdown,
  Input,
  Loader,
  Message,
  Pagination,
  Segment,
} from 'semantic-ui-react'
import {useLocation, useHistory, Link} from 'react-router-dom'

import {authAxios} from '../../config/axiosConfig'
import useAsync from '../../hooks/useAsync'
import routes from '../../routes'
import queryString from 'query-string'
import {formatResponseError} from '../../utils/format-response'
import useSubject from '../../hooks/use-subjects'
import {truncate} from '../../utils/string'
import AvatarDropdown from '../../components/shared/avatar-dropdown'
import StateContext from '../../context/stateContext'
import CreateClassModal from '../../components/teacher/class/create-class-modal'
import ClassCard from '../../components/shared/class-card'
import { useLanguage } from '../../context/languageContext'
import { content } from '../../localization/content'

const TeacherHomePage = () => {
  const [classData, setClassData] = React.useState([])
  const [searchKeyword, setSearchKeyword] = useState('')
  const {subjects, errorGettingSubjects, gettingSubjects} = useSubject()
  const [truncateSubject, setTruncateSubject] = React.useState([])

  const [forceReload, setForceReload] = React.useState(false)

  const [currentSubject, setCurrentSubject] = useState()

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [limit, setLimit] = useState(10)
  const {setShowModal} = useContext(StateContext)

  const location = useLocation()
  const history = useHistory()

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  const [state] = React.useState(location?.state)

  const {
    run: getClasses,
    error: errorGettingClasses,
    isLoading: gettingclasses,
  } = useAsync()

  React.useEffect(() => {
    const {
      page = 1,
      limit = 10,
      search = '',
      currentSubjectid,
    } = queryString.parse(location.search)

    setPage(page)
    setLimit(limit)
    setSearchKeyword(search)
    setCurrentSubject(currentSubjectid)

    if (currentSubject) {
      getClasses(
        authAxios.get(
          `/class/all/instructor?sid=${currentSubject}&page=${page}&limit=${limit}&search=${search}`,
        ),
      ).then(({data}) => {
        console.log(data)
        setClassData(data.docs)
        setTotalPages(data.totalPages)
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, currentSubject, forceReload])

  const handleOnPageChange = (e, {activePage}) => {
    history.push(
      `${location.pathname}?page=${activePage}&limit=${limit}&search=${searchKeyword}&currentSubjectid=${currentSubject}`,
    )
  }

  const onSearchClick = () => {
    history.push(
      `${
        location.pathname
      }?page=${1}&limit=${limit}&search=${searchKeyword}&currentSubjectid=${currentSubject}`,
    )
  }

  React.useEffect(() => {
    // for truncate text on dropdown
    if (subjects.length > 0) {
      let arr = []
      subjects?.forEach(s =>
        arr.push({
          text: truncate(s?.text, 20),
          key: s?.key,
          value: s?.value,
        }),
      )
      setTruncateSubject(arr)
    }

    if (subjects.length && !currentSubject) {
      if (state?.currentSubject) {
        setCurrentSubject(state?.currentSubject)
        history.push(
          `${
            location.pathname
          }?page=${1}&limit=${limit}&search=${searchKeyword}&currentSubjectid=${
            state?.currentSubject
          }`,
        )
      } else {
        setCurrentSubject(subjects[0]?.value)
        history.push(
          `${
            location.pathname
          }?page=${1}&limit=${limit}&search=${searchKeyword}&currentSubjectid=${
            subjects[0]?.value
          }`,
        )
      }
    }
    // else {
    //   setCurrentSubject(currentSubject)
    // }
  }, [
    currentSubject,
    history,
    limit,
    location.pathname,
    searchKeyword,
    state,
    subjects,
  ])

  const onSubjectChange = (e, {value}) => {
    setCurrentSubject(value)
    history.push(
      `${
        location.pathname
      }?page=${1}&limit=${limit}&search=${searchKeyword}&currentSubjectid=${value}`,
    )
  }

  return (
    <div className="relative px-2 md:px-10">
      {/* <AvatarDropdown /> */}

      <Segment className="border-none shadow-none bg-transparent min-h-screen relative">
        {errorGettingSubjects ? (
          <Message error list={formatResponseError(errorGettingSubjects)} />
        ) : null}
        {errorGettingClasses ? (
          <Message error list={formatResponseError(errorGettingClasses)} />
        ) : null}
        <Dimmer active={gettingSubjects || gettingclasses} inverted>
          <Loader active={true} />
        </Dimmer>
        <div className="flex flex-wrap-reverse justify-center sm:justify-between align-center items-center w-full ">
          {/* <h1 className="text-3xl">PDFs</h1> */}
          <Input
            type="text"
            placeholder={`${langContent.Search}...`}
            size="small"
            className="mr-4 shadow-sm "
            action
            // as={Search}
            value={searchKeyword}
            onChange={(e, data) => {
              setSearchKeyword(data.value)
            }}
          >
            <input />

            <Button type="button" onClick={onSearchClick}>
              {langContent.Search}
            </Button>
          </Input>
          <AvatarDropdown />
        </div>
        <div className="flex justify-end mt-5 ml-auto ">
          <Dropdown
            placeholder="Select a subject"
            search
            selection
            value={currentSubject}
            loading={gettingSubjects}
            options={truncateSubject}
            onChange={onSubjectChange}
            className="mx-2 drop-shadow-md shadow-md  "
          />
          <Button
            size="small"
            primary
            onClick={() => setShowModal({modalName: 'createClass', data: null})}
          >
           {langContent.Create_new_class}
          </Button>
        </div>
        {/* <Breadcrumb className="mt-4">
        <Breadcrumb.Section link>Home</Breadcrumb.Section>
        <Breadcrumb.Divider>/</Breadcrumb.Divider>
        <Breadcrumb.Section active>PDFs</Breadcrumb.Section>
      </Breadcrumb> */}

        <div className="mt-12 flex flex-wrap gap-x-4 gap-y-4  items-center justify-start ">
          {/* 
        <Link to={routes.instructor.class()}>
        <h3>Class view when clicked on class card </h3>
        </Link> */}

          {classData?.map((a, idx) => (
            <ClassCard
              key={a?._id}
              classname={a?.name}
              school={a?.school?.name}
              voucherCode={a?.voucher}
              id={a?._id}
              subjectId={a?.subject?._id}
              numberOfStudents={a?.numberOfStudents}

              // reload={() => setForceReload(p => !p)}
            />
          ))}
        </div>

        <div className="absolute -bottom-20 right-0">
          <Pagination
            className="shadow-md my-10 "
            activePage={page}
            onPageChange={handleOnPageChange}
            totalPages={totalPages}
          />
        </div>
        <CreateClassModal reload={() => setForceReload(p => !p)} />
      </Segment>
    </div>
  )
}

export default TeacherHomePage
