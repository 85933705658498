import React, {useState} from 'react'
import { FiArrowLeft } from 'react-icons/fi'
import {IoIosLock, IoIosUnlock} from 'react-icons/io'
import {useHistory, useParams} from 'react-router-dom'
import {
  Button,
  Confirm,
  Dimmer,
  Icon,
  Loader,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react'
import { authAxios } from '../../../../config/axiosConfig'
import useLocalStorage from '../../../../hooks/use-local-storage'
import useAsync from '../../../../hooks/useAsync'
import routes from '../../../../routes'
import { formatResponseError } from '../../../../utils/format-response'


const NonVoucherTestLock = () => {
  const history = useHistory()
  const [confirmPex, setConfirmPex] = useState(false)
  const {subjectid, chapterid, testid} = useParams()

  const [exerciseData, setExerciseData] = React.useState({})

  const [exerciseEndTimeLS, setExerciseEndTimeLS] = useLocalStorage('exerciseEndTime', '')

  const {
    run: initExercise,
    error: errorCreatingExercise,
    isLoading: gettingExerciseInit,
  } = useAsync()

  React.useEffect(() => {
    let obj = {
      exercise: testid,
      chapter: chapterid,
      subjectId: subjectid,
    }
    initExercise(authAxios.post(`/correct/initExercise`, obj)).then(
      ({data}) => {
        console.log({exerciseData: data})
        setExerciseEndTimeLS(
          data?.duration?.hour * 60 * 60 + data?.duration?.miniute * 60,
        )
        setExerciseData(data)
      },
    )
  }, [chapterid, initExercise, testid, subjectid, setExerciseEndTimeLS])

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent">
        {errorCreatingExercise ? (
          <Message error list={formatResponseError(errorCreatingExercise)} />
        ) : null}

        <Dimmer active={gettingExerciseInit} inverted>
          <Loader active={true} />
        </Dimmer>

        {/* <div className=" h-screen flex justify-center items-center py-6 md:py-20">
        <div className=" flex flex-col items-center">

          <IoIosLock className="text-9xl my-5 text-gray-600 " />

          <p className="text-xl">This PEX is locked!</p>

          <Button
            primary
            className="rounded-md px-16 mt-4"
            onClick={() =>
              history.push(
                routes.voucherStudent.chapters.content(
                  classid,
                  subjectid,
                  chapterid,
                ),
              )
            }
          >
            {' '}
            Back to chapter{' '}
          </Button>
        </div>
      </div> */}

        <div className="px-8 md:px-20">
          <div className="flex items-center ">
            {/* <IoIosUnlock className="text-xl my-5 text-gray-600 " /> */}

            <p className="text-xl">Test Content :</p>
          </div>

          <div className="mt-12">
            {/* <Table celled striped className="shadow-lg" size="small">
              <Table.Header>
                <Table.Row className="text-sm">
                  <Table.HeaderCell className="bg-tableHeader">
                    Type
                  </Table.HeaderCell>
                  <Table.HeaderCell className="bg-tableHeader">
                    Competency
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {exerciseData?.questions?.map((a, idx) => (
                  <Table.Row textAlign="left" verticalAlign="top">
                    <Table.Cell>{a.questionType}</Table.Cell>
                    <Table.Cell>{a.competency}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table> */}
            <div className="py-8 pl-14 pr-8 border-2 solid rounded-md">
              <div className="flex justify-between ">
                <div className="flex items-center">
                  <FiArrowLeft
                    onClick={() => {
                      history.push(
                        routes.nonVoucherStudent.chapters.content(
                          subjectid,
                          chapterid,
                        ),
                      )
                    }}
                    className="text-primary bold -ml-8 text-2xl mr-2 cursor-pointer"
                  />
                  <h3 className="text-primary">{exerciseData?.name}</h3>
                </div>
                {exerciseData?.questions?.length > 0 && (
                  <Button
                    primary
                    className="rounded-md px-12"
                    onClick={() => setConfirmPex(true)}
                  >
                    Start Test
                  </Button>
                )}
              </div>

              <div className="mt-3 mb-8 ">
                {/* <p className="break-all">{exerciseData?.instructions}</p> */}
              </div>

              {exerciseData?.questions?.length > 0 &&
                exerciseData?.questions?.map((s, idx) => (
                  <>
                    <div className="flex items-start">
                      <Icon
                        name="circle"
                        className="text-primary -mr-2 -mt-1.5"
                      />
                      <div
                        className={`min-w-full ${
                          exerciseData?.questions?.length - 1 !== idx
                            ? 'border-l border-gray-300 '
                            : ''
                        }  pl-6`}
                      >
                        <p className="w-full text-primary font-medium text-lg -mt-2  flex">
                          {'Question'} {idx + 1}
                        </p>
                        <p className="flex flex-col">
                          <p className="py-2 flex items-center">
                            <p className="text-black font-medium"> Type : </p>{' '}
                            <p className="text-gray-500 mx-1">
                              {' '}
                              {s?.questionType}
                            </p>{' '}
                          </p>
                          <p className="pb-6 flex items-center">
                            <p className="text-black font-medium">
                              {' '}
                              Competency :{' '}
                            </p>{' '}
                            <p className="text-gray-500 mx-1">
                              {' '}
                              {s?.competency}
                            </p>{' '}
                          </p>
                          {/* <p className="pb-6">Competency: {s?.competency}</p> */}
                        </p>
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </div>

         
          <Confirm
            size="mini"
            content="Are you sure you want to start ?"
            open={confirmPex}
            onCancel={() => setConfirmPex(false)}
            onConfirm={() =>
              history.push(
                routes.nonVoucherStudent.chapters.exercise.test(
                  subjectid,
                  chapterid,
                  testid,
                  exerciseData?.exerciseAttempt,
                ),
              )
            }
          />
        </div>
      </Segment>
    </div>
  )
}

export default NonVoucherTestLock
