import React, {useState} from 'react'
import TeacherStudentInfoTabs from '../../../components/teacher/student/teacher-student-info-tabs'
import TeacherStudentBasicInfo from './teacher-student-basic-info'
import TeacherStudentProgress from './teacher-student-progress'

const TeacherStudentInfo = () => {
  const [activeMenu, setActiveMenu] = useState('basicInfo')

  return (
    <div>
      <div className="">
        <TeacherStudentInfoTabs
          activeMenu={activeMenu}
          setActiveMenu={value => setActiveMenu(value)}
        />
      </div>

      <div className="mt-2 ">
        {activeMenu === 'basicInfo' && <TeacherStudentBasicInfo />}
        {activeMenu === 'studentProgress' && <TeacherStudentProgress />}
      </div>
    </div>
  )
}

export default TeacherStudentInfo
