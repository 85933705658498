import {Formik} from 'formik'
import React from 'react'
import {useHistory, useParams} from 'react-router'
import {Button, Form, Image, Message} from 'semantic-ui-react'
import * as Yup from 'yup'
import FormikInput from '../../components/formik/FormikInput'
import DefaultContainer from '../../components/shared/default-container'
import {authAxios} from '../../config/axiosConfig'
import useAsync from '../../hooks/useAsync'
import routes from '../../routes'
import {formatResponseError} from '../../utils/format-response'

const AddVoucher = () => {
  const {
    run: submitVoucher,
    isLoading: submitingVoucher,
    error: errorAddingVoucher,
  } = useAsync()

  const history = useHistory()

  const newvoucherSchema = Yup.object({
    voucher: Yup.string().trim().required('Required'),
  })

  const onSubmit = values => {
    submitVoucher(authAxios.post('/student/class/voucher', values)).then(() =>
      history.push(routes.voucherStudent.dashboard),
    )
  }

  return (
    <div className='max-w-sm'>
      <h3 className="mt-4 mb-8">Vouchers</h3>

      {errorAddingVoucher ? (
        <Message error list={formatResponseError(errorAddingVoucher)} />
      ) : null}

      <Formik
        initialValues={{
          voucher: '',
        }}
        validationSchema={newvoucherSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {formik => (
          <Form loading={submitingVoucher} onSubmit={formik.handleSubmit}>
            <FormikInput
              label="Enter voucher code to access your class"
              placeholder="enter voucher code"
              name="voucher"
            />

            <div class="flex items-center justify-between mt-8">
              <Button primary type="submit">
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default AddVoucher
