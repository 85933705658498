import React, {useState} from 'react'
import {Link, useHistory, useLocation, useParams} from 'react-router-dom'
import {
  Dimmer,
  Dropdown,
  Loader,
  Message,
  Progress,
  Segment,
  Table,
} from 'semantic-ui-react'
import queryString from 'query-string'
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar'
import {AiOutlineCaretDown, AiOutlineCaretRight} from 'react-icons/ai'
import {easeQuadInOut} from 'd3-ease'

import routes from '../../../routes'
import {truncate} from '../../../utils/string'
import useAsync from '../../../hooks/useAsync'
import {authAxios} from '../../../config/axiosConfig'
import {formatResponseError} from '../../../utils/format-response'
import AnimatedProgressProvider from '../../../components/shared/AnimatedProgressProvider'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'

const StudentChapters = () => {
  const [rowToExpand, setRowToExpand] = React.useState(null)
  const [classes, setClasses] = React.useState([])
  const [chapterProgressData, setChapterProgressData] = React.useState([])

  const [currentClass, setCurrentClass] = React.useState()

  const location = useLocation()
  const [state] = React.useState(location?.state)

  const history = useHistory()
  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  const {
    run: getClasses,
    error: errorGettingClasses,
    isLoading: gettingClasses,
  } = useAsync()

  const {
    run: getExerciseInfo,
    error: errorGettingExerciseInfo,
    isLoading: gettingExerciseInfo,
  } = useAsync()

  React.useEffect(() => {
    getClasses(authAxios.get(`/student/class/all`)).then(({data}) => {
      console.log(data)
      let arr = []
      data?.forEach(s =>
        arr.push({
          text: truncate(s?.name, 20),
          key: s?._id,
          value: s?._id,
        }),
      )
      setClasses(arr)
    })
  }, [getClasses])

  React.useEffect(() => {
    const {currentClassid} = queryString.parse(location.search)

    setCurrentClass(currentClassid)
    if (currentClass && currentClassid !== 'undefined') {
      getExerciseInfo(
        authAxios.get(`/student/chapter/progress/all?class=${currentClass}`),
      ).then(({data}) => {
        console.log(data)
        setChapterProgressData(data)
      })
    }

  }, [getExerciseInfo, currentClass, location.search])

  React.useEffect(() => {
    const {currentClassid} = queryString.parse(location.search)

    if (!currentClassid && classes.length > 0) {
      setCurrentClass(classes[0].value)
      history.replace(`${location.pathname}?currentClassid=${classes[0].value}`)
    }
  }, [classes, history, location.pathname, location.search])

  const onCLassChange = (e, {value}) => {
    setCurrentClass(value)
    history.replace(`${location.pathname}?currentClassid=${value}`)
  }

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent">
        {errorGettingClasses ? (
          <Message error list={formatResponseError(errorGettingClasses)} />
        ) : null}
        {errorGettingExerciseInfo ? (
          <Message error list={formatResponseError(errorGettingExerciseInfo)} />
        ) : null}
        <Dimmer active={gettingClasses || gettingExerciseInfo} inverted>
          <Loader active={true} />
        </Dimmer>
        <div className="flex">
          <Dropdown
            placeholder="Select a class"
            search
            selection
            value={currentClass}
            loading={gettingClasses}
            options={classes}
            onChange={onCLassChange}
            className=" drop-shadow-md shadow-md  ml-auto"
          />
        </div>

        <Table className="shadow-lg my-5">
          <Table.Header>
            <Table.Row className="text-sm">
              <Table.HeaderCell
                textAlign="center"
                className="bg-tableHeader"
              ></Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader">
                {langContent.Chapters_name}
              </Table.HeaderCell>

              <Table.HeaderCell className="bg-tableHeader">
                {langContent.Progress}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {chapterProgressData?.map((a, idx) => (
              <>
                <Table.Row textAlign="left" verticalAlign="top">
                  <Table.Cell
                    width={1}
                    className="mr-0"
                    textAlign="center"
                    selectable
                    onClick={() =>
                      rowToExpand?.chapterId === a?.chapterId
                        ? setRowToExpand(null)
                        : setRowToExpand(a)
                    }
                  >
                    {rowToExpand?.chapterId === a.chapterId ? (
                      <AiOutlineCaretDown className="mx-auto mt-4" size="16" />
                    ) : (
                      <AiOutlineCaretRight className="mx-auto mt-4" size="16" />
                    )}
                  </Table.Cell>
                  <Table.Cell width={1}>{truncate(a?.chapter, 80)}</Table.Cell>
                  <Table.Cell width={5}>
                    <div className="flex items-start  h-8">
                      <Progress
                        className="w-96 mt-0"
                        size="small"
                        percent={parseFloat(a?.progress).toFixed(0)}
                        // {a?.progress >= 80 ?(success):(error)
                        // }
                        success
                      ></Progress>
                      <p className="ml-4 -mt-1">{parseFloat(a?.progress).toFixed(0)}%</p>
                    </div>
                  </Table.Cell>
                </Table.Row>

                {rowToExpand?.chapterId === a.chapterId ? (
                  <Table.Row key={a.chapterId}>
                    <Table.Cell colSpan={16} selectable={false}>
                      <div className="flex">
                        <div className="flex-1 max-w-xl flex flex-wrap">
                          {rowToExpand?.skills?.map((s, i) => (
                            <div className="w-36  m-2 flex  flex-col items-center">
                              <div>
                                <AnimatedProgressProvider
                                  valueEnd={Number.parseFloat(`${parseFloat(s?.progress).toFixed(0)}`)}
                                ></AnimatedProgressProvider>
                              </div>
                              <div className="h-9">
                                <p className="-mt-4 text-center text-sm text-gray-500">
                                  {truncate(s?.skill, 30)}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className="flex-1">
                          {rowToExpand?.feedbacks?.length > 0 ? (
                            <h3 className="text-primary mb-2">
                              Frequent errors
                            </h3>
                          ) : null}
                          <ul className="list-disc list-inside">
                            {rowToExpand?.feedbacks?.map((s, i) => (
                              <li>{s?.feedbackMessage}</li>
                            ))}
                            {/* <li>Frequent error 1</li>
                            <li>Frequent error 2</li>
                            <li>Frequent error 3</li> */}
                          </ul>
                        </div>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ) : null}
              </>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    </div>
  )
}

export default StudentChapters
