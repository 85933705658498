import React from 'react'
import {Modal} from 'semantic-ui-react'
import * as DOMPurify from 'dompurify'
import {content} from '../../localization/content'
import {useLanguage} from '../../context/languageContext'

const GeneralConditions = ({open, onClose}) => {
  const [lang] = useLanguage()
  const langContent = content[lang]

  const cleanTitle = DOMPurify.sanitize(
    langContent.generalConditionsDetails.title,
  )
  const cleanBody = DOMPurify.sanitize(
    langContent.generalConditionsDetails.body,
  )

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      closeIcon
      size="large"
      className="animate__animated animate__fadeInDown"
    >
      <Modal.Header className="text-center bg-indigo-50 rounded-t-lg">
        <div dangerouslySetInnerHTML={{__html: cleanTitle}} />
      </Modal.Header>

      <Modal.Content className="flex justify-center items-center bg-blue-50">
        <div dangerouslySetInnerHTML={{__html: cleanBody}} />
      </Modal.Content>
    </Modal>
  )
}

export default GeneralConditions
