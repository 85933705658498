// import React from "react";
// import { Animate } from "react-move";

// class AnimatedProgressProvider extends React.Component {
//   interval = undefined;

//   state = {
//     isAnimated: false
//   };

//   static defaultProps = {
//     valueStart: 0
//   };

//   componentDidMount() {
//     if (this.props.repeat) {
//       this.interval = window.setInterval(() => {
//         this.setState({
//           isAnimated: !this.state.isAnimated
//         });
//       }, this.props.duration * 1000);
//     } else {
//       this.setState({
//         isAnimated: !this.state.isAnimated
//       });
//     }
//   }

//   componentWillUnmount() {
//     window.clearInterval(this.interval);
//   }

//   render() {
//     return (
//       <Animate
//         start={() => ({
//           value: this.props.valueStart
//         })}
//         update={() => ({
//           value: [
//             this.state.isAnimated ? this.props.valueEnd : this.props.valueStart
//           ],
//           timing: {
//             duration: this.props.duration * 1000,
//             ease: this.props.easingFunction
//           }
//         })}
//       >
//         {({ value }) => this.props.children(value)}
//       </Animate>
//     );
//   }
// }
//  {/* <AnimatedProgressProvider
//                                   valueStart={0}
//                                   valueEnd={s?.progress}
//                                   duration={1}
//                                   easingFunction={easeQuadInOut}
//                                   // repeat
//                                 >
//                                   {value => {
//                                     const roundedValue = Math.round(value)
//                                     return (
//                                       <CircularProgressbar
//                                         value={value}
//                                         text={`${roundedValue}%`}
//                                         styles={buildStyles({
//                                           pathTransition: 'none',

//                                           // Colors
//                                           pathColor: `${
//                                             s?.progress >= 80
//                                               ? 'rgba(29, 177, 103)'
//                                               : '#f88'
//                                           }`,
//                                           textColor: `${
//                                             s?.progress >= 80
//                                               ? 'rgba(29, 177, 103)'
//                                               : '#f88'
//                                           }`,
//                                           trailColor: '#d6d6d6',
//                                           backgroundColor: '#3e98c7',
//                                         })}
//                                       />
//                                     )
//                                   }}
//                                 </AnimatedProgressProvider> */}

// export default AnimatedProgressProvider;

import React from 'react'
import ReactApexChart from 'react-apexcharts'
import {Animate} from 'react-move'

class AnimatedProgressProvider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      series: [this.props.valueEnd],
      options: {
        chart: {
          height: 350,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              showOn: 'always',
              name: {
                offsetY: -10,
                show: false,
                color: '#888',
                fontSize: '13px',
              },
              value: {
                color: '#888',
                fontSize: '16px',
                offsetY: 5,
                show: true,
              },
            },
            hollow: {
              size: '55%',
              track: {
                background: '#f2f2f2',
              },
            },
            track: {
              // background: '#f2f2f2'
            },
          },
        },
        fill: {
          type: 'solid',
          // colors: ['#F44336', '#E91E63', '#9C27B0']

          colors: this.props.valueEnd >= 80 ? 'rgba(29, 177, 103)' : '#F0340C',
        },
        labels: ['progress'],
      },
    }
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="radialBar"
          height={200}
        />
      </div>
    )
  }
}

export default AnimatedProgressProvider
