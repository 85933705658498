import React, {useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {
  Accordion,
  Button,
  Dimmer,
  Icon,
  Loader,
  Message,
  Segment,
  Step,
} from 'semantic-ui-react'
import {FaLock} from 'react-icons/fa'

import {authAxios} from '../../../config/axiosConfig'
import useAsync from '../../../hooks/useAsync'
import {formatResponseError} from '../../../utils/format-response'
import NonVoucherHomeWorkRoomRview from './nonVoucher-homework-room-review'

const NonVoucherHomework = () => {
  const [forceReload, setForceReload] = React.useState(false)
  const [isSubscriber, setIsSubscriber] = useState(false)
  const [activeIndex, setActiveIndex] = useState(-1)
  const history = useHistory()
  const {chapterid, subjectid} = useParams()

  const {
    run: getUserSubscribtion,
    error: errorGettingUserSubscribtion,
    isLoading: gettingUserSubscribtion,
  } = useAsync()

  React.useEffect(() => {
    getUserSubscribtion(
      authAxios.get(`/user/subscribe/valid/?subject=${subjectid}`),
    ).then(({data}) => {
      console.log(data)
      setIsSubscriber(data.isSubscriber)
    })
  }, [getUserSubscribtion, subjectid])

  const handleOnClickTitle = (e, itemProps) => {
    console.log(itemProps)
    setActiveIndex(itemProps.index)
  }

  const handleClickAccordion = (e, titleProps) => {
    const {index} = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent w-full">
        {errorGettingUserSubscribtion ? (
          <Message
            error
            list={formatResponseError(errorGettingUserSubscribtion)}
          />
        ) : null}
        <Dimmer active={gettingUserSubscribtion} inverted>
          <Loader active={true} />
        </Dimmer>
        {/* <Accordion
          defaultActiveIndex={0}
          activeIndex={activeIndex}
          onTitleClick={handleOnClickTitle}
          panels={rootPanels}
          styled
          className="w-full my-5 "

        /> */}

        <Accordion
          fluid
          styled
          className="rounded-t-md w-full shadow-sm mb-4 hover:shadow-md hover:opacity-95 p-3 border-2 border-solid  animate__animated animate__fadeInUp"
        >
          <div className="relative shadow-none bg-transparent">
            <div class="border-l-4 solid h-full border-primaryLightPurbel absolute rounded-lg top-0 -left-1"></div>

            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={handleClickAccordion}
              className="flex items-center shadow-none bg-transparent border-none"
            >
              <Icon name="dropdown" />
              <div className="flex justify-between items-center">
                <p className=" font-semibold">Basic Level</p>
              </div>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <div>
                <NonVoucherHomeWorkRoomRview
                  level="beginner"
                  onUpdate={forceReload}
                  onReload={() => setForceReload(p => !p)}
                />
              </div>
            </Accordion.Content>
          </div>
        </Accordion>

        {isSubscriber ? (
          <>
            <Accordion
              fluid
              styled
              className="rounded-t-md w-full shadow-sm mb-4 hover:shadow-md hover:opacity-95 p-3 border-2 border-solid relative animate__animated animate__fadeInUp"
            >
              <div className="relative shadow-none bg-transparent">
                <div class="border-l-4 solid h-full border-primaryYellow absolute rounded-lg top-0 -left-1"></div>
                <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={handleClickAccordion}
                  className="flex items-center shadow-none bg-transparent border-none"
                >
                  <Icon name="dropdown" />
                  <div className="flex justify-between items-center">
                    <p className=" font-semibold">Intermidate Level</p>
                  </div>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                  <div>
                    <NonVoucherHomeWorkRoomRview
                      level="intermidate"
                      onUpdate={forceReload}
                      onReload={() => setForceReload(p => !p)}
                    />
                  </div>
                </Accordion.Content>
              </div>
            </Accordion>

            <Accordion
              fluid
              styled
              className="rounded-t-md w-full shadow-sm mb-4 hover:shadow-md hover:opacity-95 p-3 border-2 border-solid relative animate__animated animate__fadeInUp"
            >
              <div className="relative shadow-none bg-transparent">
                <div class="border-l-4 solid h-full border-primaryRed absolute rounded-lg top-0 -left-1"></div>
                <Accordion.Title
                  active={activeIndex === 2}
                  index={2}
                  onClick={handleClickAccordion}
                  className="flex items-center shadow-none bg-transparent border-none"
                >
                  <Icon name="dropdown" />
                  <div className="flex justify-between items-center">
                    <p className=" font-semibold">Advanced Level</p>
                  </div>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                  <div>
                    <NonVoucherHomeWorkRoomRview
                      level="advanced"
                      onUpdate={forceReload}
                      onReload={() => setForceReload(p => !p)}
                    />
                  </div>
                </Accordion.Content>
              </div>
            </Accordion>
          </>
        ) : (
          <>
            <Accordion
              fluid
              styled
              className="rounded-t-md w-full shadow-sm mb-4 hover:shadow-md hover:opacity-95 p-3 border-2 border-solid relative animate__animated animate__fadeInUp"
            >
              <div className="relative shadow-none bg-transparent">
                <div class="border-l-4 solid h-full border-primaryYellow absolute rounded-lg top-0 -left-1"></div>
                <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={handleClickAccordion}
                  className="flex items-center shadow-none bg-transparent border-none"
                >
                  {/* <Icon name="dropdown" /> */}
                  <div className="flex justify-between items-center w-full">
                    <p className=" font-semibold">Intermidate Level</p>
                    <div className="flex items-center justify-center">
                      <FaLock className="mx-2 -mt-0.5" />
                      <p className="text-primary">
                        Subscribe to unlock this section
                      </p>
                    </div>
                  </div>
                </Accordion.Title>
                {/* <Accordion.Content active={activeIndex === 1}>
                  <div>
                    <NonVoucherHomeWorkRoomRview
                      level="intermidate"
                      onUpdate={forceReload}
                      onReload={() => setForceReload(p => !p)}
                    />
                  </div>
                </Accordion.Content> */}
              </div>
            </Accordion>

            <Accordion
              fluid
              styled
              className="rounded-t-md w-full shadow-sm mb-4 hover:shadow-md hover:opacity-95 p-3 border-2 border-solid relative animate__animated animate__fadeInUp"
            >
              <div className="relative shadow-none bg-transparent">
                <div class="border-l-4 solid h-full border-primaryRed absolute rounded-lg top-0 -left-1"></div>
                <Accordion.Title
                  active={activeIndex === 2}
                  index={2}
                  onClick={handleClickAccordion}
                  className="flex items-center shadow-none bg-transparent border-none"
                >
                  {/* <Icon name="dropdown" /> */}
                  <div className="flex justify-between items-center w-full">
                    <p className=" font-semibold">Advanced Level</p>
                    <div className="flex items-center justify-center">
                      <FaLock className="mx-2 -mt-0.5" />
                      <p className="text-primary">
                        Subscribe to unlock this section
                      </p>
                    </div>
                  </div>
                </Accordion.Title>
                {/* <Accordion.Content active={activeIndex === 2}>
                  <div>
                    <NonVoucherHomeWorkRoomRview
                      level="advanced"
                      onUpdate={forceReload}
                      onReload={() => setForceReload(p => !p)}
                    />
                  </div>
                </Accordion.Content> */}
              </div>
            </Accordion>
          </>
        )}
      </Segment>
    </div>
  )
}

export default NonVoucherHomework
