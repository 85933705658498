import React from 'react'
import {Link, useParams} from 'react-router-dom'
import {
  Dimmer,
  Icon,
  Image,
  Loader,
  Message,
  Segment,
  Step,
} from 'semantic-ui-react'
import {BsCheck} from 'react-icons/bs'

import {authAxios} from '../../../config/axiosConfig'
import useAsync from '../../../hooks/useAsync'
import {formatResponseError} from '../../../utils/format-response'
import checked from '../../../assets/images/checked.svg'
import redCircle from '../../../assets/images/redCircle.svg'
import pending from '../../../assets/images/pending.svg'
import lock from '../../../assets/images/lock.svg'
import routes from '../../../routes'

const NonVoucherHomeWorkRoomRview = ({level, onUpdate}) => {
  const [levelData, setLevelData] = React.useState(false)

  const {subjectid, chapterid} = useParams()

  const {
    run: getLevel,
    error: errorGettingLevel,
    isLoading: gettingLevel,
  } = useAsync()

  React.useEffect(() => {
    getLevel(
      authAxios.get(
        `/user/chapter/view/homework/?chapter=${chapterid}&subject=${subjectid}&type=${level}`,
      ),
    ).then(({data}) => {
      console.log({levelData: data})
      setLevelData(data)
    })
  }, [chapterid, subjectid, getLevel, level, onUpdate])

  return (
    <div className="p-2 border-2 solid rounded-md">
      <Segment className="border-none shadow-none  bg-transparent">
        {errorGettingLevel ? (
          <Message error list={formatResponseError(errorGettingLevel)} />
        ) : null}

        <Dimmer className="h-full" active={gettingLevel} inverted>
          <Loader active={true} />
        </Dimmer>

        <Step.Group
          vertical
          className="border-none shadow-none gap-y-1 bg-transparent w-full"
        >
          {levelData?.length > 0 &&
            levelData?.map((s, idx) => (
              <>
                <div className="flex items-start -my-1">
                  {s?.flag === 'success' ? (
                    <Image
                      src={checked}
                      className="rounded-full bg-white p-0 w-3 -mr-1.5"
                    />
                  ) : s?.flag === 'failed' ? (
                    <Image
                      src={redCircle}
                      className="rounded-full bg-white p-0 w-3 -mr-1.5 "
                    />
                  ) : s?.flag === 'locked' ? (
                    <Image
                      src={lock}
                      className=" rounded-full p-0 bg-white -mr-1.5"
                    />
                  ) : (
                    <Image
                      src={pending}
                      className="rounded-full bg-white p-0 w-3 -mr-1.5 "
                    />
                  )}

                  <div
                    className={`min-w-full ${
                      levelData?.length - 1 !== idx
                        ? 'border-l border-gray-300 '
                        : ''
                    }  pl-4`}
                  >
                    <p className="w-full -mt-1 flex">
                      {s?.type === 'wex' ? 'WEX' : 'Test'} {idx + 1}
                      {s?.locks?.length > 0 ? (
                        <>
                          <p className="mx-2.5">{s?.name}</p>
                        </>
                      ) : (
                        <>
                          {s?.type === 'wex' ? (
                            <Link
                              className="mt-0 flex cursor-pointer mx-2"
                              to={routes.nonVoucherStudent.chapters.exercise.wexlock(
                                subjectid,
                                chapterid,
                                s._id,
                              )}
                            >
                              {s?.name}
                              {s?.score > -1 && (
                                <p
                                  className={`mt-0 mx-2 ${
                                    s?.flag === 'success'
                                      ? 'text-primaryGreen'
                                      : 'text-primaryRed'
                                  } `}
                                >
                                  {'('}
                                  {s?.score?.toFixed(0)}
                                  {'% )'}
                                </p>
                              )}
                            </Link>
                          ) : (
                            <Link
                              className="mt-0 flex cursor-pointer mx-3"
                              to={routes.nonVoucherStudent.chapters.exercise.testlock(
                                subjectid,
                                chapterid,
                                s._id,
                              )}
                            >
                              {s?.name}
                              {s?.score > -1 && (
                                <p
                                  className={`mt-0 mx-2 ${
                                    s?.flag === 'success'
                                      ? 'text-primaryGreen'
                                      : 'text-primaryRed'
                                  } `}
                                >
                                  {'('} {s?.score?.toFixed(0)}
                                  {'% )'}
                                </p>
                              )}
                            </Link>
                          )}
                        </>
                      )}
                    </p>
                    <p className="flex text-primaryRed mb-4">
                      {s?.locks?.length > 0 && <> You need to pass{' ('} </>}
                      {s?.locks?.length > 0 &&
                        s?.locks?.map((l, i) => (
                          <>
                            {' '}
                            {l?.name} {s?.locks?.length - 1 === i ? null : ', '}
                          </>
                        ))}{' '}
                      {s?.locks?.length > 0 && (
                        <> {')'} to unlock this exercise </>
                      )}
                    </p>
                  </div>
                </div>
              </>
            ))}
        </Step.Group>
      </Segment>
    </div>
  )
}

export default NonVoucherHomeWorkRoomRview
