import React from 'react'
import {BsPen} from 'react-icons/bs'
import {Link} from 'react-router-dom'
import {Image} from 'semantic-ui-react'
import { useLanguage } from '../../context/languageContext'
import { content } from '../../localization/content'
import routes from '../../routes'
import {truncate} from '../../utils/string'

const VoucherChapterCard = ({
  chapterSrc,
  name,
  brief,
  chapterid,
  subjectid,
  order,
  classid,
}) => {
  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  return (
    <Link
      className="rounded-xl shadow-sm hover:shadow-md hover:text-current hover:opacity-95 flex flex-col border-2 solid animate__animated animate__fadeInUp"
      to={routes.voucherStudent.chapters.content(classid, subjectid, chapterid)}
    >
      <Image
        className="w-80 h-44 bg-contain rounded-t-md bg-primaryLight"
        src={chapterSrc}
        alt="No image provided"
      ></Image>

      <div className="flex flex-col p-3 w-80 h-32">
        <div className="flex justify-between items-center">
          <p className="text-primary text-lg font-semibold">
            {langContent.Chapter} {order}: {truncate(name, 35)}
          </p>
        </div>
        <p className="font-semibold h-full overflow-y-auto ">{brief}</p>
      </div>
    </Link>
  )
}

export default VoucherChapterCard
