import React, {useState} from 'react'
import useLocalStorage from '../../../../../hooks/use-local-storage'
import Time from '../../../../../utils/time'

const NewCountDownTimer = ({
  setTimeExpire,
  expiryTimestamp,
  exerciseAttempt,
}) => {
  const [timeRemaining, setTimeRemaining] = useState()
  const [currentTime, setCurrentTime] = useState()
  const [realEndTIme, setRealEndTIme] = useState()

  const [exerciseAttemptLS, setExerciseAttemptLS] = useLocalStorage(
    'exerciseAttempt',
    '',
  )

  React.useEffect(() => {
    let exerciseEndTime = JSON.parse(
      window.localStorage.getItem(`exerciseEndTime`),
    )
    // localStorage.endTime = Date.now() + realEndTIme
    setRealEndTIme(exerciseEndTime * 1000 + 1000)

    // if (exerciseAttempt) {
    //   if (exerciseAttempt !== exerciseAttemptLS) {
    //     localStorage.endTime = Date.now() + realEndTIme
    //   }
    // }

    console.log(exerciseEndTime)
  }, [exerciseAttempt, exerciseAttemptLS, realEndTIme])

  // let time = realEndTIme * 1000 + 1000

  const reset = React.useCallback(() => {
    localStorage.endTime = Date.now() + realEndTIme
  }, [realEndTIme])

  // if (!localStorage.endTime) {
  //   reset()
  // }

  React.useEffect(() => {
    if (timeRemaining >= 0) {
      let timeData = Time.getTimeFromSeconds(timeRemaining)
      //   console.log(timeData)
      setCurrentTime(timeData)
    }
  }, [timeRemaining])

  React.useEffect(() => {
   if (localStorage.endTime === 'NaN') {
    reset()
  }
  }, [reset])

  React.useEffect(() => {
    // setTimeData({seconds, minutes, hours, days})
    if (exerciseAttempt) {
      if (exerciseAttempt !== exerciseAttemptLS) {
        setExerciseAttemptLS(exerciseAttempt)
        reset()
      }
    }
  }, [exerciseAttempt, exerciseAttemptLS, reset, setExerciseAttemptLS])

  React.useEffect(() => {
    let interval
    if (exerciseAttempt && localStorage.endTime !== 'NaN') {
      // debugger
      interval = setInterval(function () {
        let remaining = localStorage.endTime - new Date()

        if (remaining > 0) {
          setTimeRemaining(Math.floor(remaining / 1000))
          //   console.log(Math.floor(remaining / 1000))
        } else {
          //   reset()
          clearInterval(interval)
          setTimeExpire(true)
        }
      }, 1000)
    }
    return () => {
      clearInterval(interval)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exerciseAttempt, setTimeExpire ,localStorage?.endTime])

  return (
    <div>
      {/* <div>{timeRemaining}</div> */}
      Remaining Time: <span>{currentTime?.hours}</span>:
      <span>{currentTime?.minutes}</span>:<span>{currentTime?.seconds}</span>
    </div>
  )
}

export default NewCountDownTimer
