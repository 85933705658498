import React from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {useToasts} from 'react-toast-notifications'
import {
  Button,
  Dimmer,
  Form,
  Icon,
  Image,
  Loader,
  Message,
  Popup,
  Segment,
  Table,
} from 'semantic-ui-react'
import {BsLightbulb} from 'react-icons/bs'

import {authAxios} from '../../../../config/axiosConfig'
import Time from '../../../../utils/time'
import useAsync from '../../../../hooks/useAsync'
import {formatResponseError} from '../../../../utils/format-response'
import FormInputController from '../../../../components/react-hook-form/form-input-controller'
import FormDropdownController from '../../../../components/react-hook-form/form-dropdown-controller'
import FieldArrayHook from '../../../../components/react-hook-form/field-array-hook'
import useAccountNames from '../../../../hooks/use-account-names'
import FormDateController from '../../../../components/react-hook-form/form-date-controller'
import FeedbackMessage from '../../../student/exercise/feedback-message'

const NonVoucherBalanceSheet = ({
  examType,
  data,
  setQuestionSuccess,
  setExamSuccess,
  setExamSuccessScore,
}) => {
  const [hintData, setHintData] = React.useState()
  const [popupOpen, setPopupOpen] = React.useState(false)

  const [haveSections, setHaveSections] = React.useState(false)
  const [sections, setSections] = React.useState([])

  const [uncompeletAnswer, setUncompeletAnswer] = React.useState(false)
  const [feedback, setFeedback] = React.useState()
  const {addToast} = useToasts()

  const {classid, subjectid, chapterid, sectionid, pexid, exerciseAttempt} =
    useParams()
  const history = useHistory()

  const {
    run: submitQuestion,
    error: errorSubmittingQuestion,
    isLoading: submittingQuestion,
  } = useAsync()

  const {
    run: getHint,
    error: errorGettingHint,
    isLoading: gettingHint,
  } = useAsync()

  const {
    run: getSections,
    error: errorGettingSections,
    isLoading: gettingSections,
  } = useAsync()

  const {accountNames, errorGettingAccountNames, gettingAccountNames} =
    useAccountNames()

  const reqHint = () => {
    // setPopupOpen(prevOpen => !prevOpen)
    if (hintData) {
      setPopupOpen(prevOpen => !prevOpen)
    } else {
      getHint(
        authAxios.get(
          `/correct/exercise/question/hint/${data?.attemptId}?attempt=${exerciseAttempt}`,
        ),
      ).then(({data}) => {
        setPopupOpen(true)
        console.log(data)
        setHintData(data)
      })
    }
  }

  const defaultValues = {
    date: '',
    table: [
      {
        sectionName: 'left',
        content: [
          {
            subSection: 'Fixed assets',
            answers: [
              {
                account: '',
                value: '',
              },
            ],
          },
          {
            subSection: 'sub-left-2',
            answers: [
              {
                account: '',
                value: '',
              },
            ],
          },
          {
            subSection: 'sub-left-3',
            answers: [
              {
                account: '',
                value: '',
              },
            ],
          },
        ],
        total: '',
      },
      {
        sectionName: 'right',
        subSections: [
          {
            subSection: 'sub-right-1',
            answers: [
              {
                account: '',
                value: '',
              },
            ],
          },
          {
            subSection: 'sub-right-2',
            answers: [
              {
                account: '',
                value: '',
              },
            ],
          },
          {
            subSection: 'sub-right-3',
            answers: [
              {
                account: '',
                value: '',
              },
            ],
          },
          {
            subSection: 'sub-right-4',
            answers: [
              {
                account: '',
                value: '',
              },
            ],
          },
        ],
        total: '',
      },
    ],
  }

  const {
    control,
    handleSubmit,
    setValue,
    formState,
    getValues,
    reset,
    resetField,
  } = useForm({
    defaultValues,
  })

  React.useEffect(() => {
    getSections(authAxios.get('/question/balancesheet/sections')).then(
      ({data}) => {
        // console.log(data)
        let arr = []
        for (let column of data?.sections) {
          let subS = []
          for (let i of column?.subSections) {
            i = {
              ...i,
              section: i?._id,
              answers: [
                {
                  account: '',
                  value: '',
                },
              ],
            }
            subS.push(i)
          }
          arr.push({...column, content: subS, sectionName: column?._id})
        }
        // console.log(arr)

        // reset({...getValues, table: arr})
        // reset({...getValues(), table: arr})
        console.log({table: arr})
        setSections({table: arr})
        setHaveSections(true)
      },
    )
  }, [getSections, getValues, reset])

  React.useEffect(() => {
    if (haveSections) {
      // handle data for left section on table (assets or actif)
      // merge data from getAllSection and question data to get the rest of data
      let allActifSections = sections.table[0].content
      let questionActifSections = data?.actifSection

      const mergedActifSections = questionActifSections
        .map(is => {
          const existInQuestionSections = allActifSections.find(
            qs => qs._id === is._id,
          )
          if (existInQuestionSections) {
            return existInQuestionSections
          } else {
            return
          }
        })
        .filter(item => item)
      let mergedActifObj = {...sections?.table[0], content: mergedActifSections}

      //******************************************************************************** */
      // handle data for right section on table (assets or actif)
      // merge data from getAllSection and question data to get the rest of dataf
      let allPassifSections = sections.table[1].content
      let questionPassifSections = data?.passifSection

      const mergedPassifSections = questionPassifSections
        .map(is => {
          const existInQuestionSections = allPassifSections.find(
            qs => qs._id === is._id,
          )
          if (existInQuestionSections) {
            return existInQuestionSections
          } else {
            return
          }
        })
        .filter(item => item)
      let mergedPassifObj = {
        ...sections?.table[1],
        content: mergedPassifSections,
      }
      // console.log({mergedObj: {...sections?.table[0],content:mergedPassifSections}})

      reset({...getValues(), table: [mergedActifObj, mergedPassifObj]})
      // console.log({finalData:{...getValues(), table: [mergedActifObj, mergedPassifObj]}})
      console.log({data})
    }
  }, [data, getValues, haveSections, reset, sections])

  function onSubmit(values) {
    console.log({values})
    let timer = JSON.parse(
      window.localStorage.getItem(
        `questionId-${data?._id}-attemptId-${data?.attemptId}`,
      ),
    )
    const timeData = Time.getTimeFromSeconds(timer.seconds)

    let obj = {
      ...values,
      time: {
        hours: timeData?.hours,
        minutes: timeData?.minutes,
        seconds: timeData?.seconds,
      },
      attemptId: data?.attemptId,
      questionId: data?._id,
    }

    submitQuestion(
      authAxios.post(
        `/correct/exercise/${exerciseAttempt}?qtype=balanceSheet`,
        obj,
      ),
    ).then(({data}) => {
      console.log(data)
      if (examType === 'test') {
        reset()
        setFeedback(null)
        setQuestionSuccess(p => !p)
        setUncompeletAnswer(false)
        setHintData(null)
        return
      }
      if (data?.examSuccessfull && data?.success) {
        setExamSuccess(true)
        setExamSuccessScore(data?.score)
      }
      if (!data?.success) {
        setUncompeletAnswer(true)
        setFeedback(data)
      } else {
        reset()
        setFeedback(null)
        setQuestionSuccess(p => !p)
        addToast('Correct answer ', {appearance: 'success'})
        setUncompeletAnswer(false)
        setHintData(null)
      }
    })
  }

  return (
    <div className=" my-10">
      <Segment className="border-none shadow-none bg-transparent">
        {errorGettingHint ? (
          <Message error list={formatResponseError(errorGettingHint)} />
        ) : null}
        {uncompeletAnswer ? (
          <Message error content="Incompleted Answer!" />
        ) : null}
        {errorSubmittingQuestion ? (
          <Message error list={formatResponseError(errorSubmittingQuestion)} />
        ) : null}
        {errorGettingAccountNames ? (
          <Message error list={formatResponseError(errorGettingAccountNames)} />
        ) : null}
        {errorGettingSections ? (
          <Message error list={formatResponseError(errorGettingSections)} />
        ) : null}
        <Dimmer
          active={submittingQuestion || gettingSections || gettingAccountNames}
          inverted
        >
          {/* <Loader active={true} /> */}
        </Dimmer>
        <div>
          <p className="text-primary text-xl">Instructions:</p>
          <p className="break-all">{data?.exerciseInstructions}</p>
        </div>
        <div>
          <div className="flex my-4">
            {/* <p className="text-primary font-semibold">{data?.questionTitle}</p> */}
            <Popup
              disabled={!hintData}
              content={hintData}
              open={popupOpen}
              position="bottom right"
              className="border border-primaryYellow shadow-none break-all mb-4 "
              trigger={
                <Button
                  loading={gettingHint}
                  className={`${
                    popupOpen
                      ? 'bg-white border border-solid  flex items-center ml-auto justify-center text-primaryYellow border-primaryYellow'
                      : 'bg-primaryYellow flex items-center justify-center text-white ml-auto'
                  }`}
                  onClick={() => reqHint()}
                >
                  hint
                  <BsLightbulb className="-mr-2 ml-2 -mt-0.5" />
                </Button>
              }
            />
          </div>
        </div>
        <div className="flex">
          <div className="flex-1 flex-col custom-min-width">
            <p className="text-primary font-semibold mb-4">
              {data?.questionTitle}
            </p>
            <p>{data?.question}</p>
          </div>
          {data?.image && (
            <div className="flex-1 flex justify-center custom-min-width">
              <Image
                size="medium"
                className=""
                src={data?.image}
                rounded
                as="a"
                href={data?.image}
                target="_blank"
              />
            </div>
          )}
        </div>

        <Form
          onSubmit={handleSubmit(onSubmit)}
          loading={gettingSections || gettingAccountNames}
        >
          <div className="mt-5 red-200">
            {/* <div className="mx-auto  w-56 blue-100 ">
              <p className="text-lg font">Balance au</p>
            </div> */}
            <div className="mx-auto  -mb-16  flex items-center justify-center">
              <p className="text-lg font mx-4">Balance au</p>

              <FormDateController control={control} name="date" />
              {feedback?.date && (
                <Popup
                  trigger={
                    <Icon
                      name="warning"
                      className=" ml-2 mt-"
                      color="red"
                      circular
                    />
                  }
                  content={feedback?.date}
                  className="border border-red-600 text-red-600"
                  // position='top left'
                />
              )}
            </div>
          </div>

          <FieldArrayHook control={control} name="table" setValue={setValue}>
            {({fields}) => (
              <div className="flex md:max-w-4xl sm:max-w-lg max-w-sm mx-auto px-2 md:px-4 my-20 ">
                {fields.map((f, i) => {
                  return (
                    <div className="flex flex-col flex-grow">
                      <div
                        key={f.id}
                        className="flex-1 flex-grow  relative red-400 p-4 border border-gray-600  bg-gray-100"
                      >
                        {i === 0 ? (
                          <h1 className="absolute -top-10 left-1">
                            {f?.nameEN}
                          </h1>
                        ) : (
                          <h1 className="absolute -top-10 right-1">
                            {f?.nameEN}
                          </h1>
                        )}

                        <BalanceSheetBox
                          control={control}
                          name={`table.${i}.content`}
                          accountNames={accountNames}
                          setValue={setValue}
                          tableIndex={i}
                          feedback={feedback}
                        />
                      </div>
                      <div className="flex justify-between border border-gray-600 p-4 bg-gray-100  -mt-0.5">
                        <div className="-300 w-1/4">
                          <h3 className="ml-4">Total</h3>
                        </div>
                        <div className="-400 w-3/4 flex ml-auto justify-end">
                          <FormInputController
                            control={control}
                            name={`table.${i}.total`}
                            isNumber
                            setValue={setValue}
                            inputProps={{
                              className: 'w-52',
                            }}
                          />
                          {/* <FeedbackMessage
                            value={feedback && feedback?.table[i]?.total}
                          /> */}

                          {feedback?.table[i]?.total && (
                            <Popup
                              trigger={
                                <Icon
                                  name="warning"
                                  className=" ml-2 mt-1.5"
                                  color="red"
                                  circular
                                />
                              }
                              content={feedback?.table[i]?.total}
                              className="border border-red-600 text-red-600"
                              // position='top left'
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </FieldArrayHook>
          <hr />
          <div className="my-5 mt-10 flex">
            <Button className="ml-auto" primary type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </Segment>
    </div>
  )
}

export default NonVoucherBalanceSheet

const BalanceSheetBox = ({
  control,
  name,
  accountNames,
  setValue,
  feedback,
  tableIndex,
}) => (
  <FieldArrayHook control={control} name={name}>
    {({fields}) => (
      <div>
        {fields.map((field, i) => (
          <div>
            <h3 className="ml-4 underline">
              { field?.nameEN }
            </h3>
            <div className="red-200  m-4 p-2  border-gray-600 rounded-md  relative bg-gray-100">
              <BalanceSheetSubBox
                name={`${name}.${i}.answers`}
                control={control}
                accountNames={accountNames}
                setValue={setValue}
                feedback={feedback}
                tableIndex={tableIndex}
                boxIndex={i}
              />
            </div>
            {fields.length - 1 !== i ? (
              <hr className="my-2 h-0.5 bg-gray-300" />
            ) : null}
          </div>
        ))}
      </div>
    )}
  </FieldArrayHook>
)

const BalanceSheetSubBox = ({
  control,
  name,
  accountNames,
  setValue,
  feedback,
  tableIndex,
  boxIndex,
}) => (
  <FieldArrayHook control={control} name={name}>
    {({fields, remove, append}) => {
      return fields.map((field, i) => (
        <div key={field.id} className="blue-100 my-2 w-[23rem]">
          <div className="flex justify-between gap-2">
            <div>
              <FormDropdownController
                control={control}
                name={`${name}.${i}.account`}
                // label="Account Name"
                inputProps={{
                  selection: true,
                  fluid: true,
                  options: accountNames,
                  search: true,
                  placeholder: 'account name',
                  className: 'w-52',
                  // error:true
                }}
              />
              {/* <div className='bg-red-100 '>
                er
              </div> */}
            </div>

            <FormInputController
              control={control}
              name={`${name}.${i}.value`}
              isNumber
              setValue={setValue}
              // label="value"
              inputProps={{
                className: 'w-36',
                placeholder: 'value',
              }}
              // {...register("firstName")}
            />
          </div>

          <div className="my-2">
            <FeedbackMessage
              value={
                feedback &&
                feedback?.table[tableIndex]?.content[boxIndex]?.answers[i]
                  ?.feedback
              }
            />
          </div>

          <div className="flex gray-400 justify-end">
            {i === 0 ? null : (
              <Button
                type="button"
                size="tiny"
                negative
                basic
                onClick={() => remove(i)}
              >
                -
              </Button>
            )}

            {i === fields.length - 1 && fields.length < 5 ? (
              <Button
                type="button"
                size="tiny"
                className="bg-transparent border-2 border-solid  items-center my-0.5 -mr-0.5  border-green-400"
                onClick={() =>
                  append({
                    accountName: '',
                    value: '',
                  })
                }
              >
                +
              </Button>
            ) : null}
          </div>
        </div>
      ))
    }}
  </FieldArrayHook>
)
