import React from 'react'
import {Link, useHistory, useLocation, useParams} from 'react-router-dom'
import {
  Dimmer,
  Dropdown,
  Loader,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react'
import queryString from 'query-string'

import useAsync from '../../../hooks/useAsync'
import routes from '../../../routes'
import {truncate} from '../../../utils/string'
import {authAxios} from '../../../config/axiosConfig'
import {formatResponseError} from '../../../utils/format-response'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'

const ClassExercise = () => {
  const [chapters, setChapters] = React.useState([])
  const [rowToExpand, setRowToExpand] = React.useState(null)
  const [exerciseData, setExerciseData] = React.useState([])
  const [currentChapter, setCurrentChapter] = React.useState()

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  const exerciseOptions = React.useMemo(
    () => [
      {text: `${langContent.Pex}`, value: 'pex', key: 'pex'},
      {text: `${langContent.WEX}`, value: 'wex', key: 'wex'},
      {text: `${langContent.Test}`, value: 'test', key: 'test'},
    ],
    [],
  )
  const [currentExerciseType, setCurrentExerciseType] = React.useState(
    exerciseOptions[0].value,
  )

  const {classid, subjectid} = useParams()
  const location = useLocation()
  const [state] = React.useState(location?.state)

  const history = useHistory()

  const {
    run: getChapters,
    error: errorGettingChapters,
    isLoading: gettingChapters,
  } = useAsync()

  const {
    run: getExerciseInfo,
    error: errorGettingExerciseInfo,
    isLoading: gettingExerciseInfo,
  } = useAsync()

  React.useEffect(() => {
    const {currentChapterid, currentexerciseType} = queryString.parse(
      location.search,
    )

    if (currentChapterid !== 'undefined') {
      // setCurrentExerciseType(currentexerciseType)
      setCurrentChapter(currentChapterid)
    }
    setCurrentChapter(currentChapterid)
    if (currentexerciseType !== 'undefined') {
      setCurrentExerciseType(currentexerciseType)
    }
    if (
      currentChapter &&
      currentExerciseType &&
      currentChapterid !== 'undefined'
    ) {
      getExerciseInfo(
        authAxios.get(
          `/instructor/class/chapter/exercise/all?class=${classid}&chapter=${currentChapter}&type=${currentExerciseType}`,
        ),
      ).then(({data}) => {
        console.log(data)
        setExerciseData(data)
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getExerciseInfo, currentChapter, currentExerciseType])

  React.useEffect(() => {
    getChapters(authAxios.get(`/chapter/all/publish?subject=${subjectid}`)).then(
      ({data}) => {
        let options = []
        data?.forEach(s =>
          options.push({
            text: truncate(s.chapterName, 100),
            key: s._id,
            value: s._id,
          }),
        )
        setChapters(options)
      },
    )
  }, [getChapters, subjectid])

  React.useEffect(() => {
    const {currentChapterid, currentexerciseType} = queryString.parse(
      location.search,
    )
    if (!currentexerciseType) {
      setCurrentExerciseType(exerciseOptions[0]?.value)
    }
    if (chapters.length && !currentChapter) {
      setCurrentChapter(chapters[0]?.value)
      history.replace(
        `${location.pathname}?currentChapterid=${chapters[0]?.value}&currentexerciseType=${exerciseOptions[0]?.value}`,
      )
    }
  }, [
    chapters,
    currentChapter,
    exerciseOptions,
    currentExerciseType,
    history,
    location.pathname,
    state,
    location.search,
  ])

  const onChapterChange = (e, {value}) => {
    setCurrentChapter(value)
    history.replace(
      `${location.pathname}?currentChapterid=${value}&currentexerciseType=${currentExerciseType}`,
    )
  }

  const onExerciseTypeChang = (e, {value}) => {
    setCurrentExerciseType(value)
    history.replace(
      `${location.pathname}?currentexerciseType=${value}&currentChapterid=${currentChapter}`,
    )
  }
  let fakeData = [
    {_id: '61e048ce84408f1fb0c0f7a5', name: 'ACHAT 3', __v: 0},
    {_id: '61b087d604e0a6920bcbeff3', name: 'Achats 2', __v: 0},
    {
      _id: '61a8ee0473542a10309ae931',
      name: 'Achats marchandises fournisseur',
      __v: 0,
    },
    {
      _id: '61af83de95bf31487bf42501',
      name: 'AchatsElectriciteFournisseur',
      __v: 0,
    },
    {_id: '61b0c55a61d41b263b36884d', name: 'AchatsMsesFournisseur', __v: 0},
    {
      _id: '61af83bb95bf31487bf424f1',
      name: 'AchatsVehiculeFournisseur',
      __v: 0,
    },
    {
      _id: '61b0813004e0a6920bcbef96',
      name: 'ChargeElectriciteFournisseur',
      __v: 0,
    },
    {
      _id: '61deb096836ffe6fd9c4c07a',
      name: 'ChargeFraisPersonnelBanque',
      __v: 0,
    },
    {_id: '61af7f2595bf31487bf424ac', name: 'ChargeInterestsBanque', __v: 0},
    {
      _id: '61af87b395bf31487bf4255e',
      name: 'ChargeReparationFournisseur',
      __v: 0,
    },
  ]

  return (
    <div>
      {errorGettingChapters ? (
        <Message error list={formatResponseError(errorGettingChapters)} />
      ) : null}
      {errorGettingExerciseInfo ? (
        <Message error list={formatResponseError(errorGettingExerciseInfo)} />
      ) : null}
      <Segment className="border-none shadow-none bg-transparent ">
        <Dimmer active={gettingChapters || gettingExerciseInfo} inverted>
          <Loader active={true} />
        </Dimmer>
        <div className="flex">
          <Dropdown
            placeholder="Select a chapter"
            search
            selection
            value={currentChapter}
            loading={gettingChapters}
            options={chapters}
            onChange={onChapterChange}
            className=" drop-shadow-md shadow-md  ml-auto"
          />
          <Dropdown
            placeholder="Exercise type"
            selection
            value={currentExerciseType}
            options={exerciseOptions}
            onChange={onExerciseTypeChang}
            className=" drop-shadow-md shadow-md  mx-2"
          />
        </div>

        <Table celled className="shadow-lg my-5">
          <Table.Header>
            <Table.Row className="text-sm">
              <Table.HeaderCell className="bg-tableHeader">
                {langContent.Exercice_name}
              </Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader">
                {langContent.Number_of_students}
              </Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader">
                {langContent.Avergae_score}
              </Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader">
                {langContent.Avergae_number_of_hints}
              </Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader">
                {langContent.Avergae_time}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {exerciseData.map((a, idx) => (
              <Table.Row textAlign="left" verticalAlign="top">
                <Table.Cell>
                  <Link
                    to={routes.instructor.exerciseBreakdown(
                      classid,
                      subjectid,
                      currentChapter,
                      a?._id,
                    )}
                  >
                    {truncate(a?.exercise, 45)}
                  </Link>
                </Table.Cell>
                <Table.Cell>{a?.students}</Table.Cell>
                <Table.Cell>{parseFloat(a?.average).toFixed(0)}%</Table.Cell>
                <Table.Cell>{a?.hints}</Table.Cell>
                <Table.Cell>
                  <>
                    {' '}
                    {parseInt(a?.time?.hours)} : {parseInt(a?.time?.minutes)} :{' '}
                    {parseInt(a?.time?.seconds)}
                  </>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    </div>
  )
}

export default ClassExercise
