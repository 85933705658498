import React from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {
  Dimmer,
  Dropdown,
  Loader,
  Message,
  Progress,
  Segment,
  Table,
} from 'semantic-ui-react'
import queryString from 'query-string'
import {AiOutlineCaretDown, AiOutlineCaretRight} from 'react-icons/ai'
import {FaLock} from 'react-icons/fa'

import {authAxios} from '../../../config/axiosConfig'
import {formatResponseError} from '../../../utils/format-response'
import AnimatedProgressProvider from '../../../components/shared/AnimatedProgressProvider'
import useAsync from '../../../hooks/useAsync'
import {truncate} from '../../../utils/string'
import useSubject from '../../../hooks/use-subjects'

const NonVoucherStudentChapter = () => {
  const [rowToExpand, setRowToExpand] = React.useState(null)
  const [chapterProgressData, setChapterProgressData] = React.useState([])

  const {subjects, errorGettingSubjects, gettingSubjects} = useSubject()

  const [currentSubject, setCurrentSubject] = React.useState()

  const location = useLocation()

  const history = useHistory()

  const {
    run: getExerciseInfo,
    error: errorGettingExerciseInfo,
    isLoading: gettingExerciseInfo,
  } = useAsync()

  React.useEffect(() => {
    const {currentSubjectid} = queryString.parse(location.search)

    setCurrentSubject(currentSubjectid)
    if (currentSubject &&  currentSubjectid !== 'undefined') {
      getExerciseInfo(
        authAxios.get(`/user/chapter/progress/all?subject=${currentSubject}`),
      ).then(({data}) => {
        console.log(data)
        setChapterProgressData(data)
      })
    }

  }, [getExerciseInfo, currentSubject, location.search])

  React.useEffect(() => {
    const {currentSubjectid} = queryString.parse(location.search)

    if (!currentSubjectid && subjects.length > 0) {
      setCurrentSubject(subjects[0].value)
      history.replace(
        `${location.pathname}?currentSubjectid=${subjects[0].value}`,
      )
    }
  }, [subjects, history, location.pathname, location.search])

  const onSubjectChange = (e, {value}) => {
    setCurrentSubject(value)
    history.replace(`${location.pathname}?currentSubjectid=${value}`)
  }

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent">
        {errorGettingSubjects ? (
          <Message error list={formatResponseError(errorGettingSubjects)} />
        ) : null}
        {errorGettingExerciseInfo ? (
          <Message error list={formatResponseError(errorGettingExerciseInfo)} />
        ) : null}
        <Dimmer active={gettingSubjects || gettingExerciseInfo} inverted>
          <Loader active={true} />
        </Dimmer>
        <div className="flex">
          <Dropdown
            placeholder="Select a class"
            search
            selection
            value={currentSubject}
            loading={gettingSubjects}
            options={subjects}
            onChange={onSubjectChange}
            className=" drop-shadow-md shadow-md  ml-auto"
          />
        </div>

        <Table className="shadow-lg my-5">
          <Table.Header>
            <Table.Row className="text-sm">
              <Table.HeaderCell
                textAlign="center"
                className="bg-tableHeader"
              ></Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader">
                Chapter's name
              </Table.HeaderCell>

              <Table.HeaderCell className="bg-tableHeader">
                Progress
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {chapterProgressData?.map((a, idx) => (
              <>
                {a?.isSubscriber !== false ? (
                  <>
                    <Table.Row textAlign="left" verticalAlign="top">
                      <Table.Cell
                        width={1}
                        className="mr-0"
                        textAlign="center"
                        selectable
                        onClick={() =>
                          rowToExpand?.chapterId === a?.chapterId
                            ? setRowToExpand(null)
                            : setRowToExpand(a)
                        }
                      >
                        {rowToExpand?.chapterId === a.chapterId ? (
                          <AiOutlineCaretDown
                            className="mx-auto mt-4"
                            size="16"
                          />
                        ) : (
                          <AiOutlineCaretRight
                            className="mx-auto mt-4"
                            size="16"
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell width={1}>
                        {truncate(a?.chapter, 35)}
                      </Table.Cell>
                      <Table.Cell width={5}>
                        <div className="flex items-start  h-8">
                          <Progress
                            className="w-96 mt-2"
                            size="small"
                            percent={a?.progress}
                            // {a?.progress >= 80 ?(success):(error)
                            // }
                            success
                          ></Progress>
                          <p className="ml-4 mt-1">{a?.progress}%</p>
                        </div>
                      </Table.Cell>
                    </Table.Row>

                    {rowToExpand?.chapterId === a.chapterId ? (
                      <Table.Row key={a.chapterId}>
                        <Table.Cell colSpan={16} selectable={false}>
                          <div className="flex">
                            <div className="flex-1 max-w-xl flex flex-wrap">
                              {rowToExpand?.skills?.map((s, i) => (
                                <div className="w-36  m-2 flex  flex-col items-center">
                                  <div>
                                    <AnimatedProgressProvider
                                      valueEnd={Number.parseFloat(
                                        `${s?.progress}`,
                                      )}
                                    ></AnimatedProgressProvider>
                                  </div>
                                  <div className="h-9">
                                    <p className="-mt-4 text-center text-sm text-gray-500">
                                      {truncate(s?.skill, 30)}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>

                            <div className="flex-1">
                              {rowToExpand?.feedbacks?.length > 0 ? (
                                <h3 className="text-primary mb-2">
                                  Frequent errors
                                </h3>
                              ) : null}
                              <ul className="list-disc list-inside">
                                {rowToExpand?.feedbacks?.map((s, i) => (
                                  <li>{s?.feedbackMessage}</li>
                                ))}
                                {/* <li>Frequent error 1</li>
                            <li>Frequent error 2</li>
                            <li>Frequent error 3</li> */}
                              </ul>
                            </div>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    ) : null}
                  </>
                ) : (
                  <>
                    <Table.Row textAlign="left" verticalAlign="top">
                      <Table.Cell
                        width={1}
                        className="mr-0"
                        textAlign="center"
                        selectable
                        onClick={() =>
                          rowToExpand?.chapterId === a?.chapterId
                            ? setRowToExpand(null)
                            : setRowToExpand(a)
                        }
                      >
                        {rowToExpand?.chapterId === a.chapterId ? (
                          <FaLock
                            className="mx-auto mt-4"
                            size="16"
                          />
                        ) : (
                          <FaLock
                            className="mx-auto mt-4"
                            size="16"
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell width={1}>
                        {truncate(a?.chapter, 35)}
                      </Table.Cell>
                      <Table.Cell width={16} className='flex '>
                        <div className="flex items-start flex-1 h-8">
                          <Progress
                            className="w-96 mt-2"
                            size="small"
                            percent={a?.progress}
                            // {a?.progress >= 80 ?(success):(error)
                            // }
                            success
                          ></Progress>
                          <p className="ml-4  mt-1">{a?.progress}%</p>
                        </div>
                        <div className="flex items-center justify-center w-full  flex-1">
                          <FaLock className="mx-2 -mt-0.5" />
                          <p className="text-primary">
                            Subscribe to unlock this chapter
                          </p>
                        </div>
                      </Table.Cell>
                    </Table.Row>

                  </>
                )}
              </>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    </div>
  )
}

export default NonVoucherStudentChapter
