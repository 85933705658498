import {useState} from 'react'
import {Grid, Icon, Image, Input} from 'semantic-ui-react'
import logoWhiteUpdated1310 from '../../assets/images/logoWhiteUpdated1310.png'
import Privacy from './privacy'
import GeneralConditions from './general-conditions'
import {content} from '../../localization/content'
import {useLanguage} from '../../context/languageContext'

const Footer = () => {
  const [lang] = useLanguage()
  const langContent = content[lang]
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false)
  const [openGeneralConditionsModal, setOpenGeneralConditionsModal] =
    useState(false)

  return (
    <div className="mt-5 lg:mt-0 ">
      <Grid stackable>
        <Grid.Row className="px-4 py-3 xl:px-28 lg:py-2 bg-primaryDarkColor text-gray-400">
          <Grid.Column
            tablet={8}
            computer={8}
            mobile={16}
            className="flex flex-wrap flex-col"
          >
            <div className="my-5">
              <Image
                className="text-white h-10 w-22"
                src={logoWhiteUpdated1310}
              />
            </div>

            <div>
              <Icon name="mail outline" />
              <a
                href="mailto:Indil.direct@gmail.com"
                className="hover:text-current"
              >
                Indil.direct@gmail.com
              </a>
            </div>

            {/* <div>
                  <Icon name="call" />
                  <a href="tel:+352691558975" className="hover:text-current">
                    +352 691 55 89 75
                  </a>
                </div> */}
          </Grid.Column>

          <Grid.Column
            tablet={8}
            computer={8}
            mobile={16}
            className="px-4 lg:px-10 py-3 lg:pt-4 "
          >
            <div className="font-semibold pt-1 pb-3">
              <span
                className="cursor-pointer hover:text-white-700"
                onClick={() => setOpenPrivacyModal(true)}
              >
                {langContent.Privacy_and_Policy}
              </span>
            </div>

            <div className="font-semibold pt-1 pb-3">
              <span
                className="cursor-pointer hover:text-white-700"
                onClick={() => setOpenGeneralConditionsModal(true)}
              >
                {langContent.General_conditions}
              </span>
            </div>

            <div className="font-semibold pt-1 pb-3">
              <span
                className="cursor-pointer hover:text-white-700"
                onClick={() =>
                  window.open(
                    'https://firebasestorage.googleapis.com/v0/b/indil-350517.appspot.com/o/user-guide.pdf?alt=media&token=c4f841bc-91f6-4e9b-a882-35bf684d0e1c',
                    '_blank',
                  )
                }
              >
                {langContent.User_guide}
              </span>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Privacy
        open={openPrivacyModal}
        onClose={() => setOpenPrivacyModal(false)}
      />

      <GeneralConditions
        open={openGeneralConditionsModal}
        onClose={() => setOpenGeneralConditionsModal(false)}
      />
    </div>
  )
}

export default Footer
