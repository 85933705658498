import React from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import {
  Button,
  Dimmer,
  Loader,
  Message,
  Modal,
  Segment,
} from 'semantic-ui-react'
import {
  AiOutlineCheckCircle,
  AiOutlineClose,
  AiOutlineCloseCircle,
} from 'react-icons/ai'
import {FaCalculator} from 'react-icons/fa'
import Draggable from 'react-draggable'

import {useLanguage} from '../../../../context/languageContext'
import {content} from '../../../../localization/content'
import useAsync from '../../../../hooks/useAsync'
import {authAxios} from '../../../../config/axiosConfig'
import {formatResponseError} from '../../../../utils/format-response'
import StopWatchTimer from '../../../../components/shared/stop-watch-timer'
import Calculator from '../../../../components/shared/calculator/calculator'
import BlankTeacher from '../teacher-questions/teacher-blank'
import ChoiceTeacher from '../teacher-questions/teacher-choice'
import JournalTeacher from '../teacher-questions/teacher-Journal'
import TeacherAmortization from '../teacher-questions/teacher-amortization'
import TAccountTeacher from '../teacher-questions/teacher-taccount'
import routes from '../../../../routes'
import TeacherBalanceSheet from '../teacher-questions/teacher-balance-sheet'

const TeacherPex = () => {
  const {classid, subjectid, chapterid, sectionid, pexid, exerciseAttempt} =
    useParams()
  const [questionData, setQuestionData] = React.useState({})
  const [pexData, setPexData] = React.useState({})
  const history = useHistory()

  const [questionSuccess, setQuestionSuccess] = React.useState(false)

  const [examSuccess, setExamSuccess] = React.useState(false)
  const [examSuccessScore, setExamSuccessScore] = React.useState()
  const [examFalid, setExamFalid] = React.useState(false)
  const [openCalc, setOpenCalc] = React.useState(false)

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  const {
    run: getQuestion,
    error: errorGettingQuestion,
    isLoading: gettingQuestion,
  } = useAsync()

  const {
    run: getWexPex,
    error: errorGettingWexPex,
    isLoading: gettingWexPex,
  } = useAsync()

  React.useEffect(() => {
    if (pexid)
      getWexPex(authAxios.get(`/exercise/${pexid}`)).then(({data}) => {
        setPexData(data)
      })
  }, [getWexPex, pexid])

  React.useEffect(() => {
    getQuestion(
      authAxios.get(`/correct/exercise/next/${exerciseAttempt}`),
    ).then(({data}) => {
      console.log({questionData: data})
      if (data?.examSuccessfull) {
        // history.push(routes.voucherStudent.chapters.content(classid, subjectid, chapterid))
        setExamSuccess(true)
      }
      if (data?.lastQuestion && !data?.examSuccessfull) {
        // history.push(routes.voucherStudent.chapters.content(classid, subjectid, chapterid))
        setExamFalid(true)
      }
      setQuestionData(data)
    })
  }, [
    chapterid,
    classid,
    exerciseAttempt,
    getQuestion,
    history,
    questionSuccess,
    subjectid,
  ])

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent relative ">
        {errorGettingQuestion ? (
          <Message error list={formatResponseError(errorGettingQuestion)} />
        ) : null}

        {errorGettingWexPex ? (
          <Message error list={formatResponseError(errorGettingWexPex)} />
        ) : null}

        <Dimmer active={gettingQuestion || gettingWexPex} inverted>
          <Loader active={true} />
        </Dimmer>
        <StopWatchTimer
          attemptId={questionData?.attemptId}
          questionId={questionData?._id}
        />
        <div className="flex my-3">
          <Link
            className="ml-auto"
            to={routes.instructor.chapterContent(classid, subjectid, chapterid)}
          >
            {langContent.Cancel_exercice}
          </Link>
        </div>

        {/* <div className="absolute right-4 bottom-44 z-1">
          <Button
            primary
            onClick={() => setOpenCalc(p => !p)}
            className="rounded-2xl p-3"
          >
            <FaCalculator className="text-3xl" />
          </Button>
        </div>
        <div
          className={`absolute right-20 bottom-44 w-64 h-72 z-1  ${
            openCalc ? '' : 'hidden'
          }`}
        >
          <Calculator />
        </div> */}

        <Draggable bounds="parent">
          <div className="absolute right-4 bottom-44 z-1">
            <Button
              primary
              onClick={() => setOpenCalc(p => !p)}
              className="rounded-2xl p-3"
            >
              <FaCalculator id="calcIcon" className="text-3xl" />
            </Button>
            <div
              className={`absolute right-16 bottom-0 w-64 h-72 z-1  ${
                openCalc ? '' : 'hidden'
              }`}
            >
              <Calculator />
            </div>
          </div>
        </Draggable>

        <div className="bg-primary p-4 rounded-sm text-white flex items-center justify-evenly">
          <p className="mr-4">
            {' '}
            {langContent.PEX}: {pexData?.name}
          </p>
          {/* <p className="mr-4">Score: {questionData?.totalScore}%</p> */}
        </div>
        {questionData?.lastQuestion ? (
          <div className="flex justify-center items-center bg-yellow-100 my-4">
            <p className="text-4xl my-4">{langContent.Question_finished}!</p>
          </div>
        ) : (
          <>
            {questionData?.type === 'blank' && (
              <BlankTeacher
                data={questionData}
                setQuestionSuccess={setQuestionSuccess}
                setExamSuccess={setExamSuccess}
                setExamSuccessScore={setExamSuccessScore}
              />
            )}
            {questionData?.type === 'choice' && (
              <ChoiceTeacher
                data={questionData}
                setQuestionSuccess={setQuestionSuccess}
                setExamSuccess={setExamSuccess}
                setExamSuccessScore={setExamSuccessScore}
              />
            )}
            {questionData?.type === 'journal' && (
              <JournalTeacher
                data={questionData}
                setQuestionSuccess={setQuestionSuccess}
                setExamSuccess={setExamSuccess}
                setExamSuccessScore={setExamSuccessScore}
              />
            )}
            {questionData?.type === 'amortization' && (
              <TeacherAmortization
                data={questionData}
                setQuestionSuccess={setQuestionSuccess}
                setExamSuccess={setExamSuccess}
                setExamSuccessScore={setExamSuccessScore}
              />
            )}
            {questionData?.type === 'taccount' && (
              <TAccountTeacher
                data={questionData}
                setQuestionSuccess={setQuestionSuccess}
                setExamSuccess={setExamSuccess}
                setExamSuccessScore={setExamSuccessScore}
              />
            )}
            {questionData?.type === 'balanceSheet' && (
              <TeacherBalanceSheet
                data={questionData}
                setQuestionSuccess={setQuestionSuccess}
                setExamSuccess={setExamSuccess}
                setExamSuccessScore={setExamSuccessScore}
              />
            )}
          </>
        )}
        <ExamSuccessModal
          open={examSuccess}
          onClose={() => setExamSuccess(false)}
          score={questionData?.score || examSuccessScore}
        />

        <ExamFailedModal
          open={examFalid}
          onClose={() => setExamFalid(false)}
          score={questionData?.score}
        />
      </Segment>
    </div>
  )
}

export default TeacherPex

const ExamSuccessModal = ({open, onClose, score}) => {
  const {classid, subjectid, chapterid, sectionid, pexid, exerciseAttempt} =
    useParams()
  const history = useHistory()
  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      size="tiny"
      // closeIcon
    >
      <Modal.Content className="flex  flex-col h-60 items-center relative">
        <AiOutlineClose
          onClick={() => {
            onClose()
            history.push(
              routes.instructor.chapterContent(classid, subjectid, chapterid)
            )
          }}
          className="absolute top-4 right-4 bg-transparent text-2xl font-semibold cursor-pointer"
        />

        <div>
          <AiOutlineCheckCircle className="text-8xl text-green-600 " />
        </div>
        <div>
          <p className="text-green-600 my-3 font-semibold">
            {langContent.Your_score_is} {parseFloat(score)?.toFixed(0)}%!{' '}
          </p>
        </div>
        <div>
          <p className="text-gray-600 my-3 font-semibold">
            {langContent.Your_can_proceed_to_the_next_PEX}
          </p>
        </div>
      </Modal.Content>
    </Modal>
  )
}

const ExamFailedModal = ({open, onClose, score}) => {
  const {classid, subjectid, chapterid, sectionid, pexid, exerciseAttempt} =
    useParams()
  const history = useHistory()
  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      size="tiny"
    >
      <Modal.Content className="flex  flex-col h-60 items-center relative">
        <AiOutlineClose
          onClick={() => {
            onClose()
            history.push(
              routes.instructor.chapterContent(classid, subjectid, chapterid)
            )
          }}
          className="absolute top-4 right-4 bg-transparent text-2xl font-semibold cursor-pointer"
        />

        <div>
          <AiOutlineCloseCircle className="text-8xl text-red-600 " />
        </div>
        <div>
          <p className="text-red-600 my-3 font-semibold">
            {langContent.Your_score_is} {parseFloat(score)?.toFixed(2)}%!{' '}
          </p>
        </div>
        <div>
          <p className="text-gray-600 my-3 font-semibold">
            {langContent.Your_need_to_try_it_again}
          </p>
        </div>
      </Modal.Content>
    </Modal>
  )
}
