import React, {useState} from 'react'
import TeacherClassTabs from '../../components/teacher/class/teacher-class-tabs'
import useLocalStorage from '../../hooks/use-local-storage'
import ClassChapterProgress from './class-view/class-chapter-progress'
import ClassExercise from './class-view/class-exercise'
import ClassPdfs from './class-view/class-pdfs'
import ClassStudentinfo from './class-view/class-student-info'
import ClassSubjectContent from './class-view/class-subject-content'

const TeacherClassView = () => {
  const [activeMenu, setActiveMenu] = useLocalStorage(
    'activeMenu',
    'studentsInfo',
  )

  return (
    <div>
      <div className="">
        <TeacherClassTabs
          activeMenu={activeMenu}
          setActiveMenu={value => setActiveMenu(value)}
        />
      </div>

      <div className="mt-2 ">
        {activeMenu === 'studentsInfo' && <ClassStudentinfo />}
        {activeMenu === 'chapterProgress' && <ClassChapterProgress />}
        {activeMenu === 'exercise' && <ClassExercise />}
        {activeMenu === 'subjectContent' && <ClassSubjectContent />}
        {activeMenu === 'pdfs' && <ClassPdfs />}
      </div>
    </div>
  )
}

export default TeacherClassView
