import React from 'react'
import {useHistory} from 'react-router'
import {Button} from 'semantic-ui-react'
import Auth from '../config/auth'
import routes from '../routes'
import {useUser} from '../context/user-context'
import logo from '../assets/images/logo.png'
import pendingRequest from '../assets/images/pendingRequest.svg'
import confirmedRequest from '../assets/images/confirmedRequest.svg'
import AcceptRequest from './teacher/accept-request'
import PendingRequest from './teacher/pending-request'

const InstructorProfile = () => {
  const history = useHistory()
  const {setUser} = useUser()

  const handleOnClickLogout = () => {
    Auth.logout()
    setUser('')
    history.push(routes.home)
  }
  return (
    <div>
      <AcceptRequest/>
      {/* <PendingRequest/> */}
    </div>
    // <div className="text-4xl">
    //    Instructor Profile
    //   <Button onClick={handleOnClickLogout}>logout</Button>
    // </div>

    // // pending Request
    // <div className=" h-screen flex justify-center items-center py-6 md:py-20">
    //   <div className=" flex flex-col items-center">
    //     <img src={logo} alt="Indil logo" className="w-28 md:w-36 py-4" />

    //     <img src={pendingRequest} alt="Pending Request" className="my-8" />

    //     <p className="text-xl">Your request has been sent to the admin.</p>
    //     <p className="text-xl">
    //       It might take up to 48 hours to receive a response,
    //     </p>

    //     <Button
    // primary
    // className='rounded-md px-16 mt-4'
    //       onClick={() => history.push(routes.home)}
    //     >
    //       {' '}
    //       Back to landing page{' '}
    //     </Button>
    //   </div>
    // </div>

    // accepted request
    // <div className=" h-screen flex justify-center items-center py-6 md:py-20">
    //   <div className=" flex flex-col items-center">
    //     <img src={logo} alt="Indil logo" className="w-28 md:w-36 py-4" />

    //     <img src={confirmedRequest} alt="Pending Request" className="my-8" />

    //     <p className="text-xl">Your request has been accepted.</p>

    //     <Button
    //       primary
    //       className='rounded-md px-16 mt-4'
    //       onClick={() => history.push(routes.instructor.homePage)}
    //     >
    //       {' '}
    //       Go to home page{' '}
    //     </Button>
    //   </div>
    // </div>
  )
}

export default InstructorProfile
