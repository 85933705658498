/* eslint-disable react/display-name, jsx-a11y/click-events-have-key-events */
import {Navigation} from 'react-minimal-side-navigation'
import {Link, useHistory, useLocation} from 'react-router-dom'
import Icon from 'awesome-react-icons'
import React, {useState} from 'react'
import {Icon as IconSemantic} from 'semantic-ui-react'

import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css'

import {AiOutlineLogout} from 'react-icons/ai'
import {RiDashboardLine} from 'react-icons/ri'

import routes from '../../routes'
import Auth from '../../config/auth'
import {useUser} from '../../context/user-context'
import logo from '../../assets/images/logo.png'
import {Dropdown} from 'semantic-ui-react'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'

export const TeacherSidebar = ({isSmall}) => {
  const history = useHistory()
  const location = useLocation()
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const {getUser, setUser} = useUser()

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  const onLogout = () => {
    Auth.logout()
    setUser('')
    history.push(routes.login)
  }

  return (
    <React.Fragment>
      {/* Sidebar Overlay */}
      <div
        onClick={() => setIsSidebarOpen(false)}
        className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${
          isSidebarOpen ? 'block' : 'hidden'
        }`}
      />

      <div className={`${isSmall ? 'flex' : 'hidden'}`}>
        <button
          className="btn-menu "
          onClick={() => setIsSidebarOpen(true)}
          type="button"
        >
          <Icon name="burger" className="w-6 h-6" />
        </button>
      </div>

      {/* Sidebar */}
      <div
        className={`Sidebar fixed  inset-y-0 left-0 z-50 w-64 overflow-y-auto transition duration-300 ease-out h-screen min-h-full bg-gray-200 transform translate-x-0  border-r-2 lg:translate-x-0 lg:static lg:inset-0 ${
          isSidebarOpen ? 'ease-out translate-x-0' : 'ease-in -translate-x-full'
        } flex flex-col`}
      >
        <div className="flex items-center justify-center text-center py-10">
          <Link to="/">
            <img src={logo} alt="Indil logo" className="w-28 md:w-32 py-4" />
          </Link>
        </div>

        {/* https://github.com/abhijithvijayan/react-minimal-side-navigation */}
        <Navigation
          activeItemId={location.pathname}
          onSelect={({itemId}) => {
            history.push(itemId)
          }}
          items={[
            {
              title: `${langContent.Dashboard}`,
              itemId: routes.instructor.homePage,
              elemBefore: () => <RiDashboardLine size="16" />,
            },
          ]}
        />
        <div className="mt-auto pl-8 py-3 hover:bg-gray-100 cursor-pointer">
          <Dropdown
            direction="left"
            trigger={
              <div className="flex flex-row items-center font-semibold">
                <IconSemantic name="world" />
                <span className="capitalize font-semibold mt-2 ml-2">
                  {lang}
                </span>
              </div>
            }
            className="flex items-center w-28 mr-3 md:mr-6 mb-1 "
          >
            <Dropdown.Menu className="-mt-3 border-2">
              <Dropdown.Item
                onClick={() => {
                  setLang('en')
                  window.location.reload()
                }}
                className="text-labelColor  justify-center text-center  hover:bg-gray-100 transition-colors duration-300"
              >
                En
              </Dropdown.Item>
              <hr />
              <Dropdown.Item
                onClick={() => {
                  setLang('fr')
                  window.location.reload()
                }}
                className="text-labelColor  justify-center text-center  hover:bg-gray-100 transition-colors duration-300"
              >
                Fr
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div
          className="flex items-center text-red-600 mt-2 pl-8 py-3 hover:bg-gray-100 cursor-pointer"
          onClick={onLogout}
        >
          <AiOutlineLogout size="16" className="mr-4" />
          {langContent.Logout}
        </div>
      </div>
    </React.Fragment>
  )
}
