import { customerApi } from "./customer.api.service"
import { customerTypes } from "./customer.models";


async function deleteCustomer() {
    let result = false;
    try {
        const apiResult = await customerApi.deleteCurrentCustomer()
        result = apiResult !== customerTypes.undefined;
    } catch (e) {
        result = false;
    }
    return result;
}
export const customerService = {
    deleteCustomer
}

