import React from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {
  Breadcrumb,
  Button,
  Dimmer,
  Loader,
  Message,
  Segment,
  Image,
} from 'semantic-ui-react'
import {useParams} from 'react-router-dom'

import Icon_Visa from '../../../assets/images/Icon_Visa.svg'
import useAsync from '../../../hooks/useAsync'
import {authAxios} from '../../../config/axiosConfig'
import {formatResponseError} from '../../../utils/format-response'
import VoucherChapterCard from '../../../components/student/voucher-chapter-card'
import routes from '../../../routes'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'

const AllChapters = () => {
  const [chapters, setChapters] = React.useState([])

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  const location = useLocation()
  const history = useHistory()

  const {classid, subjectid} = useParams()

  const {
    run: getSubjectChapters,
    error: errorGettingSubjectChapters,
    isLoading: gettingSubjectChapters,
  } = useAsync()

  React.useEffect(() => {
    getSubjectChapters(
      authAxios.get(`/student/chapter/all/?classId=${classid}`),
    ).then(({data}) => {
      console.log(data)
      setChapters(data)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSubjectChapters, location.search])

  return (
    <div className="">
      <Segment className="border-none shadow-none bg-transparent w-full">
        {errorGettingSubjectChapters ? (
          <Message
            error
            list={formatResponseError(errorGettingSubjectChapters)}
          />
        ) : null}

        <Dimmer active={gettingSubjectChapters} inverted>
          <Loader active={true} />
        </Dimmer>
        <div className="flex justify-between align-center">
          <h1 className="text-3xl">{langContent.Chapters} </h1>
        </div>
        <Breadcrumb className="mt-4">
          <Breadcrumb.Section onClick={() => history.push('/')} link>
            {langContent.home}
          </Breadcrumb.Section>
          <Breadcrumb.Divider>/</Breadcrumb.Divider>
          <Breadcrumb.Section
            onClick={() => history.push(routes.voucherStudent.dashboard)}
            link
          >
            {' '}
            {langContent.My_Class}
          </Breadcrumb.Section>
          <Breadcrumb.Divider>/</Breadcrumb.Divider>
          <Breadcrumb.Section active> {langContent.Class} </Breadcrumb.Section>
        </Breadcrumb>

        <div className="mt-12">
          <div className="flex flex-wrap gap-x-5 gap-y-5 items-center justify-start">
            {chapters?.map((a, idx) => (
              <VoucherChapterCard
                key={a?.id}
                name={a?.chapterName}
                brief={a?.brief}
                chapterSrc={a?.image}
                chapterid={a?._id}
                subjectid={subjectid}
                order={a?.order}
                status={a?.status}
                classid={classid}
              />
            ))}
          </div>
        </div>
      </Segment>
    </div>
  )
}

export default AllChapters
