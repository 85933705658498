import React, {useState} from 'react'
import Time from '../utils/time'
import useInterval from './use-interval'
import useLocalStorage from './use-local-storage'

export default function useStopwatch({key, attemptId}) {
  const [isRunning, setIsRunning] = useState(true)

  const [stopWatchTime, setStopWatchTime] = useLocalStorage(
    key,
    {
      seconds: 0,
      startedAt: new Date(),
    },
    JSON.stringify,
    JSON.parse,
  )

  React.useEffect(() => {
    if(attemptId){

      if (window.localStorage.getItem('questAttemptId') !== attemptId) {
        setStopWatchTime({
          seconds: 0,
          startedAt: new Date(),
        })
      }
    }
    }, [attemptId])

  // React.useEffect(() => {
  //   if (isRunning) {

  //     useInterval(() => {
  //       // console.log(Time.getSecondsFromPrevTime(stopWatchTime.startedAt, true))
  //       setStopWatchTime({
  //         ...stopWatchTime,
  //         seconds: Time.getSecondsFromPrevTime(stopWatchTime.startedAt, true),
  //       })
  //     }, 1000)

  //   }
  // }, [isRunning, stopWatchTime, setStopWatchTime])

  useInterval(
    () => {
      // console.log(Time.getSecondsFromPrevTime(stopWatchTime.startedAt, true))
      setStopWatchTime({
        ...stopWatchTime,
        seconds: Time.getSecondsFromPrevTime(stopWatchTime.startedAt, true),
      })
    },
    isRunning ? 1000 : null,
  )

  const reset = () => {
    setStopWatchTime({
      ...stopWatchTime,
      // startedAt: new Date(),
    })
  }

  const stop = () => {
    setIsRunning(false)
    setStopWatchTime({
      seconds: 0,
      // startedAt: new Date(),
    })
  }

  const start = () => {
    setIsRunning(true)
    setStopWatchTime({
      seconds: 0,
      // startedAt: new Date(),
    })
  }

  const pause = () => {
    setIsRunning(false)
  }

  const Resume = () => {
    setIsRunning(true)
  }

  return {
    ...Time.getTimeFromSeconds(stopWatchTime.seconds),
    reset,
    stop,
    start,
    pause,
    Resume,
  }
}

export function useStopwatchOld({autoStart, key, offsetTimestamp}) {
  const [stopWatchTime, setStopWatchTime] = useLocalStorage(
    key,
    {
      seconds: 0,
      passedSeconds: 0,
      prevTime: new Date(),
    },
    JSON.stringify,
    JSON.parse,
  )

  // const [passedSeconds, setPassedSeconds] = useState(stopWatchTime.passedSeconds, 0);
  const [prevTime, setPrevTime] = useState(stopWatchTime.prevTime)
  const [seconds, setSeconds] = useState(
    stopWatchTime.passedSeconds +
      Time.getSecondsFromPrevTime(stopWatchTime.passedSeconds || 0, true),
  )
  const [isRunning, setIsRunning] = useState(true)

  console.log({prevTime, seconds})

  useInterval(
    () => {
      const newPrevTime = new Date()
      console.log(Time.getSecondsFromPrevTime(stopWatchTime.prevTime, true))
      setStopWatchTime({
        ...stopWatchTime,
        prevTime: newPrevTime,
        seconds:
          stopWatchTime.passedSeconds +
          Time.getSecondsFromPrevTime(newPrevTime, true),
      })
    },
    isRunning ? 1000 : null,
  )

  React.useEffect(() => {
    setSeconds(stopWatchTime.seconds)
    setPrevTime(stopWatchTime.prevTime)
    // setPassedSeconds(stopWatchTime.passedSeconds)
  }, [stopWatchTime])

  function start() {
    const newPrevTime = new Date()
    console.log({newPrevTime})
    setStopWatchTime({
      ...stopWatchTime,
      prevTime: newPrevTime,
      // seconds: passedSeconds + Time.getSecondsFromPrevTime(newPrevTime, true)
    })

    // setPrevTime(newPrevTime);
    // setIsRunning(true);
    // setSeconds(passedSeconds + Time.getSecondsFromPrevTime(newPrevTime, true));
  }

  function pause() {
    // setPassedSeconds(seconds);
    setIsRunning(false)
  }

  function stop() {
    // setPassedSeconds(seconds);
    setIsRunning(false)
  }

  function reset(offset = 0, newAutoStart = true) {
    const newPassedSeconds = Time.getSecondsFromExpiry(offset, true) || 0
    const newPrevTime = new Date()
    setPrevTime(newPrevTime)
    // setPassedSeconds(newPassedSeconds);
    setIsRunning(newAutoStart)
    setSeconds(
      newPassedSeconds + Time.getSecondsFromPrevTime(newPrevTime, true),
    )
  }

  return {
    ...Time.getTimeFromSeconds(seconds),
    start,
    pause,
    reset,
    isRunning,
  }
}
