import React from 'react'
import {Route, Switch, useLocation} from 'react-router-dom'

import StudentNonVoucherRoute from '../components/shared/student-non-voucher-route'
import {StudentNonVoucherSidebar} from '../components/shared/student-nonVoucher-sidbar'
import useMediaQuery from '../hooks/use-media-query'
import ErrorComp from '../pages/error/error'
import AllChaptersNonVoucher from '../pages/non-voucher-student/chapter/all-chapters-non-voucher'
import NonVoucherChapterContent from '../pages/non-voucher-student/chapter/non-voucher-chapter-content'
import NonVoucherSectionPex from '../pages/non-voucher-student/exercise/pex/non-voucher-section-pex'
import NonVoucherSectionPexLock from '../pages/non-voucher-student/exercise/pex/non-voucher-section-pex-lock'
import NonVoucherTest from '../pages/non-voucher-student/exercise/testExercise/non-voucher-test'
import NonVoucherTestLock from '../pages/non-voucher-student/exercise/testExercise/non-voucher-test-lock'
import NonVoucherWex from '../pages/non-voucher-student/exercise/wex/non-voucher-wex'
import NonVoucherWexLock from '../pages/non-voucher-student/exercise/wex/non-voucher-wex-lock'
import NonVoucherStudentProfile from '../pages/non-voucher-student/non-voucher-student-profile'
import NonVoucherStudentRoom from '../pages/non-voucher-student/NonVoucherStudentRoom/non-voucher-student-room'
import Checkout from '../pages/non-voucher-student/subscribe/checkout'
import Enrollment from '../pages/non-voucher-student/subscribe/enrollment'
import SubscribePlanes from '../pages/non-voucher-student/subscribe/subscribe-planes'
import StudentNonVoucherDashboard from '../pages/student-nonVoucher-dashboard'
import routes from '../routes'

const StudentNonVoucherLayout = () => {
  const isSmall = useMediaQuery('(max-width: 1023px)')
  const {pathname} = useLocation()
  let isTestRoute = Boolean(pathname.match(/(\/wex\/|\/pex\/|\/test\/)/gi))

  React.useEffect(() => {
    document.body.classList.add('bg-gray-50')
    return () => document.body.classList.remove('bg-gray-50')
  })
  return (
    <div className="flex relative">
      <div>
        <StudentNonVoucherSidebar isSmall={isSmall} />
      </div>

      <div
        className={`${
          isSmall ? 'pl-0' : 'ml-64 md:px-4 xl:px-10'
        } mx-auto mt-10 w-full ${isTestRoute ? 'overflow-x-auto' : ''}`}
      >
        <Switch>
          <StudentNonVoucherRoute
            path={routes.nonVoucherStudent.dashboard}
            component={StudentNonVoucherDashboard}
          />

          <StudentNonVoucherRoute
            path={routes.nonVoucherStudent.profile}
            component={NonVoucherStudentProfile}
          />

          {/* Student Room */}
          <StudentNonVoucherRoute
            path={routes.nonVoucherStudent.room.studentRoom}
            component={NonVoucherStudentRoom}
          />

          {/* Chapter Section */}
          <StudentNonVoucherRoute
            path={routes.nonVoucherStudent.chapters.all()}
            component={AllChaptersNonVoucher}
            exact
          />

          <StudentNonVoucherRoute
            path={routes.nonVoucherStudent.chapters.content()}
            component={NonVoucherChapterContent}
            exact
          />
          {/* <StudentNonVoucherRoute
            path={routes.nonVoucherStudent.chapters.section.video()}
            component={SectionVideo}
            exact
          /> */}

          {/* subscribe planes  */}
          <StudentNonVoucherRoute
            path={routes.nonVoucherStudent.chapters.subscribe.pLanes()}
            component={SubscribePlanes}
            exact
          />

          <StudentNonVoucherRoute
            path={routes.nonVoucherStudent.chapters.subscribe.checkout()}
            component={Checkout}
            exact
          />
          <StudentNonVoucherRoute
            path={routes.nonVoucherStudent.enrollment}
            component={Enrollment}
            exact
          />

          {/* exercise section (correct) */}
          <StudentNonVoucherRoute
            path={routes.nonVoucherStudent.chapters.section.pexlock()}
            component={NonVoucherSectionPexLock}
            exact
          />
          <StudentNonVoucherRoute
            path={routes.nonVoucherStudent.chapters.section.pex()}
            component={NonVoucherSectionPex}
            exact
          />
          <StudentNonVoucherRoute
            path={routes.nonVoucherStudent.chapters.exercise.wexlock()}
            component={NonVoucherWexLock}
            exact
          />
          <StudentNonVoucherRoute
            path={routes.nonVoucherStudent.chapters.exercise.wex()}
            component={NonVoucherWex}
            exact
          />

          <StudentNonVoucherRoute
            path={routes.nonVoucherStudent.chapters.exercise.testlock()}
            component={NonVoucherTestLock}
            exact
          />
          <StudentNonVoucherRoute
            path={routes.nonVoucherStudent.chapters.exercise.test()}
            component={NonVoucherTest}
            exact
          />

          <Route path="*" component={ErrorComp} />
        </Switch>
      </div>
    </div>
  )
}

export default StudentNonVoucherLayout
