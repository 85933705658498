import React, {useCallback} from 'react'
import {Link} from 'react-router-dom'
import {
  Button,
  Dimmer,
  Dropdown,
  Loader,
  Message,
  Modal,
  Segment,
} from 'semantic-ui-react'
import {useParams} from 'react-router-dom'
import {useLocation} from 'react-router-dom/cjs/react-router-dom.min'
import ReactPlayer from 'react-player'
import useAsync from '../../../hooks/useAsync'
import { authAxios } from '../../../config/axiosConfig'
import { formatResponseError } from '../../../utils/format-response'
import NonVoucherSectionCard from './nonVoucher-section-card'


// for get all sections
const NonVoucherPracticeRoom = () => {
  const [sections, setSections] = React.useState([])
  const [activeIndex, setActiveIndex] = React.useState(-1)

  const [watchingVideo, setwatchingVideo] = React.useState(false)
  const [videoId, setVideoId] = React.useState()

  const location = useLocation()

  const {subjectid, chapterid } = useParams()

  const {
    run: getSections,
    error: errorGettingSections,
    isLoading: gettingSections,
  } = useAsync()

  const {
    run: submitSectionsOrder,
    error: errorGettingSectionsOrder,
    isLoading: gettingSectionsOrder,
  } = useAsync()

  React.useEffect(() => {
    getSections(
      authAxios.get(
        `/user/chapter/view/section/?chapter=${chapterid}&subject=${subjectid}`,
      ),
    ).then(({data}) => {
      console.log({sectionData: data})
      setSections(data)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSections, location.search])

  const handleClickAccordion = (e, titleProps) => {
    const {index} = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent w-full">
        {errorGettingSections ? (
          <Message error list={formatResponseError(errorGettingSections)} />
        ) : null}
        {errorGettingSectionsOrder ? (
          <Message
            error
            list={formatResponseError(errorGettingSectionsOrder)}
          />
        ) : null}
        <Dimmer active={gettingSections || gettingSectionsOrder} inverted>
          <Loader active={true} />
        </Dimmer>
        <div className="flex justify-between align-center">
          <h1 className="text-3xl">Sections </h1>
          <div></div>
        </div>

        <div className="mt-12">
          <div className="flex flex-wrap gap-x-5 gap-y-1 items-center justify-start">
            {sections?.map((a, idx) => (
              <NonVoucherSectionCard
                activeIndex={activeIndex}
                index={idx}
                key={a._id}
                pexid={a._id}
                name={a?.name}
                description={a?.description}
                sectionid={a?._id}
                chapterid={chapterid}
                subjectid={subjectid}
                order={a?.order}
                competency={a?.competency}
                pexs={a?.pexs}
                videoLink={a?.videoLink}
                videoTitle={a?.videoTitle}
                clickAccordion={handleClickAccordion}
                setwatchingVideo={setwatchingVideo}
                isSubscriber={a?.isSubscriber}
                setVideoId={setVideoId}
              />
            ))}
          </div>
        </div>

        <VideoModal
          videoId={videoId}
          open={watchingVideo}
          onClose={() => setwatchingVideo(false)}
        />
      </Segment>
    </div>
  )
}

export default NonVoucherPracticeRoom

const VideoModal = ({open, onClose, videoId}) => {
  const [section, setSection] = React.useState()

  const {
    run: getchapter,
    error: errorGettingchapter,
    isLoading: gettingchapter,
  } = useAsync()

  React.useEffect(() => {
    if (videoId)
      getchapter(authAxios.get(`/chapter/section/${videoId}`)).then(
        ({data}) => {
          setSection(data)
          console.log(data)
        },
      )
  }, [getchapter, videoId])
  return (
    <Segment className="border-none shadow-none bg-transparent">
      {errorGettingchapter ? (
        <Message error list={formatResponseError(errorGettingchapter)} />
      ) : null}

      <Modal
        open={open}
        onClose={onClose}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        closeIcon
        size="small"
        className="animate__animated animate__fadeInDown"
      >
        <Dimmer active={gettingchapter} inverted>
          <Loader active={true} />
        </Dimmer>

        <Modal.Header className="text-center bg-indigo-50 rounded-t-lg">
          {section?.videoTitle}
        </Modal.Header>
        <Modal.Content className="flex justify-center items-center bg-blue-50">
          {/* <Embed
       icon="play circle"
       placeholder="https://knetic.org.uk/wp-content/uploads/2020/07/Video-Placeholder.png"
       url="https://www.myfav.es/jack"
       source="https://www.youtube.com/watch?v=egn0FkBeAlA"
     /> */}
          <ReactPlayer
            url={section?.videoLink}
            width={650}
            height={400}
            controls
          />
        </Modal.Content>
      </Modal>
    </Segment>
  )
}
