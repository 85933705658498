import React, {useState} from 'react'
import {useHistory} from 'react-router'
import {
  Breadcrumb,
  Button,
  Dimmer,
  Dropdown,
  Form,
  Image,
  Loader,
  Message,
  Modal,
  Segment,
} from 'semantic-ui-react'
import routes from '../routes'
import useAsync from '../hooks/useAsync'
import {authAxios} from '../config/axiosConfig'
import {Link, useLocation} from 'react-router-dom'
import {formatResponseError} from '../utils/format-response'
import AvatarDropdown from '../components/shared/avatar-dropdown'
import {useUser} from '../context/user-context'
import StudentNonVoucherSubjectCard from '../components/shared/student-nonVoucher-subject-card'
import {truncate} from '../utils/string'

const StudentNonVoucherDashboard = () => {
  const {getUser} = useUser()

  const location = useLocation()
  const history = useHistory()

  const [state] = React.useState(location?.state)

  const [subjects, setSubjects] = React.useState([])
  const [subscribedSubjects, setSubscribedSubjects] = React.useState([])

  const {
    run: getSubjects,
    error: errorGettingSubjects,
    isLoading: gettingSubjects,
  } = useAsync()

  const {
    run: getSubscribedSubjects,
    error: errorGettingSubscribedSubjects,
    isLoading: gettingSubscribedSubjects,
  } = useAsync()

  React.useEffect(() => {
    getSubjects(authAxios.get('/user/subject')).then(({data}) => {
      console.log(data)
      setSubjects(data)
    })
  }, [getSubjects])

  React.useEffect(() => {
    getSubscribedSubjects(authAxios.get('/user/subscribe')).then(({data}) => {
      console.log(data)
      setSubscribedSubjects(data)
    })
  }, [getSubscribedSubjects])

  return (
    <div className="relative h-screen px-2 md:px-10">
      <Segment className="border-none shadow-none bg-transparent w-full">
        {/* <AvatarDropdown /> */}
        {errorGettingSubjects ? (
          <Message error list={formatResponseError(errorGettingSubjects)} />
        ) : null}

        {errorGettingSubscribedSubjects ? (
          <Message error list={formatResponseError(errorGettingSubscribedSubjects)} />
        ) : null}

        <Dimmer
          active={gettingSubjects || gettingSubscribedSubjects }
          inverted
        >
          <Loader active={true} inverted />
        </Dimmer>
        <div className="flex flex-wrap-reverse justify-center sm:justify-between align-center items-center w-full ">
          <h3 className="text-primary animate__animated animate__fadeInUp ">
            Welcome {getUser()?.name}
          </h3>

          <AvatarDropdown />
        </div>
        <div className="flex justify-between  mt-8">
          <h3 className="">Subjects</h3>
          {/* <Breadcrumb className="mt-4">
            <Breadcrumb.Section onClick={() => history.push('/')} link>
              Home
            </Breadcrumb.Section>
            <Breadcrumb.Divider>/</Breadcrumb.Divider>
            <Breadcrumb.Section active> My classes </Breadcrumb.Section>
          </Breadcrumb> */}
        </div>
        {/* <div className="mt-4 bg-primaryLight p-5 rounded-md border border-solid border-primary">
              <p className='text-xl px-2'>You're within your free trial</p>
        </div> */}

        <p className="mt-12 mb-4 text-gray-500">My Subjects</p>
        <div className="flex flex-wrap gap-x-4 gap-y-4  items-center justify-start mb-10">
          {subscribedSubjects?.map((a, idx) => (
            <div className="relative rounded-md w-72 h-56 shadow-md hover:shadow-xl hover:opacity-95 flex flex-col border solid border-gray-300 animate__animated animate__fadeInUp">
              <Link to={routes.nonVoucherStudent.chapters.all(a?.subject?._id)}>
                <Image
                  className="w-full h-40 rounded-t-md bg-indigo-50"
                  src={a?.subject?.image}
                  alt="no image provided"
                />
              </Link>

              <p className="bg-primarySemiLight px-4 text-sm rounded-2xl absolute top-2 right-3">
                Enrolled
              </p>

              <div className="flex justify-between my-3 px-3">
                <Link to={routes.nonVoucherStudent.chapters.all(a?._id)}>
                  <p className="text-primary font-semibold cursor-pointer overflow-y-auto">
                    {truncate(a?.subject?.name, 25)}
                  </p>
                </Link>
              </div>
            </div>
          ))}
        </div>

        <hr className="" />

        <p className="mt-8 mb-4 text-gray-500">Explore</p>
        <div className="flex flex-wrap gap-x-4 gap-y-4  items-center justify-start mb-10">
          {subjects?.map((a, idx) => (
            <StudentNonVoucherSubjectCard
              subjectSrc={a?.image}
              name={a?.name}
              id={a?._id}
            />
          ))}
        </div>
      </Segment>
    </div>
  )
}

export default StudentNonVoucherDashboard
