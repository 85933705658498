import React from 'react'
import { useParams } from 'react-router-dom'
import { Dimmer, Loader, Message, Segment } from 'semantic-ui-react'
import PdfCard from '../../../components/shared/pdf-card'
import { authAxios } from '../../../config/axiosConfig'
import useAsync from '../../../hooks/useAsync'
import { formatResponseError } from '../../../utils/format-response'

const TeacherPdf = () => {

  const [contentPdfsData, setContentPdfsData] = React.useState([])

  const {chapterid, classid, subjectid} = useParams()

  const {
    run: getContentPdfs,
    error: errorGettingContentPdfs,
    isLoading: gettingContentPdfs,
  } = useAsync()
  React.useEffect(() => {
    getContentPdfs(
      authAxios.get(`/school/subject/pdf/all?sid=${subjectid}`),
    ).then(({data}) => {
      console.log(data)
      setContentPdfsData(data.docs)
    })
  }, [getContentPdfs, subjectid])

  return (
    <div>
      <Segment className="border-none shadow-none  bg-transparent h-screen relative mb-10">

        {errorGettingContentPdfs ? (
          <Message error list={formatResponseError(errorGettingContentPdfs)} />
        ) : null}

        <Dimmer active={gettingContentPdfs} inverted>
          <Loader active={true} />
        </Dimmer>

        <h3 className='mt-12 mb-6'>Content pdfs : </h3>
        <div className="flex flex-wrap gap-x-5 gap-y-5 items-center justify-start">
          {contentPdfsData?.map((a, idx) => (
            <PdfCard key={a?._id} title={a?.title} pdfLink={a?.link} />
          ))}
        </div>
      </Segment>
    </div>
  )
}

export default TeacherPdf