import React from 'react'
import {Card, Icon, Image} from 'semantic-ui-react'

const TestimonialsCard = ({TeacherName, src , Testimonials_p}) => {
  return (
    <div className="w-96">
      <div className="p-0">

        {/* <Image className="justify-center h-80 w-72 mx-auto" alt="no img" src={src} /> */}
        <Image
            className=" rounded-full mx-auto h-20 w-20"
            src={src}
            size="tiny"
            circular
            rounded
          />

        <div>
          <div className="p-2 mb-2 flex flex-col text-center">
            <p className="font-medium my-5">
            {Testimonials_p}
            </p>
            <p className="text-xl font-semibold">{TeacherName}</p>
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default TestimonialsCard
