import React, {useState} from 'react'
import {
  Dimmer,
  Dropdown,
  Loader,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react'
import queryString from 'query-string'
import {useHistory, useLocation} from 'react-router-dom'
import useAsync from '../../../hooks/useAsync'
import {authAxios} from '../../../config/axiosConfig'
import {truncate} from '../../../utils/string'
import {useUser} from '../../../context/user-context'
import {formatResponseError} from '../../../utils/format-response'
import { useLanguage } from '../../../context/languageContext'
import { content } from '../../../localization/content'

const StudentExercises = () => {
  const location = useLocation()
  const history = useHistory()
  const {getUser} = useUser()

  const [studentInfoDetails, setStudentInfoDetails] = React.useState([])
  const [userData, setUserData] = useState({})

  const [currentChapter, setCurrentChapter] = React.useState()
  const [currentCLass, setCurrentCLass] = React.useState()

  const [chapters, setChapters] = React.useState([])
  const [classes, setClasses] = React.useState([])

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]


  const {
    run: getChapters,
    error: errorGettingChapters,
    isLoading: gettingChapters,
  } = useAsync()

  const {
    run: getClasses,
    error: errorGettingClasses,
    isLoading: gettingClasses,
  } = useAsync()

  const {
    run: getExerciseInfo,
    error: errorGettingExerciseInfo,
    isLoading: gettingExerciseInfo,
  } = useAsync()

  React.useEffect(() => {
    const {currentChapterid, currentClassid} = queryString.parse(
      location.search,
    )
    setCurrentCLass(currentClassid)

    if (currentChapterid && currentChapterid !== 'undefined') {
      setCurrentChapter(currentChapterid)
    }

    if (currentChapterid && currentClassid && userData?._id && currentChapterid !== 'undefined' && currentClassid !== 'undefined') {
      getExerciseInfo(
        authAxios.get(
          `/student/chapter/exercise/info/${userData?._id}?class=${currentClassid}&chapter=${currentChapterid}`,
        ),
      ).then(({data}) => {
        console.log(data)
        setStudentInfoDetails(data)
      })
    }
  }, [getExerciseInfo, location.pathname, location.search, userData?._id])

  const getChaptersOptions = classId => {
    setStudentInfoDetails([])
    if(classId && classId !== 'undefined')
    getChapters(authAxios.get(`/student/chapter/all/?classId=${classId}`)).then(
      ({data}) => {
        let options = []
        data?.forEach(s =>
          options.push({text: s.chapterName, key: s._id, value: s._id}),
        )
        setChapters(options)
        setCurrentChapter(options[0]?.value)
        history.replace(
          `${location.pathname}?currentChapterid=${options[0]?.value}&currentClassid=${classId}`,
        )
      },
    )
  }

  React.useEffect(() => {
    getClasses(authAxios.get(`/student/class/all`)).then(({data}) => {
      console.log({data})
      let arr = []
      data?.forEach(s =>
        arr.push({
          text: truncate(s?.name, 20),
          key: s?._id,
          value: s?._id,
        }),
      )
      setClasses(arr)
      setCurrentCLass(arr[0]?.value)
      getChaptersOptions(arr[0]?.value)
      history.replace(`${location.pathname}?currentClassid=${arr[0]?.value}`)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (!getUser()) return

    setUserData(getUser())
    // return () => setUserData(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUser()?.email, userData?.email])

  const onClassChange = (e, {value}) => {
    getChaptersOptions(value)

    setStudentInfoDetails([])
    setCurrentCLass(value)

    history.replace(`${location.pathname}?currentClassid=${value}`)
  }

  const onChapterChange = (e, {value}) => {
    setCurrentChapter(value)
    history.replace(
      `${location.pathname}?currentChapterid=${value}&currentClassid=${currentCLass}`,
    )
  }

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent flex flex-col">
        {errorGettingChapters ? (
          <Message error list={formatResponseError(errorGettingChapters)} />
        ) : null}
        {errorGettingClasses ? (
          <Message error list={formatResponseError(errorGettingClasses)} />
        ) : null}
        {errorGettingExerciseInfo ? (
          <Message error list={formatResponseError(errorGettingExerciseInfo)} />
        ) : null}
        <Dimmer
          active={gettingClasses || gettingExerciseInfo || gettingChapters}
          inverted
        >
          <Loader active={true} />
        </Dimmer>

        <div className="flex">
          <Dropdown
            placeholder="Select a Class"
            search
            selection
            value={currentCLass}
            loading={gettingClasses}
            options={classes}
            onChange={onClassChange}
            className="drop-shadow-md shadow-md ml-auto"
          />
          <Dropdown
            placeholder="Select a chapter"
            search
            selection
            value={currentChapter}
            loading={gettingChapters}
            options={chapters}
            onChange={onChapterChange}
            disabled={gettingChapters || !currentCLass}
            className=" drop-shadow-md shadow-md  mx-2"
          />
        </div>

        <div className="bg-transparent shadow-none border-none">
          <div className="bg-gray-100 p-4 rounded-md">
            <div className="flex">
              <Table.Cell
                selectable={false}
                className="border solid border-gray-300 bg-primaryLight rounded-md mr-2 flex-1 shadow-none p-2"
              >
                <div>
                  <p className="text-center text-primary font-semibold">
                    {langContent.PEXs_completed}
                  </p>

                  <Table celled basic="very" collapsing size="small" unstackable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={2}>{langContent.Name}</Table.HeaderCell>
                        <Table.HeaderCell width={2}>{langContent.Score}</Table.HeaderCell>
                        <Table.HeaderCell width={2}>{langContent.Duration}</Table.HeaderCell>
                        <Table.HeaderCell width={2}>{langContent.Hints}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {studentInfoDetails?.pexs?.map(c => (
                        <Table.Row>
                          <Table.Cell>{c?.pex}</Table.Cell>
                          <Table.Cell>
                            {c?.message ? (
                              <p>{c?.message}</p>
                            ) : (
                              <p>{c?.score?.toFixed(0)}%</p>
                            )}
                          </Table.Cell>

                          <Table.Cell>
                            {!c?.message && (
                              <p>
                                {parseInt(c?.duration?.hours)} : {parseInt(c?.duration?.minutes)} :{' '}
                                {parseInt(c?.duration?.seconds)}
                              </p>
                            )}
                          </Table.Cell>
                          <Table.Cell>{c?.hint}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              </Table.Cell>

              {/* <div class="border-l-2 m-2 h-40 border-gray-300 p-0 w-5 place-self-center "/> */}

              <Table.Cell
                selectable={false}
                className="border solid border-gray-300 bg-primaryLight rounded-md  flex-1 shadow-none p-2"
              >
                <p className="text-center text-primary font-semibold">
                  {langContent.WEX_completed}
                </p>
                <Table celled basic="very" size="small" unstackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={2}>{langContent.Name}</Table.HeaderCell>
                      <Table.HeaderCell width={2}>{langContent.Level}</Table.HeaderCell>
                      <Table.HeaderCell width={2}>{langContent.Score}</Table.HeaderCell>
                      <Table.HeaderCell width={2}>{langContent.Duration}</Table.HeaderCell>
                      <Table.HeaderCell width={2}>{langContent.Hints}</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {studentInfoDetails?.wexs?.beginner?.map(c => (
                      <Table.Row>
                        <Table.Cell>{c?.wex}</Table.Cell>
                        <Table.Cell>{langContent.beginner}</Table.Cell>
                        <Table.Cell>
                          {c?.message ? (
                            <p>{c?.message}</p>
                          ) : (
                            <p>{c?.score?.toFixed(0)}%</p>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {!c?.message && (
                            <p>
                               {parseInt(c?.duration?.hours)} : {parseInt(c?.duration?.minutes)} :{' '}
                                {parseInt(c?.duration?.seconds)}
                            </p>
                          )}
                        </Table.Cell>
                        <Table.Cell>{c?.hint}</Table.Cell>
                      </Table.Row>
                    ))}
                    {studentInfoDetails?.wexs?.intermidate?.map(c => (
                      <Table.Row>
                        <Table.Cell>{c?.wex}</Table.Cell>
                        <Table.Cell>{langContent.intermediate}</Table.Cell>
                        <Table.Cell>
                          {c?.message ? (
                            <p>{c?.message}</p>
                          ) : (
                            <p>{c?.score?.toFixed(0)}%</p>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {!c?.message && (
                            <p>
                               {parseInt(c?.duration?.hours)} : {parseInt(c?.duration?.minutes)} :{' '}
                                {parseInt(c?.duration?.seconds)}
                            </p>
                          )}
                        </Table.Cell>
                        <Table.Cell>{c?.hint}</Table.Cell>
                      </Table.Row>
                    ))}
                    {studentInfoDetails?.wexs?.advanced?.map(c => (
                      <Table.Row>
                        <Table.Cell>{c?.wex}</Table.Cell>
                        <Table.Cell>{langContent.advanced}</Table.Cell>
                        <Table.Cell>
                          {c?.message ? (
                            <p>{c?.message}</p>
                          ) : (
                            <p>{c?.score?.toFixed(0)}%</p>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {!c?.message && (
                            <p>
                               {parseInt(c?.duration?.hours)} : {parseInt(c?.duration?.minutes)} :{' '}
                                {parseInt(c?.duration?.seconds)}
                            </p>
                          )}
                        </Table.Cell>
                        <Table.Cell>{c?.hint}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Table.Cell>

              <Table.Cell
                selectable={false}
                className="border solid border-gray-300 bg-primaryLight rounded-md ml-2 flex-1 shadow-none p-2"
              >
                <p className="text-center text-primary font-semibold">
                  {langContent.Tests_completed}
                </p>
                <Table celled basic="very" size="small" unstackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={2}>{langContent.Name}</Table.HeaderCell>
                      <Table.HeaderCell width={2}>{langContent.Level}</Table.HeaderCell>
                      <Table.HeaderCell width={2}>{langContent.Score}</Table.HeaderCell>
                      <Table.HeaderCell width={2}>{langContent.Duration}</Table.HeaderCell>
                      <Table.HeaderCell width={2}>{langContent.Hints}</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {studentInfoDetails?.tests?.beginner?.map(c => (
                      <Table.Row>
                        <Table.Cell>{c?.test}</Table.Cell>
                        <Table.Cell>{langContent.beginner}</Table.Cell>
                        <Table.Cell>
                          {c?.message ? (
                            <p>{c?.message}</p>
                          ) : (
                            <p>{c?.score?.toFixed(0)}%</p>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {!c?.message && (
                            <p>
                               {parseInt(c?.duration?.hours)} : {parseInt(c?.duration?.minutes)} :{' '}
                                {parseInt(c?.duration?.seconds)}
                            </p>
                          )}
                        </Table.Cell>
                        <Table.Cell>{c?.hint}</Table.Cell>
                      </Table.Row>
                    ))}
                    {studentInfoDetails?.tests?.intermidate?.map(c => (
                      <Table.Row>
                        <Table.Cell>{c?.test}</Table.Cell>
                        <Table.Cell>{langContent.intermediate}</Table.Cell>
                        <Table.Cell>
                          {c?.message ? (
                            <p>{c?.message}</p>
                          ) : (
                            <p>{c?.score?.toFixed(0)}%</p>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {!c?.message && (
                            <p>
                               {parseInt(c?.duration?.hours)} : {parseInt(c?.duration?.minutes)} :{' '}
                                {parseInt(c?.duration?.seconds)}
                            </p>
                          )}
                        </Table.Cell>
                        <Table.Cell>{c?.hint}</Table.Cell>
                      </Table.Row>
                    ))}
                    {studentInfoDetails?.tests?.advanced?.map(c => (
                      <Table.Row>
                        <Table.Cell>{c?.test}</Table.Cell>
                        <Table.Cell>{langContent.advanced}</Table.Cell>
                        <Table.Cell>
                          {c?.message ? (
                            <p>{c?.message}</p>
                          ) : (
                            <p>{c?.score?.toFixed(0)}%</p>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {!c?.message && (
                            <p>
                               {parseInt(c?.duration?.hours)} : {parseInt(c?.duration?.minutes)} :{' '}
                                {parseInt(c?.duration?.seconds)}
                            </p>
                          )}
                        </Table.Cell>
                        <Table.Cell>{c?.hint}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Table.Cell>
            </div>
          </div>
        </div>
      </Segment>
    </div>
  )
}

export default StudentExercises
