import { FieldArray, Formik } from 'formik'
import React from 'react'
import { BsLightbulb } from 'react-icons/bs'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import {
  Button,
  Dimmer,
  Form,
  Icon,
  Image,
  Label,
  Loader,
  Message,
  Popup,
  Segment,
  Table,
} from 'semantic-ui-react'

import FormikDate from '../../../../components/formik/FormikDate'
import FormikDropdown from '../../../../components/formik/FormikDropdown'
import FormikInput from '../../../../components/formik/FormikInput'
import { authAxios } from '../../../../config/axiosConfig'
import { useLanguage } from '../../../../context/languageContext'
import useAsync from '../../../../hooks/useAsync'
import { content } from '../../../../localization/content'
import { formatResponseError } from '../../../../utils/format-response'
import Time from '../../../../utils/time'
import FeedbackMessage from '../feedback-message'

const Amortization = ({
  examType,
  data,
  setQuestionSuccess,
  setExamSuccess,
  setExamSuccessScore,
}) => {
  const [hintData, setHintData] = React.useState()
  const [uncompeletAnswer, setUncompeletAnswer] = React.useState(false)
  const [popupOpen, setPopupOpen] = React.useState(false)
  const [feedback, setFeedback] = React.useState()
  const { addToast } = useToasts()

  const [assets, setAssets] = React.useState([])

  const { classid, subjectid, chapterid, sectionid, pexid, exerciseAttempt } =
    useParams()
  const history = useHistory()

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  const {
    run: getAssets,
    error: errorGettingAssets,
    isLoading: gettingAssets,
  } = useAsync()

  const {
    run: getHint,
    error: errorGettingHint,
    isLoading: gettingHint,
  } = useAsync()

  const {
    run: submitQuestion,
    error: errorSubmittingQuestion,
    isLoading: submittingQuestion,
  } = useAsync()

  React.useEffect(() => {
    getAssets(authAxios.get('/question/amortization/fixedasset')).then(
      ({ data }) => {
        let options = []
        data?.forEach(s =>
          options.push({ text: s.name, key: s._id, value: s._id }),
        )
        setAssets(options)
      },
    )
  }, [data, getAssets, data?.attemptId])

  function onSubmit(values, { resetForm }) {
    let timer = JSON.parse(
      window.localStorage.getItem(
        `questionId-${data?._id}-attemptId-${data?.attemptId}`,
      ),
    )
    const timeData = Time.getTimeFromSeconds(timer.seconds)

    let obj = {
      ...values,
      time: {
        hours: timeData?.hours,
        minutes: timeData?.minutes,
        seconds: timeData?.seconds,
      },
      attemptId: data?.attemptId,
      questionId: data?._id,
    }

    submitQuestion(
      authAxios.post(
        `/correct/exercise/${exerciseAttempt}?qtype=amortization`,
        obj,
      ),
    ).then(({ data }) => {
      if (examType === 'test') {
        resetForm()
        setFeedback(null)
        setQuestionSuccess(p => !p)
        setUncompeletAnswer(false)
        setHintData(null)
        return
      }
      if (data?.examSuccessfull && data?.success) {
        setExamSuccess(true)
        setExamSuccessScore(data?.score)
      }
      if (!data?.success) {
        setUncompeletAnswer(true)
        setFeedback(data)
      } else {
        resetForm()
        setFeedback(null)
        setQuestionSuccess(p => !p)
        addToast(langContent.Correct_answer, { appearance: 'success' })
        setUncompeletAnswer(false)
        setHintData(null)
      }
    })
  }

  const reqHint = () => {
    if (hintData) {
      setPopupOpen(prevOpen => !prevOpen)
    } else {
      getHint(
        authAxios.get(
          `/correct/exercise/question/hint/${data?.attemptId}?attempt=${exerciseAttempt}`,
        ),
      ).then(({ data }) => {
        setPopupOpen(true)
        console.log(data)
        setHintData(data)
      })
    }
  }

  return (
    <div style={{ marginTop: "2.5rem" }}>
      <Segment className="border-none shadow-none bg-transparent" style={{ paddingBottom: "0px" }}>
        {errorGettingAssets ? (
          <Message error list={formatResponseError(errorGettingAssets)} />
        ) : null}

        {uncompeletAnswer ? (
          <Message error content={langContent.Incomplete_answer} />
        ) : null}

        {errorSubmittingQuestion ? (
          <Message error list={formatResponseError(errorSubmittingQuestion)} />
        ) : null}

        <Dimmer active={gettingAssets || submittingQuestion} inverted>
        </Dimmer>
        <div>
          <p className="text-primary text-xl">Instructions:</p>
          <p className="break-all">{data?.exerciseInstructions}</p>
        </div>
        <div>
          <div className="flex my-4">
            <Popup
              disabled={!hintData}
              content={hintData}
              open={popupOpen}
              position="bottom right"
              className="border border-primaryYellow shadow-none break-all mb-4 "
              trigger={
                <Button
                  loading={gettingHint}
                  className={`${popupOpen
                    ? 'bg-white border border-solid  flex items-center ml-auto justify-center text-primaryYellow border-primaryYellow'
                    : 'bg-primaryYellow flex items-center justify-center text-white ml-auto'
                    }`}
                  onClick={() => reqHint()}
                >
                  {langContent.hint}
                  <BsLightbulb className="-mr-2 ml-2 -mt-0.5" />
                </Button>
              }
            />
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="flex-1 flex-col custom-min-width">
            <p className="text-primary font-semibold mb-4">
              {data?.questionTitle}
            </p>
            <p>{data?.question}</p>
          </div>
          {data?.image && (
            <div className="flex-1 flex justify-center custom-min-width">
              <Image
                size="medium"
                className=""
                src={data?.image}
                rounded
                as="a"
                href={data?.image}
                target="_blank"
              />
            </div>
          )}
        </div>

        <Formik
          initialValues={{
            fixedAsset: '',
            originalValue: '',
            depreciationRate: '',
            date: '',
            answers: [
              {
                year: '',
                netValueBegin: '',
                netValueEnd: '',
                lossValue: '',
                accumulatedLoss: '',
              },
            ],
          }}
          onSubmit={onSubmit}
        >
          {formik => (
            <Form
              autocomplete="off"
              loading={submittingQuestion}
            >
              <div className="my-4 py-8">
                <h3 className="mb-5">Amortization Table</h3>
                <Table
                  celled
                  structured
                  className="shadow-sm max-w-4xl"
                  size="small"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell colSpan="2">
                        {langContent.Fixed_Assets}
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan="1">
                        <div className="flex  items-center">
                          {/* dropdown for assets */}
                          <FormikDropdown
                            loading={gettingAssets}
                            name="fixedAsset"
                            placeholder={langContent.Fixed_Assets}
                            options={assets}
                            className="w-full"
                          />

                          {feedback?.fixedAsssetFeedback && (
                            <Popup
                              trigger={
                                <Icon
                                  name="warning"
                                  className=" ml-2 -mt-1"
                                  color="red"
                                  circular
                                />
                              }
                              content={feedback?.fixedAsssetFeedback}
                              className="border border-red-600 text-red-600"
                            />
                          )}
                        </div>

                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan="1">
                        {langContent.Acquistion_date}
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan="1">
                        <div className="flex  items-center ">
                          {/* date input */}
                          <FormikDate
                            placeholder={langContent.Acquistion_date_value}
                            name="date"
                            className="w-full"
                          />

                          {feedback?.dateFeedback && (
                            <Popup
                              trigger={
                                <Icon
                                  name="warning"
                                  className=" ml-2 -mt-1"
                                  color="red"
                                  circular
                                />
                              }
                              content={feedback?.dateFeedback}
                              className="border border-red-600 text-red-600"
                            />
                          )}
                        </div>

                      </Table.HeaderCell>
                      <Table.HeaderCell rowSpan="3">Actions</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell colSpan="2">
                        {langContent.Original_value}
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan="1">
                        {/* original value input */}
                        <div className="flex items-center">
                          <FormikInput
                            name="originalValue"
                            placeholder={langContent.Original_value}
                            isNumber
                            className="w-full"
                          />

                          {feedback?.originalValueFeedback && (
                            <Popup
                              trigger={
                                <Icon
                                  name="warning"
                                  className=" ml-2 -mt-1"
                                  color="red"
                                  circular
                                />
                              }
                              content={feedback?.originalValueFeedback}
                              className="border border-red-600 text-red-600"
                            />
                          )}
                        </div>
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan="1">
                        {langContent.Depreciation_rate}
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan="1">
                        <div className="flex  items-center ">
                          {/* depreciation rate input */}
                          <FormikInput
                            name="depreciationRate"
                            placeholder={langContent.Depreciation_rate}
                            icon="percent"
                            className="w-full"
                          />
                          {feedback?.deprectionRateFeedback && (
                            <Popup
                              trigger={
                                <Icon
                                  name="warning"
                                  className=" ml-2 -mt-1"
                                  color="red"
                                  circular
                                />
                              }
                              content={feedback?.deprectionRateFeedback}
                              className="border border-red-600 text-red-600"
                            />
                          )}
                        </div>
                      </Table.HeaderCell>
                    </Table.Row>

                    <Table.Row>
                      <Table.HeaderCell rowSpan="1">
                        {' '}
                        {langContent.Year_column}
                      </Table.HeaderCell>
                      <Table.HeaderCell rowSpan="1">
                        {langContent.Net_value_at_beginning_of_the_year}
                      </Table.HeaderCell>
                      <Table.HeaderCell rowSpan="1">
                        {langContent.Loss_in_value_per_year}
                      </Table.HeaderCell>
                      <Table.HeaderCell rowSpan="1">
                        {langContent.Accumulated_loss}
                      </Table.HeaderCell>
                      <Table.HeaderCell rowSpan="1">
                        {langContent.Net_value_at_the_end_of_the_year}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <FieldArray name="answers">
                      {({ insert, remove, push }) => (
                        <>
                          {formik.values.answers.length > 0 &&
                            formik.values.answers.map((choice, index, arr) => {
                              return (
                                <Table.Row>
                                  <>
                                    <Table.Cell>
                                      <FormikInput
                                        name={`answers.${index}.year`}
                                        placeholder={langContent.Enter_year}
                                      />

                                      <FeedbackMessage
                                        value={
                                          feedback &&
                                          feedback?.table[index]?.yearFeedback
                                        }
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <FormikInput
                                        name={`answers.${index}.netValueBegin`}
                                        isNumber
                                        placeholder={
                                          langContent.Enter_net_value_beginning
                                        }
                                      />
                                      <FeedbackMessage
                                        value={
                                          feedback &&
                                          feedback?.table[index]
                                            ?.netValueBeginFeedback
                                        }
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <FormikInput
                                        name={`answers.${index}.lossValue`}
                                        isNumber
                                        placeholder={
                                          langContent.Enter_loss_in_value
                                        }
                                      />
                                      <FeedbackMessage
                                        value={
                                          feedback &&
                                          feedback?.table[index]
                                            ?.lossValueFeedback
                                        }
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <FormikInput
                                        name={`answers.${index}.accumulatedLoss`}
                                        isNumber
                                        placeholder={
                                          langContent.Enter_accumulated_loss
                                        }
                                      />
                                      <FeedbackMessage
                                        value={
                                          feedback &&
                                          feedback?.table[index]
                                            ?.accumulatedLossFeedback
                                        }
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <FormikInput
                                        name={`answers.${index}.netValueEnd`}
                                        isNumber
                                        placeholder={
                                          langContent.Enter_net_value_end
                                        }
                                      />
                                      <FeedbackMessage
                                        value={
                                          feedback &&
                                          feedback?.table[index]
                                            ?.netValueEndFeedback
                                        }
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <div className="flex">
                                        {index === 0 ? null : (
                                          <Button
                                            size="tiny"
                                            negative
                                            basic
                                            onClick={() => remove(index)}
                                            type="button"
                                          >
                                            -
                                          </Button>
                                        )}

                                        {index ===
                                          formik.values.answers.length - 1 &&
                                          formik.values.answers.length < 6 ? (
                                          <Button
                                            size="tiny"
                                            className="bg-white border-2 border-solid  items-center  border-blue-400"
                                            onClick={() =>
                                              push({
                                                year: '',
                                                netValueBegin: '',
                                                netValueEnd: '',
                                                lossValue: '',
                                                accumulatedLoss: '',
                                              })
                                            }
                                            type="button"
                                          >
                                            +
                                          </Button>
                                        ) : null}
                                      </div>
                                    </Table.Cell>
                                  </>
                                </Table.Row>
                              )
                            })}
                        </>
                      )}
                    </FieldArray>
                  </Table.Body>
                </Table>
              </div>

              <div className="my-5 mt-10 flex ">
                <Button
                  className="ml-auto"
                  primary
                  type="button"
                  onClick={formik.submitForm}
                >
                  {langContent.Submit}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Segment>
    </div>
  )
}

export default React.memo(Amortization)
