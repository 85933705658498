import React from 'react'
import {Button, Modal} from 'semantic-ui-react'
import { useLanguage } from '../../../context/languageContext'
import { content } from '../../../localization/content'

const ModalComponent = ({open, onClose, title, textBody}) => {
    const [lang] = useLanguage();
    const langContent = content[lang];

  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      closeIcon
      size="small"
      className="animate__animated animate__fadeInDown"
    >
      <Modal.Header className="text-center bg-indigo-50 rounded-t-lg">
       {title}
      </Modal.Header>
      <Modal.Content className="flex justify-center items-center bg-blue-50">
        {textBody}
      </Modal.Content>
      <Modal.Actions>
        <Button content={langContent.no}  onClick={() => onClose(false)} />          
        <Button
          content={langContent.yes}          
          onClick={() => onClose(true)}
          color='black'
        />
      </Modal.Actions>
    </Modal>
  )
}

export default ModalComponent
