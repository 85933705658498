import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import '../../assets/css/app.css'

const Fader = ({ text, delay }) => {

    const [fadeProp, setFadeProp] = useState({
        fade: 'fade-out',
    });

    useEffect(() => {
        const timeout = setInterval(() => {
            if (fadeProp.fade === 'fade-out') {
                setFadeProp({
                    fade: 'fade-in'
                })
            }
        }, delay);

        return () => clearInterval(timeout)
    }, [fadeProp])

    return <span className={fadeProp.fade}>{text}</span>
}

export default Fader