import React from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useToasts} from 'react-toast-notifications'
import {
  Button,
  Dimmer,
  Form,
  Icon,
  Image,
  Loader,
  Message,
  Popup,
  Segment,
  Table,
} from 'semantic-ui-react'
import {BsLightbulb} from 'react-icons/bs'
import {FieldArray, Formik} from 'formik'

import {authAxios} from '../../../../config/axiosConfig'
import Time from '../../../../utils/time'
import useAsync from '../../../../hooks/useAsync'
import {formatResponseError} from '../../../../utils/format-response'
import FormikDropdown from '../../../../components/formik/FormikDropdown'
import FormikDate from '../../../../components/formik/FormikDate'
import FormikInput from '../../../../components/formik/FormikInput'
import FeedbackMessage from '../feedback-message'
import useAccountNames from '../../../../hooks/use-account-names'
import FormikTextArea from '../../../../components/formik/FormikTextArea'
import { useLanguage } from '../../../../context/languageContext'
import { content } from '../../../../localization/content'

const JournalTeacher = ({examType, data, setQuestionSuccess,setExamSuccess, setExamSuccessScore}) => {
  const [hintData, setHintData] = React.useState()
  const [popupOpen, setPopupOpen] = React.useState(false)
  const [uncompeletAnswer, setUncompeletAnswer] = React.useState(false)
  const [feedback, setFeedback] = React.useState()
  const {addToast} = useToasts()

  const {classid, subjectid, chapterid, sectionid, pexid, exerciseAttempt} =
    useParams()
  const history = useHistory()

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]



  const {
    accountNames,
    accountNumbers,
    errorGettingAccountNames,
    gettingAccountNames,
  } = useAccountNames()

  const {
    run: submitQuestion,
    error: errorSubmittingQuestion,
    isLoading: submittingQuestion,
  } = useAsync()

  const {
    run: getHint,
    error: errorGettingHint,
    isLoading: gettingHint,
  } = useAsync()

  const reqHint = () => {
    // setPopupOpen(prevOpen => !prevOpen)
    if (hintData) {
      setPopupOpen(prevOpen => !prevOpen)
    } else {
      getHint(
        authAxios.get(
          `/correct/exercise/question/hint/${data?.attemptId}?attempt=${exerciseAttempt}`,
        ),
      ).then(({data}) => {
        setPopupOpen(true)
        console.log(data)
        setHintData(data)
      })
    }
  }
  function onSubmit(values, {resetForm}) {
    let timer = JSON.parse(
      window.localStorage.getItem(
        `questionId-${data?._id}-attemptId-${data?.attemptId}`,
      ),
    )
    const timeData = Time.getTimeFromSeconds(timer.seconds)
    // let objToSend = values.answers.map((a,i) => {
    //     if(a[i]?.accountnumber === 'accountnumber'){
    //         a[i]= undefined
    //     }
    //     // console.log(a)
    // })
    let objToSend = {
      ...values,
      answers: values.answers.map(a => ({
        ...a,
        isDebit: a?.debitAccountName ? true : false,
        account: a['accountnumber'],
        accountnumber: undefined,
        creditAccountName: undefined,
        debitAccountName: undefined,
      })),
    }

    // let objToSend = values.answers
    // for (let i of objToSend) {
    //   // console.log(i)
    //   for (let k in i) {
    //     if (k === 'accountnumber') {
    //       i[k] = undefined
    //     }
    //     if (k === 'creditAccountName') {
    //       // k='account'
    //       if (i[k]) {
    //         delete Object.assign(i, {['account']: i['creditAccountName']})[
    //           'creditAccountName'
    //         ]
    //       } else {
    //         i[k] = undefined
    //       }
    //     }
    //     if (k === 'debitAccountName') {
    //       // k='account'
    //       if (i[k]) {
    //         delete Object.assign(i, {['account']: i['debitAccountName']})[
    //           'debitAccountName'
    //         ]
    //       } else {
    //         i[k] = undefined
    //       }
    //     }
    //   }
    // }

    let obj = {
      ...objToSend,
      time: {
        hours: timeData?.hours,
        minutes: timeData?.minutes,
        seconds: timeData?.seconds,
      },
      attemptId: data?.attemptId,
      questionId: data?._id,
    }
    submitQuestion(
      authAxios.post(`/correct/exercise/${exerciseAttempt}?qtype=journal`, obj),
    ).then(({data}) => {
      console.log(data)
      if (examType === 'test') {
        resetForm()
        setFeedback(null)
        setQuestionSuccess(p => !p)
        setUncompeletAnswer(false)
        setHintData(null)
        return
      }
      if (data?.examSuccessfull && data?.success) {
        setExamSuccess(true)
        setExamSuccessScore(data?.score)
      }
      if (!data?.success) {
        setUncompeletAnswer(true)
        setFeedback(data)
      } else {
        resetForm()
        setFeedback(null)
        setQuestionSuccess(p => !p)
        addToast(langContent.Correct_answer, {appearance: 'success'})
        setUncompeletAnswer(false)
        setHintData(null)
      }
    })
  }

  return (
    <div className=" my-10">
      {errorGettingAccountNames ? (
        <Message error list={formatResponseError(errorGettingAccountNames)} />
      ) : null}
      {errorGettingHint ? (
        <Message error list={formatResponseError(errorGettingHint)} />
      ) : null}
      <Segment className="border-none shadow-none bg-transparent">
        {uncompeletAnswer ? (
          <Message error content={langContent.Incomplete_answer} />
        ) : null}

        {errorSubmittingQuestion ? (
          <Message error list={formatResponseError(errorSubmittingQuestion)} />
        ) : null}

        <Dimmer active={submittingQuestion} inverted>
          {/* <Loader active={true} /> */}
        </Dimmer>
        <div>
          <p className="text-primary text-xl">Instructions:</p>
          <p className="break-all">{data?.exerciseInstructions}</p>
        </div>
        <div>
          <div className="flex my-4">
            {/* <p className="text-primary font-semibold">{data?.questionTitle}</p> */}
            <Popup
              disabled={!hintData}
              content={hintData}
              open={popupOpen}
              position="bottom right"
              className="border border-primaryYellow shadow-none break-all mb-4 "
              trigger={
                <Button
                  loading={gettingHint}
                  className={`${
                    popupOpen
                      ? 'bg-white border border-solid  flex items-center ml-auto justify-center text-primaryYellow border-primaryYellow'
                      : 'bg-primaryYellow flex items-center justify-center text-white ml-auto'
                  }`}
                  onClick={() => reqHint()}
                >
                   {langContent.hint}
                  <BsLightbulb className="-mr-2 ml-2 -mt-0.5" />
                </Button>
              }
            />
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="flex-1 flex-col custom-min-width">
            <p className="text-primary font-semibold mb-4">
              {data?.questionTitle}
            </p>
            <p>{data?.question}</p>
          </div>
          {data?.image && (
            <div className="flex-1 flex justify-center custom-min-width">
              <Image
                size="medium"
                className=""
                src={data?.image}
                rounded
                as="a"
                href={data?.image}
                target="_blank"
              />
            </div>
          )}
        </div>

        <Formik
          initialValues={{
            date: '',
            wording: '',
            answers: Array.from(Array(data?.amountOfAccounts).keys()).map(
              () => ({
                accountnumber: '',
                debitAccountName: '',
                creditAccountName: '',
                debitValue: '',
                creditValue: '',
              }),
            ),
            // answers: [
            //   {
            //     accountnumber: '',
            //     debitAccountName: '',
            //     creditAccountName: '',
            //     debitValue: '',
            //     creditValue: '',
            //   },
            // ],
          }}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
        >
          {formik => {
            return (
              <Form
                autocomplete="off"
                // onSubmit={formik.handleSubmit}
                loading={submittingQuestion}
              >
                <div className="my-4 ">
                  <div className="flex  items-center justify-center ">
                    {/* date input */}
                    <FormikDate
                      placeholder={`${langContent.Enter_Date}: (dd-mm-yyyy)`}
                      name="date"
                      className="w-full max-w-sm"
                    />

                    {feedback?.date && (
                      <Popup
                        trigger={
                          <Icon
                            name="warning"
                            className=" ml-2 -mt-3"
                            color="red"
                            circular
                          />
                        }
                        content={feedback?.date}
                        className="border border-red-600 text-red-600"
                        // position='top left'
                      />
                    )}
                  </div>
                  <div className="my-4 max-w-sm"></div>
                  {/* <h3 className="mb-5">Journal Table</h3> */}
                  <Table
                    celled
                    structured
                    size="small"
                    textAlign="center"
                    className="border-t-2 border-l-2 border-r-2 border-b-0 solid border-black mt-8"
                  >
                    <Table.Header className="border-2 solid border-black">
                      <Table.Row className="text-base">
                        <Table.HeaderCell
                          className="border-2 solid border-black text-primary"
                          width={2}
                        >
                         {langContent.Account_Number}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          className="text-primary"
                          width={4}
                          colSpan="2"
                        >
                         {langContent.Account_Name}
                        </Table.HeaderCell>
                        {/* <Table.HeaderCell width={4}>
                          Account name on debit
                        </Table.HeaderCell>
                        <Table.HeaderCell width={4}>
                          Account name on credit
                        </Table.HeaderCell> */}
                        <Table.HeaderCell
                          width={1}
                          className="border-2 solid border-black text-primary"
                        >
                          {langContent.Debit_Value}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          width={1}
                          className="border-2 solid border-black text-primary"
                        >
                          {langContent.Credit_Value}
                        </Table.HeaderCell>
                        {/* <Table.HeaderCell>Actions</Table.HeaderCell> */}
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      <FieldArray name="answers">
                        {({insert, remove, push}) => (
                          <>
                            {formik.values.answers.length > 0 &&
                              formik.values.answers.map(
                                (choice, index, arr) => {
                                 
                                  return (
                                    <Table.Row
                                      className={` ${
                                        feedback?.table &&
                                       ( feedback?.table?.filter(
                                          a =>
                                            a.row === index &&
                                            (a?.error ||
                                              a?.feedback ||
                                               a?.error == null ),
                                        )[0]
                                          ? 'bg-red-50'
                                          : 'bg-green-50')
                                      } `}
                                    >
                                      <Table.Cell
                                        className={`border-r-2 solid border-black
                                        ${
                                        feedback?.table &&
                                       ( feedback?.table?.filter(
                                          a =>
                                            a.row === index &&
                                            ( !a?.error && a?.feedback
                                              ),
                                        )[0]
                                          ? 'bg-green-50'
                                          : '')
                                      } 
                                        `}
                                        width={1}
                                      >
                                        <FormikDropdown
                                          name={`answers.${index}.accountnumber`}
                                          placeholder={langContent.Account_Number}
                                          options={accountNumbers}
                                          loading={gettingAccountNames}
                                          fluid
                                          selection
                                          disabled
                                          className="w-full journalDropdown"
                                        />

                                        {/* <FeedbackMessage
                                        value={
                                          feedback &&
                                          feedback?.table[index]?.yearFeedback
                                        }
                                      /> */}
                                      </Table.Cell>

                                      <Table.Cell
                                        width={3}
                                        className={`journalBorder
                                        ${
                                        feedback?.table &&
                                       ( feedback?.table?.filter(
                                          a =>
                                            a.row === index &&
                                            ( !a?.error && a?.feedback
                                              ),
                                        )[0]
                                          ? 'bg-green-50'
                                          : '')
                                      } 
                                        `}
                                      >
                                        <FormikDropdown
                                          options={accountNames}
                                          search
                                          clearable
                                          loading={gettingAccountNames}
                                          name={`answers.${index}.debitAccountName`}
                                          placeholder={`${langContent.Account_Name} ${langContent.Debit_Value}`}
                                          fluid
                                          selection
                                          className="border-none border-0 journalDropdown border-transparent"
                                          disabled={
                                            Boolean(
                                              formik.values.answers[index]
                                                .creditAccountName,
                                            ) ||
                                            Boolean(
                                              formik.values.answers[index]
                                                .creditValue,
                                            )
                                          }
                                          onChangeCB={value => {
                                            formik.setFieldValue(
                                              `answers.${index}.accountnumber`,
                                              value,
                                            )

                                            formik.setFieldValue(
                                              `answers.${index}.debitValue`,
                                              '',
                                            )

                                            console.log('called feedback')
                                            console.log(
                                              feedback?.table?.map(a =>
                                                a.row === index
                                                  ? a?.error
                                                    ? {...a, error: null}
                                                    : a
                                                  : a,
                                              ),
                                            )
                                            setFeedback({
                                              ...feedback,
                                              table: feedback?.table?.map(a =>
                                                a.row === index
                                                  ? a?.error
                                                    ? {...a, error: null}
                                                    : a
                                                  : a,
                                              ),
                                            })
                                          }}
                                        />

                                        {/* <FeedbackMessage
                                        value={
                                          feedback &&
                                          feedback?.table[index]?.error
                                        }
                                      /> */}

                                        {feedback?.table?.map(a =>
                                          a.row === index &&
                                          formik.values.answers[index]
                                            .debitAccountName
                                            ? a?.error && (
                                                <FeedbackMessage
                                                  value={a?.error}
                                                />
                                              )
                                            : null,
                                        )}

                                        {feedback?.table?.map(a =>
                                          a.row === index &&
                                          !formik.values.answers[index]
                                            .debitAccountName &&
                                          !formik.values.answers[index]
                                            .creditAccountName
                                            ? a?.error && (
                                                <FeedbackMessage
                                                  value={a?.error}
                                                />
                                              )
                                            : null,
                                        )}

                                        {/* <div className='bg-red-500 border border-r-2 border-solid'>

                                        </div> */}
                                      </Table.Cell>

                                      <Table.Cell
                                        width={3}
                                        className={`border-r-2 border-l-0  solid border-black
                                        ${
                                        feedback?.table &&
                                       ( feedback?.table?.filter(
                                          a =>
                                            a.row === index &&
                                            ( !a?.error && a?.feedback
                                              ),
                                        )[0]
                                          ? 'bg-green-50'
                                          : '')
                                      } 
                                        `}
                                      >
                                        <FormikDropdown
                                          options={accountNames}
                                          clearable
                                          loading={gettingAccountNames}
                                          name={`answers.${index}.creditAccountName`}
                                          placeholder={`${langContent.Account_Name} ${langContent.Credit_Value}`}
                                          search
                                          disabled={
                                            Boolean(
                                              formik.values.answers[index]
                                                .debitAccountName,
                                            ) ||
                                            Boolean(
                                              formik.values.answers[index]
                                                .debitValue,
                                            )
                                          }
                                          onChangeCB={value => {
                                            //   console.log(value)
                                            formik.setFieldValue(
                                              `answers.${index}.accountnumber`,
                                              value,
                                            )
                                            formik.setFieldValue(
                                              `answers.${index}.creditValue`,
                                              '',
                                            )
                                            setFeedback({
                                              ...feedback,
                                              table: feedback?.table?.map(a =>
                                                a.row === index
                                                  ? a?.error
                                                    ? {...a, error: null}
                                                    : a
                                                  : a,
                                              ),
                                            })
                                          }}
                                          className="w-full journalDropdown"
                                          fluid
                                          selection
                                        />

                                        {feedback?.table?.map(a =>
                                          a.row === index &&
                                          formik.values.answers[index]
                                            .creditAccountName
                                            ? a?.error && (
                                                <FeedbackMessage
                                                  value={a?.error}
                                                />
                                              )
                                            : null,
                                        )}

                                        {feedback?.table?.map(a =>
                                          a.row === index &&
                                          !formik.values.answers[index]
                                            .debitAccountName &&
                                          !formik.values.answers[index]
                                            .creditAccountName
                                            ? a?.error && (
                                                <FeedbackMessage
                                                  value={a?.error}
                                                />
                                              )
                                            : null,
                                        )}
                                      </Table.Cell>

                                      <Table.Cell
                                        className="border-l-2 solid border-black"
                                        width={1}
                                      >
                                        <FormikInput
                                          placeholder={langContent.Debit_Value}
                                          name={`answers.${index}.debitValue`}
                                          className="journalInput"
                                          disabled={
                                            Boolean(
                                              formik.values.answers[index]
                                                .creditAccountName,
                                            ) ||
                                            Boolean(
                                              formik.values.answers[index]
                                                .creditValue,
                                            )
                                          }
                                          isNumber
                                        />
                                        {/* {feedback?.table[index]?.isDebit && (
                                        <FeedbackMessage
                                          value={
                                            feedback &&
                                            feedback?.table[index]?.feedback
                                          }
                                        />
                                      )} */}
                                        {feedback?.table?.map(a =>
                                          a.row === index
                                            ? a?.isDebit && (
                                                <FeedbackMessage
                                                  value={a?.feedback}
                                                />
                                              )
                                            : null,
                                        )}
                                      </Table.Cell>

                                      <Table.Cell
                                        className="border-r-2 solid border-black"
                                        width={1}
                                      >
                                        <FormikInput
                                          placeholder={langContent.Credit_Value}
                                          className="journalInput"
                                          name={`answers.${index}.creditValue`}
                                          disabled={
                                            Boolean(
                                              formik.values.answers[index]
                                                .debitAccountName,
                                            ) ||
                                            Boolean(
                                              formik.values.answers[index]
                                                .debitValue,
                                            )
                                          }
                                          isNumber
                                        />
                                        {/* {!feedback?.table[index]?.isDebit && (
                                        <FeedbackMessage
                                          value={
                                            feedback &&
                                            feedback?.table[index]?.feedback
                                          }
                                        />
                                      )} */}

                                        {feedback?.table?.map(a =>
                                          a.row === index
                                            ? !a?.isDebit && (
                                                <FeedbackMessage
                                                  value={a?.feedback}
                                                />
                                              )
                                            : null,
                                        )}
                                      </Table.Cell>

                                      {/* <Table.Cell>
                                      <div className="flex">
                                        {index === 0 ? null : (
                                          <Button
                                            size="tiny"
                                            negative
                                            basic
                                            onClick={() => remove(index)}
                                            type="button"
                                          >
                                            -
                                          </Button>
                                        )}

                                        {index ===
                                        formik.values.answers.length - 1 ? (
                                          <Button
                                            size="tiny"
                                            className="bg-white border-2 border-solid  items-center  border-blue-400"
                                            onClick={() =>
                                              push({
                                                accountnumber: '',
                                                debitAccountName: '',
                                                creditAccountName: '',
                                                debitValue: '',
                                                creditValue: '',
                                              })
                                            }
                                            type="button"
                                          >
                                            +
                                          </Button>
                                        ) : null}
                                      </div>
                                    </Table.Cell> */}
                                    </Table.Row>
                                  )
                                },
                              )}
                          </>
                        )}
                      </FieldArray>
                    </Table.Body>
                  </Table>
                  <div className="max-w-md px-3 mx-auto flex flex-col text-center">
                    <FormikTextArea
                      name="wording"
                      label={langContent.Enter_wording}
                      placeholder={langContent.Enter_wording}
                      className="w-full"
                    />
                    {feedback?.wording && (
                      <div>
                        <FeedbackMessage value={feedback?.wording} />
                      </div>
                    )}
                  </div>
                </div>

                <hr />
                <div className="my-5 mt-10 flex ">
                  <Button className="ml-auto" primary type="button" onClick={formik.submitForm}>
                  {langContent.Submit}
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Segment>
    </div>
  )
}

export default JournalTeacher
