
import Auth from '../config/auth';
import routes from '../routes'
import { useHistory } from 'react-router-dom'

import { useUser } from '../context/user-context'

function useAuth() {
    const { setUser } = useUser()
    const history = useHistory()

    const logout = () => {
        setUser('');
        Auth.logout()

    }

    const redirectToLogin = () => {

        history.push(routes.login)
        history.go(0)
    }
    const logoutAndRedirect = () => {
        logout();
        redirectToLogin();
    }
    return { logoutAndRedirect, redirectToLogin, logout }
}

export default useAuth