import React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import InstructorRoute from '../components/shared/instructor-route'
import StudentNonVoucherRoute from '../components/shared/student-non-voucher-route'
import StudentVoucherRoute from '../components/shared/student-voucher-route'

import StudentRoute from '../components/shared/student-voucher-route'
import ErrorComp from '../pages/error/error'
import InstructorProfile from '../pages/InstructorProfile'
import routes from '../routes'
import InstructorLayout from './instructor-layout'
import StudentNonVoucherLayout from './student-nonVoucher-layout'
import StudentVoucherLayout from './student-voucher-layout'

const DashboardLayout = () => {
    const {pathname} = useLocation()
    
    return (
    <div>
        <Switch>
            {/* <StudentVoucherRoute path='/dashboard/student/voucher' component={() => <StudentVoucherLayout isTestRoute={pathname.match(/(\/wex\/|\/pex\/|\/test\/)/gi)} />}  /> */}
            <StudentVoucherRoute path='/dashboard/student/voucher' component={StudentVoucherLayout} />
            <StudentNonVoucherRoute path='/dashboard/student/nonvoucher' component={StudentNonVoucherLayout} />
            {/* <InstructorRoute exact path={routes.instructorProfile} component={InstructorProfile} /> */}
            <InstructorRoute  path='/dashboard/instructor' component={InstructorLayout} />
            <Route path="*" component={ErrorComp} />
        </Switch>
    </div>
    )
}

export default DashboardLayout
