import {Menu} from 'semantic-ui-react'
import { useLanguage } from '../../../context/languageContext'
import { content } from '../../../localization/content'

const TeacherExerciseBreakdownTabs = ({activeMenu, setActiveMenu}) => {
  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  return (
    <div class="flex">
      <Menu
        className="mt-2 border-none shadow-none  bg-transparent text-xl  m-2"
        stackable
      >
        <>
          <Menu.Item
            active={activeMenu === 'overview'}
            onClick={() => setActiveMenu('overview')}
            className={`${
              activeMenu === 'overview'
                ? 'border-b-4 border-primary text-primary bg-transparent'
                : 'bg-gray-50'
            }`}
          >
            <div className="flex flex-col w-full ">
              <p className="font-medium text-labelColor">{langContent.Overview}</p>
            </div>
          </Menu.Item>
          <Menu.Item
            active={activeMenu === 'questionbreakdown'}
            onClick={() => setActiveMenu('questionbreakdown')}
            className={` ${
              activeMenu === 'questionbreakdown'
                ? 'border-b-4 border-primary text-primary bg-transparent'
                : 'bg-gray-50'
            }`}
          >
            <div className="flex flex-col w-full ">
              <p className="font-medium text-labelColor">{langContent.Question_breakdown}</p>
            </div>
          </Menu.Item>
        </>
      </Menu>
    </div>
  )
}

export default TeacherExerciseBreakdownTabs
