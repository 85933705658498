import React from 'react'
import {RiDeleteBin7Fill} from 'react-icons/ri'
import { FaCamera } from "react-icons/fa";

function ImageAttachment({setAttachmentData ,setImagePath, className}) {
  const [file, setFile] = React.useState(null)

  const handleOnSelectedFile = e => {
    const objectUrl = URL.createObjectURL(e.target.files[0])
    setImagePath(objectUrl)
    setFile(e.target.files[0])
    setAttachmentData(e.target.files[0])
  }

  const clearAttachment = () => {
    setFile(null)
    setAttachmentData(null)
  }

  return (
    <div className={`w-14 rounded-full flex items-center justify-center ${className}`}>
      {file ? (
        <div className="flex justify-between bg-gray-100 rounded-full p-3">
          <div className="flex items-center justify-center cursor-pointer">
            <RiDeleteBin7Fill
              size="24"
              onClick={clearAttachment}
              className="ltr:mr-3 rtl:ml-3 text-primary"
            />
          </div>
        </div>
      ) : (
        <>
          <input
            type="file"
            alt="image"
            id="file-input"
            accept="image/png, image/jpeg"
            className="hidden"
            onChange={handleOnSelectedFile}
          />
          <label
            htmlFor="file-input"
            className="text-primaryRedColor-default flex bg-gray-100 rounded-full p-3"
          >
            <div className="flex items-center justify-center cursor-pointer">
              <FaCamera size="24" className="ltr:mr-3 rtl:ml-3 text-primary" />
            </div>
          </label>
        </>
      )}
    </div>
  )
}

export default ImageAttachment
