import {Formik} from 'formik'
import {Link, useHistory} from 'react-router-dom'
import {Button, Checkbox, Form, Message} from 'semantic-ui-react'
import FormikControl from '../formik/FormikControl'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'
import routes from '../../routes'
import * as Yup from 'yup'
import useAsync from '../../hooks/useAsync'
import {axios} from '../../config/axiosConfig'
import {useToasts} from 'react-toast-notifications'
import {formatResponseError} from '../../utils/format-response'
import {useState} from 'react'
import Privacy from '../shared/privacy'
import FormikCheckbox from '../formik/FormikCheckbox'

const StudentRegisterWithVoucher = () => {
  const [lang] = useLanguage()
  const langContent = content[lang]
  const history = useHistory()
  const {run, isLoading, isError, error} = useAsync()

  const [checkPrivacy, setCheckPrivacy] = useState(false)
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false)

  const {addToast} = useToasts()

  const registerSchema = Yup.object({
    firstname: Yup.string()
      .min(3, 'must be more than 3 characters')
      .max(25, 'must be less than 25 characters')
      .required(langContent.required),
    lastname: Yup.string()
      .min(3, 'must be more than 3 characters')
      .max(25, 'must be less than 25 characters')
      .required(langContent.required),
    Voucherlink: Yup.string(),
    email: Yup.string() // .matches(/^[A-Za-z0-9._%+-]+@gmail.com$/igm,'Email must ends with @gmail.com ')
      .email('Email not valid')
      .required(langContent.required)
      .trim(),
    password: Yup.string()
      .min(8, 'password must be more than 8 characters')
      .max(20, 'password must be less than 20 characters')
      .required(langContent.required),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], langContent.notMatch)
      .required(langContent.required),
    isAgreePolicy: Yup.bool() // use bool instead of boolean
      .oneOf([true], langContent.requiredTerms),
  })

  const handleOnSubmit = values => {
    console.log(values)
    run(axios.post('/student/signup', values)).then(() => {
      addToast(langContent.Please_check_your_email, {
        appearance: 'info',
        autoDismiss: false,
      })
      history.push(routes.home)
    })
  }

  return (
    <div>
      {isError ? <Message error list={formatResponseError(error)} /> : null}
      <Formik
        initialValues={{
          firstname: '',
          lastname: '',
          email: '',
          password: '',
          confirmPassword: '',
          Voucherlink: '',
          isAgreePolicy: false,
        }}
        validationSchema={registerSchema}
        onSubmit={handleOnSubmit}
      >
        {formik => {
          console.log(formik)
          return (
            <Form loading={isLoading} onSubmit={formik.handleSubmit}>
              <Form.Field required>
                <FormikControl
                  name="firstname"
                  className="font-semibold"
                  label={langContent.First_Name}
                  control="input"
                />
              </Form.Field>

              <Form.Field required>
                <FormikControl
                  name="lastname"
                  className="font-semibold"
                  label={langContent.Last_Name}
                  control="input"
                />
              </Form.Field>

              <Form.Field required>
                <FormikControl
                  name="email"
                  label={langContent.Email_Address}
                  control="input"
                  type="email"
                />
              </Form.Field>

              <Form.Field required>
                <FormikControl
                  name="password"
                  label={langContent.Password}
                  control="input"
                  type="password"
                />
              </Form.Field>

              <Form.Field required>
                <FormikControl
                  name="confirmPassword"
                  label={langContent.Confirm_password}
                  control="input"
                  type="password"
                />
              </Form.Field>

              {/* <Form.Field>
              <FormikControl
                name="Voucherlink"
                label='Voucher code'
                control="input"
                className="mb-10"
              />
            </Form.Field> */}
              <div className="flex mb-4">
                <Form.Field required>
                  <FormikCheckbox
                    label={langContent.I_have_16_and_I_agree_to}
                    name="isAgreePolicy"
                  />
                </Form.Field>
                <div
                  className="ml-1 -mt-0.5 underline cursor-pointer hover:text-blue-700"
                  onClick={() => setOpenPrivacyModal(true)}
                >
                  {langContent.I_agree_to_Privacy_and_Policy}
                </div>
              </div>

              <div className="text-center">
                <Button
                  // disabled={!checkPrivacy}
                  content={langContent.Register}
                  type="submit"
                  fluid
                  className="bg-primary px-28 text-white"
                />
              </div>
            </Form>
          )
        }}
      </Formik>
      <Privacy
        open={openPrivacyModal}
        onClose={() => setOpenPrivacyModal(false)}
      />
    </div>
  )
}

export default StudentRegisterWithVoucher
