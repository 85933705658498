import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, Icon, Image } from 'semantic-ui-react'
import { useLanguage } from '../../context/languageContext'
import { useUser } from '../../context/user-context'
import { content } from '../../localization/content'
import routes from '../../routes'
import useAuth from '../../hooks/use-auth'
import ModalComponent from './modal/modal-component'
import { customerService } from '../../services/customer/customer.service'
import { useToasts } from 'react-toast-notifications'
import Privacy from '../shared/privacy'

const AvatarDropdown = () => {
    const { getUser } = useUser()
    const [userData, setUserData] = useState({})
    const { addToast } = useToasts()
    const [lang] = useLanguage()
    const langContent = content[lang]

    const { logoutAndRedirect, logout, redirectToLogin } = useAuth();
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [openPrivacyModal, setOpenPrivacyModal] = useState(false)
    React.useEffect(() => {
        const user = getUser()
        if (!user) return

        setUserData(user)
        // return () => setUserData(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser, setUserData])
    const handleOnClickDelete = async () => {

        const result = await customerService.deleteCustomer();
        if (result) {
            addToast(langContent.account_delete_success, {
                appearance: 'success'
            });
            logout();
        } else {
            addToast(langContent.account_delete_error, {
                appearance: 'error'
            });
        }
    }
    const handleOnClickLogout = () => {
        logoutAndRedirect()
    }
    const processModalResult = (result) => {
        setOpenConfirmModal(false);
        result && handleOnClickDelete()
    }
    return (
        <div className="">
            {userData?.email ? (
                <Dropdown
                    direction="left"
                    trigger={
                        <div className="flex flex-row items-center">
                            {userData?.avatar ? (
                                <Image
                                    src={userData?.avatar}
                                    alt="avatar"
                                    className={`${userData?.avatar ? 'visible' : 'hidden'
                                        } w-12 h-12 rounded-full mx-auto`}
                                />
                            ) : (
                                <Icon name="user" className="-mt-2" />
                            )}
                            <div>
                                {userData?.['name'] && (
                                    <div className="mx-1 mb-0 text-labelColor ml-2 font-medium">
                                        <p >
                                            {userData?.['name']}</p>
                                        {userData?.isStudent ? (
                                            <p className="text-gray-400">{langContent.Student}</p>
                                        ) : (
                                            <p className="text-gray-400">Teacher</p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                    className="flex items-center ml-3 "
                >
                    <Dropdown.Menu className="w-36 ">
                        {userData?.isStudent ? (
                            <>
                                {userData?.haveVoucher ? (
                                    <Link
                                        className="text-center justify-center text-black hover:text-black"
                                        to={routes.voucherStudent.profile}
                                    >
                                        <Dropdown.Item className="text-labelColor text-black hover:text-black py-2 justify-center text-center  hover:bg-gray-100 ">
                                            {langContent.Profile}
                                        </Dropdown.Item>
                                    </Link>
                                ) : (
                                    <Link
                                        className="text-center justify-center text-black hover:text-black"
                                        to={routes.nonVoucherStudent.profile}
                                    >
                                        <Dropdown.Item className="text-labelColor text-black hover:text-black py-2 justify-center text-center  hover:bg-gray-100 ">
                                            {langContent.Profile}
                                        </Dropdown.Item>
                                    </Link>
                                )}
                            </>
                        ) : (
                            <div></div>
                            // <Link
                            //   className="text-center justify-center text-black hover:text-black"
                            //   to={routes.instructorProfile}
                            // >
                            //   <Dropdown.Item className="text-labelColor text-black hover:text-black py-2 justify-center text-center  hover:bg-gray-100 ">
                            //     {langContent.Profile}
                            //   </Dropdown.Item>
                            // </Link>
                        )}
                        <hr />
                        <Dropdown.Item
                            className="text-labelColor hover:text-black transition-colors duration-300 text-center"
                            onClick={() => setOpenPrivacyModal(true)}
                        >
                            {langContent.privacyDetails.menuItemTitle}
                        </Dropdown.Item>
                        <hr />
                        <Dropdown.Item
                            className="text-labelColor hover:text-black transition-colors duration-300 text-center"
                            onClick={() => setOpenConfirmModal(true)}
                        >
                            {langContent.Delete}
                        </Dropdown.Item>

                        <hr />
                        <Dropdown.Item
                            className="text-labelColor hover:text-black transition-colors duration-300 text-center"
                            onClick={handleOnClickLogout}
                        >
                            {langContent.Logout}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            ) : null}

            <ModalComponent
                open={openConfirmModal}
                title={langContent.confirm_title}
                textBody={langContent.confirm_delete_account}
                onClose={(result) => processModalResult(result)}></ModalComponent>

            <Privacy
                open={openPrivacyModal}
                onClose={() => setOpenPrivacyModal(false)}
            />
        </div>

    )
}

export default AvatarDropdown
