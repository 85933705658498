import React from 'react'
import {BsPen} from 'react-icons/bs'
import {Link} from 'react-router-dom'
import {Image} from 'semantic-ui-react'

import routes from '../../../routes'
import {truncate} from '../../../utils/string'
import {FaLock} from 'react-icons/fa'
import lock from '../../../assets/images/lock-subscribtion.svg'

const ChapterCardNonVoucher = ({
  chapterSrc,
  name,
  brief,
  chapterid,
  subjectid,
  order,
  isSubscriber,
  setopenConfirm,
}) => {
  return (
    <>
      {isSubscriber != false ? (
        <Link
          className="rounded-xl shadow-sm hover:shadow-md hover:text-current flex flex-col  animate__animated animate__fadeInUp"
          to={routes.nonVoucherStudent.chapters.content(subjectid, chapterid)}
        >
          <Image
            className="w-80 h-40 bg-contain rounded-t-lg bg-primaryLight"
            src={chapterSrc}
            alt="No image provided"
          ></Image>

          <div className="flex flex-col p-3 w-80 h-24 border solid border-gray-300 rounded-b-xl">
            <div className="flex justify-between items-center">
              <p className="text-primary text-lg font-semibold">
                Chapter {order}: {truncate(name, 20)}
              </p>
            </div>
            <p className="font-semibold h-10 overflow-y-auto ">{brief}</p>
          </div>
        </Link>
      ) : (
        <div onClick={()=> setopenConfirm(true)} className="rounded-xl darker-card chapter-disable-card shadow-sm relative hover:text-current cursor-pointer flex flex-col animate__animated animate__fadeInUp">
          <Image
            className="w-80 h-40 bg-contain rounded-t-lg bg-primaryLight cursor-pointer "
            src={chapterSrc}
            alt="No image provided"
          ></Image>

          {/* <FaLock className="absolute left-0 right-0 ml-auto mr-auto top-1/3 text-6xl text-gray-50 bg-gray-600 opacity-75 rounded-full p-4 hover:bg-primary"/> */}

          <Image  src={lock} className='w-14 h-14 lock-icon absolute left-0 right-0 ml-auto mr-auto centered-axis-y bg-gray-700 opacity-90 rounded-full p-3 hover:bg-primary text-white'/>

          <div className="flex flex-col p-3 w-80 h-24 border solid border-gray-300 rounded-b-xl dark-card">
            <div className="flex justify-between items-center">
              <p className="text-primary text-lg font-semibold">
                Chapter {order}: {truncate(name, 20)}
              </p>
            </div>
            <p className="font-semibold h-10 overflow-y-auto ">{brief}</p>
          </div>
        </div>
      )}
    </>
  )
}

export default ChapterCardNonVoucher
