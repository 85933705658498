import React, {useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {
  Accordion,
  Button,
  Dimmer,
  Icon,
  Loader,
  Segment,
  Step,
} from 'semantic-ui-react'
import { useLanguage } from '../../../context/languageContext'
import { content } from '../../../localization/content'
import TeacherHomeworkRoomReview from './teacher-homework-room-review'

const TEacherHomeWorkRoom = () => {
  const [forceReload, setForceReload] = React.useState(false)
  const [activeIndex, setActiveIndex] = useState(-1)
  const history = useHistory()
  const {chapterid, subjectid} = useParams()

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]


  const handleOnClickTitle = (e, itemProps) => {
    console.log(itemProps)
    setActiveIndex(itemProps.index)
  }

  const handleClickAccordion = (e, titleProps) => {
    const {index} = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent w-full">
        {/* <Accordion
          defaultActiveIndex={0}
          activeIndex={activeIndex}
          onTitleClick={handleOnClickTitle}
          panels={rootPanels}
          styled
          className="w-full my-5 "

        /> */}

        <Accordion
          fluid
          styled
          className="rounded-t-md w-full shadow-sm mb-4 hover:shadow-md hover:opacity-95 p-3 border-2 border-solid  animate__animated animate__fadeInUp"
        >
          <div className="relative shadow-none bg-transparent">
            <div class="border-l-4 solid h-full border-primaryLightPurbel absolute rounded-lg top-0 -left-1"></div>

            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={handleClickAccordion}
              className="flex items-center shadow-none bg-transparent border-none"
            >
              <Icon name="dropdown" />
              <div className="flex justify-between items-center">
                <p className=" font-semibold">{langContent.Basic_Level}</p>
              </div>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <div>
                <TeacherHomeworkRoomReview
                  level="beginner"
                  onUpdate={forceReload}
                  onReload={() => setForceReload(p => !p)}
                />
              </div>
            </Accordion.Content>
          </div>
        </Accordion>

        <Accordion
          fluid
          styled
          className="rounded-t-md w-full shadow-sm mb-4 hover:shadow-md hover:opacity-95 p-3 border-2 border-solid relative animate__animated animate__fadeInUp"
        >
         <div className="relative shadow-none bg-transparent">
            <div class="border-l-4 solid h-full border-primaryYellow absolute rounded-lg top-0 -left-1"></div>
          <Accordion.Title
            active={activeIndex === 1}
            index={1}
            onClick={handleClickAccordion}
            className="flex items-center shadow-none bg-transparent border-none"
          >

            <Icon name="dropdown" />
            <div className="flex justify-between items-center">
              <p className=" font-semibold">{langContent.Intermediate_level}</p>
            </div>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            <div>
              <TeacherHomeworkRoomReview
                level="intermidate"
                onUpdate={forceReload}
                onReload={() => setForceReload(p => !p)}
              />
            </div>
          </Accordion.Content>
          </div>
        </Accordion>

        <Accordion
          fluid
          styled
          className="rounded-t-md w-full shadow-sm mb-4 hover:shadow-md hover:opacity-95 p-3 border-2 border-solid relative animate__animated animate__fadeInUp"
        >
        <div className="relative shadow-none bg-transparent">
            <div class="border-l-4 solid h-full border-primaryRed absolute rounded-lg top-0 -left-1"></div>
          <Accordion.Title
            active={activeIndex === 2}
            index={2}
            onClick={handleClickAccordion}
            className="flex items-center shadow-none bg-transparent border-none"
          >
            <Icon name="dropdown" />
            <div className="flex justify-between items-center">
              <p className=" font-semibold">{langContent.Advanced_level}</p>
            </div>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 2}>
            <div>
              <TeacherHomeworkRoomReview
                level="advanced"
                onUpdate={forceReload}
                onReload={() => setForceReload(p => !p)}
              />
            </div>
          </Accordion.Content>
          </div>
        </Accordion>
      </Segment>
    </div>
  )
}

export default TEacherHomeWorkRoom
