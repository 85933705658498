import {Formik} from 'formik'
import {Link, useHistory} from 'react-router-dom'
import {Button, Checkbox, Form, Message} from 'semantic-ui-react'
import FormikControl from '../../components/formik/FormikControl'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'
import routes from '../../routes'
import * as Yup from 'yup'
import useAsync from '../../hooks/useAsync'
import {axios} from '../../config/axiosConfig'
import {useEffect, useState} from 'react'
import {useToasts} from 'react-toast-notifications'
import {formatResponseError} from '../../utils/format-response'
import Privacy from '../shared/privacy'
import FormikCheckbox from '../formik/FormikCheckbox'

const InstructorRegister = () => {
  const [lang] = useLanguage()
  const langContent = content[lang]
  const [subjects, setSubjects] = useState([])
  // const [school, setSchool] = useState([])

  const [checkPrivacy, setCheckPrivacy] = useState(false)
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false)

  const history = useHistory()

  const {run, isLoading, isError, error} = useAsync()
  const {run: getSubjects, isLoading: gettingSubjects} = useAsync()
  // const { run: getSchools, isLoading: gettingSchools } = useAsync()

  const {addToast} = useToasts()

  const registerSchema = Yup.object({
    name: Yup.string()
      .min(3, 'must be more than 3 characters')
      .max(25, 'must be less than 25 characters')
      .required(langContent.required),
    subject: Yup.string().required('You have to select one subject'),
    // school: Yup.string().required('You have to select one school'),
    email: Yup.string()
      .email(langContent.required)
      .required(langContent.required),
    password: Yup.string()
      .min(8, 'must be more than 8 characters')
      .max(20, 'must be less than 20 characters')
      .required(langContent.required),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], langContent.notMatch)
      .required(langContent.required),
    isAgreePolicy: Yup.bool() // use bool instead of boolean
      .oneOf([true], langContent.requiredTerms),
  })

  const handleOnSubmit = values => {
    run(axios.post('/instructor/signup', values)).then(() => {
      addToast(langContent.Please_check_your_email, {
        appearance: 'info',
        autoDismiss: false,
      })

      history.push(routes.login)
    })
  }

  useEffect(() => {
    getSubjects(axios.get('/school/subject/all')).then(({data}) => {
      console.log(data)
      console.log(data?.subjects[0].name)
      let subjectsOptions = []

      data?.subjects?.map(d =>
        subjectsOptions.push({
          key: d._id,
          text: d.name,
          value: d._id,
        }),
      )
      setSubjects(subjectsOptions)
    })

    // getSchools(axios.get('/school/all')).then(({ data }) => {
    //     console.log(data)
    //     let schoolOptions = []

    //     data?.schools?.map(d =>
    //         schoolOptions.push({
    //             key: d._id,
    //             text: d.name,
    //             value: d._id,
    //         }),
    //     )
    //     setSchool(schoolOptions)
    // })
  }, [getSubjects /*, getSchools*/])

  return (
    <div>
      {isError ? <Message error list={formatResponseError(error)} /> : null}
      <Formik
        initialValues={{
          name: '',
          email: '',
          password: '',
          confirmPassword: '',
          subject: '',
          //school: 'Lycée in Luxembourg',
          isAgreePolicy: false,
        }}
        validationSchema={registerSchema}
        onSubmit={handleOnSubmit}
      >
        {formik => (
          <Form onSubmit={formik.submitForm} loading={isLoading}>
            <Form.Field required>
              <FormikControl
                name="name"
                className="font-semibold"
                label={langContent.Full_Name}
                control="input"
              />
            </Form.Field>

            <Form.Field required>
              <FormikControl
                name="email"
                label={langContent.Email_Address}
                control="input"
                type="email"
              />
            </Form.Field>

            <Form.Field required>
              <FormikControl
                name="password"
                label={langContent.Password}
                control="input"
                type="password"
              />
            </Form.Field>

            <Form.Field required>
              <FormikControl
                name="confirmPassword"
                label={langContent.Confirm_password}
                control="input"
                type="password"
              />
            </Form.Field>

            <Form.Field>
              <FormikControl
                name="subject"
                control="dropdown"
                label={langContent.Subject}
                options={subjects}
                fluid
                selection
                loading={gettingSubjects}
              />
            </Form.Field>

            {/* <Form.Field>
                            <FormikControl
                              name="school"
                              control="dropdown"
                              label={langContent.school}
                              options={school}
                              fluid
                              selection
                              loading={gettingSchools}
                              value={0}
                            />
                        </Form.Field> */}

            <div className="flex mb-4 mt-4">
              <div style={{minWidth: '8em'}}>
                <Form.Field required>
                  <FormikCheckbox
                    label={langContent.I_have_16_and_I_agree_to}
                    name="isAgreePolicy"
                  />
                </Form.Field>
              </div>
              <div
                className="ml-4 underline cursor-pointer hover:text-blue-700"
                onClick={() => setOpenPrivacyModal(true)}
              >
                {langContent.I_agree_to_Privacy_and_Policy}
              </div>
            </div>

            <div className="text-center">
              <Button
                // disabled={!checkPrivacy}
                content={langContent.Register}
                fluid
                type="submit"
                className="bg-primary px-28 text-white"
              />
            </div>
          </Form>
        )}
      </Formik>

      <Privacy
        open={openPrivacyModal}
        onClose={() => setOpenPrivacyModal(false)}
      />
    </div>
  )
}

export default InstructorRegister
