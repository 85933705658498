import Auth from './auth'
import Axios from 'axios'

Axios.defaults.baseURL =
  process.env.NODE_ENV !== 'prod'
    ? process.env.REACT_APP_DEV_URL : process.env.REACT_APP_SERVER_URL

const AuthAxios = Axios.create()
AuthAxios.interceptors.request.use(config => {
  let accessToken = Auth.getToken()
  config.headers = {
    Authorization: `Bearer ${accessToken}`,
    'Accept-Language': window.localStorage.getItem(`language`),
  }
  return config
})

export const axios = Axios
export const authAxios = AuthAxios
