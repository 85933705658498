import React from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {FiArrowLeft} from 'react-icons/fi'
import {Breadcrumb, Button, Icon} from 'semantic-ui-react'

import routes from '../../../routes'

const SubscribePlanes = () => {
  const history = useHistory()
  const {subjectid} = useParams()

  return (
    <div>
      <div className="mt-8">
        <Breadcrumb className="mt-4">
          <Breadcrumb.Section onClick={() => history.push('/')} link>
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider>/</Breadcrumb.Divider>
          <Breadcrumb.Section
            onClick={() => history.push(routes.nonVoucherStudent.dashboard)}
            link
          >
            {' '}
            Subjects{' '}
          </Breadcrumb.Section>
          <Breadcrumb.Divider>/</Breadcrumb.Divider>
          <Breadcrumb.Section
            onClick={() =>
              history.push(routes.nonVoucherStudent.chapters.all(subjectid))
            }
          >
            {' '}
            Chapters{' '}
          </Breadcrumb.Section>
          <Breadcrumb.Divider>/</Breadcrumb.Divider>
          <Breadcrumb.Section active> subscription plans </Breadcrumb.Section>
        </Breadcrumb>
      </div>

      <div className="flex items-center mt-12 mb-6">
        <FiArrowLeft
          onClick={() => {
            history.push(routes.nonVoucherStudent.chapters.all(subjectid))
          }}
          className=" bold  text-2xl mr-2 cursor-pointer"
        />
        <h3 className="">Subscription plans</h3>
      </div>

      <div className="flex flex-wrap gap-x-8 -gap-y-4">
        <div className="flex flex-col w-72 h-96 border-2 border-solid border-primary rounded-md py-4 px-4 shadow-sm hover:shadow-md">
          <div className="text-2xl font-medium mx-auto mt-4">
            <p>Monthly subscription</p>
          </div>

          <div className="flex items-center text-4xl font-extrabold ml-3 my-2">
            <Icon name="eur"></Icon>
            <p className="mt-1.5">4.99</p>
          </div>

          <div className="flex mt-4">
            <Icon name="check" className="text-green-500"></Icon>
            <p className="px-1">The perfect way to get used to our tools</p>
          </div>

          <div className="flex mt-4">
            <Icon name="check" className="text-green-500"></Icon>
            <p className="px-1">
              You will be able to check 1 chapter and practice before
              subscribing
            </p>
          </div>
          <div className="mt-7 mx-auto">
            <Button
              primary
              className="px-12"
              onClick={() =>
                history.push(
                  routes.nonVoucherStudent.chapters.subscribe.checkout(
                    subjectid,
                    'monthly'
                  ),
                )
              }
            >
              Subscribe{' '}
            </Button>
          </div>
        </div>
        <div className="flex flex-col w-72 h-96 border-2 border-solid border-primary rounded-md py-4 px-4 shadow-sm hover:shadow-md">
          <div className="text-2xl font-medium mx-auto mt-4">
            <p>Yearly subscription</p>
          </div>

          <div className="flex items-center text-4xl font-extrabold ml-3 my-2">
            <Icon name="eur"></Icon>
            <p className="mt-1.5">39.99</p>
          </div>

          <div className="flex mt-4">
            <Icon name="check" className="text-green-500"></Icon>
            <p className="px-1">The perfect way to get used to our tools</p>
          </div>

          <div className="flex mt-4">
            <Icon name="check" className="text-green-500"></Icon>
            <p className="px-1">
              You will be able to check 1 chapter and practice before
              subscribing
            </p>
          </div>
          <div className="mt-7 mx-auto">
            <Button primary className="px-12"
             onClick={() =>
                history.push(
                  routes.nonVoucherStudent.chapters.subscribe.checkout(
                    subjectid,
                    'annual'
                  ),
                )
              }>
              Subscribe{' '}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscribePlanes
