import React from 'react'
import { useParams} from 'react-router-dom'
import {useToasts} from 'react-toast-notifications'
import {
  Button,
  Dimmer,
  Form,
  Image,
  Message,
  Popup,
  Segment,
} from 'semantic-ui-react'
import {BsLightbulb} from 'react-icons/bs'
import {FieldArray, Formik} from 'formik'

import {authAxios} from '../../../../config/axiosConfig'
import Time from '../../../../utils/time'
import useAsync from '../../../../hooks/useAsync'
import {formatResponseError} from '../../../../utils/format-response'
import FormikInput from '../../../../components/formik/FormikInput'
import FeedbackMessage from '../feedback-message'
import { useLanguage } from '../../../../context/languageContext'
import { content } from '../../../../localization/content'

const ChoiceTeacher = ({examType, data, setQuestionSuccess, setExamSuccess, setExamSuccessScore}) => {
  const [hintData, setHintData] = React.useState()
  const [popupOpen, setPopupOpen] = React.useState(false)
  const [uncompeletAnswer, setUncompeletAnswer] = React.useState(false)
  const [feedback, setFeedback] = React.useState()
  const {addToast} = useToasts()

  const [selectedOptions, setSelectedOptions] = React.useState([])

  const {classid, subjectid, chapterid, sectionid, pexid, exerciseAttempt} =
    useParams()

  const answerOptions = React.useMemo(
    () => [
      {text: 'A', value: 'A', key: 'A'},
      {text: 'B', value: 'B', key: 'B'},
      {text: 'C', value: 'C', key: 'C'},
      {text: 'D', value: 'D', key: 'D'},
    ],
    [],
  )

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]


  const {
    run: submitQuestion,
    error: errorSubmittingQuestion,
    isLoading: submittingQuestion,
  } = useAsync()

  const {
    run: getHint,
    error: errorGettingHint,
    isLoading: gettingHint,
  } = useAsync()

  const reqHint = () => {
    // setPopupOpen(prevOpen => !prevOpen)
    if (hintData) {
      setPopupOpen(prevOpen => !prevOpen)
    } else {
      getHint(
        authAxios.get(
          `/correct/exercise/question/hint/${data?.attemptId}?attempt=${exerciseAttempt}`,
        ),
      ).then(({data}) => {
        setPopupOpen(true)
        console.log(data)
        setHintData(data)
      })
    }
  }

  const onSelectChoice = choice => {
    const choiceIdx = selectedOptions.findIndex(x => x === choice)
    if (choiceIdx !== -1) {
      setSelectedOptions([
        ...selectedOptions.slice(0, choiceIdx),
        ...selectedOptions.slice(choiceIdx + 1),
      ])
    } else {
      setSelectedOptions([...selectedOptions, choice])
    }
  }

  function onSubmit(values, {resetForm}) {
    let timer = JSON.parse(
      window.localStorage.getItem(
        `questionId-${data?._id}-attemptId-${data?.attemptId}`,
      ),
    )
    const timeData = Time.getTimeFromSeconds(timer.seconds)

    const {answers, ...rest} = values
    const answersToSend = []
    selectedOptions.forEach(a => answersToSend.push({choiceNumber: a}))

    let obj = {
      answers: answersToSend,
      time: {
        hours: timeData?.hours,
        minutes: timeData?.minutes,
        seconds: timeData?.seconds,
      },
      attemptId: data?.attemptId,
      questionId: data?._id,
    }
    // console.log(Array.from(new Set(answersToSend)))

    submitQuestion(
      authAxios.post(`/correct/exercise/${exerciseAttempt}?qtype=choice`, obj),
    ).then(({data}) => {
      console.log(data)
      if (examType === 'test') {
        resetForm()
        setFeedback(null)
        setQuestionSuccess(p => !p)
        setUncompeletAnswer(false)
        setHintData(null)
        return
      }
      if (data?.examSuccessfull && data?.success) {
        setExamSuccess(true)
        setExamSuccessScore(data?.score)
      }

      if (!data?.success) {
        setUncompeletAnswer(true)
        setFeedback(data)
      } else {
        resetForm()
        setSelectedOptions([])
        setFeedback(null)
        setQuestionSuccess(p => !p)
        addToast(langContent.Correct_answer, {appearance: 'success'})
        setUncompeletAnswer(false)
        setHintData(null)
      }
    })
  }

  return (
    <div className=" my-10">
      {errorGettingHint ? (
        <Message error list={formatResponseError(errorGettingHint)} />
      ) : null}
      <Segment className="border-none shadow-none bg-transparent">
        {uncompeletAnswer ? (
          <Message error content={langContent.Incomplete_answer} />
        ) : null}

        {errorSubmittingQuestion ? (
          <Message error list={formatResponseError(errorSubmittingQuestion)} />
        ) : null}

        <Dimmer active={submittingQuestion} inverted>
          {/* <Loader active={true} /> */}
        </Dimmer>
        <div>
          <p className="text-primary text-xl">Instructions:</p>
          <p className="break-all">{data?.exerciseInstructions}</p>
        </div>
        <div>
          <div className="flex my-4">
            {/* <p className="text-primary font-semibold">{data?.questionTitle}</p> */}
            <Popup
              disabled={!hintData}
              content={hintData}
              open={popupOpen}
              position="bottom right"
              className="border border-primaryYellow shadow-none break-all mb-4 "
              trigger={
                <Button
                  loading={gettingHint}
                  className={`${
                    popupOpen
                      ? 'bg-white border border-solid  flex items-center ml-auto justify-center text-primaryYellow border-primaryYellow'
                      : 'bg-primaryYellow flex items-center justify-center text-white ml-auto'
                  }`}
                  onClick={() => reqHint()}
                >
                   {langContent.hint}
                  <BsLightbulb className="-mr-2 ml-2 -mt-0.5" />
                </Button>
              }
            />
          </div>
        </div>
        <div className="flex">
          <div className="flex-1 flex-col custom-min-width">
            <p className="text-primary font-semibold mb-4">
              {data?.questionTitle}
            </p>
            <p>{data?.question}</p>
          </div>
          {data?.image && (
            <div className="flex-1 flex justify-center custom-min-width">
              <Image
                size="medium"
                className=""
                src={data?.image}
                rounded
                as="a"
                href={data?.image}
                target="_blank"
              />
            </div>
          )}
        </div>

        <Formik
          initialValues={{
            choices: data?.choices || [
              {
                choice: '',
                choiceNumber: 'A',
              },
              {
                choice: '',
                choiceNumber: 'B',
              },
              {
                choice: '',
                choiceNumber: 'C',
              },
              {
                choice: '',
                choiceNumber: 'D',
              },
            ],
            answers: [],
          }}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {formik => (
            <Form
              autocomplete="off"
              // onSubmit={formik.handleSubmit}
              loading={submittingQuestion}
            >
              <div className="my-4 ">
                <FieldArray name="choices">
                  {({insert, remove, push}) => (
                    <div>
                      {formik.values.choices.length > 0 &&
                        formik.values.choices.map((choice, index) => {
                          return (
                            <>
                              <div className="flex my-2">
                                {/* <FormikInput
                                  name={`choices.${index}.choiceNumber`}
                                  className="mr-4 w-10"
                                  disabled
                                /> */}
                                <Button
                                  type="button"
                                  content={
                                    formik.values.choices[index].choiceNumber
                                  }
                                  // basic
                                  primary={selectedOptions.includes(
                                    formik.values.choices[index].choiceNumber,
                                  )}
                                  className="mr-4 w-10 h-10 mt-2 p-0 border-2 solid text-sm"
                                  onClick={() => {
                                    const choice =
                                      formik.values.choices[index].choiceNumber
                                    onSelectChoice(choice)
                                  }}
                                />
                                <FormikInput
                                  name={`choices.${index}.choice`}
                                  placeholder="Answer choice"
                                  className={`mt-0 w-full max-w-6xl rounded-md`}
                                  disabled
                                />

                                {/* {feedback?.result.length > 0 ? (
                                  feedback?.result.map(
                                    (a, i) =>
                                      a.choiceNumber ===
                                        formik.values.choices[index]
                                          .choiceNumber && a.status === 'wrong',
                                  ) ? (
                                    <FormikInput
                                      name={`choices.${index}.choice`}
                                      placeholder="Answer choice"
                                      className={`mt-0 w-64 rounded-md  border solid border-red-400 } `}
                                      disabled
                                    />
                                  ) : (
                                    <FormikInput
                                      name={`choices.${index}.choice`}
                                      placeholder="Answer choice"
                                      className={`mt-0 w-64 rounded-md`}
                                      disabled
                                    />
                                  )
                                ) : (
                                  <FormikInput
                                    name={`choices.${index}.choice`}
                                    placeholder="Answer choice"
                                    className={`mt-0 w-64 rounded-md`}
                                    disabled
                                  />
                                )} */}

                                {/* <FormikInput
                                  name={`choices.${index}.choice`}
                                  placeholder="Answer choice"
                                  className={`mt-0 w-64 rounded-md ${feedback?.result.map(
                                    (a, i) =>
                                      a.choiceNumber ===
                                        formik.values.choices[index]
                                          .choiceNumber && a.status === 'wrong' 
                                  ) ? 'border solid border-red-400' : '' } `}
                                  disabled
                                /> */}
                                {/* <FormikInput
                                  name={`choices.${index}.choice`}
                                  placeholder="Answer choice"
                                  className={`mt-0 w-64  rounded-md ${feedback?.result[index].choiceNumber ===
                                        formik.values.choices[index]
                                          .choiceNumber && feedback?.result[index].status === 'wrong'  ? 'border solid border-red-400' : ''
                                   } `}
                                  disabled
                                /> */}
                              </div>
                            </>
                          )
                        })}
                    </div>
                  )}
                </FieldArray>

                {/* <FormikDropdown
                  label="Answers"
                  name="answers"
                  options={answerOptions}
                  multiple
                  className="w-80"
                /> */}
                <div className="mt-10"></div>

                {/* <FormikInput name="answer" placeholder="Type the answer" /> */}
                {feedback?.result?.length > 0 &&
                  feedback?.result.map((a, i) => {
                    return (
                      a?.status === 'wrong' && (
                        <div className="mt-3">
                          <FeedbackMessage
                            value={`${a?.choiceNumber} : ${a?.feedback}`}
                          />
                        </div>
                      )
                    )
                  })}
              </div>
              <hr />
              <div className="my-5 mt-10 flex">
                <Button className="ml-auto" primary type="button" onClick={formik.submitForm}>
                {langContent.Submit}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Segment>
    </div>
  )
}

export default ChoiceTeacher
