import React from 'react'
import {Route, Switch, useLocation} from 'react-router-dom'

import StudentVoucherRoute from '../components/shared/student-voucher-route'
import {StudentVoucherSidebar} from '../components/shared/student-voucher-sidbar'
import useMediaQuery from '../hooks/use-media-query'
import ErrorComp from '../pages/error/error'
import StudentVoucherDashboard from '../pages/student-voucher-dashboard'
import AddVoucher from '../pages/student/add-voucher'
import AllChapters from '../pages/student/chapter/all-chapters'
import ChapterContent from '../pages/student/chapter/chapter-content'
import SectionPex from '../pages/student/chapter/section-pex'
import SectionPexLock from '../pages/student/chapter/section-pex-lock'
import SectionVideo from '../pages/student/chapter/section-video'
import Test from '../pages/student/exercise/exercisetest/test'
import TestLock from '../pages/student/exercise/exercisetest/test-lock'
import Wex from '../pages/student/exercise/wex/wex'
import WexLock from '../pages/student/exercise/wex/wex-lock'
import StudentRoom from '../pages/student/studentRoom/student-room'
import VoucherStudentProfile from '../pages/student/voucher-student-profile'
import routes from '../routes'

const StudentVoucherLayout = () => {
  const isSmall = useMediaQuery('(max-width: 1023px)')
  const {pathname} = useLocation()
  let isTestRoute = Boolean(pathname.match(/(\/wex\/|\/pex\/|\/test\/)/gi))

  React.useEffect(() => {
    document.body.classList.add('bg-gray-50')
    return () => document.body.classList.remove('bg-gray-50')
  })
  return (
    <div className="flex relative">
      <div>
        <StudentVoucherSidebar isSmall={isSmall} />
      </div>

      <div
        className={`${
          isSmall ? 'pl-0' : 'ml-64 md:px-4 xl:px-10'
        } mx-auto mt-10 w-full ${isTestRoute ? 'overflow-x-auto' : ''}`}
      >
        <Switch>
          <StudentVoucherRoute
            path={routes.voucherStudent.dashboard}
            component={StudentVoucherDashboard}
          />

          <StudentVoucherRoute
            path={routes.voucherStudent.profile}
            component={VoucherStudentProfile}
          />
          {/* Student Room */}
          <StudentVoucherRoute
            path={routes.voucherStudent.room.studentRoom}
            component={StudentRoom}
          />

          {/* Chapter Section */}
          <StudentVoucherRoute
            path={routes.voucherStudent.chapters.all()}
            component={AllChapters}
            exact
          />

          <StudentVoucherRoute
            path={routes.voucherStudent.chapters.content()}
            component={ChapterContent}
            exact
          />
          <StudentVoucherRoute
            path={routes.voucherStudent.chapters.section.video()}
            component={SectionVideo}
            exact
          />

          {/* exercise section (correct) */}
          <StudentVoucherRoute
            path={routes.voucherStudent.chapters.section.pexlock()}
            component={SectionPexLock}
            exact
          />
          <StudentVoucherRoute
            path={routes.voucherStudent.chapters.section.pex()}
            component={SectionPex}
            exact
          />

          <StudentVoucherRoute
            path={routes.voucherStudent.chapters.exercise.wexlock()}
            component={WexLock}
            exact
          />
          <StudentVoucherRoute
            path={routes.voucherStudent.chapters.exercise.wex()}
            component={Wex}
            exact
          />

          <StudentVoucherRoute
            path={routes.voucherStudent.chapters.exercise.testlock()}
            component={TestLock}
            exact
          />
          <StudentVoucherRoute
            path={routes.voucherStudent.chapters.exercise.test()}
            component={Test}
            exact
          />

          <Route path="*" component={ErrorComp} />
        </Switch>
      </div>
    </div>
  )
}

export default StudentVoucherLayout
