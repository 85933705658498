module.exports = {
  // un auth app
  home: '/',

  // auth screen
  login: '/auth/login',
  register: '/auth/register',

  // auth app
  studentDashboard: '/dashboard/student',
  instructorProfile: '/dashboard/instructor',
  ////////////////////////////////////////
  instructor: {
    profile: '/dashboard/instructor/profile',
    homePage: '/dashboard/instructor/homepage',
    class(classid = ':classid', subjectid = ':subjectid') {
      return `/dashboard/instructor/class/${classid}/subject/${subjectid}`
    },

    chapterContent(
      classid = ':classid',
      subjectid = ':subjectid',
      chapterid = ':chapterid',
    ) {
      return `/dashboard/instructor/class/${classid}/subject/${subjectid}/chapter/${chapterid}`
    },

    exercise: {
      pexlock(
        classid = ':classid',
        subjectid = ':subjectid',
        chapterid = ':chapterid',
        sectionid = ':sectionid',
        pexid = ':pexid',
      ) {
        return `/dashboard/instructor/class/${classid}/subject/${subjectid}/chapter/${chapterid}/section/${sectionid}/pexlock/${pexid}`
      },
      pex(
        classid = ':classid',
        subjectid = ':subjectid',
        chapterid = ':chapterid',
        sectionid = ':sectionid',
        pexid = ':pexid',
        exerciseAttempt = ':exerciseAttempt',
      ) {
        return `/dashboard/instructor/class/${classid}/subject/${subjectid}/chapter/${chapterid}/section/${sectionid}/pex/${pexid}/attempt/${exerciseAttempt}`
      },

      wexlock(
        classid = ':classid',
        subjectid = ':subjectid',
        chapterid = ':chapterid',
        wexid = ':wexid',
      ) {
        return `/dashboard/instructor/class/${classid}/subject/${subjectid}/chapter/${chapterid}/wexlock/${wexid}`
      },
      wex(
        classid = ':classid',
        subjectid = ':subjectid',
        chapterid = ':chapterid',
        wexid = ':wexid',
        exerciseAttempt = ':exerciseAttempt',
      ) {
        return `/dashboard/instructor/class/${classid}/subject/${subjectid}/chapter/${chapterid}/wex/${wexid}/attempt/${exerciseAttempt}`
      },

      testlock(
        classid = ':classid',
        subjectid = ':subjectid',
        chapterid = ':chapterid',
        testid = ':testid',
      ) {
        return `/dashboard/instructor/class/${classid}/subject/${subjectid}/chapter/${chapterid}/testlock/${testid}`
      },
      test(
        classid = ':classid',
        subjectid = ':subjectid',
        chapterid = ':chapterid',
        testid = ':testid',
        exerciseAttempt = ':exerciseAttempt',
      ) {
        return `/dashboard/instructor/class/${classid}/subject/${subjectid}/chapter/${chapterid}/test/${testid}/attempt/${exerciseAttempt}`
      },
    },

    studetInfo(
      classid = ':classid',
      studentid = ':studentid',
      chapterid = ':chapterid',
    ) {
      return `/dashboard/instructor/class/${classid}/student/${studentid}/chapter/${chapterid}`
    },
    exerciseBreakdown(
      classid = ':classid',
      subjectid = ':subjectid',
      chapterid = ':chapterid',
      exerciseid = ':exerciseid',
    ) {
      return `/dashboard/instructor/class/${classid}/subject/${subjectid}/chapter/${chapterid}/exercisebreakdown/${exerciseid}`
    },
  },
  /////////////////////////////////////////

  voucherStudent: {
    dashboard: '/dashboard/student/voucher/dashboard',
    voucher: '/dashboard/student/voucher/addvoucher',
    profile: '/dashboard/student/voucher/profile',
    chapters: {
      all(classid = ':classid', subjectid = ':subjectid') {
        return `/dashboard/student/voucher/class/${classid}/subject/${subjectid}/chapter`
      },
      content(
        classid = ':classid',
        subjectid = ':subjectid',
        chapterid = ':chapterid',
      ) {
        return `/dashboard/student/voucher/class/${classid}/subject/${subjectid}/chapter/${chapterid}`
      },
      section: {
        video(
          classid = ':classid',
          subjectid = ':subjectid',
          chapterid = ':chapterid',
          sectionid = ':sectionid',
        ) {
          return `/dashboard/student/voucher/class/${classid}/subject/${subjectid}/chapter/${chapterid}/section/${sectionid}/video`
        },
        pexlock(
          classid = ':classid',
          subjectid = ':subjectid',
          chapterid = ':chapterid',
          sectionid = ':sectionid',
          pexid = ':pexid',
        ) {
          return `/dashboard/student/voucher/class/${classid}/subject/${subjectid}/chapter/${chapterid}/section/${sectionid}/pexlock/${pexid}`
        },
        pex(
          classid = ':classid',
          subjectid = ':subjectid',
          chapterid = ':chapterid',
          sectionid = ':sectionid',
          pexid = ':pexid',
          exerciseAttempt = ':exerciseAttempt',
        ) {
          return `/dashboard/student/voucher/class/${classid}/subject/${subjectid}/chapter/${chapterid}/section/${sectionid}/pex/${pexid}/attempt/${exerciseAttempt}`
        },
        // pexReview(classid = ':classid', subjectid = ':subjectid', chapterid = ':chapterid', sectionid = ':sectionid', pexid = ':pexid',exerciseAttempt = ':exerciseAttempt', type = '') {
        //   return `/dashboard/student/voucher/class/${classid}/subject/${subjectid}/chapter/${chapterid}?type=${type}&questionId=${pexid}&section=${sectionid}&attempt=${exerciseAttempt}`
        // }
      },
      exercise: {
        wexlock(
          classid = ':classid',
          subjectid = ':subjectid',
          chapterid = ':chapterid',
          wexid = ':wexid',
        ) {
          return `/dashboard/student/voucher/class/${classid}/subject/${subjectid}/chapter/${chapterid}/wexlock/${wexid}`
        },
        wex(
          classid = ':classid',
          subjectid = ':subjectid',
          chapterid = ':chapterid',
          wexid = ':wexid',
          exerciseAttempt = ':exerciseAttempt',
        ) {
          return `/dashboard/student/voucher/class/${classid}/subject/${subjectid}/chapter/${chapterid}/wex/${wexid}/attempt/${exerciseAttempt}`
        },
        testlock(
          classid = ':classid',
          subjectid = ':subjectid',
          chapterid = ':chapterid',
          testid = ':testid',
        ) {
          return `/dashboard/student/voucher/class/${classid}/subject/${subjectid}/chapter/${chapterid}/testlock/${testid}`
        },
        test(
          classid = ':classid',
          subjectid = ':subjectid',
          chapterid = ':chapterid',
          testid = ':testid',
          exerciseAttempt = ':exerciseAttempt',
        ) {
          return `/dashboard/student/voucher/class/${classid}/subject/${subjectid}/chapter/${chapterid}/test/${testid}/attempt/${exerciseAttempt}`
        },
      },
    },
    room: {
      studentRoom: '/dashboard/student/voucher/room',
    },
  },

  ///////////////////////////////////////////
  nonVoucherStudent: {
    dashboard: '/dashboard/student/nonvoucher/dashboard',
    profile: '/dashboard/student/nonvoucher/profile',
    chapters: {
      all(subjectid = ':subjectid') {
        return `/dashboard/student/nonvoucher/subject/${subjectid}/chapter`
      },
      content(subjectid = ':subjectid', chapterid = ':chapterid') {
        return `/dashboard/student/nonvoucher/subject/${subjectid}/chapter/${chapterid}`
      },
      subscribe: {
        pLanes(subjectid = ':subjectid') {
          return `/dashboard/student/nonvoucher/subject/${subjectid}/subscribe/planes`
        },
        checkout(subjectid = ':subjectid', planid = ':planid') {
          return `/dashboard/student/nonvoucher/subject/${subjectid}/subscribe/${planid}/checkout`
        },
      },
      section: {
        pexlock(
          subjectid = ':subjectid',
          chapterid = ':chapterid',
          sectionid = ':sectionid',
          pexid = ':pexid',
        ) {
          return `/dashboard/student/nonvoucher/subject/${subjectid}/chapter/${chapterid}/section/${sectionid}/pexlock/${pexid}`
        },
        pex(
          subjectid = ':subjectid',
          chapterid = ':chapterid',
          sectionid = ':sectionid',
          pexid = ':pexid',
          exerciseAttempt = ':exerciseAttempt',
        ) {
          return `/dashboard/student/nonvoucher/subject/${subjectid}/chapter/${chapterid}/section/${sectionid}/pex/${pexid}/attempt/${exerciseAttempt}`
        },
      },
      exercise: {
        wexlock(
          subjectid = ':subjectid',
          chapterid = ':chapterid',
          wexid = ':wexid',
        ) {
          return `/dashboard/student/nonvoucher/subject/${subjectid}/chapter/${chapterid}/wexlock/${wexid}`
        },
        wex(
          subjectid = ':subjectid',
          chapterid = ':chapterid',
          wexid = ':wexid',
          exerciseAttempt = ':exerciseAttempt',
        ) {
          return `/dashboard/student/nonvoucher/subject/${subjectid}/chapter/${chapterid}/wex/${wexid}/attempt/${exerciseAttempt}`
        },
        testlock(
          subjectid = ':subjectid',
          chapterid = ':chapterid',
          testid = ':testid',
        ) {
          return `/dashboard/student/nonvoucher/subject/${subjectid}/chapter/${chapterid}/testlock/${testid}`
        },
        test(
          subjectid = ':subjectid',
          chapterid = ':chapterid',
          testid = ':testid',
          exerciseAttempt = ':exerciseAttempt',
        ) {
          return `/dashboard/student/nonvoucher/subject/${subjectid}/chapter/${chapterid}/test/${testid}/attempt/${exerciseAttempt}`
        },
      },
    },
    room: {
      studentRoom: '/dashboard/student/nonvoucher/room',
    },
    enrollment: `/dashboard/student/nonvoucher/enrollment`,
  },
}
