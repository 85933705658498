import {Menu} from 'semantic-ui-react'
import { useLanguage } from '../../../context/languageContext'
import { content } from '../../../localization/content'

const TeacherClassTabs = ({activeMenu, setActiveMenu}) => {
  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  return (
    <div class="flex">
        <Menu
          className="mt-2 border-none shadow-none  bg-transparent text-xl  m-2"
          stackable
        >
            <>
              <Menu.Item
                active={activeMenu === 'studentsInfo'}
                onClick={() => setActiveMenu('studentsInfo')}
                 className={`${
                      activeMenu === 'studentsInfo' ? 'border-b-4 border-primary text-primary bg-transparent' : 'bg-gray-50'
                    }`}
              >
                <div className="flex flex-col w-full ">
                 
                  <p className="font-medium text-labelColor">{langContent.Students_info}</p>
                  <div
                    // className={` ${
                    //   activeMenu === 'studentsInfo' ? 'border-t-2 border-red-800' : 'hidden'
                    // }`}
                  ></div>
                </div>
              </Menu.Item>
              <Menu.Item
                active={activeMenu === 'chapterProgress'}
                onClick={() => setActiveMenu('chapterProgress')}
                 className={` ${
                      activeMenu === 'chapterProgress' ? 'border-b-4 border-primary text-primary bg-transparent' : 'bg-gray-50'
                    }`}
              >
                <div className="flex flex-col w-full ">
                 
                  <p className="font-medium text-labelColor">{langContent.Chapter_progress}</p>
                  <div
                    // className={`bottom-arrow ${
                    //   activeMenu === 'chapterProgress' ? 'border-t-2 border-red-800' : 'hidden'
                    // }`}
                  ></div>
                </div>
              </Menu.Item>
              <Menu.Item
                active={activeMenu === 'exercise'}
                onClick={() => setActiveMenu('exercise')}
                 className={` ${
                      activeMenu === 'exercise' ? 'border-b-4 border-primary text-primary bg-transparent' : 'bg-gray-50'
                    }`}
              >
                <div className="flex flex-col w-full ">
                 
                  <p className="font-medium text-labelColor">{langContent.Exercise}</p>
                  <div
                    // className={` ${
                    //   activeMenu === 'exercise' ? 'border-t-2 border-red-800' : 'hidden'
                    // }`}
                  ></div>
                </div>
              </Menu.Item>
              <Menu.Item
                active={activeMenu === 'subjectContent'}
                onClick={() => setActiveMenu('subjectContent')}
                 className={` ${
                      activeMenu === 'subjectContent' ? 'border-b-4 border-primary text-primary bg-transparent' : 'bg-gray-50'
                    }`}
              >
                <div className="flex flex-col w-full ">
                 
                  <p className="font-medium text-labelColor">{langContent.Subject_content}</p>
                  <div
                    // className={` ${
                    //   activeMenu === 'subjectContent' ? 'border-t-2 border-red-800' : 'hidden'
                    // }`}
                  ></div>
                </div>
              </Menu.Item>
              <Menu.Item
                active={activeMenu === 'pdfs'}
                onClick={() => setActiveMenu('pdfs')}
                className={` ${
                      activeMenu === 'pdfs' ? 'border-b-4 border-primary text-primary bg-transparent' : 'bg-gray-50'
                    }`}
              >
                <div className="flex flex-col w-full ">
          
                  <p className="font-medium text-labelColor">{langContent.PDFs}</p>
                  <div
                    className={` ${
                      activeMenu === 'pdfs' ? 'visible' : 'hidden'
                    }`}
                  ></div>
                </div>
              </Menu.Item>
            </>
        </Menu>
    </div>
  )
}

export default TeacherClassTabs
