import React, {useState} from 'react'
import TeacherExerciseBreakdownTabs from '../../../components/teacher/class/teacher-exercise-breakdown-tabs'
import TeacherExerciseOverview from './teacher-exercise-overview'
import TeacherExerciseQuestionBreakdown from './teacher-exercise-question-breakdown'

const TeacherExerciseBreakdown = () => {
  const [activeMenu, setActiveMenu] = useState('overview')

  return (
    <div className='xl:px-12'>
      <div className="">
        <TeacherExerciseBreakdownTabs
          activeMenu={activeMenu}
          setActiveMenu={value => setActiveMenu(value)}
        />
      </div>
      <div className="mt-2 ">
        {activeMenu === 'overview' && <TeacherExerciseOverview />}
        {activeMenu === 'questionbreakdown' && (
          <TeacherExerciseQuestionBreakdown />
        )}
      </div>
    </div>
  )
}

export default TeacherExerciseBreakdown
