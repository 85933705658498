import React from 'react'
import {FaMoneyCheck, FaUserGraduate} from 'react-icons/fa'
import {BiTimeFive} from 'react-icons/bi'
import {Dimmer, Loader, Message, Segment, Table} from 'semantic-ui-react'
import {useParams} from 'react-router-dom'

import {truncate} from '../../../utils/string'
import useAsync from '../../../hooks/useAsync'
import {authAxios} from '../../../config/axiosConfig'
import {formatResponseError} from '../../../utils/format-response'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'

const TeacherExerciseOverview = () => {
  const {classid, subjectid, chapterid, exerciseid} = useParams()
  const [exerciseData, setExerciseData] = React.useState([])

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  const {
    run: getExerciseDetails,
    error: errorGettingExerciseDetails,
    isLoading: gettingExerciseDetails,
  } = useAsync()

  React.useEffect(() => {
    getExerciseDetails(
      authAxios.get(
        `/instructor/class/chapter/exercise/?classId=${classid}&chapter=${chapterid}&exercise=${exerciseid}`,
      ),
    ).then(({data}) => {
      console.log(data)
      setExerciseData(data)
    })
  }, [chapterid, classid, exerciseid, getExerciseDetails])

  return (
    <div className=" h-screen p-0">
      <Segment className="border-none shadow-none bg-transparent ">
        {errorGettingExerciseDetails ? (
          <Message
            error
            list={formatResponseError(errorGettingExerciseDetails)}
          />
        ) : null}
        <Dimmer active={gettingExerciseDetails} inverted>
          <Loader active={true} />
        </Dimmer>
        <div className="mt-12 flex flex-wrap gap-x-4 xl:gap-x-12 gap-y-4  items-center ">
          <div className="w-72 h-36 shadow-xl rounded-2xl p-8">
            <p className="font-semibold text-sm text-gray-400">
              {langContent.Number_of_students}
            </p>
            <div className="flex justify-between mt-8">
              <p className="text-2xl font-semibold">
                {exerciseData?.joinedStudents}/{exerciseData?.totalStudents}
              </p>
              <FaUserGraduate className="text-3xl text-blue-500" />
            </div>
          </div>

          <div className="w-72 h-36 shadow-xl rounded-2xl p-8">
            <p className="font-semibold text-sm text-gray-400">
              {langContent.Avergae_score}
            </p>
            <div className="flex justify-between mt-8">
              <p className="text-2xl font-semibold">
                {parseFloat(exerciseData?.averageScore).toFixed(0)}%
              </p>
              <FaMoneyCheck className="text-3xl text-green-400" />
            </div>
          </div>

          <div className="w-72 h-36 shadow-xl rounded-2xl p-8">
            <p className="font-semibold text-sm text-gray-400">
              {langContent.Average_time_taken_by_students}
            </p>
            <div className="flex justify-between mt-8">
              <p className="text-2xl font-semibold">
                {parseInt(exerciseData?.time?.hours)} :{' '}
                {parseInt(exerciseData?.time?.minutes)} :{' '}
                {parseInt(exerciseData?.time?.seconds)}
              </p>
              <BiTimeFive className="text-3xl text-yellow-600" />
            </div>
          </div>

          <Table striped className="shadow-lg my-14">
            <Table.Header>
              <Table.Row
                className="text-sm"
                textAlign="center"
                verticalAlign="middle"
              >
                <Table.HeaderCell className="bg-tableHeader">
                  {langContent.Students_name}
                </Table.HeaderCell>
                <Table.HeaderCell className="bg-tableHeader">
                  {langContent.Status}
                </Table.HeaderCell>
                <Table.HeaderCell className="bg-tableHeader">
                  Score
                </Table.HeaderCell>
                <Table.HeaderCell className="bg-tableHeader">
                  {langContent.Duration}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {exerciseData?.students?.map((a, idx) => (
                <Table.Row textAlign="center" verticalAlign="middle" key={idx}>
                  <Table.Cell>{truncate(a?.name, 30)}</Table.Cell>
                  <Table.Cell>
                    <p
                      className={` ${
                        a?.status === 'Not Done' || a?.status === 'Non'
                          ? 'bg-red-500 text-white '
                          : 'bg-green-500 text-white'
                      }   py-4 px-0 text-center rounded-lg`}
                    >
                      {truncate(a?.status, 30)}
                    </p>
                  </Table.Cell>
                  <Table.Cell>{a?.score?.toFixed(0)}%</Table.Cell>
                  <Table.Cell>
                    {a?.duration?.seconds && (
                      <>
                        {parseInt(a?.duration?.hours)} :{' '}
                        {parseInt(a?.duration?.minutes)} :{' '}
                        {parseInt(a?.duration?.seconds)}
                      </>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </Segment>
    </div>
  )
}

export default TeacherExerciseOverview
