import {Menu} from 'semantic-ui-react'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'

const ChapterTabs = ({activeMenu, setActiveMenu}) => {
  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  return (
    <div class="flex">
      <Menu
        className="mt-2 border-none shadow-none text-xl  m-2"
        // stackable
      >
        <>
          <Menu.Item
            active={activeMenu === 'practicRoom'}
            onClick={() => setActiveMenu('practicRoom')}
            className={`bottom-arrow ${
              activeMenu === 'practicRoom'
                ? 'border-b-4 border-primary bg-gray-50'
                : 'bg-gray-50'
            }`}
          >
            <div className="flex flex-col w-full ">
              <p className="font-medium text-labelColor">
                {langContent.Practic_Room}
              </p>
              <div
              // className={`bottom-arrow ${
              //   activeMenu === 'practicRoom' ? 'border-t-2 border-red-800' : 'hidden'
              // }`}
              ></div>
            </div>
          </Menu.Item>
          <Menu.Item
            active={activeMenu === 'homeworkRoom'}
            onClick={() => setActiveMenu('homeworkRoom')}
            className={`bottom-arrow ${
              activeMenu === 'homeworkRoom'
                ? 'border-b-4 border-primary bg-gray-50'
                : 'bg-gray-50'
            }`}
          >
            <div className="flex flex-col w-full ">
              <p className="font-medium text-labelColor">
                {langContent.Homework_room}
              </p>
              <div
                className={`bottom-arrow ${
                  activeMenu === 'homeworkRoom' ? 'visible' : 'hidden'
                }`}
              ></div>
            </div>
          </Menu.Item>
          <Menu.Item
            active={activeMenu === 'studentPdf'}
            onClick={() => setActiveMenu('studentPdf')}
            className={`bottom-arrow ${
              activeMenu === 'studentPdf'
                ? 'border-b-4 border-primary bg-gray-50'
                : 'bg-gray-50'
            }`}
          >
            <div className="flex flex-col w-full ">
              <p className="font-medium text-labelColor">Student PDFs</p>
            </div>
          </Menu.Item>
        </>
      </Menu>
    </div>
  )
}

export default ChapterTabs
