import {FieldArray, Formik} from 'formik'
import React from 'react'
import {useHistory, useParams} from 'react-router'
import {
  Button,
  Dimmer,
  Form,
  Icon,
  Image,
  Loader,
  Message,
  Popup,
  Segment,
  Table,
} from 'semantic-ui-react'
import {IoMdAdd, IoMdRemove} from 'react-icons/io'
import {BsLightbulb} from 'react-icons/bs'

import useAccountNames from '../../../../hooks/use-account-names'
import useAsync from '../../../../hooks/useAsync'
import {authAxios} from '../../../../config/axiosConfig'
import {formatResponseError} from '../../../../utils/format-response'
import FormikDropdown from '../../../../components/formik/FormikDropdown'
import FormikInput from '../../../../components/formik/FormikInput'
import {useToasts} from 'react-toast-notifications'
import Time from '../../../../utils/time'
import FeedbackMessage from '../feedback-message'
import {useLanguage} from '../../../../context/languageContext'
import {content} from '../../../../localization/content'

const UpdateTAccountQuestion = ({
  examType,
  data,
  setQuestionSuccess,
  setExamSuccess,
  setExamSuccessScore,
}) => {
  const [hintData, setHintData] = React.useState()
  const [popupOpen, setPopupOpen] = React.useState(false)
  const [uncompeletAnswer, setUncompeletAnswer] = React.useState(false)
  const [feedback, setFeedback] = React.useState()
  const {addToast} = useToasts()
  const {exerciseAttempt} = useParams()
  const history = useHistory()

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  const {accountNames, errorGettingAccountNames, gettingAccountNames} =
    useAccountNames()

  const {
    run: getSectionNames,
    data: sectionNames,
    error: errorGettingSectionNames,
    isLoading: gettingSectionNames,
  } = useAsync({data: []})

  const {
    run: submitQuestion,
    error: errorSubmittingQuestion,
    isLoading: submittingQuestion,
  } = useAsync()

  const {
    run: getHint,
    error: errorGettingHint,
    isLoading: gettingHint,
  } = useAsync()

  const reqHint = () => {
    // setPopupOpen(prevOpen => !prevOpen)
    if (hintData) {
      setPopupOpen(prevOpen => !prevOpen)
    } else {
      getHint(
        authAxios.get(
          `/correct/exercise/question/hint/${data?.attemptId}?attempt=${exerciseAttempt}`,
        ),
      ).then(({data}) => {
        setPopupOpen(true)
        console.log(data)
        setHintData(data)
      })
    }
  }

  function onSubmit(values, {resetForm}) {
    let timer = JSON.parse(
      window.localStorage.getItem(
        `questionId-${data?._id}-attemptId-${data?.attemptId}`,
      ),
    )
    const timeData = Time.getTimeFromSeconds(timer.seconds)

    let obj = {
      ...values,
      time: {
        hours: timeData?.hours,
        minutes: timeData?.minutes,
        seconds: timeData?.seconds,
      },
      attemptId: data?.attemptId,
      questionId: data?._id,
    }

    console.log(obj)

    submitQuestion(
      authAxios.post(
        `/correct/exercise/${exerciseAttempt}?qtype=taccount`,
        obj,
      ),
    ).then(({data}) => {
      console.log(data)
      if (examType === 'test') {
        resetForm()
        setFeedback(null)
        setQuestionSuccess(p => !p)
        setUncompeletAnswer(false)
        setHintData(null)
        return
      }
      if (data?.examSuccessfull && data?.success) {
        setExamSuccess(true)
        setExamSuccessScore(data?.score)
      }
      if (!data?.success) {
        setUncompeletAnswer(true)
        setFeedback(data)
      } else {
        resetForm()
        setFeedback(null)
        setQuestionSuccess(p => !p)
        addToast(langContent.Correct_answer, {appearance: 'success'})
        setUncompeletAnswer(false)
        setHintData(null)
      }
    })
  }

  React.useEffect(() => {
    getSectionNames(authAxios.get('/question/taccount/sectionname'))
  }, [getSectionNames])

  return (
    <div className=" my-10">
      <Segment className="border-none shadow-none bg-transparent">
        {uncompeletAnswer ? (
          <Message error content={langContent.Incomplete_answer} />
        ) : null}
        {errorGettingAccountNames ? (
          <Message error list={formatResponseError(errorGettingAccountNames)} />
        ) : null}

        {errorGettingSectionNames ? (
          <Message error list={formatResponseError(errorGettingSectionNames)} />
        ) : null}

        {errorGettingHint ? (
          <Message error list={formatResponseError(errorGettingHint)} />
        ) : null}
        {errorSubmittingQuestion ? (
          <Message error list={formatResponseError(errorSubmittingQuestion)} />
        ) : null}

        <Dimmer
          active={
            submittingQuestion || gettingSectionNames || gettingAccountNames
          }
          inverted
        >
          <Loader active={true} />
        </Dimmer>

        <div>
          <p className="text-primary text-xl">Instructions:</p>
          <p className="break-all">{data?.exerciseInstructions}</p>
        </div>
        <div>
          <div className="flex my-4">
            {/* <p className="text-primary font-semibold">{data?.questionTitle}</p> */}
            <Popup
              disabled={!hintData}
              content={hintData}
              open={popupOpen}
              position="bottom right"
              className="border border-primaryYellow shadow-none break-all mb-4 "
              trigger={
                <Button
                  loading={gettingHint}
                  className={`${
                    popupOpen
                      ? 'bg-white border border-solid  flex items-center ml-auto justify-center text-primaryYellow border-primaryYellow'
                      : 'bg-primaryYellow flex items-center justify-center text-white ml-auto'
                  }`}
                  onClick={() => reqHint()}
                >
                  {langContent.hint}
                  <BsLightbulb className="-mr-2 ml-2 -mt-0.5" />
                </Button>
              }
            />
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="flex-1 flex-col custom-min-width">
            <p className="text-primary font-semibold mb-4">
              {data?.questionTitle}
            </p>
            <p>{data?.question}</p>
          </div>
          {data?.image && (
            <div className="flex-1 flex justify-center custom-min-width">
              <Image
                size="medium"
                className=""
                src={data?.image}
                rounded
                as="a"
                href={data?.image}
                target="_blank"
              />
            </div>
          )}
        </div>

        <div className="md:max-w-5xl sm:max-w-lg max-w-sm mx-auto px-2 md:px-4">
          <Formik
            initialValues={{
              answers: sectionNames?.map(s => ({
                sectionName: s._id,
                answers: [
                  {
                    account: '',
                    debitValue: '',
                    creditValue: '',
                  },
                ],
              })),
            }}
            enableReinitialize
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {formik => (
              <Form
              // onSubmit={formik.handleSubmit}
              // loading={gettingSectionNames || gettingAccountNames}
              >
                <div className="mt-10">
                  <div class="flex flex-wrap">
                    <FieldArray name="answers">
                      {() =>
                        formik.values.answers?.length > 0
                          ? formik.values.answers.map((s, i) => (
                              <div class="flex flex-col mb-8" key={i}>
                                <p
                                  className={`mb-2 font-semibold text-center ${
                                    i % 2 === 0 ? '' : 'ml-4'
                                  }`}
                                >
                                  {sectionNames?.find(
                                    sn => sn._id === s.sectionName,
                                  )?.name === 'Active'
                                    ? langContent.Active
                                    : sectionNames?.find(
                                        sn => sn._id === s.sectionName,
                                      )?.name === 'Charges'
                                    ? langContent.Charges
                                    : sectionNames?.find(
                                        sn => sn._id === s.sectionName,
                                      )?.name === 'Products'
                                    ? langContent.Products
                                    : sectionNames?.find(
                                        sn => sn._id === s.sectionName,
                                      )?.name === 'Liabilities'
                                    ? langContent.Liabilities
                                    : null}
                                </p>
                                <FieldArray
                                  key={i}
                                  name={`answers.${i}.answers`}
                                >
                                  {({push, remove}) =>
                                    formik.values.answers[i].answers
                                      ? formik.values.answers[i].answers?.map(
                                          (a, j) => {
                                            return (
                                              <div
                                                key={j}
                                                className={`mb-4 w-111 ${
                                                  i % 2 === 0
                                                    ? 'sm:mr-10'
                                                    : 'sm:ml-10'
                                                }`}
                                              >
                                                <div class="flex items-end">
                                                  <div className="flex-grow mr-2 ">
                                                    <FormikDropdown
                                                      width={20}
                                                      name={`answers.${i}.answers.${j}.account`}
                                                      options={accountNames}
                                                      placeholder="Account name"
                                                      search
                                                      className="taccountDropdown "
                                                      clearable
                                                    />

                                                    {feedback?.sections?.[
                                                      `${
                                                        sectionNames?.find(
                                                          sn =>
                                                            sn._id ===
                                                            s.sectionName,
                                                        )?.name
                                                      }`
                                                    ]?.table?.map(
                                                      a =>
                                                        a?.row === j &&
                                                        a?.feedback && (
                                                          <FeedbackMessage
                                                            value={a?.feedback}
                                                          />
                                                        ),
                                                    )}
                                                  </div>
                                                  <div class="flex items-center">
                                                    {formik.values.answers[i]
                                                      .answers.length -
                                                      1 ===
                                                      j &&
                                                    formik.values.answers[i]
                                                      .answers.length < 3 ? (
                                                      <Button
                                                        basic
                                                        type="button"
                                                        size="small"
                                                        className="mr-2"
                                                        onClick={() =>
                                                          push({
                                                            account: '',
                                                            debitValue: '',
                                                            creditValue: '',
                                                          })
                                                        }
                                                      >
                                                        <IoMdAdd />
                                                      </Button>
                                                    ) : null}
                                                    {j !== 0 ? (
                                                      <Button
                                                        basic
                                                        type="button"
                                                        size="small"
                                                        onClick={() =>
                                                          remove(j)
                                                        }
                                                      >
                                                        <IoMdRemove />
                                                      </Button>
                                                    ) : null}
                                                  </div>
                                                </div>
                                                <Table basic="very" singleLine>
                                                  <Table.Row>
                                                    <Table.HeaderCell>
                                                      D
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell textAlign="right">
                                                      C
                                                    </Table.HeaderCell>
                                                  </Table.Row>
                                                  <Table.Row>
                                                    <Table.Cell className="max-w-sm border-r-2 border-l-0 solid border-gray-400">
                                                      <div className="flex items-center justify-center">
                                                        <FormikInput
                                                          className="taccountInput"
                                                          name={`answers.${i}.answers.${j}.debitValue`}
                                                          placeholder="debit value"
                                                          isNumber
                                                          disabled={Boolean(
                                                            formik.values
                                                              .answers[i]
                                                              .answers[j]
                                                              .creditValue,
                                                          )}
                                                        />

                                                        {/* {feedback?.sections?.[
                                                        `${
                                                          sectionNames?.find(
                                                            sn =>
                                                              sn._id ===
                                                              s.sectionName,
                                                          )?.name
                                                        }`
                                                      ]?.table?.map(
                                                        a =>
                                                          a?.row === j &&
                                                          a?.debitValueFeedback && (
                                                            <FeedbackMessage
                                                              value={
                                                                a?.debitValueFeedback
                                                              }
                                                            />
                                                          ),
                                                      )} */}

                                                        {feedback?.sections?.[
                                                          `${
                                                            sectionNames?.find(
                                                              sn =>
                                                                sn._id ===
                                                                s.sectionName,
                                                            )?.name
                                                          }`
                                                        ]?.table?.map(
                                                          a =>
                                                            a?.row === j &&
                                                            a?.debitValueFeedback && (
                                                              <div className="">
                                                                <Popup
                                                                  trigger={
                                                                    <Icon
                                                                      name="warning"
                                                                      className=" ml-2 -mt-1"
                                                                      color="red"
                                                                      circular
                                                                    />
                                                                  }
                                                                  content={
                                                                    a?.debitValueFeedback
                                                                  }
                                                                  className="border border-red-600 text-red-600"
                                                                  // position='top left'
                                                                />
                                                              </div>
                                                            ),
                                                        )}
                                                      </div>
                                                    </Table.Cell>
                                                    <Table.Cell className="max-w-sm border-l border-r-0  solid border-gray-400">
                                                      <div className="flex items-center justify-center">
                                                        <FormikInput
                                                          className="taccountInput"
                                                          name={`answers.${i}.answers.${j}.creditValue`}
                                                          placeholder="credit value"
                                                          isNumber
                                                          disabled={Boolean(
                                                            formik.values
                                                              .answers[i]
                                                              .answers[j]
                                                              .debitValue,
                                                          )}
                                                        />

                                                        {/* {feedback?.sections?.[
                                                        `${
                                                          sectionNames?.find(
                                                            sn =>
                                                              sn._id ===
                                                              s.sectionName,
                                                          )?.name
                                                        }`
                                                      ]?.table?.map(
                                                        a =>
                                                          a?.row === j &&
                                                          a?.creditValueFeedback && (
                                                            <FeedbackMessage
                                                              value={
                                                                a?.creditValueFeedback
                                                              }
                                                            />
                                                          ),
                                                      )} */}

                                                        {feedback?.sections?.[
                                                          `${
                                                            sectionNames?.find(
                                                              sn =>
                                                                sn._id ===
                                                                s.sectionName,
                                                            )?.name
                                                          }`
                                                        ]?.table?.map(
                                                          a =>
                                                            a?.row === j &&
                                                            a?.creditValueFeedback && (
                                                              <div className="">
                                                                <Popup
                                                                  trigger={
                                                                    <Icon
                                                                      name="warning"
                                                                      className=" ml-2 -mt-1"
                                                                      color="red"
                                                                      circular
                                                                    />
                                                                  }
                                                                  content={
                                                                    a?.creditValueFeedback
                                                                  }
                                                                  className="border border-red-600 text-red-600"
                                                                  // position='top left'
                                                                />
                                                              </div>
                                                            ),
                                                        )}
                                                      </div>
                                                    </Table.Cell>
                                                  </Table.Row>
                                                </Table>
                                              </div>
                                            )
                                          },
                                        )
                                      : null
                                  }
                                </FieldArray>
                              </div>
                            ))
                          : null
                      }
                    </FieldArray>
                  </div>
                </div>
                <hr className="my-5" />

                <div className="my-5 flex">
                  <Button
                    className="ml-auto"
                    primary
                    type="button"
                    onClick={formik.submitForm}
                  >
                    {langContent.Submit}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Segment>
    </div>
  )
}

export default UpdateTAccountQuestion
