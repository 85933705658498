import React, {useContext, useState} from 'react'
import {useToasts} from 'react-toast-notifications'
import {Modal} from 'semantic-ui-react'
import StateContext from '../../../context/stateContext'
import useAsync from '../../../hooks/useAsync'
import ClassCopyLink from './class-copy-link'
import CreateClassForm from './create-class-form'

const CreateClassModal = ({reload}) => {
  // const [lang] = useLanguage()
  // const selectedLang = content[lang]
  const [open, setOpen] = useState(false)
  const {addToast} = useToasts()
  const {showModal, setShowModal} = useContext(StateContext)
  const [activeStep, setActiveStep] = useState(1)
  const [data, setData] = useState()

  React.useEffect(() => {
    if (showModal.modalName === 'createClass') {
      setOpen(true)
    } else {
      setOpen(false)
      setActiveStep(1)
    }
  }, [showModal])

  const handleNext = v => {
    setData(v)
    setActiveStep(p => p + 1)
  }

  return (
    <Modal
      onClose={() => setShowModal({modalName: '', data: null})}
      open={open}
      closeIcon
      className="main-font animate__animated animate__fadeInDown"
      size="tiny"
      closeOnDimmerClick={false}
      
    >
      <Modal.Content>
        {activeStep === 1 && <CreateClassForm reload={reload} nextStep={v => handleNext(v)} />}
        {activeStep === 2 && <ClassCopyLink data={data} />}
      </Modal.Content>
    </Modal>
  )
}

export default CreateClassModal
