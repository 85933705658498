import React from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useToasts} from 'react-toast-notifications'
import {
  Button,
  Dimmer,
  Form,
  Icon,
  Image,
  Loader,
  Message,
  Popup,
  Segment,
  Table,
} from 'semantic-ui-react'
import {BsLightbulb} from 'react-icons/bs'
import {FieldArray, Formik} from 'formik'
import useAsync from '../../../../hooks/useAsync'
import { authAxios } from '../../../../config/axiosConfig'
import Time from '../../../../utils/time'
import { formatResponseError } from '../../../../utils/format-response'
import FormikInput from '../../../../components/formik/FormikInput'
import FeedbackMessage from '../../../student/exercise/feedback-message'


const NonVoucherBlank = ({examType, data, setQuestionSuccess, setExamSuccess, setExamSuccessScore}) => {
  const [hintData, setHintData] = React.useState()
  const [popupOpen, setPopupOpen] = React.useState(false)
  const [uncompeletAnswer, setUncompeletAnswer] = React.useState(false)
  const [feedback, setFeedback] = React.useState()
  const {addToast} = useToasts()

  const {classid, subjectid, chapterid, sectionid, pexid, exerciseAttempt} =
    useParams()
  const history = useHistory()

  const {
    run: submitQuestion,
    error: errorSubmittingQuestion,
    isLoading: submittingQuestion,
  } = useAsync()

  const {
    run: getHint,
    error: errorGettingHint,
    isLoading: gettingHint,
  } = useAsync()

  const reqHint = () => {
    // setPopupOpen(prevOpen => !prevOpen)
    if (hintData) {
      setPopupOpen(prevOpen => !prevOpen)
    } else {
      getHint(
        authAxios.get(
          `/correct/exercise/question/hint/${data?.attemptId}?attempt=${exerciseAttempt}`,
        ),
      ).then(({data}) => {
        setPopupOpen(true)
        console.log(data)
        setHintData(data)
      })
    }
  }

  function onSubmit(values, {resetForm}) {
    let timer = JSON.parse(
      window.localStorage.getItem(
        `questionId-${data?._id}-attemptId-${data?.attemptId}`,
      ),
    )
    const timeData = Time.getTimeFromSeconds(timer.seconds)

    let obj = {
      ...values,
      time: {
        hours: timeData?.hours,
        minutes: timeData?.minutes,
        seconds: timeData?.seconds,
      },
      attemptId: data?.attemptId,
      questionId: data?._id,
    }

    submitQuestion(
      authAxios.post(`/correct/exercise/${exerciseAttempt}?qtype=blank`, obj),
    ).then(({data}) => {
      if (examType === 'test') {
        resetForm()
        setFeedback(null)
        setQuestionSuccess(p => !p)
        setUncompeletAnswer(false)
        setHintData(null)
        return
      }
      if (data?.examSuccessfull) {
        setExamSuccess(true)
        setExamSuccessScore(data?.score)
      }
      if (!data?.success) {
        setUncompeletAnswer(true)
        setFeedback(data)
      } else {
        resetForm()
        setFeedback(null)
        setQuestionSuccess(p => !p)
        addToast('Correct answer ', {appearance: 'success'})
        setUncompeletAnswer(false)
        setHintData(null)
      }
    })
  }

  return (
    <div className=" my-10">
      <Segment className="border-none shadow-none bg-transparent">
        {errorGettingHint ? (
          <Message error list={formatResponseError(errorGettingHint)} />
        ) : null}
        {uncompeletAnswer ? (
          <Message error content="Incompleted Answer!" />
        ) : null}

        {errorSubmittingQuestion ? (
          <Message error list={formatResponseError(errorSubmittingQuestion)} />
        ) : null}

        <Dimmer active={submittingQuestion} inverted>
          {/* <Loader active={true} /> */}
        </Dimmer>
        <div>
          <p className="text-primary text-xl">Instructions:</p>
          <p className="break-all">{data?.exerciseInstructions}</p>
        </div>
        <div>
          <div className="flex my-4">
            {/* <p className="text-primary font-semibold">{data?.questionTitle}</p> */}
            <Popup
              disabled={!hintData}
              content={hintData}
              open={popupOpen}
              position="bottom right"
              className="border border-primaryYellow shadow-none break-all mb-4 "
              trigger={
                <Button
                  loading={gettingHint}
                  className={`${
                    popupOpen
                      ? 'bg-white border border-solid  flex items-center ml-auto justify-center text-primaryYellow border-primaryYellow'
                      : 'bg-primaryYellow flex items-center justify-center text-white ml-auto'
                  }`}
                  onClick={() => reqHint()}
                >
                  Hint
                  <BsLightbulb className="-mr-2 ml-2 -mt-0.5" />
                </Button>
              }
            />
          </div>
        </div>
        <div className="flex">
          <div className="flex-1 flex-col custom-min-width">
            <p className="text-primary font-semibold mb-4">
              {data?.questionTitle}
            </p>
            <p>{data?.question}</p>
          </div>
          {data?.image && (
            <div className="flex-1 flex justify-center custom-min-width">
            <Image size="medium" className="" src={data?.image} rounded as='a' href={data?.image} target="_blank" />
            </div>
          )}
        </div>

        <Formik
          initialValues={{
            answer: '',
          }}
          onSubmit={onSubmit}
        >
          {formik => (
            <Form
              autocomplete="off"
              // onSubmit={formik.handleSubmit}
              loading={submittingQuestion}
            >
              <div className="my-4 max-w-xl">
                <FormikInput name="answer" placeholder="Type the answer"  isNumber/>
                {feedback?.feedback && (
                  <FeedbackMessage value={feedback?.feedback} />
                )}
              </div>
              <hr />
              <div className="my-5  flex">
                <Button className="ml-auto" primary type="button" onClick={formik.submitForm}>
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Segment>
    </div>
  )
}

export default NonVoucherBlank
