import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js'
import React from 'react'
import {useLocation} from 'react-router-dom'
import {Button, Dimmer, Loader, Message, Segment} from 'semantic-ui-react'
import queryString from 'query-string'

import {formatResponseError} from '../../../utils/format-response'

const CheckoutForm = ({clientSecret ,setStatusModal}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  const stripe = useStripe()
  const elements = useElements()
  const location = useLocation()

  
  // React.useEffect(() => {
  //   if (!stripe || !elements) {
  //     setIsLoading(true)
  //   }else{
  //     setIsLoading(false)
  //   }
  // }, [elements, stripe])
  
  
  React.useEffect(() => {
    const {payment_intent: paymentIntentId} = queryString.parse(location.search)
    

    if (stripe && clientSecret && paymentIntentId) {
      stripe?.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
        switch(paymentIntent.status) {
          case 'succeeded':
            setStatusModal('succeeded')
            break
          case 'processing':
            // Payment proccessing, we'll updated you once the payment is received
          break
          case 'required_payment_method':
            // payment failed, please try another payment method
            break
          default:
            break
        }
      })
    }
  }, [clientSecret, location.search, setStatusModal, stripe])

  const handleSubmit = async event => {
    setIsLoading(true)
    event.preventDefault()

    if (!stripe || !elements) {
    setIsLoading(false)
    return
    }
    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
    })

    setIsLoading(false)

    if (error) {
      console.log(error)
      setErrorMessage(error.message)
    }
  }

  return (
    <div className="flex flex-col mt-8">
      <Segment className="border-none shadow-none bg-transparent w-full">
        {errorMessage ? (
          <Message error content={errorMessage} />
        ) : null}

        <Dimmer active={isLoading} inverted>
          <Loader active={true} />
        </Dimmer>
        <PaymentElement />
        <div className='flex items-center justify-center'>

        <Button
          primary
          className="my-4 px-16  mx-auto"
          disabled={!stripe}
          onClick={handleSubmit}
        >
          Checkout securely
        </Button>
        </div>

      </Segment>
    </div>
  )
}

export default CheckoutForm
