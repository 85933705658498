import React, {useLayoutEffect} from 'react'
import Countdown from 'react-countdown'
import {useState, useEffect} from 'react'
import useLocalStorage from '../../../../../hooks/use-local-storage'

const time = Date.now()

const CountDownTimer = ({
  attemptId,
  questionId,
  expiryTimestamp,
  setTimeExpire,
}) => {
  const [questAttemptId, setQuestAttemptId] = useLocalStorage(
    'questAttemptId',
    '',
  )
  const [questId, setQuestId] = useLocalStorage('questId', '')
  const [itemONLG, setItemONLG] = useState(false)
  //   const [wantedDelay, setWantedDelay] = useState(false)

  const [data, setData] = useState(
    {date: 0, delay: 0}, //10 seconds
  )
  const wantedDelay = 60 * 1000

  const Completionist = () => <span>Time Finished</span>

  const renderer = ({hours, minutes, seconds, completed}) => {
    if (completed) {
      // Render a complete state
      setTimeExpire(true)
      return <Completionist />
    } else {
      // Render a countdown
      return (
        <span>
          Time remaining : {hours}:{minutes}:{seconds}
        </span>
      )
    }
  }

  // React.useEffect(() => {
  //   if(expiryTimestamp){
  //     setData({date: Date.now(), delay: expiryTimestamp * 1000 })
  //     setWantedDelay(expiryTimestamp * 1000)
  //   }
  // }, [expiryTimestamp])

  const getLocalStorageValue = s => localStorage.getItem(s)

  
  //////////////////
  //[START] componentDidMount
  //Code runs only one time after each reloading
  // useEffect(() => {
  //   if (questionId && attemptId) {
  //     if (attemptId !== questAttemptId) {
  //       setQuestAttemptId(attemptId)
  //     }
  //     if (questionId !== questId) {
  //       setQuestId(questionId)
  //     }
  //     if (attemptId !== questAttemptId) {
  //       localStorage.removeItem(
  //         `questionId-${questId}-attemptId-${questAttemptId}`,
  //       )
  //       // localStorage.setItem(`questionId-${questId}-attemptId-${questAttemptId}`, JSON.stringify(data.date + data.delay))

  //       //   for (let key in window.localStorage) {
  //       //     if (key === `questionId-${questId}-attemptId-${questAttemptId}`)
  //       //       continue
  //       //     else if (key.startsWith('questionId-')) {
  //       //       window.localStorage.removeItem(key)
  //       //     }
  //       //   }

  //       let savedDate = getLocalStorageValue(
  //         `questionId-${questId}-attemptId-${questAttemptId}`,
  //       )
  //       let delta = null
  //       const currentTime = Date.now()

  //       if (savedDate != null && !isNaN(savedDate))
  //         delta = parseInt(savedDate, 10) - currentTime
  //       else delta = parseInt(currentTime, 10) - currentTime

  //       // Have we reached the end?
  //       if (delta > wantedDelay) {
  //         //Yes we clear uour saved end date
  //         if (
  //           localStorage.getItem(
  //             `questionId-${questId}-attemptId-${questAttemptId}`,
  //           ).length > 0
  //         )
  //           localStorage.removeItem(
  //             `questionId-${questId}-attemptId-${questAttemptId}`,
  //           )
  //       } else {
  //         // No update the end date with the current date
  //         setData({date: currentTime, delay: delta})
  //       }
  //     }
  //   }

  //   let savedDate = getLocalStorageValue(
  //     `questionId-${questId}-attemptId-${questAttemptId}`,
  //   )
  //   let delta = null
  //   const currentTime = Date.now()

  //   if (savedDate != null && !isNaN(savedDate))
  //     delta = parseInt(savedDate, 10) - currentTime
  //   else delta = parseInt(currentTime, 10) - currentTime

  //   // if (savedDate != null && !isNaN(savedDate))

  //   // {
  //   //   const currentTime = Date.now()
  //   //   const delta = parseInt(savedDate, 10) - currentTime

  //   // Have we reached the end?
  //   if (delta > wantedDelay) {
  //     //Yes we clear uour saved end date
  //     if (
  //       localStorage.getItem(
  //         `questionId-${questId}-attemptId-${questAttemptId}`,
  //       ).length > 0
  //     )
  //       localStorage.removeItem(
  //         `questionId-${questId}-attemptId-${questAttemptId}`,
  //       )
  //   } else {
  //     // No update the end date with the current date
  //     setData({date: currentTime, delay: delta})
  //   }
  //   //   if (attemptId && questAttemptId) {
  //   //     if (attemptId !== questAttemptId) {
  //   //       localStorage.removeItem(`questionId-${questId}-attemptId-${questAttemptId}`)
  //   //     }
  //   //   }
  //   // }
  // }, [
  //   attemptId,
  //   questAttemptId,
  //   questId,
  //   questionId,
  //   setQuestAttemptId,
  //   setQuestId,
  //   wantedDelay,
  // ])
  //[END] componentDidMount

  useEffect(() => {
    // if (questionId && attemptId) {
    //   let savedDate = null
    //   const currentTime = Date.now()

    //   if (attemptId !== questAttemptId) {
    //     setQuestAttemptId(attemptId)
    //   }

    //   if (questionId !== questId) {
    //     setQuestId(questionId)
    //   }

    //   if (attemptId !== questAttemptId) {
    //     localStorage.removeItem(
    //       `questionId-${questId}-attemptId-${questAttemptId}`,
    //     )
    //   } else {
    //     savedDate = localStorage.getItem(
    //       `questionId-${questId}-attemptId-${questAttemptId}`,
    //     )
    //   }

    //   if (!savedDate) {
    //     localStorage.setItem(
    //       `questionId-${questId}-attemptId-${questAttemptId}`,
    //       currentTime
    //     )
    //     savedDate = currentTime
    //   }

    //   const delta = parseInt(savedDate, 10) - currentTime

    //   if (delta > wantedDelay) {
    //     //Yes we clear our saved end date
    //     if (
    //       localStorage.getItem(
    //         `questionId-${questId}-attemptId-${questAttemptId}`,
    //       )
    //     )
    //       localStorage.removeItem(
    //         `questionId-${questId}-attemptId-${questAttemptId}`,
    //       )
    //   } else {
    //     // No update the end date with the current date
    //     setData({date: currentTime, delay: delta})
    //   }
    // }
    
    const currentTime = Date.now()
    setData({date: currentTime, delay:  60 * 1000})
  }, [
    attemptId,
    questAttemptId,
    questId,
    questionId,
    setQuestAttemptId,
    setQuestId,
    wantedDelay,
  ])

  return (
    <div>
      <div>
        <Countdown
          date={data.date + data.delay}
          renderer={renderer}
          onStart={delta => {
            //Save the end date
            // if (localStorage.getItem(`questionId-${questId}-attemptId-${questAttemptId}`) == null) {
            //   localStorage.setItem(
            //     `questionId-${questId}-attemptId-${questAttemptId}`,
            //     JSON.stringify(data.date + data.delay),
            //   )
            // }
          }}
          onComplete={() => {
            if (
              localStorage.getItem(
                `questionId-${questId}-attemptId-${questAttemptId}`,
              ) != null
            )
              localStorage.removeItem(
                `questionId-${questId}-attemptId-${questAttemptId}`,
              )
          }}
        />
      </div>
    </div>
  )
}

export default CountDownTimer
