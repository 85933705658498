import React, {useContext, useState} from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import {useToasts} from 'react-toast-notifications'
import {IoCalendar, IoCopyOutline, IoShareSocial} from 'react-icons/io5'
import {Button, Input} from 'semantic-ui-react'
import {useHistory} from 'react-router-dom'
import routes from '../../../routes'
import StateContext from '../../../context/stateContext'

const ClassCopyLink = ({data}) => {
  const history = useHistory()
  const {showModal, setShowModal} = useContext(StateContext)

  const [copied, setCopied] = useState(false)
  const {addToast} = useToasts()

  const handleOnCopy = () => {
    setCopied(true)
  }

 
  return (
    <div className="px-10">
      <p className=" text-center font-semibold text-2xl text-labelColor mb-1">
        Class {data?.data?.name} are created
      </p>
      <p className="mt-8 mb-4 text-center">
        Kindly share this code with the students enrolled in your class
      </p>



      <div className="mb-6">
        <p className="text-center  py-2 px-10 border-2 border-gray-400">
          {data?.data.voucher} {copied ? (<p  className='text-green-500 animate-bounce '>copied!</p>) :(null) }
        </p>
      </div>
      <CopyToClipboard
        text={data?.data.voucher || '--'}
        onCopy={() => handleOnCopy()}
      >
        <div className="p-2 bg-primary text-white cursor-pointer rounded-md flex justify-center items-center">
          <IoCopyOutline size={22} />
          <p className="text-lg px-3">Copy Code</p>
        </div>
      </CopyToClipboard>

      <Button
        className="my-6"
        fluid
        primary
        onClick={() => {
            setShowModal({modalName: '', data: null})
        }}
      >
        Back to Dashboard
      </Button>
    </div>
  )
}

export default ClassCopyLink
