import React from 'react'
import {Link} from 'react-router-dom'
import {Image} from 'semantic-ui-react'
import routes from '../../routes'
import {truncate} from '../../utils/string'

const StudentNonVoucherSubjectCard = ({subjectSrc, name, id}) => {
  return (
    <div className="rounded-md w-72 h-56 shadow-md hover:shadow-xl hover:opacity-95 flex flex-col border solid border-gray-300 animate__animated animate__fadeInUp">
      <Link to={routes.nonVoucherStudent.chapters.all(id)}>
        <Image
          className="w-full h-40 rounded-t-md bg-indigo-50"
          src={subjectSrc}
          alt="no image provided"
        />
      </Link>

      <div className="flex justify-between my-3 px-3">
        <Link to={routes.nonVoucherStudent.chapters.all(id)}>
          <p className="text-primary font-semibold cursor-pointer overflow-y-auto">
            {truncate(name, 25)}
          </p>
        </Link>
      </div>
    </div>
  )
}

export default StudentNonVoucherSubjectCard
