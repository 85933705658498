import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {Button, Dropdown, Icon, Image} from 'semantic-ui-react'
import {useHistory} from 'react-router'

import logo from '../../assets/images/logo.png'
import routes from '../../routes'
import Auth from '../../config/auth'
import {useUser} from '../../context/user-context'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'

const Navbar = () => {
  const history = useHistory()
  const {getUser, setUser} = useUser()
  const [userData, setUserData] = useState({})

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  React.useEffect(() => {
    if (!getUser()) return

    setUserData(getUser())
    // return () => setUserData(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUser()?.email])

  const handleOnClickLogout = () => {
    setUser('')
    Auth.logout()
    history.push(routes.login)
  }

  const onRouteClick = (e, {name, route}) => {
    const anchor = document.querySelector(route)
    anchor.scrollIntoView({behavior: 'smooth', block: 'center'})
  }

  return (
    <nav className="flex items-center px-4 md:px-8 pt-1 bg-primaryDarkColor bg-opacity-80">
      <img src={logo} alt="Indil logo" className="w-28 md:w-32 py-4" />

      <div className="mt-4 hidden md:flex items-center ml-auto">
        <ul className="flex">
          <NavItem
            active={false}
            name={langContent.home}
            route="#home"
            onClick={onRouteClick}
          />
          <NavItem
            active={false}
            name={langContent.about_us}
            route="#about"
            onClick={onRouteClick}
          />
          {/* <NavItem
            active={false}
            name={langContent.courses}
            route="#courses"
            onClick={onRouteClick}
          /> */}
          <NavItem
            active={false}
            name={langContent.contact_us}
            route="#contact-us"
            onClick={onRouteClick}
          />

          {getUser()?.isStudent && getUser()?.haveVoucher ? (
            <Button
              className="border border-solid mx-2 ml-4 border-primary py-0 bg-primary text-white"
              onClick={() => {
                history.push(routes.voucherStudent.dashboard)
              }}
            >
              {langContent.My_Portal}
            </Button>
          ) : null}

          {getUser()?.isStudent && !getUser()?.haveVoucher ? (
            <Button
              className="border border-solid mx-2 ml-4 border-primary py-0 bg-primary text-white"
              onClick={() => {
                history.push(routes.nonVoucherStudent.dashboard)
              }}
            >
              {langContent.My_Portal}
            </Button>
          ) : null}

          {getUser()?.email ? (
            <Dropdown
              direction="left"
              trigger={
                <div className="flex flex-row items-center">
                  {userData?.avatar ? (
                    <Image
                      src={userData?.avatar}
                      alt="avatar"
                      className={`${
                        userData?.avatar ? 'visible' : 'hidden'
                      } w-12 h-12 rounded-full mx-auto`}
                    />
                  ) : (
                    <Icon name="user" className="-mt-2" />
                  )}
                  <div>
                    {userData?.['name'] && (
                      <>
                        <p className="mx-1 mb-0 text-labelColor text-center font-medium">
                          {userData?.['name']}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              }
              className="flex items-center ml-3 text-white"
            >
              <Dropdown.Menu className="w-36 ">
                {getUser()?.isStudent ? (
                  <>
                    {getUser()?.haveVoucher ? (
                      <Link
                        className="text-center justify-center text-black hover:text-black"
                        to={routes.voucherStudent.profile}
                      >
                        <Dropdown.Item className="text-labelColor text-black hover:text-black py-2 justify-center text-center  hover:bg-gray-100 ">
                          {langContent.My_Profile}
                        </Dropdown.Item>
                      </Link>
                    ) : (
                      <Link
                        className="text-center justify-center text-black hover:text-black"
                        to={routes.nonVoucherStudent.profile}
                      >
                        <Dropdown.Item className="text-labelColor text-black hover:text-black py-2 justify-center text-center  hover:bg-gray-100 ">
                          {langContent.My_Profile}
                        </Dropdown.Item>
                      </Link>
                    )}
                  </>
                ) : (
                  <Link
                    className="text-center justify-center text-black hover:text-black"
                    to={routes.instructor.homePage}
                  >
                    <Dropdown.Item className="text-labelColor text-black hover:text-black py-2 justify-center text-center  hover:bg-gray-100 ">
                      {langContent.My_Profile}
                    </Dropdown.Item>
                  </Link>
                )}

                <hr />
                <Dropdown.Item
                  className="text-labelColor hover:text-black transition-colors duration-300 text-center"
                  onClick={handleOnClickLogout}
                >
                  {langContent.Logout}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <></>
            // <NavItem
            //   active={false}
            //   name={langContent.login}
            //   onClick={() => {
            //     history.push(routes.login)
            //   }}
            // />
          )}
        </ul>

        {/* {getUser()?.email ? null : (
          <Button
            className=" ml-2  bg-primaryDarkYellow text-white"
            onClick={() => {
              history.push(routes.register)
            }}
          >
            Get Started
          </Button>
        )} */}

        <Dropdown
          direction="left"
          trigger={
            <div className="flex flex-row items-center font-semibold">
              <Icon name="world" />
              <span className="capitalize font-semibold mt-2 ">{lang}</span>
            </div>
          }
          className="flex items-center ml-3 mr-3 md:mr-6 mb-1 text-white"
        >
          <Dropdown.Menu className="-mt-3 border-2">
            <Dropdown.Item
              onClick={() => {
                setLang('en')
                window.location.reload()
              }}
              className="text-labelColor  justify-center text-center  hover:bg-gray-100 transition-colors duration-300"
            >
              En
            </Dropdown.Item>
            <hr />
            <Dropdown.Item
              onClick={() => {
                setLang('fr')
                window.location.reload()
              }}
              className="text-labelColor  justify-center text-center  hover:bg-gray-100 transition-colors duration-300"
            >
              Fr
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <MobileNav />
    </nav>
  )
}

export default Navbar

const NavItem = ({active, name, route, onClick}) => {
  return (
    <li
      className={`${
        active ? 'border-primary font-bold' : 'text-white font-bold'
      } text-lg ml-5 py-2 px-0 lg:px-3 cursor-pointer border-b-2 border-transparent hover:border-primary`}
      onClick={e => onClick(e, {name, route})}
    >
      {name}
    </li>
  )
}

function MobileNav() {
  const [showNav, setShowNav] = useState(false)

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  function expandNav() {
    setShowNav(true)
  }

  function collapseNav() {
    setShowNav(false)
  }

  //////////////////////////

  const history = useHistory()
  const {getUser, setUser} = useUser()
  const [userData, setUserData] = useState({})

  React.useEffect(() => {
    if (!getUser()) return

    setUserData(getUser())
    // return () => setUserData(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUser()?.email])

  const handleOnClickLogout = () => {
    setUser('')
    Auth.logout()
    history.push(routes.login)
  }

  return (
    <>
      {getUser()?.email ? (
        <Dropdown
          direction="left"
          trigger={
            <div className="flex flex-row items-center -mr-4">
              {userData?.avatar ? (
                <Image
                  src={userData?.avatar}
                  alt="avatar"
                  className={`${
                    userData?.avatar ? 'visible' : 'hidden'
                  } w-12 h-12 rounded-full mx-auto`}
                />
              ) : (
                <Icon name="user" className="-mt-2" />
              )}
              <div>
                {userData?.['name'] && (
                  <>
                    <p className="mx-3 mb-0 text-labelColor  text-center font-medium">
                      {userData?.['name']}
                    </p>
                  </>
                )}
              </div>
            </div>
          }
          className="flex items-center justify-end md:hidden ml-auto mr-2 mt-2 text-white "
        >
          <Dropdown.Menu className=" border-2 w-40 ">
            {getUser()?.isStudent ? null : (
              <Link
                className="text-center justify-center text-black hover:text-black"
                to={routes.instructor.homePage}
              >
                <Dropdown.Item className="text-labelColor text-black hover:text-black py-2 justify-center text-center  hover:bg-gray-100 ">
                  {langContent.Profile}
                </Dropdown.Item>
              </Link>
            )}
            {/* <Dropdown.Item className="text-labelColor hover:text-black justify-center text-center  hover:bg-gray-100 ">
              {getUser()?.isStudent ? (
                <Link
                  className="text-center justify-center hover:text-black"
                  to={routes.studentDashboard}
                >
                  Profile
                </Link>
              ) : (
                <Link
                  className="text-center justify-center hover:text-black"
                  to={routes.instructorProfile}
                >
                  Profile
                </Link>
              )}
            </Dropdown.Item> */}
            <hr />
            <Dropdown.Item
              className="text-labelColor hover:text-black transition-colors duration-300 text-center"
              onClick={handleOnClickLogout}
            >
              {langContent.Logout}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ) : null}

      <div
        className={`flex justify-end mt-3 md:hidden ${
          getUser()?.email ? '' : 'ml-auto'
        }`}
        onClick={expandNav}
      >
        <svg
          className="h-8 w-8 text-white hover:cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </div>
      {showNav ? (
        <div className="absolute top-0 inset-x-0 p-2 z-10 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="p-2">
              <div class="flex flex-grow items-center justify-between mb-4 mt-2">
                <div className="ml-2">
                  <img src={logo} alt="Logo" className="w-16" />
                </div>
                <button
                  type="button"
                  className="bg-white -mt-2 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-800"
                  onClick={collapseNav}
                >
                  <span className="sr-only">Close main menu</span>
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="main-menu"
              >
                <a
                  href="#home"
                  className="hover:bg-gray-100 cursor-pointer px-4 py-2 rounded-md block"
                >
                  {langContent.home}
                </a>
                <a
                  href="#about"
                  className="hover:bg-gray-100 cursor-pointer px-4 py-2 rounded-md block"
                >
                  {langContent.about_us}
                </a>
                <a
                  href="#courses"
                  className="hover:bg-gray-100 cursor-pointer px-4 py-2 rounded-md block"
                >
                  {langContent.courses}
                </a>
                <a
                  href="#contact-us"
                  className="hover:bg-gray-100 cursor-pointer px-4 py-2 rounded-md block"
                >
                  {langContent.contact_us}
                </a>
              </div>
            </div>

            {getUser()?.isStudent && getUser()?.haveVoucher ? (
              <Button
                className="block w-full px-5 py-3 text-center font-semibold text-primary bg-gray-50 hover:bg-gray-100"
                onClick={() => {
                  history.push(routes.voucherStudent.dashboard)
                }}
              >
                {langContent.My_Portal}
              </Button>
            ) : null}

            {getUser()?.isStudent && !getUser()?.haveVoucher ? (
              <Button
                className="border border-solid mx-2 ml-4 border-primary py-0 bg-primary text-white"
                onClick={() => {
                  history.push(routes.nonVoucherStudent.dashboard)
                }}
              >
                {langContent.My_Portal}
              </Button>
            ) : null}

            {/* {getUser()?.email ? null : (
              <div role="none">
                <Link
                  to={routes.login}
                  className="block w-full px-5 py-3 text-center font-medium text-blue-dark bg-gray-50 hover:bg-gray-100"
                  role="menuitem"
                >
                  Login
                </Link>
              </div>
            )} */}

            {/* <div role="none">
              <Link
                to={routes.login}
                className="block w-full px-5 py-3 text-center font-medium text-blue-dark bg-gray-50 hover:bg-gray-100"
                role="menuitem"
              >
                Login
              </Link>
            </div> */}

            {/* {getUser()?.email ? null : (
              <div role="none">
                <Link
                  to={routes.register}
                  className="block w-full px-5 py-3 text-center font-medium text-blue-dark bg-gray-50 hover:bg-gray-100"
                  role="menuitem"
                >
                  Get Started
                </Link>
              </div>
            )} */}

            {/* <div role="none">
              <Link
                to={routes.register}
                className="block w-full px-5 py-3 text-center font-medium text-blue-dark bg-gray-50 hover:bg-gray-100"
                role="menuitem"
              >
                Get Started
              </Link>
            </div> */}

            {/* <div role="none">
              <Link
                to={routes.register}
                className="block w-full m-2 py-3 text-center font-medium text-gray-600 border border-primary hover:bg-blue-light rounded-br-md rounded-bl-md"
                role="menuitem"
              >
                Register
              </Link>
            </div> */}
          </div>
        </div>
      ) : null}
    </>
  )
}
