import React, {useState} from 'react'
import {Grid, Icon} from 'semantic-ui-react'

import {Link} from 'react-router-dom'
import routes from '../../routes'
import LoginRequestTabs from '../../components/LoginComponents/LoginRequestTabs'
import InstructorLogin from '../../components/LoginComponents/InstructorLogin'
import {FaArrowCircleLeft} from 'react-icons/fa'
import StudentLoginVoucher from '../../components/LoginComponents/student-Login-voucher'
import StudentLoginNonVoucher from '../../components/LoginComponents/student-Login-Nonvoucher'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'

const Login = () => {
  const [activeMenu, setActiveMenu] = useState('studentvoucher')

  const [lang] = useLanguage()
  const langContent = content[lang]

  return (
    <Grid className="h-screen m-0">
      <Grid.Row className="p-0">
        <Grid.Column
          computer={8}
          className="hidden lg:block w-full h-full bg-primary login-sidebar-img"
        >
          {/* <Image
              className="w-full h-screen ml-2 login-sidebar-img"
              src={loginBackgound}
            ></Image> */}
        </Grid.Column>

        <Grid.Column
          tablet={16}
          computer={8}
          mobile={16}
          className="place-self-center "
        >
          <div className="px-4 ml-5">
            <Link to={routes.home} className="text-primary">
              {/* <Icon  name='hand point left '/>  */}
              <FaArrowCircleLeft className="w-20 h-8" />
            </Link>
          </div>

          <div className="p-3 max-w-2xl mx-auto">
            <div className="items-center text-center p-8 text-primaryBlueColor-default font-extrabold text-3xl">
              {langContent.Log_in_to_your_account}
            </div>

            {/* <LoginForm /> */}
            <LoginRequestTabs
              activeMenu={activeMenu}
              setActiveMenu={value => setActiveMenu(value)}
            />

            <div className="relative max-w-xl mx-auto p-6">
              {activeMenu === 'studentvoucher' && <StudentLoginVoucher />}
              {activeMenu === 'studentNonVoucher' && <StudentLoginNonVoucher />}
              {/* {activeMenu === 'student' && <StudentMainRegisiter />} */}
              {activeMenu === 'instructor' && <InstructorLogin />}
            </div>

            <div className="items-center text-center p-6 font-semibold">
              {langContent.Dont_have_an_account_on_INDIL_Register}
              <Link to={routes.register} className="text-red-600 ml-2">
              {langContent.Register}
              </Link>
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default Login
