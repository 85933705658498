import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

import 'semantic-ui-css/semantic.min.css'
import './assets/css/fonts.css'
import './assets/css/tailwind.css'
import './assets/css/app.css'

import {LanguageProvider} from './context/languageContext'
import {UserProvider} from './context/user-context'
import ToastContainer from './components/ToastContainer'
import {ToastProvider} from 'react-toast-notifications'

ReactDOM.render(
  <UserProvider>
    <ToastProvider
      components={ToastContainer}
      autoDismiss={true}
      placement="top-center"
    >
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </ToastProvider>
    </UserProvider>,
  document.getElementById('root'),
)
