import React from 'react'
import {Route, Switch, useLocation} from 'react-router-dom'
import AvatarDropdown from '../components/shared/avatar-dropdown'
import InstructorRoute from '../components/shared/instructor-route'
import {TeacherSidebar} from '../components/shared/teacher-sidbar'
import useMediaQuery from '../hooks/use-media-query'
import ErrorComp from '../pages/error/error'
import ChapterContent from '../pages/teacher/chapter/teacher-chapter-content'
import TeacherExerciseBreakdown from '../pages/teacher/class-view/teacher-exercise-breakdown'
import TeacherPex from '../pages/teacher/exercise/pex/teacher-pex'
import TeacherPexLock from '../pages/teacher/exercise/pex/teacher-pex-lock'
import TeacherTest from '../pages/teacher/exercise/test/teacher-test'
import TeacherTestLock from '../pages/teacher/exercise/test/teacher-test-lock'
import TeacherWex from '../pages/teacher/exercise/wex/teacher-wex'
import TeacherWexLock from '../pages/teacher/exercise/wex/teacher-wex-lock'
import TeacherStudentInfo from '../pages/teacher/student/teacher-student-info'
import TeacherClassView from '../pages/teacher/teacher-class-view'
import TeacherHomePage from '../pages/teacher/teacher-home-page'
import routes from '../routes'

const InstructorLayout = () => {
  const isSmall = useMediaQuery('(max-width: 1023px)')
  const {pathname} = useLocation()
  let isTestRoute = Boolean(pathname.match(/(\/wex\/|\/pex\/|\/test\/)/gi))

  React.useEffect(() => {
    document.body.classList.add('bg-gray-50')
    return () => document.body.classList.remove('bg-gray-50')
  })
  return (
    <div className="flex relative">
      <div>
        <TeacherSidebar isSmall={isSmall} />
      </div>

      <div
        className={`${
          isSmall ? 'pl-0' : 'ml-64 md:px-4 xl:px-10'
        } mx-auto mt-10 w-full ${isTestRoute ? 'overflow-x-auto' : ''}`}
      >
        <Switch>
          <InstructorRoute
            exact
            path={routes.instructor.homePage}
            component={TeacherHomePage}
          />

          <InstructorRoute
            exact
            path={routes.instructor.class()}
            component={TeacherClassView}
          />

          <InstructorRoute
            exact
            path={routes.instructor.chapterContent()}
            component={ChapterContent}
          />

          <InstructorRoute
            exact
            path={routes.instructor.exercise.pexlock()}
            component={TeacherPexLock}
          />

          <InstructorRoute
            exact
            path={routes.instructor.exercise.pex()}
            component={TeacherPex}
          />

          <InstructorRoute
            exact
            path={routes.instructor.exercise.wexlock()}
            component={TeacherWexLock}
          />

          <InstructorRoute
            exact
            path={routes.instructor.exercise.wex()}
            component={TeacherWex}
          />

          <InstructorRoute
            exact
            path={routes.instructor.exercise.testlock()}
            component={TeacherTestLock}
          />

          <InstructorRoute
            exact
            path={routes.instructor.exercise.test()}
            component={TeacherTest}
          />

          <InstructorRoute
            exact
            path={routes.instructor.studetInfo()}
            component={TeacherStudentInfo}
          />

          <InstructorRoute
            exact
            path={routes.instructor.exerciseBreakdown()}
            component={TeacherExerciseBreakdown}
          />

          <Route path="*" component={ErrorComp} />
        </Switch>
      </div>
    </div>
  )
}

export default InstructorLayout
