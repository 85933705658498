import React, {useState} from 'react'
import {Button, Checkbox, Form, Icon} from 'semantic-ui-react'
import StdRegisterWithoutVoucher from './StdRegisterWithoutVoucher'
import StudentRegisterWithVoucher from './StudentRegisterWithVoucher'
import { FaArrowLeft } from "react-icons/fa";
import { useLanguage } from '../../context/languageContext';
import { content } from '../../localization/content';

const StudentMainRegisiter = () => {
  const [voucher, setvoucher] = useState(true)
  const [submitVoucher, setSubmitVoucher] = useState(false)

  const [lang] = useLanguage()
  const langContent = content[lang]

  const handleOnSubmit = () => {
    setSubmitVoucher(true)
    console.log(voucher)
  }
  return (
    <div>
      {!submitVoucher ? (
        <div className="max-w-2xl ml-20">
          <Form className="mx-auto">
            <Form.Field>
              <Checkbox
                radio
                className="font-semibold"
                label={langContent.I_have_a_voucher}
                name="checkboxRadioGroup"
                value="haveVoucher"
                checked={voucher === true}
                onChange={(e) => setvoucher(true)}
              />
            </Form.Field>
            {/* <Form.Field>
              <Checkbox
                radio
                className="font-semibold"
                label={langContent.I_dont_have_a_voucher}
                name="checkboxRadioGroup"
                value="DoNothaveVoucher"
                checked={voucher === false}
                onChange={(e) => setvoucher(false)}
              />
            </Form.Field> */}
            <Button
              className="bg-primary px-24 mt-5 text-white"
              onClick={handleOnSubmit}
            >
              {langContent.Create_Account}
            </Button>
          </Form>
        </div>
      ) : voucher ? (
        <div>
          <Button
            className="bg-transparent"
            onClick={e => setSubmitVoucher(false)}
          >
            <FaArrowLeft
              className="-ml-6 mb-5"
            />
          </Button>
          <StudentRegisterWithVoucher />
        </div>
      ) : (
        <div>
          <Button
            className="bg-transparent"
            onClick={e => setSubmitVoucher(false)}
          >
           
           <FaArrowLeft
              className="-ml-6 "
            />
          </Button>
          <StdRegisterWithoutVoucher />
        </div>
      )}
    </div>
  )
}

export default StudentMainRegisiter
