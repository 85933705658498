import React from 'react'
import {BsCheck, BsPen} from 'react-icons/bs'
import {Link} from 'react-router-dom'
import {Accordion, Button, Icon, Image, Step} from 'semantic-ui-react'
import {FaLock} from 'react-icons/fa'

import routes from '../../../routes'
import checked from '../../../assets/images/checked.svg'
import redCircle from '../../../assets/images/redCircle.svg'
import pending from '../../../assets/images/pending.svg'

const NonVoucherSectionCard = ({
  name,
  description,
  chapterid,
  subjectid,
  sectionid,
  order,
  index,
  activeIndex,
  clickAccordion,
  competency,
  pexs,
  videoLink,
  videoTitle,
  pexid,
  setVideoId,
  setwatchingVideo,
  isSubscriber,
}) => {
  return (
    <>
      {isSubscriber != false ? (
        <Accordion
          fluid
          styled
          className="rounded-t-md w-full shadow-sm mb-4 hover:shadow-md hover:opacity-95 p-3 border-2 border-solid animate__animated animate__fadeInUp"
        >
          <Accordion.Title
            active={activeIndex === index}
            index={index}
            onClick={clickAccordion}
            className="flex items-center"
          >
            <Icon name="dropdown" />
            <div className="flex justify-between items-center">
              <p className=" font-semibold">
                Section {order}: {name}
              </p>
            </div>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === index}>
            <div className="-mt-3 mb-2 ml-2">
              {competency?.map(s => {
                return (
                  <div className="font-semibold text-primary">
                    Skill (competency): {s?.name}
                  </div>
                )
              })}
            </div>

            <div className="flex text-gray-800 items-center">
              <p className="ml-2">{description}</p>
            </div>

            <div>
              <div>
                <div className="my-4 ml-4">
                  <Step.Group
                    vertical
                    className="border-none shadow-none gap-y-1 bg-transparent w-3"
                  >
                    <div className="flex items-center -my-1">
                      <Image src={pending} className="mr-4" />

                      <p>Video:</p>
                      <p
                        className="mt-0 cursor-pointer hover:text-primary"
                        onClick={() => {
                          setwatchingVideo(true)
                          setVideoId(sectionid)
                        }}
                      >
                        <p className="ml-2 min-w-max"> {videoTitle}</p>
                      </p>
                    </div>

                    {pexs?.map((s, idx) => (
                      <>
                        <div
                          class={`border-l-2 solid h-8 ${
                            s?.flag === 'success'
                              ? 'border-gray-300'
                              : 'border-gray-300'
                          }     place-self-center`}
                        />
                        <div className="flex items-center -my-1">
                          {/* <Step className="border-none shadow-none bg-transparent w-3 bg-gray-500 rounded-full p-0 h-3 flex justify-center items-center" /> */}
                          {s?.flag === 'success' ? (
                            <Image src={checked} className="mr-2" />
                          ) : s?.flag === 'failed' ? (
                            <Image src={redCircle} className="mr-2" />
                          ) : (
                            <Image src={pending} className="mr-2" />
                          )}

                          <p className="ml-2 min-w-max flex items-center  ">
                            PEX {idx + 1}:
                            <Link
                              className="mt-0 cursor-pointer mx-2"
                              to={routes.nonVoucherStudent.chapters.section.pexlock(
                                subjectid,
                                chapterid,
                                sectionid,
                                s?._id,
                              )}
                            >
                              {s?.name}
                            </Link>
                            {s?.score > -1 && (
                              <p
                                className={`mt-0 ${
                                  s?.flag === 'success'
                                    ? 'text-primaryGreen'
                                    : 'text-primaryRed'
                                } `}
                              >
                                {'('} {s?.score?.toFixed(0)}
                                {'% )'}
                              </p>
                            )}
                          </p>
                        </div>
                      </>
                    ))}

                    {/* <div class="border-l-2 solid h-10 border-gray-500  place-self-center"></div>
             <div className='flex items-center -mb-1'>
              <Step  className='border-none shadow-none bg-transparent w-3 bg-gray-500 rounded-full p-0 h-3 flex justify-center items-center'/>
              <p className='ml-2 min-w-max'>Video: {videoTitle}</p>
            </div> */}
                  </Step.Group>
                </div>
              </div>
            </div>
          </Accordion.Content>
        </Accordion>
      ) : (
        <Accordion
          fluid
          styled
          className="rounded-t-md w-full shadow-sm mb-4 hover:shadow-md hover:opacity-95 p-3 border-2 border-solid animate__animated animate__fadeInUp"
        >
          <Accordion.Title
            active={activeIndex === index}
            index={index}
            onClick={clickAccordion}
            className="flex items-center"
          >
            <Icon name="dropdown" />
            <div className="flex justify-between items-center w-full">
              <p className=" font-semibold">
                Section {order}: {name}
              </p>
              <div className="flex items-center justify-center">
                <FaLock className="mx-2 -mt-0.5" />
                <p className="text-primary">Subscribe to unlock this section</p>
              </div>
            </div>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === index}>
            <div className="-mt-3 mb-2 ml-2">
              {competency?.map(s => {
                return (
                  <div className="font-semibold text-primary">
                    Skill (competency): {s?.name}
                  </div>
                )
              })}
            </div>

            <div className="flex text-gray-800 items-center">
              <p className="ml-2">{description}</p>
            </div>
          </Accordion.Content>
        </Accordion>
      )}
    </>
  )
}

export default NonVoucherSectionCard
