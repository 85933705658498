import React from 'react'
import {useParams} from 'react-router-dom'
import {Embed} from 'semantic-ui-react'
import {authAxios} from '../../../config/axiosConfig'
import useAsync from '../../../hooks/useAsync'
import ReactPlayer from 'react-player'

const SectionVideo = () => {
  const {chapterid, subjectid, sectionid} = useParams()
  const [section, setSection] = React.useState()

  const {
    run: getchapter,
    error: errorGettingchapter,
    isLoading: gettingchapter,
  } = useAsync()

  React.useEffect(() => {
    if (sectionid)
      getchapter(authAxios.get(`/chapter/section/${sectionid}`)).then(
        ({data}) => {
          console.log({getData: data})
          setSection(data)
        },
      )
  }, [getchapter, sectionid])

  return (
    <div>
      <h2 className="mb-10">Video: {section?.videoTitle}</h2>
     
      {/* <Embed
        icon="play circle"
        placeholder="https://knetic.org.uk/wp-content/uploads/2020/07/Video-Placeholder.png"
        url="https://www.myfav.es/jack"
        source="https://www.youtube.com/watch?v=egn0FkBeAlA"
      /> */}
      <ReactPlayer
        url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
        width={650}
        height={400}
        controls
      />
    </div>
  )
}

export default SectionVideo
