import React from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {
  Breadcrumb,
  Button,
  Dimmer,
  Loader,
  Message,
  Modal,
  Segment,
} from 'semantic-ui-react'
import {useParams} from 'react-router-dom'

import useAsync from '../../../hooks/useAsync'
import {authAxios} from '../../../config/axiosConfig'
import {formatResponseError} from '../../../utils/format-response'
import routes from '../../../routes'
import VoucherChapterCard from '../../../components/student/voucher-chapter-card'
import ChapterCardNonVoucher from './chapter-card-nonVoucher'

const AllChaptersNonVoucher = () => {
  const [chapters, setChapters] = React.useState([])
  const [isSubscriber, setIsSubscriber] = React.useState(false)
  const [openConfirm, setopenConfirm] = React.useState(false)

  const location = useLocation()
  const history = useHistory()

  const {subjectid} = useParams()

  const {
    run: getSubjectChapters,
    error: errorGettingSubjectChapters,
    isLoading: gettingSubjectChapters,
  } = useAsync()

  const {
    run: getUserSubscribtion,
    error: errorGettingUserSubscribtion,
    isLoading: gettingUserSubscribtion,
  } = useAsync()

  React.useEffect(() => {
    getSubjectChapters(
      authAxios.get(`/user/chapter/all?subject=${subjectid}`),
    ).then(({data}) => {
      console.log(data)
      setChapters(data)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSubjectChapters, location.search])

  React.useEffect(() => {
    getUserSubscribtion(
      authAxios.get(`/user/subscribe/valid/?subject=${subjectid}`),
    ).then(({data}) => {
      console.log(data)
      setIsSubscriber(data.isSubscriber)
    })
  }, [getUserSubscribtion, subjectid])

  return (
    <div className="">
      <Segment className="border-none shadow-none bg-transparent w-full">
        {errorGettingSubjectChapters ? (
          <Message
            error
            list={formatResponseError(errorGettingSubjectChapters)}
          />
        ) : null}
        {errorGettingUserSubscribtion ? (
          <Message
            error
            list={formatResponseError(errorGettingUserSubscribtion)}
          />
        ) : null}

        <Dimmer
          active={gettingSubjectChapters || gettingUserSubscribtion}
          inverted
        >
          <Loader active={true} />
        </Dimmer>
        <div className="flex justify-between align-center">
          <h1 className="text-3xl">Chapters </h1>
        </div>
        <Breadcrumb className="mt-4">
          <Breadcrumb.Section onClick={() => history.push('/')} link>
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider>/</Breadcrumb.Divider>
          <Breadcrumb.Section
            onClick={() => history.push(routes.nonVoucherStudent.dashboard)}
            link
          >
            {' '}
            Subjects{' '}
          </Breadcrumb.Section>
          <Breadcrumb.Divider>/</Breadcrumb.Divider>
          <Breadcrumb.Section active> Chapters </Breadcrumb.Section>
        </Breadcrumb>

        {!isSubscriber && (
          <div className="mt-4 bg-primaryLight p-5 rounded-md border border-solid border-primary flex justify-between items-center">
            <p className="text-xl px-2">
              You're within your free trial, subscribe to unlock the rest of the
              chapters.
            </p>

            <Button
              className="px-8 bg-primary text-white"
              onClick={() =>
                history.push(
                  routes.nonVoucherStudent.chapters.subscribe.pLanes(subjectid),
                )
              }
            >
              Subscribe
            </Button>
          </div>
        )}

        <div className="mt-12">
          <div className="flex flex-wrap gap-x-5 gap-y-5 items-center justify-start">
            {chapters?.map((a, idx) => (
              <ChapterCardNonVoucher
                key={a?._id}
                name={a?.chapterName}
                brief={a?.brief}
                chapterSrc={a?.image}
                chapterid={a?._id}
                subjectid={subjectid}
                order={a?.order}
                status={a?.status}
                setopenConfirm={setopenConfirm}
                isSubscriber={a?.isSubscriber}
              />
            ))}
          </div>
        </div>

        <Modal
          open={openConfirm}
          onClose={() => setopenConfirm(p => !p)}
          closeIcon
          closeOnEscape={false}
          closeOnDimmerClick={false}
          size="tiny"
          className="animate__animated animate__fadeInDown"
        >
          {/* <Modal.Header>New category name</Modal.Header> */}
          <Modal.Content>
            <div className="flex flex-col items-center">
              <h3 className="text-primary">Go Pro!</h3>

              <p className="my-7">
                You need to subscribe to proceed with the rest of the course
              </p>

              <Button
                // className="border border-solid border-primary bg-transparent text-primary px-16"
                className='px-16'
                primary
                onClick={() =>
                  history.push(
                    routes.nonVoucherStudent.chapters.subscribe.pLanes(
                      subjectid,
                    ),
                  )
                }
              >
                Check plans
              </Button>
            </div>
          </Modal.Content>
        </Modal>
      </Segment>
    </div>
  )
}

export default AllChaptersNonVoucher
