import React, {useEffect, useState} from 'react'
import {Button, Transition, List} from 'semantic-ui-react'
import {useHistory} from 'react-router'
import {Link} from 'react-router-dom'
import routes from '../../routes'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'
import {useUser} from '../../context/user-context'

const LoginButtons = () => {
  const history = useHistory()
  const {getUser, setUser} = useUser()

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(true)
  }, [])

  return (
    <Transition visible={visible} animation="fly right" duration={2000}>
      <div>
        {!getUser()?.email ? (
          <div className="float-left">
            <Button
              className="ml-2 bg-primaryLight text-black"
              onClick={() => {
                history.push(routes.login)
              }}
            >
              {langContent.Login_sign}
            </Button>

            <Button
              className="ml-2 bg-primaryLight text-black"
              onClick={() => {
                history.push(routes.register)
              }}
            >
              {langContent.Create_Account}
            </Button>
          </div>
        ) : null}

        <Button
          className="ml-2 bg-primaryDarkYellow text-black"
          onClick={() => {
            window.open(
              'https://firebasestorage.googleapis.com/v0/b/indil-350517.appspot.com/o/user-guide.pdf?alt=media&token=c4f841bc-91f6-4e9b-a882-35bf684d0e1c',
              '_blank',
            )
          }}
        >
          {langContent.User_guide}
        </Button>
      </div>
    </Transition>
  )
}

export default LoginButtons
