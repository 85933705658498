import {Menu} from 'semantic-ui-react'
import { useLanguage } from '../../context/languageContext'
import { content } from '../../localization/content'

const LoginRequestTabs = ({activeMenu, setActiveMenu}) => {
  const [lang] = useLanguage()
  const langContent = content[lang]

  return (
    <div className="flex">
        <Menu
          className="mt-0 border-none shadow-none lg:text-lg text-sm p-3 m-1"
          stackable
          widths={3}
        >
            <>
              <Menu.Item
                active={activeMenu === 'studentvoucher'}
                onClick={() => setActiveMenu('studentvoucher')}
                 className={`bottom-arrow ${
                      activeMenu === 'studentvoucher' ? 'border-t-4 border-primary bg-white' : 'bg-gray-50'
                    }`}
              >
                <div className="flex flex-col w-full">

                  <p className="font-medium text-labelColor">{langContent.I_am_student}</p>
                  <div
                    // className={`bottom-arrow ${
                    //   activeMenu === 'studentvoucher' ? 'border-t-2 border-red-800' : 'hidden'
                    // }`}
                  ></div>
                </div>
              </Menu.Item>


              {/* <Menu.Item
                active={activeMenu === 'studentNonVoucher'}
                onClick={() => setActiveMenu('studentNonVoucher')}
                 className={`bottom-arrow ${
                      activeMenu === 'studentNonVoucher' ? 'border-t-4 border-primary bg-white' : 'bg-gray-50'
                    }`}
              >
                <div className="flex flex-col w-full">

                  <p className="font-medium text-labelColor">{langContent.Student_non_voucher}</p>
                  <div
                    // className={`bottom-arrow ${
                    //   activeMenu === 'studentNonVoucher' ? 'border-t-2 border-red-800' : 'hidden'
                    // }`}
                  ></div>
                </div>
              </Menu.Item> */}
              <Menu.Item
                active={activeMenu === 'instructor'}
                onClick={() => setActiveMenu('instructor')}
                className={`bottom-arrow ${
                      activeMenu === 'instructor' ? 'border-t-4 border-primary bg-white' : 'bg-gray-50'
                    }`}
              >
                <div className="flex flex-col w-full">

                  <p className="font-medium text-labelColor">{langContent.I_am_instructor}</p>
                  <div
                    className={`bottom-arrow ${
                      activeMenu === 'instructor' ? 'visible' : 'hidden'
                    }`}
                  ></div>
                </div>
              </Menu.Item>
            </>
        </Menu>
    </div>
  )
}

export default LoginRequestTabs
