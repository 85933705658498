import React from 'react'
import {
  Dimmer,
  Dropdown,
  Image,
  Loader,
  Message,
  Progress,
  Segment,
  Table,
} from 'semantic-ui-react'
import {truncate} from '../../../utils/string'
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import {useParams} from 'react-router-dom'
import useAsync from '../../../hooks/useAsync'
import {authAxios} from '../../../config/axiosConfig'
import {formatResponseError} from '../../../utils/format-response'
import AnimatedProgressProvider from '../../../components/shared/AnimatedProgressProvider'
import { useLanguage } from '../../../context/languageContext'
import { content } from '../../../localization/content'

const TeacherStudentProgress = () => {
  const [userData, setUserData] = React.useState()
  const {classid, studentid, chapterid} = useParams()

  const [lang, setLang] = useLanguage()
  const langContent = content[lang]

  const {
    run: getStudentInfo,
    error: errorGettingStudentInfo,
    isLoading: gettingStudentInfo,
  } = useAsync()

  React.useEffect(() => {
    getStudentInfo(
      authAxios.get(
        `/instructor/class/studentsinfo/${studentid}/progress?class=${classid}&chapter=${chapterid}`,
      ),
    ).then(({data}) => {
      setUserData(data)
      console.log(data)
    })
  }, [getStudentInfo, studentid, classid, chapterid])

  return (
    <div className="mb-20">
      <Segment className="border-none shadow-none bg-transparent flex flex-col">
        {errorGettingStudentInfo ? (
          <Message error list={formatResponseError(errorGettingStudentInfo)} />
        ) : null}

        <Dimmer active={gettingStudentInfo} inverted>
          <Loader active={true} />
        </Dimmer>
        <div>
          <div className="flex items-center my-5">
            <p className="mr-12 ml-4 -mt-1">
              {userData?.chapterProgress?.chapter} :{' '}
            </p>
            <Progress
              className="max-w-sm w-60 mt-8"
              size="small"
              percent={parseFloat(userData?.chapterProgress?.progress).toFixed(0)}
              success
            />
            <p className="ml-4 -mt-1">{parseFloat(userData?.chapterProgress?.progress).toFixed(0)}%</p>
          </div>

          <div className="flex">
            <div className="flex-1 max-w-xl flex flex-wrap">
              {userData?.chapterProgress?.skills?.map((s, i) => (
                <div key={i} className="w-36  m-2 flex  flex-col items-center">
                  <div>
                    <AnimatedProgressProvider
                      valueEnd={Number.parseFloat(`${parseFloat(s?.progress).toFixed(0)}`)}
                    ></AnimatedProgressProvider>
                  </div>
                  <div className="h-9">
                    <p className="-mt-4 text-center text-sm text-gray-500">
                      {truncate(s?.skill, 30)}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex-1">
              {userData?.chapterProgress?.feedbacks?.length > 0 ? (
                <h3 className="text-primary mb-2">Frequent errors</h3>
              ) : null}
              <ul className="list-disc list-inside">
                {userData?.chapterProgress?.feedbacks?.map((s, i) => (
                  <li>{s?.feedbackMessage}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <Table
          striped
          celled
          size="small"
          unstackable
          className="shadow-lg my-5"
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>{langContent.Name}</Table.HeaderCell>
              <Table.HeaderCell width={2}>{langContent.Level}</Table.HeaderCell>
              <Table.HeaderCell width={2}>{langContent.Score}</Table.HeaderCell>
              <Table.HeaderCell width={2}>{langContent.Duration}</Table.HeaderCell>
              <Table.HeaderCell width={2}>{langContent.Hints}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {userData?.exercises?.pexs?.map(c => (
              <Table.Row>
                <Table.Cell>{c?.pex}</Table.Cell>
                <Table.Cell>-----</Table.Cell>
                <Table.Cell>
                  {c?.message ? (
                    <p>{c?.message}</p>
                  ) : (
                    <p>{c?.score?.toFixed(0)}%</p>
                  )}
                </Table.Cell>

                <Table.Cell>
                  {!c?.message && (
                    <p>
                      {parseInt(c?.duration?.hours)} :{' '}
                      {parseInt(c?.duration?.minutes)} :{' '}
                      {parseInt(c?.duration?.seconds)}
                    </p>
                  )}
                </Table.Cell>
                <Table.Cell>{c?.hint}</Table.Cell>
              </Table.Row>
            ))}
            {/* <div className='w-full my-1 bg-gray-600'></div> */}
            {userData?.exercises?.wexs?.beginner?.map(c => (
              <Table.Row>
                <Table.Cell>{c?.wex}</Table.Cell>
                <Table.Cell>{langContent.beginner}</Table.Cell>
                <Table.Cell>
                  {c?.message ? (
                    <p>{c?.message}</p>
                  ) : (
                    <p>{c?.score?.toFixed(0)}%</p>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {!c?.message && (
                    <p>
                      {parseInt(c?.duration?.hours)} :{' '}
                      {parseInt(c?.duration?.minutes)} :{' '}
                      {parseInt(c?.duration?.seconds)}
                    </p>
                  )}
                </Table.Cell>
                <Table.Cell>{c?.hint}</Table.Cell>
              </Table.Row>
            ))}
            {userData?.exercises?.wexs?.intermidate?.map(c => (
              <Table.Row>
                <Table.Cell>{c?.wex}</Table.Cell>
                <Table.Cell>{langContent.intermediate}</Table.Cell>
                <Table.Cell>
                  {c?.message ? (
                    <p>{c?.message}</p>
                  ) : (
                    <p>{c?.score?.toFixed(0)}%</p>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {!c?.message && (
                    <p>
                      {parseInt(c?.duration?.hours)} :{' '}
                      {parseInt(c?.duration?.minutes)} :{' '}
                      {parseInt(c?.duration?.seconds)}
                    </p>
                  )}
                </Table.Cell>
                <Table.Cell>{c?.hint}</Table.Cell>
              </Table.Row>
            ))}
            {userData?.exercises?.wexs?.advanced?.map(c => (
              <Table.Row>
                <Table.Cell>{c?.wex}</Table.Cell>
                <Table.Cell>{langContent.advanced}</Table.Cell>
                <Table.Cell>
                  {c?.message ? (
                    <p>{c?.message}</p>
                  ) : (
                    <p>{c?.score?.toFixed(0)}%</p>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {!c?.message && (
                    <p>
                      {parseInt(c?.duration?.hours)} :{' '}
                      {parseInt(c?.duration?.minutes)} :{' '}
                      {parseInt(c?.duration?.seconds)}
                    </p>
                  )}
                </Table.Cell>
                <Table.Cell>{c?.hint}</Table.Cell>
              </Table.Row>
            ))}
            {userData?.exercises?.tests?.beginner?.map(c => (
              <Table.Row>
                <Table.Cell>{c?.test}</Table.Cell>
                <Table.Cell>{langContent.beginner}</Table.Cell>
                <Table.Cell>
                  {c?.message ? (
                    <p>{c?.message}</p>
                  ) : (
                    <p>{c?.score?.toFixed(0)}%</p>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {!c?.message && (
                    <p>
                      {parseInt(c?.duration?.hours)} :{' '}
                      {parseInt(c?.duration?.minutes)} :{' '}
                      {parseInt(c?.duration?.seconds)}
                    </p>
                  )}
                </Table.Cell>
                <Table.Cell>{c?.hint}</Table.Cell>
              </Table.Row>
            ))}
            {userData?.exercises?.tests?.intermidate?.map(c => (
              <Table.Row>
                <Table.Cell>{c?.test}</Table.Cell>
                <Table.Cell>{langContent.intermediate}</Table.Cell>
                <Table.Cell>
                  {c?.message ? (
                    <p>{c?.message}</p>
                  ) : (
                    <p>{c?.score?.toFixed(0)}%</p>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {!c?.message && (
                    <p>
                      {parseInt(c?.duration?.hours)} :{' '}
                      {parseInt(c?.duration?.minutes)} :{' '}
                      {parseInt(c?.duration?.seconds)}
                    </p>
                  )}
                </Table.Cell>
                <Table.Cell>{c?.hint}</Table.Cell>
              </Table.Row>
            ))}
            {userData?.exercises?.tests?.advanced?.map(c => (
              <Table.Row>
                <Table.Cell>{c?.test}</Table.Cell>
                <Table.Cell>{langContent.advanced}</Table.Cell>
                <Table.Cell>
                  {c?.message ? (
                    <p>{c?.message}</p>
                  ) : (
                    <p>{c?.score?.toFixed(0)}%</p>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {!c?.message && (
                    <p>
                      {parseInt(c?.duration?.hours)} :{' '}
                      {parseInt(c?.duration?.minutes)} :{' '}
                      {parseInt(c?.duration?.seconds)}
                    </p>
                  )}
                </Table.Cell>
                <Table.Cell>{c?.hint}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    </div>
  )
}

export default TeacherStudentProgress
