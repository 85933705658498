import React from 'react'
import {Formik} from 'formik'
import {Link, useHistory} from 'react-router-dom'
import {Button, Container, Message, Form} from 'semantic-ui-react'
import FormikControl from '../formik/FormikControl'
import {useLanguage} from '../../context/languageContext'
import {content} from '../../localization/content'
import routes from '../../routes'
import * as Yup from 'yup'
import useAsync from '../../hooks/useAsync'

import {axios} from '../../config/axiosConfig'
import Auth from '../../config/auth'
import useLocalStorage from '../../hooks/use-local-storage'
import {useState} from 'react'
import {useToasts} from 'react-toast-notifications'
import {useUser} from '../../context/user-context'
import {formatResponseError} from '../../utils/format-response'

const StudentLoginNonVoucher = () => {
  const [lang] = useLanguage()
  const langContent = content[lang]

  const [showVerifyBtn, setShowVerifyBtn] = React.useState(false)
  const [studentData, setStudentData] = React.useState(false)

  const history = useHistory()
  const {setUser} = useUser()
  const {addToast} = useToasts()

  const {run, isLoading, isError, error} = useAsync()
  const {
    run: verifyEmail,
    isLoading: verifingEmail,
    isError: isErrorVerifyEmail,
    error: errorVerifyEmail,
  } = useAsync()

  const loginSchema = Yup.object({
    email: Yup.string()
      .email('Enter a valid Mail')
      .required(langContent.required),
    password: Yup.string().required(langContent.required),
  })


  React.useEffect(() => {
    console.log(error)
    if (
      error?.message &&
      error?.message === 'please approve your account first'
    ) {
      console.log("FIRED")
      setShowVerifyBtn(true)
    }
  }, [error, error?.message])


  const handleOnSubmit = values => {
    setStudentData(values)
    run(axios.post('/user/signin', values)).then(({data}) => {
      console.log(data)
      Auth.setToken(data.token)

      setUser({
        name: data.user.name,
        email: data.user.email,
        _id: data.user.id,
        isStudent: true,
        haveVoucher: false,
      })
      addToast(langContent.Successfully_loged_in, {appearance: 'success'})

      history.push(routes.home)
    })
    console.log(values)
  }

  const onVerifyEmail = () => {
    console.log('verify')
    verifyEmail(axios.post('/user/verify', {email: studentData?.email})).then(
      ({data}) => {
        console.log(data)
        addToast(data?.message, {appearance: 'success'})
        setShowVerifyBtn(false)
      },
    )
  }

  return (
    <div>
      {isError ? <Message error list={formatResponseError(error)} /> : null}
      {isErrorVerifyEmail ? (
        <Message error list={formatResponseError(errorVerifyEmail)} />
      ) : null}

      {showVerifyBtn && (
        <div className="my-3">
          <Button
            className="w-full"
            onClick={() => onVerifyEmail()}
            loading={verifingEmail}
            basic
            color="blue"
          >
            {langContent.Resend_verification_email}
          </Button>
        </div>
      )}
      <Formik
        initialValues={{email: '', password: ''}}
        validationSchema={loginSchema}
        onSubmit={handleOnSubmit}
      >
        {formik => (
          <Form loading={isLoading} onSubmit={formik.submitForm}>
            <Form.Field required>
              <FormikControl
                control="input"
                label={langContent.Email_Address}
                name="email"
              />
            </Form.Field>

            <Form.Field required>
              <FormikControl
                control="input"
                label={langContent.Password}
                type="password"
                name="password"
              />
            </Form.Field>

            {/* <div className="flex justify-between">
              <Link
                to={routes.forgotPassword}
                className="text-red-600 font-semibold"
              >
                {langContent.forgotPassword}
              </Link>
            </div> */}

            <div className="text-center">
              <Button
                fluid
                content={langContent.Login_sign}
                type="submit"
                className="bg-primary rounded-full px-28 mt-10 text-white font-bold"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default StudentLoginNonVoucher
