import React, {useState} from 'react'
import useLocalStorage from '../../hooks/use-local-storage'
import useStopwatch from '../../hooks/use-stopwatch'

const StopWatchTimer = ({attemptId, questionId, setTimeData}) => {
  const [questAttemptId, setQuestAttemptId] = useLocalStorage(
    'questAttemptId',
    '',
  )
  const [questId, setQuestId] = useLocalStorage('questId', '')

  // const [questionKey, setQuestionKey] = useState('');
  // const [stopWatchTime, setStopWatchTime] = useLocalStorage(
  //   'stopWatchTime',
  //   {
  //     seconds: 0,
  //     minutes: 0,
  //     hours: 0,
  //   },
  //   JSON.stringify,
  //   JSON.parse,
  // )
  //   const {seconds , minutes, hours, days, isRunning, start, pause, reset} =  useStopwatch({autoStart: false})
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    stop,
    reset,
    pause,
    Resume,
  } = useStopwatch({key: `questionId-${questId}-attemptId-${questAttemptId}`, attemptId:attemptId})

  React.useEffect(() => {
    // setTimeData({seconds, minutes, hours, days})

    if (questionId && attemptId) {
      if (attemptId !== questAttemptId) {
        setQuestAttemptId(attemptId)
      }
      if (questionId !== questId) {
        setQuestId(questionId)
      }

      for (let key in window.localStorage) {
        if (key === `questionId-${questionId}-attemptId-${attemptId}`) continue
        else if (key.startsWith('questionId-'))
          window.localStorage.removeItem(key)
      }
    }
  }, [
    days,
    attemptId,
    hours,
    minutes,
    questionId,
    seconds,
    setTimeData,
    questAttemptId,
    questId,
    setQuestAttemptId,
    setQuestId,
  ])

  //   let stopwatchOffset = new Date()
  //   stopwatchOffset.setSeconds(
  //     stopwatchOffset.getSeconds() + stopWatchTime?.seconds,
  //   )

  //   stopwatchOffset.setMinutes(stopwatchOffset.getMinutes() + stopWatchTime?.minutes);
  //   stopwatchOffset.setHours(stopwatchOffset.getHours() + stopWatchTime?.hours);

  //   React.useEffect(() => {

  //     // if (isRunning) {
  //     //   setStopWatchTime({
  //     //     seconds: seconds,
  //     //     minutes: minutes,
  //     //     hours: hours,
  //     //   })

  //     //    console.log(stopWatchTime);
  //     }
  //   }, [hours, isRunning, minutes, seconds, setStopWatchTime ])

  return (
    <div>
      <div className='hidden'>
        Time: <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
      </div>
      {/* <p>{isRunning ? 'Running' : 'Not running'}</p>
      <button onClick={start}>Start</button>
      <button className="mx-5" onClick={stop}>
        stop
      </button>
      <button onClick={reset}>Reset</button>
      <button className="mx-5" onClick={pause}>
        pause
      </button>
      <button onClick={Resume}>Resume</button> */}
    </div>
  )
}

export default StopWatchTimer
