import React, {useState} from 'react'
import {
  Dimmer,
  Dropdown,
  Loader,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react'
import queryString from 'query-string'
import {useHistory, useLocation} from 'react-router-dom'

import useAsync from '../../../hooks/useAsync'
import {authAxios} from '../../../config/axiosConfig'
import {useUser} from '../../../context/user-context'
import {formatResponseError} from '../../../utils/format-response'
import {useLanguage} from '../../../context/languageContext'
import {content} from '../../../localization/content'

const NonVoucherStudentExercise = () => {
  const location = useLocation()
  const history = useHistory()
  const {getUser} = useUser()

  const [studentInfoDetails, setStudentInfoDetails] = React.useState([])
  const [userData, setUserData] = useState({})
  const [subjects, setSubjects] = React.useState([])

  // const {subjects, errorGettingSubjects, gettingSubjects} = useSubject()

  const [currentChapter, setCurrentChapter] = React.useState()
  const [currentSubject, setCurrentSubject] = React.useState()

  const [chapters, setChapters] = React.useState([])

  const [lang] = useLanguage()
  const langContent = content[lang]

  const {
    run: getChapters,
    error: errorGettingChapters,
    isLoading: gettingChapters,
  } = useAsync()

  const {
    run: getExerciseInfo,
    error: errorGettingExerciseInfo,
    isLoading: gettingExerciseInfo,
  } = useAsync()

  const {
    run: getSubjects,
    error: errorGettingSubjects,
    isLoading: gettingSubjects,
  } = useAsync()

  React.useEffect(() => {
    const {currentChapterid, currentSubjectid} = queryString.parse(
      location.search,
    )
    setCurrentSubject(currentSubjectid)

    if (currentChapterid && currentChapterid !== 'undefined') {
      setCurrentChapter(currentChapterid)
    }

    if (
      currentChapterid &&
      currentSubjectid &&
      userData?._id &&
      currentChapterid !== 'undefined' &&
      currentSubjectid !== 'undefined'
    ) {
      getExerciseInfo(
        authAxios.get(
          `/user/chapter/exercise/info/?subject=${currentSubjectid}&chapter=${currentChapterid}`,
        ),
      ).then(({data}) => {
        console.log(data)
        setStudentInfoDetails(data)
      })
    }
  }, [getExerciseInfo, location.pathname, location.search, userData?._id])

  const getChaptersOptions = subjectid => {
    setStudentInfoDetails([])

    getChapters(authAxios.get(`/user/chapter/all?subject=${subjectid}`)).then(
      ({data}) => {
        let options = []
        data?.forEach(s =>
          options.push({text: s.chapterName, key: s._id, value: s._id}),
        )
        setChapters(options)
        setCurrentChapter(options[0]?.value)
        history.replace(
          `${location.pathname}?currentChapterid=${options[0]?.value}&currentSubjectid=${subjectid}`,
        )
      },
    )
  }

  React.useEffect(() => {
    getSubjects(authAxios.get('/school/subject/all')).then(({data}) => {
      let options = []
      data?.subjects.forEach(s =>
        options.push({text: s.name, key: s._id, value: s._id}),
      )
      setSubjects(options)
      setCurrentSubject(options[0]?.value)
      getChaptersOptions(options[0]?.value)
      history.replace(
        `${location.pathname}?currentSubjectid=${options[0]?.value}`,
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // React.useEffect(() => {
  //   // const {currentSubjectid ,currentChapterid} = queryString.parse(location.search)
  //   if(currentSubject && currentChapter && !chapters.length > 0 ){
  //     // console.log('fired')
  //     getChaptersOptions(currentSubject)
  //   }
  //   console.log({currentSubject})
  //   console.log({currentChapter})
  //   console.log(chapters)

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentSubject , currentChapter])

  // React.useEffect(() => {
  //   // const {currentSubjectid ,currentChapterid} = queryString.parse(location.search)
  //   if(currentSubject && !currentChapter ){
  //     getChaptersOptions(currentSubject)
  //   }

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentSubject , currentChapter])

  React.useEffect(() => {
    if (!getUser()) return

    setUserData(getUser())
    // return () => setUserData(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUser()?.email, userData?.email])

  const onSubjectChange = (e, {value}) => {
    getChaptersOptions(value)

    setStudentInfoDetails([])
    setCurrentSubject(value)

    history.replace(`${location.pathname}?currentSubjectid=${value}`)
  }

  const onChapterChange = (e, {value}) => {
    setCurrentChapter(value)
    history.replace(
      `${location.pathname}?currentChapterid=${value}&currentSubjectid=${currentSubject}`,
    )
  }

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent flex flex-col">
        {errorGettingChapters ? (
          <Message error list={formatResponseError(errorGettingChapters)} />
        ) : null}
        {errorGettingSubjects ? (
          <Message error list={formatResponseError(errorGettingSubjects)} />
        ) : null}
        {errorGettingExerciseInfo ? (
          <Message error list={formatResponseError(errorGettingExerciseInfo)} />
        ) : null}
        <Dimmer
          active={gettingSubjects || gettingExerciseInfo || gettingChapters}
          inverted
        >
          <Loader active={true} />
        </Dimmer>

        <div className="flex">
          <Dropdown
            placeholder="Select a Class"
            search
            selection
            value={currentSubject}
            loading={gettingSubjects}
            options={subjects}
            onChange={onSubjectChange}
            className="drop-shadow-md shadow-md ml-auto"
          />
          <Dropdown
            placeholder="Select a chapter"
            search
            selection
            value={currentChapter}
            loading={gettingChapters}
            options={chapters}
            onChange={onChapterChange}
            disabled={gettingChapters || !currentSubject}
            className=" drop-shadow-md shadow-md  mx-2"
          />
        </div>

        <div className="bg-transparent shadow-none border-none">
          <div className="bg-gray-100 p-4 rounded-md">
            <div className="flex">
              <Table.Cell
                selectable={false}
                className="border solid border-gray-300 bg-primaryLight rounded-md mr-2 flex-1 shadow-none p-2"
              >
                <div>
                  <p className="text-center text-primary font-semibold">
                    {langContent.PEXs_completed}
                  </p>

                  <Table
                    celled
                    basic="very"
                    collapsing
                    size="small"
                    unstackable
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={2}>
                          {langContent.Name}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                          {langContent.Score}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                          {langContent.Duration}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                          {langContent.Hints}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {studentInfoDetails?.pexs?.map(c => (
                        <Table.Row>
                          <Table.Cell>{c?.pex}</Table.Cell>
                          <Table.Cell>
                            {c?.message ? (
                              <p>{c?.message}</p>
                            ) : (
                              <p>{c?.score?.toFixed(0)}%</p>
                            )}
                          </Table.Cell>

                          <Table.Cell>
                            {!c?.message && (
                              <p>
                                {parseInt(c?.duration?.hours)} :{' '}
                                {parseInt(c?.duration?.minutes)} :{' '}
                                {parseInt(c?.duration?.seconds)}
                              </p>
                            )}
                          </Table.Cell>
                          <Table.Cell>{c?.hint}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              </Table.Cell>

              {/* <div class="border-l-2 m-2 h-40 border-gray-300 p-0 w-5 place-self-center "/> */}

              <Table.Cell
                selectable={false}
                className="border solid border-gray-300 bg-primaryLight rounded-md  flex-1 shadow-none p-2"
              >
                <p className="text-center text-primary font-semibold">
                  {langContent.WEX_completed}
                </p>
                <Table celled basic="very" size="small" unstackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={2}>
                        {langContent.Name}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}>
                        {langContent.Level}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}>
                        {langContent.Score}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}>
                        {langContent.Duration}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}>
                        {langContent.Hints}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {studentInfoDetails?.wexs?.beginner?.map(c => (
                      <Table.Row>
                        <Table.Cell>{c?.wex}</Table.Cell>
                        <Table.Cell>{langContent.beginner}</Table.Cell>
                        <Table.Cell>
                          {c?.message ? (
                            <p>{c?.message}</p>
                          ) : (
                            <p>{c?.score?.toFixed(0)}%</p>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {!c?.message && (
                            <p>
                              {parseInt(c?.duration?.hours)} :{' '}
                              {parseInt(c?.duration?.minutes)} :{' '}
                              {parseInt(c?.duration?.seconds)}
                            </p>
                          )}
                        </Table.Cell>
                        <Table.Cell>{c?.hint}</Table.Cell>
                      </Table.Row>
                    ))}
                    {studentInfoDetails?.wexs?.intermidate?.map(c => (
                      <Table.Row>
                        <Table.Cell>{c?.wex}</Table.Cell>
                        <Table.Cell>{langContent.intermediate}</Table.Cell>
                        <Table.Cell>
                          {c?.message ? (
                            <p>{c?.message}</p>
                          ) : (
                            <p>{c?.score?.toFixed(0)}%</p>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {!c?.message && (
                            <p>
                              {parseInt(c?.duration?.hours)} :{' '}
                              {parseInt(c?.duration?.minutes)} :{' '}
                              {parseInt(c?.duration?.seconds)}
                            </p>
                          )}
                        </Table.Cell>
                        <Table.Cell>{c?.hint}</Table.Cell>
                      </Table.Row>
                    ))}
                    {studentInfoDetails?.wexs?.advanced?.map(c => (
                      <Table.Row>
                        <Table.Cell>{c?.wex}</Table.Cell>
                        <Table.Cell>{langContent.advanced}</Table.Cell>
                        <Table.Cell>
                          {c?.message ? (
                            <p>{c?.message}</p>
                          ) : (
                            <p>{c?.score?.toFixed(0)}%</p>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {!c?.message && (
                            <p>
                              {parseInt(c?.duration?.hours)} :{' '}
                              {parseInt(c?.duration?.minutes)} :{' '}
                              {parseInt(c?.duration?.seconds)}
                            </p>
                          )}
                        </Table.Cell>
                        <Table.Cell>{c?.hint}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Table.Cell>

              <Table.Cell
                selectable={false}
                className="border solid border-gray-300 bg-primaryLight rounded-md ml-2 flex-1 shadow-none p-2"
              >
                <p className="text-center text-primary font-semibold">
                  {langContent.Tests_completed}
                </p>
                <Table celled basic="very" size="small" unstackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={2}>
                        {langContent.Name}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}>
                        {langContent.Level}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}>
                        {langContent.Score}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}>
                        {langContent.Duration}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}>
                        {langContent.Hints}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {studentInfoDetails?.tests?.beginner?.map(c => (
                      <Table.Row>
                        <Table.Cell>{c?.test}</Table.Cell>
                        <Table.Cell>{langContent.beginner}</Table.Cell>
                        <Table.Cell>
                          {c?.message ? (
                            <p>{c?.message}</p>
                          ) : (
                            <p>{c?.score?.toFixed(0)}%</p>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {!c?.message && (
                            <p>
                              {parseInt(c?.duration?.hours)} :{' '}
                              {parseInt(c?.duration?.minutes)} :{' '}
                              {parseInt(c?.duration?.seconds)}
                            </p>
                          )}
                        </Table.Cell>
                        <Table.Cell>{c?.hint}</Table.Cell>
                      </Table.Row>
                    ))}
                    {studentInfoDetails?.tests?.intermidate?.map(c => (
                      <Table.Row>
                        <Table.Cell>{c?.test}</Table.Cell>
                        <Table.Cell>{langContent.intermediate}</Table.Cell>
                        <Table.Cell>
                          {c?.message ? (
                            <p>{c?.message}</p>
                          ) : (
                            <p>{c?.score?.toFixed(0)}%</p>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {!c?.message && (
                            <p>
                              {parseInt(c?.duration?.hours)} :{' '}
                              {parseInt(c?.duration?.minutes)} :{' '}
                              {parseInt(c?.duration?.seconds)}
                            </p>
                          )}
                        </Table.Cell>
                        <Table.Cell>{c?.hint}</Table.Cell>
                      </Table.Row>
                    ))}
                    {studentInfoDetails?.tests?.advanced?.map(c => (
                      <Table.Row>
                        <Table.Cell>{c?.test}</Table.Cell>
                        <Table.Cell>{langContent.advanced}</Table.Cell>
                        <Table.Cell>
                          {c?.message ? (
                            <p>{c?.message}</p>
                          ) : (
                            <p>{c?.score?.toFixed(0)}%</p>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {!c?.message && (
                            <p>
                              {parseInt(c?.duration?.hours)} :{' '}
                              {parseInt(c?.duration?.minutes)} :{' '}
                              {parseInt(c?.duration?.seconds)}
                            </p>
                          )}
                        </Table.Cell>
                        <Table.Cell>{c?.hint}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Table.Cell>
            </div>
          </div>
        </div>
      </Segment>
    </div>
  )
}

export default NonVoucherStudentExercise
