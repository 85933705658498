import {Route, Redirect} from 'react-router-dom'
import Auth from '../../config/auth'
import {useUser} from '../../context/user-context'
import routes from '../../routes'

export default function StudentNonVoucherRoute({component: Component, ...rest}) {
  const {getUser} = useUser()
  return (
    <Route
      {...rest}
      render={props =>
        Auth.isAuth() && getUser()?.isStudent && !getUser()?.haveVoucher ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: routes.home,
              state: {from: props.location},
            }}
          />
        )
      }
    />
  )
}
