import React, {useState} from 'react'
import {Switch, BrowserRouter as Router, Route} from 'react-router-dom'
import PrivateRoute from './components/shared/pirvate-route'
import StateContext from './context/stateContext'

import AuthLayout from './layouts/auth-layout'
import DashboardLayout from './layouts/dashboard-layout'
import ErrorComp from './pages/error/error'
import HomePage from './pages/landing-page/home-page'
import routes from './routes'

const App = () => {
  const [showModal, setShowModal] = useState({modalName: [], data: null})

  return (
    <StateContext.Provider
      value={{
        showModal,
        setShowModal,
      }}
    >
      <Router>
        <Switch>
          <Route exact path={routes.home} component={HomePage} />
          <Route path="/auth" component={AuthLayout} />

          <PrivateRoute path="/dashboard" component={DashboardLayout} />

          <Route path="*" component={ErrorComp} />
        </Switch>
      </Router>
    </StateContext.Provider>
  )
}

export default App
