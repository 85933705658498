import {Menu} from 'semantic-ui-react'

const NonCoucherStudentRoomTabs = ({activeMenu, setActiveMenu}) => {
  return (
    <div class="flex">
      <Menu
        className="mt-2 border-none shadow-none  bg-transparent text-xl  m-2"
      >
        <>
          <Menu.Item
            active={activeMenu === 'studentChapters'}
            onClick={() => setActiveMenu('studentChapters')}
            className={`${
              activeMenu === 'studentChapters'
                ? 'border-b-4 border-primary text-primary bg-transparent'
                : 'bg-gray-50'
            }`}
          >
            <div className="flex flex-col w-full ">
              <p className="font-medium text-labelColor">Student Chapters</p>
            </div>
          </Menu.Item>
          <Menu.Item
            active={activeMenu === 'studentExercises'}
            onClick={() => setActiveMenu('studentExercises')}
            className={` ${
              activeMenu === 'studentExercises'
                ? 'border-b-4 border-primary text-primary bg-transparent'
                : 'bg-gray-50'
            }`}
          >
            <div className="flex flex-col w-full ">
              <p className="font-medium text-labelColor">Student Exercises</p>
            </div>
          </Menu.Item>
        </>
      </Menu>
    </div>
  )
}

export default NonCoucherStudentRoomTabs
